import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { StockLocation, StockLocationFormValues } from "../models/location";
import { TableStore } from "./tableStore";

export default class StockLocationStore extends TableStore<StockLocation> {
  stockLocationRegistry = new Map<string, StockLocation>();
  stockLocationDropdown: StockLocation[] = [];

  constructor() {
    super();
    makeObservable(this, {
      stockLocationRegistry: observable,
      stockLocationDropdown: observable,
      loadStockLocations: action,
      loadStockLocation: action,
      loadDropDown: action,
      stockLocationList: computed,
      createStockLocation: action,
      updateStockLocation: action,
    });

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadStockLocations();
      }
    );
  }

  loadStockLocations = async () => {
    this.loading = true;

    try {
      const result = await agent.Location.list(this.axiosParams);
      runInAction(() => {
        this.stockLocationRegistry.clear();
        result.forEach((location) => {
          this.stockLocationRegistry.set(location.id, location);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  loadStockLocation = async (id: string) => {
    this.loading = true;

    try {
      const result = await agent.Location.details(id);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  loadDropDown = async () => {
    try {
      const dropdown = await agent.Location.dropdown();
      runInAction(() => {
        this.stockLocationDropdown = dropdown;
      });
    } catch (error) {
      console.log(error);
    }
  };

  get stockLocationList() {
    return Array.from(this.stockLocationRegistry);
  }

  createStockLocation = async (location: StockLocationFormValues) => {
    try {
      await agent.Location.create(location).then(() => {
        runInAction(() => {
          let newLocation = new StockLocation(location);
          this.stockLocationRegistry.set(newLocation.id, newLocation);
        });
      });
      return "Create stock location success!";
    } catch (error) {
      throw error;
    }
  };

  updateStockLocation = async (location: StockLocationFormValues) => {
    try {
      await agent.Location.update(location).then(() => {
        runInAction(() => {
          if (location.id) {
            this.stockLocationRegistry.set(
              location.id,
              location as StockLocation
            );
          }
        });
      });
      return "Stock location updated!";
    } catch (error) {
      throw error;
    }
  };
}
