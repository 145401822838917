import {
  Grid,
  Button,
  Typography,
  Divider,
  Chip,
  LinearProgress,
  Hidden,
  Fade,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import Filter from "../../../app/common/filter/Filter";
import DateInputField from "../../../app/common/form/DateInputFIeld";
import Dropdown2 from "../../../app/common/form/Dropdown2";
import ButtonPrint from "../../../app/common/print/ButtonPrint";
import ComponentToPrint from "../../../app/common/print/ComponentToPrint";
import { useStyles } from "../../../app/layout/style";
import Title from "../../../app/layout/Title";
import { ProfitAndLossSearch } from "../../../app/models/report";
import { AvatarName } from "../../../app/models/table";
import { useStore } from "../../../app/stores/store";
import ReportProfitAndLossCard from "./ReportProfitAndLossCard";
import ReportProfitAndLossPerStore from "./ReportProfitAndLossPerStore";
import ReportProfitAndLossTable from "./ReportProfitAndLossTable";
import ReportProfitAndLossTotalAllStore from "./ReportProfitAndLossTotalAllStore";

export default observer(function ReportProfitAndLoss() {
  const classes = useStyles();
  const { userStore, profitAndLossStore } = useStore();
  const { storeGranted } = userStore;
  const {
    setPredicate,
    loadReportProfitAndLoss,
    storeList,
    profitRegistry,
    lossRegistry,
    spendingRegistry,
    creditRegistry,
    loading,
    filter,
  } = profitAndLossStore;
  const [search, setSearch] = useState(new ProfitAndLossSearch());
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState<"monthly" | "yearly">("monthly");
  let componentRef = useRef(null);

  useEffect(() => {
    loadReportProfitAndLoss();
  }, [loadReportProfitAndLoss]);

  const handleSetSearch = () => {
    setSearch(filter);
  };

  const handleSearchClick = (values: ProfitAndLossSearch) => {
    setPredicate(values);
  };

  return (
    <>
      <Title menuName="Report Profit And Loss">
        <>
          <ButtonPrint
            componentToPrint={componentRef}
            disabled={loading || profitRegistry.size === 0 || tab === "yearly"}
          />
          <Filter open={open} setOpen={setOpen} setSearch={handleSetSearch}>
            <div className={classes.filter}>
              <Formik
                enableReinitialize
                initialValues={search}
                onSubmit={(values) => handleSearchClick(values)}
              >
                {({ handleSubmit, values }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-end"
                      spacing={1}
                    >
                      <Grid item>
                        <Dropdown2
                          name="storeId"
                          options={storeGranted.map((x) => {
                            return { text: x.name, value: x.id };
                          })}
                          label="Store"
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          titleDefaultSelected="All Selected"
                          displayDefaultSelected
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="Start Date"
                          name="startDate"
                          maxDate={values.endDate}
                          placeholder="Please input Start Date"
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="End Date"
                          name="endDate"
                          minDate={values.startDate}
                          placeholder="Please input End Date"
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </Filter>
        </>
      </Title>
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button
            size="small"
            color={tab === "monthly" ? "primary" : "default"}
            variant={tab === "monthly" ? "contained" : "text"}
            onClick={() => {
              setTab("monthly");
              console.log(tab);
            }}
          >
            Monthly
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            color={tab === "yearly" ? "primary" : "default"}
            variant={tab === "yearly" ? "contained" : "text"}
            onClick={() => {
              setTab("yearly");
              console.log(tab);
            }}
          >
            Yearly
          </Button>
        </Grid>
      </Grid>
      <Fade in={tab === "monthly"}>
        <div style={{ display: tab === "monthly" ? "inherit" : "none" }}>
          <ReportProfitAndLossTotalAllStore />
          <Grid container spacing={1}>
            {profitRegistry.size > 0 &&
              storeList.map((store) => {
                const avatarName = new AvatarName(store.name);

                return (
                  <div className={classes.paper} key={store.id}>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                          <Chip
                            variant="outlined"
                            color="secondary"
                            label={avatarName.getAvatarName}
                          />
                        </Grid>
                        <Grid>
                          <Typography variant="subtitle1" component="p">
                            {store.name}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            gutterBottom
                            component="p"
                          >
                            {store.city}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />
                    {loading ? (
                      <LinearProgress />
                    ) : (
                      <>
                        <ReportProfitAndLossPerStore
                          profit={profitRegistry.get(store)}
                          loss={lossRegistry.get(store)}
                          spending={spendingRegistry.get(store)}
                          credit={creditRegistry.get(store)}
                        />
                        <Hidden lgUp implementation="css">
                          <ReportProfitAndLossCard
                            profit={profitRegistry.get(store)}
                            loss={lossRegistry.get(store)}
                            spending={spendingRegistry.get(store)}
                            credit={creditRegistry.get(store)}
                          />
                        </Hidden>
                        <Hidden mdDown implementation="css">
                          <ReportProfitAndLossTable
                            profit={profitRegistry.get(store)}
                            loss={lossRegistry.get(store)}
                            spending={spendingRegistry.get(store)}
                            credit={creditRegistry.get(store)}
                          />
                        </Hidden>
                      </>
                    )}
                  </div>
                );
              })}
          </Grid>
          <ComponentToPrint
            title="Report Profit And Loss"
            componentToPrint={componentRef}
          >
            <>
              <ReportProfitAndLossTotalAllStore />
              {profitRegistry.size > 0 &&
                storeList.map((store) => {
                  const avatarName = new AvatarName(store.name);

                  return (
                    <div className={classes.paper} key={store.id}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                              <Chip
                                variant="outlined"
                                color="secondary"
                                label={avatarName.getAvatarName}
                              />
                            </Grid>
                            <Grid>
                              <Typography variant="subtitle1" component="p">
                                {store.name}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                color="textSecondary"
                                gutterBottom
                                component="p"
                              >
                                {store.city}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider />
                      <ReportProfitAndLossPerStore
                        profit={profitRegistry.get(store)}
                        loss={lossRegistry.get(store)}
                        spending={spendingRegistry.get(store)}
                        credit={creditRegistry.get(store)}
                      />
                      <ReportProfitAndLossTable
                        profit={profitRegistry.get(store)}
                        loss={lossRegistry.get(store)}
                        spending={spendingRegistry.get(store)}
                        credit={creditRegistry.get(store)}
                      />
                    </div>
                  );
                })}
            </>
          </ComponentToPrint>
        </div>
      </Fade>
      <Fade in={tab === "yearly"}>
        <div style={{ display: tab === "yearly" ? "inherit" : "none" }}>
          Coming Soon
        </div>
      </Fade>
    </>
  );
});
