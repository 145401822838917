import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { Store } from "../models/location";
import { Credit, CreditSearch } from "../models/report";
import { store } from "./store";
import { TableStore } from "./tableStore";

export default class CreditStore extends TableStore<Credit> {
  creditRegistry = new Map<Store, Credit[]>();

  constructor() {
    super();

    makeObservable(this, {
      creditRegistry: observable,
      loadReportCredit: action,
      creditList: computed,
      totalCredit: action,
    });

    this.setPredicate(new CreditSearch());

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadReportCredit();
      }
    );
  }

  setPredicate = (search: CreditSearch) => {
    runInAction(() => {
      Object.keys(search).forEach((key) => {
        this.predicate.delete(key as keyof Credit);
      });
      this.predicate.set(
        "storeId",
        search.storeId === "All" ? "" : search.storeId
      );
      this.predicate.set(
        "startDate",
        search.startDate.toLocaleDateString("EN-US")
      );
      this.predicate.set("endDate", search.endDate.toLocaleDateString("EN-US"));
      this.predicate.set("customerName", search.customerName);
      this.predicate.set("documentNo", search.documentNo);
    });
  };

  get filter() {
    return new CreditSearch({
      storeId:
        this.predicate.get("storeId") === undefined ||
        this.predicate.get("storeId") === ""
          ? "All"
          : this.predicate.get("storeId")!,
      startDate:
        this.predicate.get("startDate") === undefined
          ? new Date()
          : new Date(this.predicate.get("startDate")!),
      endDate:
        this.predicate.get("endDate") === undefined
          ? new Date()
          : new Date(this.predicate.get("endDate")!),
      customerName:
        this.predicate.get("customerName") === undefined
          ? ""
          : this.predicate.get("customerName")!,
      documentNo:
        this.predicate.get("documentNo") === undefined
          ? ""
          : this.predicate.get("documentNo")!,
    });
  }

  loadReportCredit = async () => {
    this.loading = true;
    this.creditRegistry.clear();

    try {
      let locations: Store[] = [];
      const storeId = this.predicate.get("storeId");

      if (storeId === "") locations = store.userStore.storeGranted;
      else
        locations = store.userStore.storeGranted.filter(
          (x) => x.id === storeId
        );

      for await (const location of locations) {
        const result = await agent.Reports.reportCredit(
          this.storeParams(location)
        );
        runInAction(() => {
          this.creditRegistry.set(location, result);
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get creditList() {
    return Array.from(this.creditRegistry.keys());
  }

  totalCredit = (credit: Credit[]) => {
    return credit.reduce(
      (total, currentData) => (total = total + currentData.amount),
      0
    );
  };
}
