import {
  IconButton,
  InputAdornment,
  TextField,
  BaseTextFieldProps,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { useField } from "formik";
import { useRef } from "react";

interface Props extends BaseTextFieldProps {
  name: string;
}

export default function SearchFilter(props: Props) {
  const [field, meta, helpers] = useField(props.name);
  const ref = useRef<any>(null);

  return (
    <TextField
      {...field}
      {...props}
      inputRef={ref}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error ? meta.error : props.helperText}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size="small"
              onClick={(e) => {
                helpers.setValue("");
                ref.current.focus();
              }}
              onMouseDown={(e) => e.preventDefault()}
            >
              {field.value && <ClearIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
