import {Typography} from "@material-ui/core";

export default function Copyright() {
  return (
    <Typography variant="body1">
      {"Copyright © PANDE GROUP "} {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
