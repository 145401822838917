import { Grid, Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { usePosStyles } from "../../app/layout/pos";
import SaveIcon from "@material-ui/icons/Save";
import PaymentIcon from "@material-ui/icons/Payment";

interface Props {
  loading: boolean;
  handleFormPay: (submitForm: () => void) => void;
  submitForm: () => void;
}

export default observer(function POSButton(props: Props) {
  const classes = usePosStyles();
  const { loading, handleFormPay, submitForm } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xl={12} xs={6} >
        <Button
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          className={classes.save}
          disabled={loading}
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
      </Grid>
      <Grid item xl={12} xs={6}>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.pay}
          disabled={loading}
          onClick={() => handleFormPay(submitForm)}
          startIcon={<PaymentIcon />}
        >
          Pay
        </Button>
      </Grid>
    </Grid>
  );
});
