import { makeAutoObservable } from "mobx";
import { DropDownProps } from "../models/dropDownProps";

export default class TransferListStore {
  itemsChecked: DropDownProps[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setItemsChecked = (items: DropDownProps[]) => {
    this.itemsChecked = items;
  };

  not(a: DropDownProps[], b: DropDownProps[]) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  intersection(a: DropDownProps[], b: DropDownProps[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  union(a: DropDownProps[], b: DropDownProps[]) {
    return a.length === 0 ? b : [];
  }
}
