import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { Store } from "../models/location";
import { InvoiceSummarySearch, SalesSummary } from "../models/report";
import { HeadCell } from "../models/table";
import { store } from "./store";
import { TableStore } from "./tableStore";

export default class SummaryStore extends TableStore<SalesSummary> {
  salesSummaryRegistry = new Map<Store, SalesSummary[]>();
  itemHeaderRegistry = new Map<Store, string[]>();
  paymentHeaderRegistry = new Map<Store, string[]>();

  constructor() {
    super();

    makeObservable(this, {
      salesSummaryRegistry: observable,
      itemHeaderRegistry: observable,
      paymentHeaderRegistry: observable,
      loadReportSummary: action,
      setDocumentHeader: action,
      summaryList: computed,
      totalAmountperStore: action,
    });

    this.setPredicate(new InvoiceSummarySearch());

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadReportSummary();
      }
    );
  }

  setPredicate = (search: InvoiceSummarySearch) => {
    runInAction(() => {
      Object.keys(search).forEach((key) => {
        this.predicate.delete(key as keyof InvoiceSummarySearch);
      });
      this.predicate.set(
        "storeId",
        search.storeId === "All" ? "" : search.storeId
      );
      this.predicate.set(
        "startDate",
        search.startDate.toLocaleDateString("EN-US")
      );
      this.predicate.set("endDate", search.endDate.toLocaleDateString("EN-US"));
      this.predicate.set("customerName", search.customerName);
      this.predicate.set("documentNo", search.documentNo);
      this.predicate.set(
        "driverId",
        search.driverId === "All" ? "" : search.driverId
      );
      this.predicate.set(
        "deliveryStatus",
        search.deliveryStatus === "All" ? "" : search.deliveryStatus
      );
      this.predicate.set("shift", search.shift === "All" ? "" : search.shift);
    });
  };

  get filter() {
    return new InvoiceSummarySearch({
      storeId:
        this.predicate.get("storeId") === undefined ||
        this.predicate.get("storeId") === ""
          ? "All"
          : this.predicate.get("storeId")!,
      startDate:
        this.predicate.get("startDate") === undefined
          ? new Date()
          : new Date(this.predicate.get("startDate")!),
      endDate:
        this.predicate.get("endDate") === undefined
          ? new Date()
          : new Date(this.predicate.get("endDate")!),
      customerName:
        this.predicate.get("customerName") === undefined
          ? ""
          : this.predicate.get("customerName")!,
      documentNo:
        this.predicate.get("documentNo") === undefined
          ? ""
          : this.predicate.get("documentNo")!,
      driverId:
        this.predicate.get("driverId") === undefined ||
        this.predicate.get("driverId") === ""
          ? "All"
          : this.predicate.get("driverId")!,
      deliveryStatus:
        this.predicate.get("deliveryStatus") === undefined ||
        this.predicate.get("deliveryStatus") === ""
          ? "All"
          : this.predicate.get("deliveryStatus")!,
      shift:
        this.predicate.get("shift") === undefined ||
        this.predicate.get("shift") === ""
          ? "All"
          : this.predicate.get("shift")!,
    });
  }

  loadReportSummary = async () => {
    this.loading = true;
    this.salesSummaryRegistry.clear();

    try {
      let locations: Store[] = [];
      const storeId = this.predicate.get("storeId");

      if (storeId === "") locations = store.userStore.storeGranted;
      else
        locations = store.userStore.storeGranted.filter(
          (x) => x.id === storeId
        );

      for await (const location of locations) {
        const result = await agent.Reports.reportSalesSummary(
          this.storeParams(location)
        );
        const resultHeader = await agent.Sales.itemHeader(
          this.storeParams(location)
        );
        const resultPayment = await agent.Sales.paymentHeader(
          this.storeParams(location)
        );
        runInAction(() => {
          this.salesSummaryRegistry.set(location, result);
          this.itemHeaderRegistry.set(location, resultHeader);
          this.paymentHeaderRegistry.set(location, resultPayment);
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  setDocumentHeader = (itemHeader?: string[], paymentHeader?: string[]) => {
    const headCells: HeadCell<any>[] = [
      {
        id: "date",
        align: "left",
        disablePadding: false,
        label: "Date",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "isDelivered",
        align: "left",
        disablePadding: false,
        label: "Status",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "documentNo",
        align: "left",
        disablePadding: false,
        label: "Document",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "driverName",
        align: "left",
        disablePadding: false,
        label: "Driver",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "shift",
        align: "left",
        disablePadding: false,
        label: "Shift",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "dueDate",
        align: "left",
        disablePadding: false,
        label: "Due Date",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "customerName",
        align: "left",
        disablePadding: false,
        label: "Customer",
        disableSort: false,
        skeletonShape: "text",
      },
    ];

    itemHeader?.forEach((x) => {
      headCells.push({
        id: x,
        align: "center",
        disablePadding: false,
        label: x,
        disableSort: true,
        skeletonShape: "text",
      });
    });

    headCells.push({
      id: "total_amount",
      align: "right",
      disablePadding: false,
      label: "Total Amount",
      disableSort: true,
      skeletonShape: "text",
    });
    paymentHeader?.forEach((x) => {
      headCells.push({
        id: x,
        align: "right",
        disablePadding: false,
        label: x,
        disableSort: true,
        skeletonShape: "text",
      });
    });

    return headCells;
  };

  get summaryList() {
    return Array.from(this.salesSummaryRegistry.keys());
  }

  totalAmountperStore = (sales: SalesSummary[]) => {
    let totalAmount = 0;
    sales?.forEach((sale) => {
      const total = sale.details.reduce(
        (total, currentData) => (total = total + currentData.totalAmount),
        0
      );
      totalAmount = totalAmount + total;
    });

    return totalAmount;
  };
}
