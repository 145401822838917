import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { observer } from "mobx-react-lite";
import { useStyles } from "../../../app/layout/style";
import { useStore } from "../../../app/stores/store";
import UserSkeleton from "../../Users/UserSkeleton";
import ReportAssetLendingBalanceCard from "./ReportAssetLendingBalanceCard";

export default observer(function ReportAssetLendingBalanceCardList() {
  const classes = useStyles();
  const { assetLendingBalanceStore } = useStore();
  const {
    assetLendingBalanceRegistry,
    totalAssetLendingBalance,
    totalList,
    loading,
    skeletonCardArray,
  } = assetLendingBalanceStore;

  return (
    <div className={classes.paperOnlyBottom}>
      <Grid container direction="row" alignItems="stretch" spacing={1}>
        <Grid item xs={12}>
          <Grid container direction="row" alignItems="stretch" spacing={1}>
            {totalList.map((item, index) => (
              <Grid item xs key={index}>
                <Card>
                  <CardContent>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      style={{ fontWeight: "bold" }}
                      component="h6"
                    >
                      {loading ? (
                        <Skeleton variant="text" />
                      ) : (
                        `Total : ${totalAssetLendingBalance.get(item)}`
                      )}
                    </Typography>
                    <Typography variant="subtitle1">
                      {loading ? <Skeleton variant="text" /> : item}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {loading
          ? skeletonCardArray().map((item, index) => (
              <Grid key={index} item xl={3} lg={4} md={6} sm={6} xs={12}>
                <UserSkeleton />
              </Grid>
            ))
          : assetLendingBalanceRegistry.length > 0 &&
            assetLendingBalanceRegistry.map((item, index) => (
              <Grid item xl={3} lg={4} md={4} xs={12} key={index}>
                <ReportAssetLendingBalanceCard assetLendingBalance={item} />
              </Grid>
            ))}
      </Grid>
    </div>
  );
});
