import {
  Chip,
  Typography,
  Divider,
  Grid,
  Avatar,
  Paper,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStyles } from "../../../app/layout/style";
import {
  Credit,
  ProfitAndLoss,
  SpendingGroupReport,
} from "../../../app/models/report";

interface Props {
  profit: ProfitAndLoss[] | undefined;
  loss: ProfitAndLoss[] | undefined;
  spending: SpendingGroupReport[] | undefined;
  credit: Credit[] | undefined;
}

const useLocalStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.primary,
    },
  })
);

export default observer(function ReportProfitAndLossCard({
  profit,
  loss,
  spending,
  credit,
}: Props) {
  const classes = useStyles();
  const local = useLocalStyles();
  
  return (
    <div className={classes.paperOnlyBottom}>
      <Grid container spacing={1}>
        <Grid item md={3} xs={12}>
          <Paper style={{ height: "100%" }} className={classes.form}>
            <Typography variant="subtitle1" component="p">
              Gross Profit
            </Typography>
            <Divider />
            <div className={classes.paperOnlyBottom} />
            {profit?.length === 0 ? (
              <>
                No Data.
                <Divider />
              </>
            ) : (
              profit?.map((item, index) => (
                <div key={index}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        gutterBottom
                      >
                        {item.description}
                      </Typography>
                    </Grid>
                  </Grid>
                  <div className={classes.paperOnlyBottom}>
                    <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Avatar
                          alt={item.itemCode}
                          src={item.imageUrl ? item.imageUrl : "/no-image.png"}
                        />
                      </Grid>
                      <Grid item>
                        <Chip
                          variant="outlined"
                          color="primary"
                          label={item.itemDescription}
                        />
                      </Grid>
                      <Grid item xs>
                        <Typography
                          variant="caption"
                          align="right"
                          component="p"
                          noWrap
                        >
                          {`${item.qty.toLocaleString("EN-US")} ${
                            item.unitDescription
                          } x ${item.amount.toLocaleString("EN-US")}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Typography
                      variant="subtitle1"
                      align="right"
                      component="p"
                      color="primary"
                    >
                      {`IDR ${item.totalAmount.toLocaleString("EN-US")}`}
                    </Typography>
                  </div>
                </div>
              ))
            )}
          </Paper>
        </Grid>
        <Grid item md={3} xs={12}>
          <Paper style={{ height: "100%" }} className={classes.form}>
            <Typography variant="subtitle1" component="p">
              Loss
            </Typography>
            <Divider />
            <div className={classes.paperOnlyBottom} />
            {loss?.length === 0 ? (
              <>
                No Data.
                <Divider />
              </>
            ) : (
              loss?.map((item, index) => (
                <div key={index}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        gutterBottom
                      >
                        {item.description}
                      </Typography>
                    </Grid>
                  </Grid>
                  <div className={classes.paperOnlyBottom}>
                    <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Avatar
                          alt={item.itemCode}
                          src={item.imageUrl ? item.imageUrl : "/no-image.png"}
                        />
                      </Grid>
                      <Grid item>
                        <Chip
                          variant="outlined"
                          color="primary"
                          label={item.itemDescription}
                        />
                      </Grid>
                      <Grid item xs>
                        <Typography
                          variant="caption"
                          align="right"
                          component="p"
                          noWrap
                        >
                          {`${item.qty.toLocaleString("EN-US")} ${
                            item.unitDescription
                          } x ${item.amount.toLocaleString("EN-US")}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Typography
                      variant="subtitle1"
                      align="right"
                      component="p"
                      color="primary"
                    >
                      {`IDR ${item.totalAmount.toLocaleString("EN-US")}`}
                    </Typography>
                  </div>
                </div>
              ))
            )}
          </Paper>
        </Grid>
        <Grid item md={3} xs={12}>
          <Paper style={{ height: "100%" }} className={classes.form}>
            <Typography variant="subtitle1" component="p">
              Spending
            </Typography>
            <Divider />
            <div className={classes.paperOnlyBottom} />
            {spending?.length === 0 ? (
              <>
                No Data.
                <Divider />
              </>
            ) : (
              spending?.map((item, index) => (
                <div key={index}>
                  <Grid container justifyContent="space-between" spacing={1}>
                    <Grid item>
                      <Typography variant="subtitle1" component="p">
                        {item.group}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        color="primary"
                      >
                        {`IDR ${item.amount.toLocaleString("EN-US")}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </div>
              ))
            )}
          </Paper>
        </Grid>
        <Grid item md={3} xs={12}>
          <Paper style={{ height: "100%" }} className={classes.form}>
            <Typography variant="subtitle1" component="p">
              Customer Debt
            </Typography>
            <Divider />
            {credit?.length === 0 ? (
              <>
                No Data.
                <Divider />
              </>
            ) : (
              credit?.map((item, index) => (
                <div key={index}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Avatar className={local.avatar}>
                        {item.customerName.substring(0, 1)}
                      </Avatar>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" component="p">
                        {item.customerName}
                      </Typography>
                      <Typography
                        variant="caption"
                        component="p"
                        color="textSecondary"
                      >
                        {item.customerCode}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        color="primary"
                        align="right"
                        noWrap
                      >
                        {`IDR ${item.amount.toLocaleString("EN-US")}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </div>
              ))
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
});
