import { Grid, Paper, TablePagination } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStyles } from "../../app/layout/style";
import { Sale } from "../../app/models/sale";
import { useStore } from "../../app/stores/store";
import CardDeleteDialog from "../../app/layout/CardDeleteDialog";
import InvoiceCard from "./InvoiceCard";
import InvoiceCardSkeleton from "./InvoiceCardSkeleton";
import SortByChip from "../../app/layout/SortByChip";

export default observer(function InvoiceCardList() {
  const classes = useStyles();
  const { salesStore, snackbarStore, dialogStore } = useStore();
  const {
    documentList,
    deleteSales,
    loading,
    rowsOptions,
    setPageSize,
    setPageNumber,
    sortByElement,
    setSortBy,
    setOrderBy,
    orderBy,
    pagination,
    pageNumber,
    pageSize,
    sortBy,
    skeletonCardArray,
    initRowsOptionsAndPageSize,
    updateDeliveryStatus,
  } = salesStore;
  const { openSnackbar } = snackbarStore;
  const { openDialogDelete, closeDialog } = dialogStore;

  useEffect(() => {
    initRowsOptionsAndPageSize();
  }, [initRowsOptionsAndPageSize]);

  const handleSortByClick = (value: Extract<keyof Sale, string>) => {
    if (sortBy === value) setOrderBy(orderBy === "asc" ? "desc" : "asc");
    else setSortBy(value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  const handleDeleteSales = (id: string) => {
    openDialogDelete(
      <CardDeleteDialog
        title="Are you sure want to delete this document?"
        id={id}
      />,
      handleDelete
    );
  };

  const handleDelete = (id: string) => {
    deleteSales([id])
      .catch((error) => {
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        closeDialog();
      });
  };

  const handleDelivery = (event: React.MouseEvent<unknown>, id: string) => {
    updateDeliveryStatus(id).catch((error) => {
      openSnackbar(error, "error");
    });
  };

  return (
    <div className={classes.paperOnlyBottom}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SortByChip
            sortByElement={sortByElement}
            sortBy={sortBy}
            orderBy={orderBy}
            handleSortByClick={handleSortByClick}
          />
        </Grid>
        {loading
          ? skeletonCardArray().map((item, index) => (
              <Grid key={index} item md={6} sm={12} xs={12}>
                <InvoiceCardSkeleton />
              </Grid>
            ))
          : documentList.map((sales) => (
              <Grid key={sales.id} item md={6} sm={12} xs={12}>
                <InvoiceCard
                  sales={sales}
                  deleteSales={handleDeleteSales}
                  handleDelivery={handleDelivery}
                />
              </Grid>
            ))}
        <Grid item xs={12}>
          <Paper>
            <TablePagination
              rowsPerPageOptions={rowsOptions}
              component="div"
              count={pagination === null ? 0 : pagination.totalItems}
              page={pageNumber}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
});
