import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Link } from "react-router-dom";
import { Sale } from "../../app/models/sale";
import moment from "moment";
import { useStore } from "../../app/stores/store";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ReactToPrint from "react-to-print";
import POSSlip from "./POSSlip";

interface Props {
  sales: Sale;
  deleteSales: (id: string) => void;
  handleDelivery: (event: React.MouseEvent<unknown>, id: string) => void;
}

export default observer(function InvoiceCard(props: Props) {
  const { userStore, salesStore } = useStore();
  const { restrictedSalesUpdate } = userStore;
  const { totalQty, totalAmount, totalPayment } = salesStore;
  const { sales, deleteSales, handleDelivery } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const notPaidYet = totalAmount(sales.details) - totalPayment(sales.payments);
  const componentRef = useRef(null);
  const totalPayments =
    notPaidYet > 0
      ? totalPayment(sales.payments) + notPaidYet
      : totalPayment(sales.payments);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = (id: string) => {
    setAnchorEl(null);
    deleteSales(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        action={
          <>
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem component={Link} to={`/invoice/${sales.id}`}>
                Update
              </MenuItem>
              <MenuItem
                disabled={restrictedSalesUpdate(sales.date)}
                onClick={() => handleDelete(sales.id)}
              >
                Delete
              </MenuItem>
            </Menu>
          </>
        }
        title={sales.documentNo}
        subheader={
          sales.invoiceRef
            ? `INVOICE REF: ${sales.invoiceRef} | ${moment(sales.date).format(
                "MMMM DD, YYYY"
              )}`
            : moment(sales.date).format("MMMM DD, YYYY")
        }
      />
      <CardContent>
        <Typography gutterBottom variant="subtitle1" component="h2">
          Store : {sales.storeName}
        </Typography>
        <Typography gutterBottom variant="subtitle1" component="h2">
          Driver : {sales.driver.name}
        </Typography>
        <Typography gutterBottom variant="subtitle1" component="h2">
          Shift : {sales.shift}
        </Typography>
        <Typography gutterBottom variant="subtitle1" component="h2">
          Customer Name : {sales.customer.fullName}
        </Typography>
        <Typography gutterBottom variant="subtitle1" component="h2">
          Notes : {sales.notes}
        </Typography>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <ReactToPrint
              trigger={() => (
                <Tooltip title="Print">
                  <IconButton>
                    <ReceiptIcon />
                  </IconButton>
                </Tooltip>
              )}
              content={() => componentRef.current}
            />
            <div style={{ display: "none" }}>
              <POSSlip
                componentToPrint={componentRef}
                sales={sales}
                emptyQty={false}
              />
            </div>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                sales.isDelivered
                  ? "Set As Not Delivered Yet"
                  : "Set As Delivered"
              }
            >
              <IconButton
                onClick={(e) => handleDelivery(e, sales.id)}
                color={sales.isDelivered ? "primary" : "default"}
              >
                <LocalShippingIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Divider />
        {sales.payments.length > 0 &&
          sales.payments.map((payment) => (
            <Grid key={payment.id} container justifyContent="space-between">
              <Grid item>
                <Typography gutterBottom variant="subtitle1" component="span">
                  {payment.paymentType.type}
                </Typography>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  color="primary"
                  component="span"
                >
                  {` ${moment(payment.date).format("DD-MMM-yyyy")}`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="subtitle1" component="h2">
                  IDR {payment.amount.toLocaleString("en-US")}
                </Typography>
              </Grid>
            </Grid>
          ))}
        {notPaidYet > 0 && (
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography gutterBottom variant="subtitle1" component="h2">
                NOT PAID YET
              </Typography>
            </Grid>
            <Grid item>
              <Typography gutterBottom variant="subtitle1" component="h2">
                IDR {notPaidYet.toLocaleString("en-US")}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <Typography gutterBottom variant="subtitle1" component="h4">
              {`Total: IDR ${totalPayments.toLocaleString("en-US")}`}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        {sales.details.map((row) => (
          <div key={row.id}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Avatar
                  alt={row.itemCode}
                  src={row.imageUrl ? row.imageUrl : "/no-image.png"}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle1" component="p">
                  {row.itemDescription}
                </Typography>
              </Grid>
              <Grid item>
                <Typography align="right" variant="subtitle1" component="span">
                  {`x${row.qty} ${row.unitDescription}`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" component="span" align="right">
                  IDR {row.price.toLocaleString("en-US")}
                </Typography>
              </Grid>
            </Grid>
            <Typography align="right" variant="subtitle1" component="p">
              Total: IDR {row.totalAmount.toLocaleString("en-US")}
            </Typography>
            <Divider />
          </div>
        ))}
        <Typography align="right" variant="subtitle1" component="p">
          Total Qty: {totalQty(sales.details).toLocaleString("en-US")}
        </Typography>
        <Typography align="right" variant="subtitle1" component="p">
          Total Amount: IDR {totalAmount(sales.details).toLocaleString("en-US")}
        </Typography>
      </CardContent>
    </Card>
  );
});
