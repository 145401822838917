import { Divider, Grid, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useStyles } from "../../app/layout/style";

const useLocalStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(4, 0, 2, 0),
  },
  horizontal: {
    [theme.breakpoints.up("lg")]: {
      height: "50vh",
    },
  },
  img_search: {
    width: "7vh",
  },
  img_sad: {
    [theme.breakpoints.up("lg")]: {
      width: "55vh",
    },
  },
}));

export default function NotFound() {
  const classes = useStyles();
  const local = useLocalStyles();

  return (
    <div className={classes.root}>
      <div className={local.horizontal}>
        <Grid container justifyContent="space-evenly" alignItems="center">
          <Grid item>
            <Typography align="left" variant="h1">
              404{" "}
              <img
                alt="search icon"
                src="/loupe.svg"
                className={local.img_search}
              />
            </Typography>
            <Typography align="left" variant="h2">
              Page not found.
            </Typography>
            <Divider className={local.divider} />
            <Typography variant="h6">
              The page you are looking for doesn't exist or has been moved.
            </Typography>
          </Grid>
          <Grid item>
            <img alt="sad icon" src="/sad404.svg" className={local.img_sad} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
