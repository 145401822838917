import { Hidden } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import ButtonExcel from "../../../app/common/export/ButtonExcel";
import Filter from "../../../app/common/filter/Filter";
import DateInputField from "../../../app/common/form/DateInputFIeld";
import SearchFilter from "../../../app/common/form/SearchFilter";
import ButtonPrint from "../../../app/common/print/ButtonPrint";
import ComponentToPrint from "../../../app/common/print/ComponentToPrint";
import { useStyles } from "../../../app/layout/style";
import Title from "../../../app/layout/Title";
import { CustomerItem } from "../../../app/models/customer";
import { AssetLendingBalanceSearch } from "../../../app/models/report";
import { useStore } from "../../../app/stores/store";
import ItemsAutoComplete from "../../Customers/ItemsAutoComplete";
import ReportAssetLendingBalanceCardList from "./ReportAssetLendingBalanceCardList";
import ReportAssetLendingTable from "./ReportAssetLendingTable";

export default observer(function ReportAssetLendingBalance() {
  const classes = useStyles();
  const { assetLendingBalanceStore, itemStore } = useStore();
  const { loadDropDown: loadDropDownItem, itemsDropDown } = itemStore;
  const {
    setPredicate,
    loadReportAssetLendingBalance,
    assetLendingBalanceRegistry,
    filter,
    loading,
  } = assetLendingBalanceStore;
  const [search, setSearch] = useState(new AssetLendingBalanceSearch());
  const [value, setValue] = useState<CustomerItem | null>(null);
  const [open, setOpen] = useState(false);
  let componentRef = useRef(null);

  useEffect(() => {
    loadDropDownItem();
    loadReportAssetLendingBalance();
  }, [loadDropDownItem, loadReportAssetLendingBalance]);

  const handleSetSearch = () => {
    const item = itemsDropDown.find((x) => x.id === filter.itemId);
    setSearch(filter);
    setValue(item === undefined ? null : item);
  };

  const handleSearchClick = (values: AssetLendingBalanceSearch) => {
    const newValues = {
      ...values,
      itemId: value === null ? "" : value.id,
    };
    setPredicate(newValues);
  };

  const handleItemChange = (
    item: CustomerItem | null,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (item) {
      setValue(item);
      setFieldValue("itemId", item.id);
    } else {
      setValue(null);
      setFieldValue("itemId", "");
    }
  };

  return (
    <>
      <Title menuName="Report Asset Lending Balance">
        <>
          <ButtonExcel
            fileName="Asset Lending Balance"
            sheetName="Asset Lending Balance"
            componentToExport={componentRef}
            disabled={loading || assetLendingBalanceRegistry.length === 0}
          />
          <ButtonPrint
            componentToPrint={componentRef}
            disabled={loading || assetLendingBalanceRegistry.length === 0}
          />
          <Filter open={open} setOpen={setOpen} setSearch={handleSetSearch}>
            <div className={classes.filter}>
              <Formik
                enableReinitialize
                initialValues={search}
                onSubmit={(values) => handleSearchClick(values)}
              >
                {({ handleSubmit, setFieldValue }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-end"
                      spacing={1}
                    >
                      <Grid item>
                        <DateInputField
                          label="Date"
                          name="date"
                          placeholder="Please input Date"
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <SearchFilter
                          name="customerName"
                          label="Customer Name"
                          variant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <ItemsAutoComplete
                          name="item"
                          options={itemsDropDown.filter((x) =>
                            x.itemCode.includes("(K)")
                          )}
                          value={value}
                          setValue={setValue}
                          onChange={handleItemChange}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </Filter>
        </>
      </Title>
      <Hidden lgUp implementation="css">
        <ReportAssetLendingBalanceCardList />
      </Hidden>
      <Hidden mdDown implementation="css">
        <ReportAssetLendingTable />
        <ComponentToPrint
          title="Report Asset Lending Balance"
          componentToPrint={componentRef}
        >
          <ReportAssetLendingTable />
        </ComponentToPrint>
      </Hidden>
    </>
  );
});
