import {Card, CardHeader, CardContent} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import React from "react";

export default function UserSkeleton() {
  return (
    <Card>
      <CardHeader
        avatar={
          <Skeleton variant="circle" width={40} height={40} />
        }
        title={
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{marginBottom: 6}}
          />
        }
        subheader={<Skeleton height={10} width="40%" />}
      />
      <CardContent>
        <React.Fragment>
          <Skeleton height={10} style={{marginBottom: 6}} />
          <Skeleton height={10} width="80%" />
        </React.Fragment>
      </CardContent>
    </Card>
  );
}
