import {
  Card,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  CardContent,
  Typography,
  Divider,
  Grid,
  Button,
  Chip,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Spending } from "../../app/models/spending";

interface Props {
  document: Spending;
  deleteDocument: (id: string) => void;
  handlePost: (
    event: React.MouseEvent<unknown>,
    id: string,
    posted: boolean
  ) => void;
}

export default observer(function SpendingsCard(props: Props) {
  const { userStore } = useStore();
  const { restricted } = userStore;
  const { document, deleteDocument, handlePost } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = (id: string) => {
    setAnchorEl(null);
    deleteDocument(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card>
      <CardHeader
        action={
          <>
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                disabled={document.posted}
                component={Link}
                to={`/spending/${document.id}`}
              >
                Update
              </MenuItem>
              <MenuItem
                disabled={document.posted}
                onClick={() => handleDelete(document.id)}
              >
                Delete
              </MenuItem>
            </Menu>
          </>
        }
        title={document.documentNo}
        subheader={moment(document.date).format("MMMM DD, YYYY")}
      />
      <CardContent>
        <Typography gutterBottom variant="subtitle1" component="h2">
          Store : {document.store.name}
        </Typography>
        <Divider />
        <Typography gutterBottom variant="subtitle1" component="p">
          {document.spendingGroup.groupName}
        </Typography>
        <Typography gutterBottom variant="subtitle1" component="p">
          {document.description}
        </Typography>
        <Typography gutterBottom variant="h5" align="right" component="p">
          IDR {document.amount.toLocaleString("en-US")}
        </Typography>
        <Divider />
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            {document.posted && restricted(document.date) ? (
              <Chip variant="outlined" color="secondary" label="Posted" />
            ) : (
              <Button
                onClick={(e) => handlePost(e, document.id, document.posted)}
                variant="outlined"
                color={document.posted ? "secondary" : "primary"}
              >
                {document.posted ? "Unpost" : "Post"}
              </Button>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});
