import { observer } from "mobx-react-lite";
import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRouteWithMenu from "../../app/layout/PrivateRouteWithMenu";
import Title from "../../app/layout/Title";
import CashINForm from "./CashINForm";
import CashINList from "./CashINList";

export default observer(function Spending() {
  let { path } = useRouteMatch();

  return (
    <>
      <Title menuName="Cash-IN" />
      <Switch>
        <PrivateRouteWithMenu
          exact
          path={path}
          component={CashINList}
          link={path}
        />
        <PrivateRouteWithMenu
          path={`${path}/form`}
          component={CashINForm}
          link={path}
        />
        <PrivateRouteWithMenu
          path={`${path}/:id`}
          component={CashINForm}
          link={path}
        />
      </Switch>
    </>
  );
});
