import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { Store } from "../models/location";
import { InvoiceByCustomerSearch, SalesByCustomer } from "../models/report";
import { store } from "./store";
import { TableStore } from "./tableStore";

export default class SalesByCustomerStore extends TableStore<SalesByCustomer> {
  salesByCustomerRegistry = new Map<Store, SalesByCustomer[]>();

  constructor() {
    super();
    makeObservable(this, {
      salesByCustomerRegistry: observable,
      loadReportByCustomer: action,
      reportList: computed,
    });

    this.setPredicate(new InvoiceByCustomerSearch());

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadReportByCustomer();
      }
    );
  }

  setPredicate = (search: InvoiceByCustomerSearch) => {
    runInAction(() => {
      Object.keys(search).forEach((key) => {
        this.predicate.delete(key as keyof InvoiceByCustomerSearch);
      });
      this.predicate.set(
        "storeId",
        search.storeId === "All" ? "" : search.storeId
      );
      this.predicate.set(
        "startDate",
        search.startDate.toLocaleDateString("EN-US")
      );
      this.predicate.set("endDate", search.endDate.toLocaleDateString("EN-US"));
      this.predicate.set("customerName", search.customerName);
      this.predicate.set("itemId", search.itemId);
    });
  };

  get filter() {
    return new InvoiceByCustomerSearch({
      storeId:
        this.predicate.get("storeId") === undefined ||
        this.predicate.get("storeId") === ""
          ? "All"
          : this.predicate.get("storeId")!,
      startDate:
        this.predicate.get("startDate") === undefined
          ? new Date()
          : new Date(this.predicate.get("startDate")!),
      endDate:
        this.predicate.get("endDate") === undefined
          ? new Date()
          : new Date(this.predicate.get("endDate")!),
      customerName:
        this.predicate.get("customerName") === undefined
          ? ""
          : this.predicate.get("customerName")!,
      itemId:
        this.predicate.get("itemId") === undefined
          ? ""
          : this.predicate.get("itemId")!,
    });
  }

  loadReportByCustomer = async () => {
    this.loading = true;
    this.salesByCustomerRegistry.clear();

    try {
      let locations: Store[] = [];
      const storeId = this.predicate.get("storeId");

      if (storeId === "") locations = store.userStore.storeGranted;
      else
        locations = store.userStore.storeGranted.filter(
          (x) => x.id === storeId
        );

      for await (const location of locations) {
        const result = await agent.Reports.reportByCustomer(
          this.storeParams(location)
        );
        runInAction(() => {
          this.salesByCustomerRegistry.set(location, result);
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get reportList() {
    return Array.from(this.salesByCustomerRegistry.keys());
  }
}
