import { Grid, Paper, TablePagination } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import CardHeaderList from "../../app/common/card/CardHeaderList";
import PostDialog from "../../app/layout/PostDialog";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import CardDeleteDialog from "../../app/layout/CardDeleteDialog";
import InvoiceCardSkeleton from "../Sales/InvoiceCardSkeleton";
import CashINCard from "./CashINCard";

export default observer(function CashINCardList() {
  const classes = useStyles();
  const { cashINStore, snackbarStore, dialogStore } = useStore();
  const {
    setPageNumber,
    documentList,
    setPageSize,
    loading,
    rowsOptions,
    pagination,
    pageNumber,
    pageSize,
    deleteCashIN,
    postCashIN,
    initRowsOptionsAndPageSize,
    skeletonCardArray,
  } = cashINStore;
  const { openSnackbar } = snackbarStore;
  const { openDialogDelete, closeDialog } = dialogStore;

  useEffect(() => {
    initRowsOptionsAndPageSize();
  }, [initRowsOptionsAndPageSize]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  const handleDeleteDocument = (id: string) => {
    openDialogDelete(
      <CardDeleteDialog
        title="Are you sure want to delete this document?"
        id={id}
      />,
      handleDelete
    );
  };

  const handleDelete = (id: string) => {
    deleteCashIN([id])
      .catch((error) => {
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        closeDialog();
      });
  };

  const handleClickPost = (
    event: React.MouseEvent<unknown>,
    id: string,
    posted: boolean
  ) => {
    openDialogDelete(
      <PostDialog
        title={`Are you sure to ${posted ? "unpost" : "post"} this document`}
        id={id}
      />,
      handlePost
    );
  };

  const handlePost = (id: string) => {
    postCashIN(id)
      .catch((error) => {
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        closeDialog();
      });
  };

  return (
    <div className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CardHeaderList
            title="Document List"
            link="/cash-in/form"
            loading={loading}
          />
        </Grid>
        {loading
          ? skeletonCardArray().map((item, index) => (
              <Grid key={index} item md={4} sm={6} xs={12}>
                <InvoiceCardSkeleton />
              </Grid>
            ))
          : documentList.map((document) => (
              <Grid key={document.id} item md={4} sm={6} xs={12}>
                <CashINCard
                  document={document}
                  deleteDocument={handleDeleteDocument}
                  handlePost={handleClickPost}
                />
              </Grid>
            ))}
        <Grid item xs={12}>
          <Paper>
            <TablePagination
              rowsPerPageOptions={rowsOptions}
              component="div"
              count={pagination === null ? 0 : pagination.totalItems}
              page={pageNumber}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
});
