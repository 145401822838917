import { Button } from "@material-ui/core";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";

interface Props {
  componentToPrint: React.MutableRefObject<HTMLDivElement | null>;
  disabled: boolean;
}

export default function ButtonPrint({ componentToPrint, disabled }: Props) {
  const pageStyle = `
    @page {
      margin: "16px";
    }
  `;

  return (
    <ReactToPrint
      trigger={() => (
        <Button size="small" disabled={disabled}>
          Print <PrintIcon />
        </Button>
      )}
      pageStyle={pageStyle}
      content={() => componentToPrint.current}
    />
  );
}
