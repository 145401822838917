import {
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  useTheme,
  useMediaQuery,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import DateInputField from "../../app/common/form/DateInputFIeld";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import { InvoiceMassValues } from "../../app/models/sale";
import DriverAutoComplete from "./DriverAutoComplete";
import { Driver } from "../../app/models/customer";
import Dropdown2 from "../../app/common/form/Dropdown2";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  ids: string[];
}

export default observer(function UpdateMassInvoice({
  open,
  setOpen,
  ids,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { salesStore, customerStore, userStore, snackbarStore } = useStore();
  const { openSnackbar } = snackbarStore;
  const { updateInvoice, setSelectedItem, setSaleSelectedItem } = salesStore;
  const { drivers } = customerStore;
  const { restrictedSalesDate } = userStore;
  const [invoiceMassValues, setInvoiceMassValues] =
    React.useState<InvoiceMassValues>(new InvoiceMassValues());
  const [driver, setDriver] = React.useState<Driver | null>(null);
  const [option, setOption] = React.useState("1");

  const handleDriverChange = (
    driver: Driver | null,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (driver) {
      setDriver(driver);
      setFieldValue("driverId", driver.id);
    } else {
      setDriver(null);
      setFieldValue("driverId", null);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOption = (e: React.ChangeEvent<{ value: unknown }>) => {
    const id = e.target.value as string;
    setOption(id);
  };

  const closeDialog = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (!reason) setOpen(false);
  };

  const handleFormSubmit = (
    values: InvoiceMassValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    let newValues = new InvoiceMassValues();
    switch (option) {
      case "1": {
        newValues = new InvoiceMassValues({
          ...values,
          driverId: null,
          shift: null,
        });
        break;
      }
      case "2": {
        newValues = new InvoiceMassValues({
          ...values,
          date: null,
          shift: null,
        });
        break;
      }
      case "3": {
        newValues = new InvoiceMassValues({
          ...values,
          date: null,
          driverId: null,
        });
        break;
      }
    }

    updateInvoice(ids, newValues)
      .catch((error) => {
        setSelectedItem([]);
        setSaleSelectedItem([]);
        openSnackbar(error, "error");
        setSubmitting(false);
        setOpen(false);
      })
      .then((message) => {
        if (message !== undefined) {
          setSelectedItem([]);
          setSaleSelectedItem([]);
          openSnackbar(message, "success");
          setInvoiceMassValues(new InvoiceMassValues());
          setOpen(false);
          setSubmitting(false);
          resetForm();
        }
      });
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
    >
      <Formik
        enableReinitialize
        initialValues={invoiceMassValues}
        onSubmit={(values, { resetForm, setSubmitting }) =>
          handleFormSubmit(values, resetForm, setSubmitting)
        }
      >
        {({
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldValue,
          errors,
          touched,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <DialogTitle>POS Invoice</DialogTitle>
            <DialogContent dividers>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs>
                  <TextField
                    name="option"
                    label="Option"
                    value={option}
                    select
                    onChange={handleOption}
                    fullWidth
                    margin="dense"
                    color="primary"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="1">Update Date</MenuItem>
                    <MenuItem value="2">Update Driver</MenuItem>
                    <MenuItem value="3">Update Shift</MenuItem>
                  </TextField>
                </Grid>
                
                {option === "1" && (
                  <Grid item xs>
                    <DateInputField
                      label="Transaction Date"
                      inputVariant="outlined"
                      name="date"
                      margin="dense"
                      disabled={isSubmitting}
                      minDate={restrictedSalesDate()}
                    />
                  </Grid>
                )}
                {option === "2" && (
                  <Grid item xs>
                    <DriverAutoComplete
                      name="driverId"
                      options={drivers}
                      value={driver}
                      setValue={setDriver}
                      loading={isSubmitting}
                      onChange={handleDriverChange}
                      setFieldValue={setFieldValue}
                      error={errors.driverId}
                      touched={touched.driverId}
                      setFieldTouched={setFieldTouched}
                    />
                  </Grid>
                )}
                {option === "3" && (
                  <Grid item xs>
                    <Dropdown2
                      name="shift"
                      options={[
                        { text: "SHIFT 1", value: "SHIFT 1" },
                        {
                          text: "SHIFT 2",
                          value: "SHIFT 2",
                        },
                        {
                          text: "SHIFT 3",
                          value: "SHIFT 3",
                        },
                      ]}
                      label="Shift"
                      variant="outlined"
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={isSubmitting}
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleCancel()}
                color="primary"
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting || !isValid}
              >
                {isSubmitting && (
                  <CircularProgress
                    className={classes.progress}
                    size={16}
                    color="inherit"
                  />
                )}
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
});
