import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import moment from "moment";
import {
  StyledTableCell,
  StyledTableCellTotal,
  StyledTableRow,
} from "../../../app/common/table/EnhancedTableHead";
import { useStyles } from "../../../app/layout/style";
import { StockAllocation } from "../../../app/models/report";

interface Props {
  stockAllocation: StockAllocation[] | undefined;
}

export default observer(function ReportStockAllocationTable({
  stockAllocation,
}: Props) {
  const classes = useStyles();
  const totalAllocation = stockAllocation?.reduce(
    (total, currentData) => (total = total + currentData.allocationQty),
    0
  );
  const totalRealization = stockAllocation?.reduce(
    (total, currentData) => (total = total + currentData.realizationQty),
    0
  );
  const totalGI = stockAllocation?.reduce(
    (total, currentData) => (total = total + currentData.giQty),
    0
  );
  const grandTotal = stockAllocation?.reduce(
    (total, currentData) => (total = total + currentData.totalQty),
    0
  );
  let allocationQty = 0;
  let realizationQty = 0;
  let giQty = 0;
  let totalQty = 0;
  let count = 0;
  let templateSubTotal: JSX.Element | null = null;

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            size="medium"
            aria-label="customized table"
          >
            {stockAllocation !== undefined && stockAllocation?.length > 0 ? (
              <>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Date</StyledTableCell>
                    <StyledTableCell>Source</StyledTableCell>
                    <StyledTableCell>Move To</StyledTableCell>
                    <StyledTableCell>Document</StyledTableCell>
                    <StyledTableCell>Item Description</StyledTableCell>
                    <StyledTableCell align="center">Unit</StyledTableCell>
                    <StyledTableCell align="center">Code</StyledTableCell>
                    <StyledTableCell align="right">Allocation</StyledTableCell>
                    <StyledTableCell align="right">Realization</StyledTableCell>
                    <StyledTableCell align="right">GI</StyledTableCell>
                    <StyledTableCell align="right">DO Stuck</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stockAllocation.map((row, index) => {
                    let result = [];
                    //count all Qty
                    allocationQty = allocationQty + row.allocationQty;
                    realizationQty = realizationQty + row.realizationQty;
                    giQty = giQty + row.giQty;
                    totalQty = totalQty + row.totalQty;

                    result.push(
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {row.date === null
                            ? ""
                            : moment(row.date).format("DD-MMM-yyyy")}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.sourceStockLocation}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.moveToStockLocation}
                        </StyledTableCell>
                        <StyledTableCell>{row.documentNo}</StyledTableCell>
                        <StyledTableCell>{row.itemDescription}</StyledTableCell>
                        <StyledTableCell align="center">
                          {row.unitDescription}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.stockTransactionGroup}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.allocationQty.toLocaleString("en-US")}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.realizationQty.toLocaleString("en-US")}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.giQty.toLocaleString("en-US")}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.totalQty.toLocaleString("en-US")}
                        </StyledTableCell>
                      </StyledTableRow>
                    );

                    if (index < stockAllocation.length - 1) {
                      const nextMoveTo =
                        stockAllocation[index + 1].moveToStockLocation;
                      const nextAllocation =
                        stockAllocation[index + 1].sourceStockLocation;
                      const nextItem =
                        stockAllocation[index + 1].itemDescription;
                      const nextGroup =
                        stockAllocation[index + 1].stockTransactionGroup;
                      count++;

                      if (
                        row.moveToStockLocation !== nextMoveTo ||
                        row.sourceStockLocation !== nextAllocation ||
                        row.stockTransactionGroup !== nextGroup ||
                        row.itemDescription !== nextItem
                      ) {
                        templateSubTotal = (
                          <StyledTableRow key={`${index}_${count}`}>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="right"
                              colSpan={7}
                            >
                              {`Total for "${row.itemDescription} - ${row.stockTransactionGroup}" :`}
                            </StyledTableCellTotal>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="right"
                            >
                              {allocationQty.toLocaleString("en-US")}
                            </StyledTableCellTotal>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="right"
                            >
                              {realizationQty.toLocaleString("en-US")}
                            </StyledTableCellTotal>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="right"
                            >
                              {giQty.toLocaleString("en-US")}
                            </StyledTableCellTotal>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="right"
                            >
                              {totalQty.toLocaleString("en-US")}
                            </StyledTableCellTotal>
                          </StyledTableRow>
                        );
                        if (count > 1) result.push(templateSubTotal);
                        count = 0;
                        allocationQty = 0;
                        realizationQty = 0;
                        giQty = 0;
                        totalQty = 0;
                      }
                    } else {
                      templateSubTotal = (
                        <StyledTableRow key={`${index}_${count}`}>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="right"
                            colSpan={7}
                          >
                            {`Total for "${row.itemDescription} - ${row.stockTransactionGroup}" :`}
                          </StyledTableCellTotal>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="right"
                          >
                            {allocationQty.toLocaleString("en-US")}
                          </StyledTableCellTotal>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="right"
                          >
                            {realizationQty.toLocaleString("en-US")}
                          </StyledTableCellTotal>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="right"
                          >
                            {giQty.toLocaleString("en-US")}
                          </StyledTableCellTotal>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="right"
                          >
                            {totalQty.toLocaleString("en-US")}
                          </StyledTableCellTotal>
                        </StyledTableRow>
                      );
                    }
                    return result;
                  })}
                  {count > 0 &&
                    count !== stockAllocation.length - 1 &&
                    templateSubTotal}
                  <StyledTableRow>
                    <StyledTableCellTotal
                      component="th"
                      scope="row"
                      align="right"
                      colSpan={7}
                    >
                      Grand Total :
                    </StyledTableCellTotal>
                    <StyledTableCellTotal
                      component="th"
                      scope="row"
                      align="right"
                    >
                      {totalAllocation?.toLocaleString("en-US")}
                    </StyledTableCellTotal>
                    <StyledTableCellTotal
                      component="th"
                      scope="row"
                      align="right"
                    >
                      {totalRealization?.toLocaleString("en-US")}
                    </StyledTableCellTotal>
                    <StyledTableCellTotal
                      component="th"
                      scope="row"
                      align="right"
                    >
                      {totalGI?.toLocaleString("en-US")}
                    </StyledTableCellTotal>
                    <StyledTableCellTotal
                      component="th"
                      scope="row"
                      align="right"
                    >
                      {grandTotal?.toLocaleString("en-US")}
                    </StyledTableCellTotal>
                  </StyledTableRow>
                </TableBody>
              </>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell colSpan={8}>No Data.</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
});
