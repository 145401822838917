import {
  Grid,
  Button,
  LinearProgress,
  Typography,
  Paper,
  Divider,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useState, useMemo, useEffect, useRef } from "react";
import Filter from "../../../app/common/filter/Filter";
import DateInputField from "../../../app/common/form/DateInputFIeld";
import Dropdown2 from "../../../app/common/form/Dropdown2";
import ButtonPrint from "../../../app/common/print/ButtonPrint";
import ComponentToPrint from "../../../app/common/print/ComponentToPrint";
import { useStyles } from "../../../app/layout/style";
import Title from "../../../app/layout/Title";
import { CreditPaymentSearch } from "../../../app/models/report";
import { useStore } from "../../../app/stores/store";
import ReportCreditPaymentTable from "./ReportCreditPaymentTable";

export default observer(function ReportCreditPayment() {
  const classes = useStyles();
  const { userStore, creditPaymentStore, salesStore } = useStore();
  const { storeGranted } = userStore;
  const { loadPaymentType, paymentTypes } = salesStore;
  const {
    setPredicate,
    loadReportCreditPayment,
    creditPaymentRegistry,
    loading,
    filter,
    filterPaymentType,
    setFilterPaymentType,
  } = creditPaymentStore;
  const [paymentTypeId, setPaymentTypeId] = useState(filterPaymentType);
  const [search, setSearch] = useState(filter);
  const [open, setOpen] = useState(false);
  const store = useMemo(
    () =>
      filter.storeId === "All"
        ? "All Store"
        : storeGranted.find((x) => x.id === filter.storeId)?.name,
    [filter.storeId, storeGranted]
  );
  let componentRef = useRef(null);

  useEffect(() => {
    if (paymentTypes.length < 1) loadPaymentType();
    loadReportCreditPayment();
  }, [loadReportCreditPayment, paymentTypes.length, loadPaymentType]);

  const handleSetSearch = () => {
    setSearch(filter);
  };

  const handleSearchClick = (values: CreditPaymentSearch) => {
    setPredicate(values);
    setFilterPaymentType(paymentTypeId);
  };

  const setFilterHandleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const id = e.target.value as string;
    setPaymentTypeId(id);
  };

  return (
    <>
      <Title menuName="Report Customer Debt Payment">
        <>
          <ButtonPrint
            componentToPrint={componentRef}
            disabled={loading || creditPaymentRegistry.size === 0}
          />
          <Filter open={open} setOpen={setOpen} setSearch={handleSetSearch}>
            <div className={classes.filter}>
              <Formik
                enableReinitialize
                initialValues={search}
                onSubmit={(values) => handleSearchClick(values)}
              >
                {({ handleSubmit }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-end"
                      spacing={1}
                    >
                      <Grid item>
                        <Grid item>
                          <DateInputField
                            label="Date"
                            name="date"
                            inputVariant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item>
                          <Dropdown2
                            name="storeId"
                            options={storeGranted.map((x) => {
                              return { text: x.name, value: x.id };
                            })}
                            label="Store"
                            variant="outlined"
                            margin="dense"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            titleDefaultSelected="All Selected"
                            displayDefaultSelected
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            name="paymentTypeId"
                            label="Payment Type"
                            value={paymentTypeId}
                            select
                            onChange={setFilterHandleChange}
                            fullWidth
                            margin="dense"
                            color="primary"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            <MenuItem value="All">All Selected</MenuItem>
                            {paymentTypes
                              .filter((x) => x.type !== "DEPOSIT")
                              .map((payment) => (
                                <MenuItem key={payment.id} value={payment.id}>
                                  {payment.type}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </Filter>
        </>
      </Title>
      <>
        {store && (
          <div className={classes.paperOnlyBottom}>
            <Typography variant="subtitle1" align="center">
              {store}
            </Typography>
            <Typography variant="subtitle2" align="center">
              {moment(filter.date).format("DD-MMM-yyyy")}
            </Typography>
          </div>
        )}
        {filterPaymentType === "All"
          ? paymentTypes
              .filter((x) => x.type !== "DEPOSIT")
              .map((paymentType) => (
                <Paper className={classes.form} key={paymentType.id}>
                  <Typography variant="subtitle1">
                    {paymentType.type}
                  </Typography>
                  <Divider />
                  {loading ? (
                    <LinearProgress />
                  ) : (
                    <ReportCreditPaymentTable
                      creditPayment={creditPaymentRegistry.get(paymentType)}
                      paymentType={paymentType}
                    />
                  )}
                </Paper>
              ))
          : paymentTypes
              .filter((x) => x.id === filterPaymentType)
              .map((paymentType) => (
                <Paper className={classes.form} key={paymentType.id}>
                  <Typography variant="subtitle1">
                    {paymentType.type}
                  </Typography>
                  <Divider />
                  {loading ? (
                    <LinearProgress />
                  ) : (
                    <ReportCreditPaymentTable
                      creditPayment={creditPaymentRegistry.get(paymentType)}
                      paymentType={paymentType}
                    />
                  )}
                </Paper>
              ))}
        <ComponentToPrint
          title="Report Customer Debt Payment"
          componentToPrint={componentRef}
        >
          <>
            {store && (
              <div className={classes.paperOnlyBottom}>
                <Typography variant="subtitle1" align="center">
                  {store}
                </Typography>
                <Typography variant="subtitle2" align="center">
                  {moment(filter.date).format("DD-MMM-yyyy")}
                </Typography>
              </div>
            )}
            {filterPaymentType === "All"
              ? paymentTypes
                  .filter((x) => x.type !== "DEPOSIT")
                  .map((paymentType) => (
                    <div key={paymentType.id}>
                      <Typography variant="subtitle1">
                        {paymentType.type}
                      </Typography>
                      <Divider />
                      <ReportCreditPaymentTable
                        creditPayment={creditPaymentRegistry.get(paymentType)}
                        paymentType={paymentType}
                      />
                    </div>
                  ))
              : paymentTypes
                  .filter((x) => x.id === filterPaymentType)
                  .map((paymentType) => (
                    <div key={paymentType.id}>
                      <Typography variant="subtitle1">
                        {paymentType.type}
                      </Typography>
                      <Divider />
                      <ReportCreditPaymentTable
                        creditPayment={creditPaymentRegistry.get(paymentType)}
                        paymentType={paymentType}
                      />
                    </div>
                  ))}
          </>
        </ComponentToPrint>
      </>
    </>
  );
});
