import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Box from "@material-ui/core/Box";
import TableHead from "@material-ui/core/TableHead";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import { useStore } from "../../app/stores/store";
import { AssetLending } from "../../app/models/assetLending";

interface RowProps {
  row: AssetLending;
  labelId: string;
  isItemSelected: boolean;
  handleClick: (event: React.MouseEvent<unknown>, id: string) => void;
  handlePost: (
    event: React.MouseEvent<unknown>,
    id: string,
    posted: boolean
  ) => void;
}

export default function AssetLendingTableRow({
  row,
  labelId,
  isItemSelected,
  handleClick,
  handlePost,
}: RowProps) {
  const [open, setOpen] = React.useState(false);
  const { userStore } = useStore();
  const { restricted } = userStore;

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          {!row.posted && (
            <Checkbox
              checked={isItemSelected}
              onClick={(event) => handleClick(event, row.id)}
            />
          )}
        </TableCell>
        <TableCell padding="checkbox">
          <Tooltip title="Update">
            <IconButton
              component={Link}
              to={`/asset-lending/${row.id}`}
              color="primary"
              disabled={row.posted}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell component="th" id={labelId} scope="row">
          {moment(row.date).format("DD-MMM-yyyy")}
        </TableCell>
        <TableCell>{row.documentNo}</TableCell>
        <TableCell>{row.customer.fullName}</TableCell>
        <TableCell>{row.notes}</TableCell>
        <TableCell align="center">
          {row.posted && restricted(row.date) ? (
            <Chip variant="outlined" color="secondary" label="Posted" />
          ) : (
            <Button
              onClick={(e) => handlePost(e, row.id, row.posted)}
              variant="outlined"
              color={row.posted ? "secondary" : "primary"}
            >
              {row.posted ? "Unpost" : "Post"}
            </Button>
          )}
        </TableCell>
        {row.details.length > 0 ? (
          <TableCell>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell></TableCell>
        )}
      </TableRow>
      {row.details.length > 0 && (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                marginLeft={5}
                marginTop={1}
                marginRight={5}
                marginBottom={2}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell>Item Description</TableCell>
                      <TableCell>Unit</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.details.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>
                          <Chip
                            variant="outlined"
                            color="primary"
                            label={item.stockTransactionType.type}
                          />
                        </TableCell>
                        <TableCell>
                          <Avatar
                            alt={item.itemCode}
                            src={
                              item.imageUrl ? item.imageUrl : "/no-image.png"
                            }
                          />
                        </TableCell>
                        <TableCell>{item.itemDescription}</TableCell>
                        <TableCell>{item.unitDescription}</TableCell>
                        <TableCell align="right">
                          {item.qty.toLocaleString("en-US")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
