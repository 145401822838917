import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import { useStyles } from "../../layout/style";
import { HeadCell, Order } from "../../models/table";
import { observer } from "mobx-react-lite";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { Avatar, TableBody } from "@material-ui/core";

interface EnhancedTableProps<T> {
  headCells: HeadCell<T>[];
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  orderBy: Order;
  sortBy: Extract<keyof T, string> | null;
  rowCount: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  loading: boolean;
  data: T[];
  children?: JSX.Element | undefined;
}

const useLocalStyles = makeStyles((theme) =>
  createStyles({
    cell: {
      padding: theme.spacing(2),
    },
  })
);

export default observer(function EnhancedTableHead<T>(
  props: EnhancedTableProps<T>
) {
  const classes = useStyles();
  const local = useLocalStyles();
  const {
    headCells,
    onSelectAllClick,
    orderBy,
    sortBy,
    numSelected,
    rowCount,
    onRequestSort,
    loading = true,
    data,
    children,
  } = props;

  if (loading) {
    return (
      <TableHead>
        <TableRow>
          <TableCell className={local.cell} padding="checkbox">
            <Skeleton variant="rect" width="100%" />
          </TableCell>
          <TableCell className={local.cell} padding="checkbox">
            <Skeleton variant="rect" width="100%" />
          </TableCell>
          {headCells.map((cell, index) => (
            <TableCell
              key={index}
              className={local.cell}
              padding={cell.skeletonShape === "rect" ? "checkbox" : "normal"}
            >
              <Skeleton
                variant={cell.skeletonShape}
                width={cell.skeletonShape === "rect" ? "100%" : "50%"}
              />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <>
      {data.length > 0 ? (
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
            <TableCell padding="checkbox"></TableCell>
            <>{children}</>
            {headCells.map((headCell) =>
              headCell.label === "" ? (
                <TableCell key={headCell.id} padding="checkbox"></TableCell>
              ) : (
                <TableCell
                  key={headCell.id}
                  align={headCell.align}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={sortBy === headCell.id ? orderBy : false}
                >
                  {headCell.disableSort ? (
                    headCell.isImage === true ? (
                      <Avatar alt={headCell.label} src={headCell.label} />
                    ) : (
                      headCell.label.toLocaleUpperCase()
                    )
                  ) : (
                    <TableSortLabel
                      active={sortBy === headCell.id}
                      direction={orderBy}
                      onClick={(e) => onRequestSort(e, headCell.id)}
                    >
                      {headCell.isImage === true ? (
                        <Avatar alt={headCell.label} src={headCell.label} />
                      ) : (
                        headCell.label.toLocaleUpperCase()
                      )}
                      {sortBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {orderBy === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  )}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
      ) : (
        <TableBody>
          <TableRow>
            <TableCell align="center">No Data.</TableCell>
          </TableRow>
        </TableBody>
      )}
    </>
  );
});

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      fontWeight: "bold",
    },
  })
)(TableCell);

export const StyledTableCellTotal = withStyles((theme: Theme) =>
  createStyles({
    body: {
      fontWeight: "bold",
    },
  })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
