import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { CustomerItem } from "../../app/models/customer";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import PriceInputField from "../../app/common/form/NumberInputField";
import { VendorFormValues, VendorItemsValues } from "../../app/models/vendor";
import ItemsAutoComplete from "../Customers/ItemsAutoComplete";

interface Props {
  vendor: VendorFormValues;
  setVendor: (vendor: VendorFormValues) => void;
  items: CustomerItem[];
  setItems: (items: CustomerItem[]) => void;
  isSubmitting: boolean;
  loading: boolean;
  validateForm: (values?: any) => void;
}

export default observer(function VendorFormAddItem({
  vendor,
  setVendor,
  items,
  setItems,
  isSubmitting,
  loading,
  validateForm,
}: Props) {
  const classes = useStyles();
  const { itemStore } = useStore();
  const { itemsDropDown } = itemStore;
  const [value, setValue] = useState<CustomerItem | null>(null);

  const handleAddItems = () => {
    if (value) {
      setItems(items.concat(value));
      setVendor({
        ...vendor,
        items: vendor.items.concat(
          new VendorItemsValues({
            itemId: value.id,
            vendorId: vendor.id,
          })
        ),
      });
      setValue(null);
    }
  };

  const handleDeleteItem = (item: CustomerItem) => {
    setItems(items.filter((x) => x !== item));
    setVendor({
      ...vendor,
      items: vendor.items.filter((x) => x.itemId !== item.id),
    });
    validateForm();
  };

  return (
    <>
      <div className={classes.paper}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item xl={6} lg={4} md={6} xs={12}>
            <Typography variant="h6" component="p">
              Add Items
            </Typography>
          </Grid>
          <Grid item xl={6} lg={8} md={6} xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs>
                <ItemsAutoComplete
                  name="item"
                  options={itemsDropDown}
                  value={value}
                  setValue={setValue}
                  optionDisabled={items}
                  loading={isSubmitting || loading}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Add Items" className={classes.icon}>
                  <IconButton size="small" onClick={handleAddItems}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {items.map((item, index) => (
        <Paper key={index} className={classes.itemCard} variant="outlined">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Avatar
                alt={item.itemCode}
                src={item.imageUrl ? item.imageUrl : "/no-image.png"}
              />
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1" component="p">
                {item.itemDescription}
              </Typography>
              <Typography variant="caption" component="p">
                {item.unitDescription}
              </Typography>
            </Grid>
            <Grid item>
              <PriceInputField
                variant="outlined"
                label="Price"
                name={`items[${index}].price`}
                margin="dense"
                disabled={isSubmitting || loading}
                autoFocus
              />
            </Grid>
            <Grid item>
              <Tooltip title="Delete Item">
                <IconButton size="small" onClick={() => handleDeleteItem(item)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </>
  );
});
