import React, { SyntheticEvent } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import Icon from "@material-ui/core/Icon";
import { observer } from "mobx-react-lite";
import { MasterMenu } from "../models/masterMenu";
import ChildMenu from "./ChildMenu";
import { useStore } from "../stores/store";
import { Link, useRouteMatch } from "react-router-dom";
import { createStyles, makeStyles, Typography } from "@material-ui/core";

interface Props {
  menu: MasterMenu;
  nested: boolean;
  bold: boolean;
  activeOnlyWhenExact: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(9),
    },
    bold: {
      fontWeight: 600,
    },
  })
);

export default observer(function ParentMenu({
  menu,
  nested,
  bold,
  activeOnlyWhenExact,
}: Props) {
  const { menuStore } = useStore();
  const { setCollapse } = menuStore;
  const classes = useStyles();
  let match = useRouteMatch({
    path: menu.menuLink,
    exact: activeOnlyWhenExact,
  });

  const handleMenu = (e: SyntheticEvent, menu: MasterMenu) => {
    e.preventDefault();
    setCollapse(menu);
  };

  return (
    <>
      {menu.menuLink === "" ? (
        <>
          <ListItem button onClick={(e) => handleMenu(e, menu)}>
            {menu.logo !== "" && (
              <ListItemIcon>
                <Icon>{menu.logo}</Icon>
              </ListItemIcon>
            )}
            <ListItemText
              primary={
                <Typography variant="body2" className={bold ? classes.bold : ""}>
                  {menu.menuName.toUpperCase()}
                </Typography>
              }
            />
            {menu.childMenus?.length !== 0 &&
              menu.childMenus?.length !== undefined &&
              (menu.collapse ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          <ChildMenu collapse={menu.collapse} childs={menu.childMenus} />
        </>
      ) : (
        <>
          <ListItem
            component={Link}
            to={menu.menuLink}
            selected={match ? true : false}
            button
            className={nested ? classes.nested : ""}
          >
            {menu.logo !== "" && (
              <ListItemIcon>
                <Icon>{menu.logo}</Icon>
              </ListItemIcon>
            )}
            <ListItemText
              primary={
                <Typography variant="body2" className={bold ? classes.bold : ""}>
                  {menu.menuName.toUpperCase()}
                </Typography>
              }
            />
            {menu.childMenus?.length !== 0 &&
              menu.childMenus?.length !== undefined &&
              (menu.collapse ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          <ChildMenu collapse={menu.collapse} childs={menu.childMenus} />
        </>
      )}
    </>
  );
});
