import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import moment from "moment";
import {
  StyledTableCell,
  StyledTableCellTotal,
  StyledTableRow,
} from "../../../app/common/table/EnhancedTableHead";
import { useStyles } from "../../../app/layout/style";
import { useStore } from "../../../app/stores/store";
import { Order } from "../../../app/models/table";
import ReportTableHead from "../../../app/common/table/ReportTableHead";
import { SalesSummary } from "../../../app/models/report";

interface Props {
  salesSummary?: SalesSummary[] | undefined;
  itemHeader?: string[] | undefined;
  paymentHeader?: string[] | undefined;
  disableSort: boolean;
}

export default observer(function ReportSalesSummaryTable({
  salesSummary,
  itemHeader,
  paymentHeader,
  disableSort,
}: Props) {
  const classes = useStyles();
  const { summaryStore, salesStore } = useStore();
  const { setDocumentHeader, totalAmountperStore, stableSort, getComparator } =
    summaryStore;
  const { totalAmount } = salesStore;
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof SalesSummary>("date");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property as keyof SalesSummary);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table_small}
            size="medium"
            aria-label="customized table"
          >
            {salesSummary !== undefined && salesSummary?.length > 0 ? (
              <>
                <ReportTableHead
                  headCells={setDocumentHeader(itemHeader, paymentHeader)}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  disableSort={disableSort}
                />
                <TableBody>
                  {stableSort(salesSummary, getComparator(order, orderBy)).map(
                    (row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell>
                          {row.date === null
                            ? ""
                            : moment(row.date).format("DD-MMM-yyyy")}
                        </StyledTableCell>
                        <StyledTableCell>
                          <span
                            className={
                              row.isDelivered
                                ? classes.statusSuccess
                                : classes.statusNotSuccess
                            }
                          >
                            {row.isDelivered
                              ? "Delivered"
                              : "Not Delivered Yet"}
                          </span>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Grid container direction="column" spacing={2}>
                            <Grid item>{row.documentNo}</Grid>
                            {row.invoiceRef && (
                              <Grid
                                item
                              >{`INVOICE REF: ${row.invoiceRef}`}</Grid>
                            )}
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell>{row.driverName}</StyledTableCell>
                        <StyledTableCell>{row.shift}</StyledTableCell>
                        <StyledTableCell>{row.dueDate}</StyledTableCell>
                        <StyledTableCell>
                          {row.customerName.toLocaleUpperCase()}
                        </StyledTableCell>
                        {itemHeader?.map((itemCode, index) => {
                          const details = row.details.filter(
                            (x) => x.itemCode === itemCode
                          );
                          const total = details.reduce(
                            (total, currentData) =>
                              (total = total + currentData.qty),
                            0
                          );
                          return (
                            <StyledTableCell key={index} align="center">
                              {total === 0 ? "" : total.toLocaleString("en-US")}
                            </StyledTableCell>
                          );
                        })}
                        <StyledTableCell align="right">
                          {totalAmount(row.details).toLocaleString("en-US")}
                        </StyledTableCell>
                        {paymentHeader?.map((payment, index) => {
                          const payments = row.payments.filter(
                            (x) => x.paymentType.type === payment
                          );
                          const total = payments.reduce(
                            (total, currentData) =>
                              (total = total + currentData.amount),
                            0
                          );
                          return (
                            <StyledTableCell key={index} align="right">
                              {total === 0 ? "" : total.toLocaleString("en-US")}
                            </StyledTableCell>
                          );
                        })}
                      </StyledTableRow>
                    )
                  )}
                  <StyledTableRow>
                    <StyledTableCellTotal
                      component="th"
                      scope="row"
                      align="right"
                      colSpan={7}
                    >
                      Grand Total :
                    </StyledTableCellTotal>
                    {itemHeader?.map((itemCode, index) => {
                      let totalQty = 0;
                      salesSummary?.forEach((sales) => {
                        const details = sales.details.filter(
                          (x) => x.itemCode === itemCode
                        );
                        const total = details.reduce(
                          (total, currentData) =>
                            (total = total + currentData.qty),
                          0
                        );
                        totalQty = totalQty + total;
                      });
                      return (
                        <StyledTableCellTotal
                          component="th"
                          scope="row"
                          key={index}
                          align="center"
                        >
                          {totalQty === 0
                            ? ""
                            : totalQty.toLocaleString("en-US")}
                        </StyledTableCellTotal>
                      );
                    })}
                    <StyledTableCellTotal
                      component="th"
                      scope="row"
                      align="right"
                    >
                      {totalAmountperStore(salesSummary).toLocaleString(
                        "en-US"
                      )}
                    </StyledTableCellTotal>
                    {paymentHeader?.map((payment, index) => {
                      let totalPayment = 0;
                      salesSummary?.forEach((sales) => {
                        const details = sales.payments.filter(
                          (x) => x.paymentType.type === payment
                        );
                        const total = details.reduce(
                          (total, currentData) =>
                            (total = total + currentData.amount),
                          0
                        );
                        totalPayment = totalPayment + total;
                      });
                      return (
                        <StyledTableCellTotal
                          component="th"
                          scope="row"
                          key={index}
                          align="right"
                        >
                          {totalPayment?.toLocaleString("en-US")}
                        </StyledTableCellTotal>
                      );
                    })}
                  </StyledTableRow>
                </TableBody>
              </>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell colSpan={8}>No Data.</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
});
