import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import EnhancedTableHead from "../../app/common/table/EnhancedTableHead";
import EnhancedTableSkeleton from "../../app/common/table/EnhancedTableSkeleton";
import EnhancedTableToolbar from "../../app/common/table/EnhancedTableToolbar";
import DeleteDialog from "../../app/layout/DeleteDialog";
import { useStyles } from "../../app/layout/style";
import { ProfitSetup } from "../../app/models/profitSetup";
import { HeadCell } from "../../app/models/table";
import { useStore } from "../../app/stores/store";
import ProfitSetupTableRow from "./ProfitSetupTableRow";

export default observer(function ProfitSetupTable() {
  const classes = useStyles();
  const { profitSetupStore, snackbarStore, dialogStore } = useStore();
  const {
    pageNumber,
    pageSize,
    setupList,
    loading,
    deleteSetup,
    setPageNumber,
    setPageSize,
    pagination,
    setSortBy,
    setOrderBy,
    sortBy,
    orderBy,
    rowsOptions,
    selectedItem,
    setSelectedItem,
    selectItem,
    isSelected,
    initRowsOptionsAndPageSize,
  } = profitSetupStore;
  const { openSnackbar } = snackbarStore;
  const { openDialog, closeDialog } = dialogStore;
  const headCells: HeadCell<any>[] = [
    {
      id: "stockLocation",
      align: "left",
      disablePadding: false,
      label: "Stock Location",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "itemCode",
      align: "left",
      disablePadding: false,
      label: "Item Code",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "itemDescription",
      align: "left",
      disablePadding: false,
      label: "Item Description",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "unitDescription",
      align: "left",
      disablePadding: false,
      label: "Unit",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "stockTransactionGroup",
      align: "left",
      disablePadding: false,
      label: "Stock Transaction Group",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "type",
      align: "center",
      disablePadding: false,
      label: "Type",
      disableSort: true,
      skeletonShape: "text",
    },
    {
      id: "amount",
      align: "right",
      disablePadding: false,
      label: "Amount",
      disableSort: true,
      skeletonShape: "text",
    },
  ];

  useEffect(() => {
    initRowsOptionsAndPageSize();
  }, [initRowsOptionsAndPageSize]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    if (sortBy === property) setOrderBy(orderBy === "asc" ? "desc" : "asc");
    else setSortBy(property as keyof ProfitSetup);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (selectedItem.length === 0) {
        const newSelecteds = setupList.map((setup) => setup.id);
        setSelectedItem(newSelecteds);
        return;
      }
    }
    setSelectedItem([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    selectItem(id);
  };

  const handleClickDelete = (event: React.MouseEvent<unknown>) => {
    openDialog(
      <DeleteDialog title={"Are you sure want to delete this setup(s)"} />,
      "xs",
      handleDelete
    );
  };

  const handleDelete = () => {
    deleteSetup(selectedItem)
      .catch((error) => {
        setSelectedItem([]);
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        setSelectedItem([]);
        closeDialog();
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          title="Setup List"
          numSelected={selectedItem.length}
          handleDelete={handleClickDelete}
          linkAddNew="/profitsetup/form"
          loading={loading}
        />
        <TableContainer>
          <Table className={classes.table} size="medium" component="table">
            <EnhancedTableHead
              headCells={headCells}
              numSelected={selectedItem.length}
              orderBy={orderBy}
              sortBy={sortBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={setupList.length}
              loading={loading}
              data={setupList}
            />
            <TableBody>
              {loading ? (
                <EnhancedTableSkeleton row={5} cellArray={headCells} />
              ) : (
                setupList.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <ProfitSetupTableRow
                      key={index}
                      row={row}
                      labelId={labelId}
                      isItemSelected={isItemSelected}
                      handleClick={handleClick}
                    />
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsOptions}
          component="div"
          count={pagination === null ? 0 : pagination.totalItems}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
});
