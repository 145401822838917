import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { Store } from "../models/location";
import { SalesByQuantitySearch, SalesByQuantity } from "../models/report";
import { store } from "./store";
import { TableStore } from "./tableStore";

export default class SalesByQuantityStore extends TableStore<SalesByQuantity> {
  salesByQuantityRegistry = new Map<Store, SalesByQuantity[]>();
  dateHeader: Date[] = [];

  constructor() {
    super();
    makeObservable(this, {
      salesByQuantityRegistry: observable,
      loadReportByQuantity: action,
      reportList: computed,
    });

    this.setPredicate(new SalesByQuantitySearch());

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadReportByQuantity();
      }
    );
  }

  setPredicate = (search: SalesByQuantitySearch) => {
    runInAction(() => {
      Object.keys(search).forEach((key) => {
        this.predicate.delete(key as keyof SalesByQuantitySearch);
      });
      this.predicate.set(
        "storeId",
        search.storeId === "All" ? "" : search.storeId
      );
      this.predicate.set("month", search.month.toLocaleDateString("EN-US"));
      this.predicate.set("itemId", search.itemId);
      this.predicate.set("isVerified", search.isVerified ? "true" : "false");
    });
  };

  get filter() {
    return new SalesByQuantitySearch({
      storeId:
        this.predicate.get("storeId") === undefined ||
        this.predicate.get("storeId") === ""
          ? "All"
          : this.predicate.get("storeId")!,
      month:
        this.predicate.get("month") === undefined
          ? new Date()
          : new Date(this.predicate.get("month")!),
      itemId:
        this.predicate.get("itemId") === undefined
          ? ""
          : this.predicate.get("itemId")!,
      isVerified:
        this.predicate.get("isVerified") === undefined
          ? false
          : this.predicate.get("isVerified")! === "true",
    });
  }

  loadReportByQuantity = async () => {
    this.loading = true;
    this.salesByQuantityRegistry.clear();

    try {
      let locations: Store[] = [];
      const storeId = this.predicate.get("storeId");

      if (storeId === "") locations = store.userStore.storeGranted;
      else
        locations = store.userStore.storeGranted.filter(
          (x) => x.id === storeId
        );
      const month = this.predicate.get("month");
      this.getDaysInMonth(new Date(month!));

      for await (const location of locations) {
        const result = await agent.Reports.reportSalesByQuantity(
          this.storeParams(location)
        );
        runInAction(() => {
          this.salesByQuantityRegistry.set(location, result);
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get reportList() {
    return Array.from(this.salesByQuantityRegistry.keys());
  }

  private getDaysInMonth(dateInput: Date) {
    const date = new Date(dateInput.getFullYear(), dateInput.getMonth(), 1);
    const days: Date[] = [];
    while (date.getMonth() === dateInput.getMonth()) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    runInAction(() => (this.dateHeader = days));
  }
}
