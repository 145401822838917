import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { PaginatedResult } from "../models/pagination";
import {
  StockOutgoing,
  StockOutgoingDetail,
  StockOutgoingFormValues,
  StockTransactionGroup,
} from "../models/stockoutgoing";
import { TableStore } from "./tableStore";

export default class StockOutgoingStore extends TableStore<StockOutgoing> {
  documentRegistry = new Map<string, StockOutgoing>();
  stockTransactionGroup: StockTransactionGroup[] = [];

  constructor() {
    super();
    makeObservable(this, {
      documentRegistry: observable,
      stockTransactionGroup: observable,
      loadDocuments: action,
      loadDocument: action,
      documentList: computed,
      createStockOutgoing: action,
      updateStockOutgoing: action,
      deleteStockOutgoing: action,
      postStockOutgoing: action,
    });

    this.setPageNumber(1);
    this.setSortBy(null);
    this.setOrderBy("desc");
    this.sortByElement = [
      { id: "date", label: "Date" },
      { id: "documentNo", label: "Document" },
      { id: "sourceStockLocation", label: "Source Location" },
      { id: "moveToStockLocation", label: "Move Location" },
    ];

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadDocuments();
      }
    );
  }

  private loadDocumentProcess = (result: PaginatedResult<StockOutgoing[]>) => {
    runInAction(() => {
      this.documentRegistry.clear();
      result.data.forEach((document) => {
        let documentResult = {
          ...document,
          items: this.sortDetails(document.details),
        };
        this.documentRegistry.set(document.id, documentResult);
      });
      this.pagination = result.pagination;
    });
  };

  loadDocuments = async () => {
    this.loading = true;

    try {
      const result = await agent.StockOutgoings.list(this.axiosParams);
      this.loadDocumentProcess(result);
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get documentList() {
    return Array.from(this.documentRegistry.values());
  }

  loadTransactionGroup = async () => {
    this.setLoading(true);

    try {
      const result = await agent.StockOutgoings.loadTransactionGroup();
      runInAction(() => {
        this.stockTransactionGroup = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  loadDocument = async (id: string) => {
    this.loading = true;

    try {
      const result = await agent.StockOutgoings.details(id);
      return {
        ...result,
        details: this.sortDetails(result.details),
      };
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  createStockOutgoing = async (document: StockOutgoingFormValues) => {
    try {
      if (document.details) {
        for await (const x of document.details) {
          x.stockOutgoingId = document.id;
        }
      }
      await agent.StockOutgoings.create(document);
      const result = await agent.StockOutgoings.list(this.axiosParams);
      this.loadDocumentProcess(result);
      return "Create stock outgoing document is success!";
    } catch (error) {
      throw error;
    }
  };

  updateStockOutgoing = async (document: StockOutgoingFormValues) => {
    try {
      await agent.StockOutgoings.update(document).then((document) => {
        runInAction(() => {
          this.documentRegistry.set(document.id, document);
        });
      });
      return "Update stock outgoing document is success!";
    } catch (error) {
      throw error;
    }
  };

  deleteStockOutgoing = async (ids: string[]) => {
    this.loading = true;

    try {
      await agent.StockOutgoings.delete(ids);
      const result = await agent.StockOutgoings.list(this.axiosParams);
      this.loadDocumentProcess(result);
      return "Delete stock outgoing(s) success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  postStockOutgoing = async (id: string) => {
    this.loading = true;

    try {
      await agent.StockOutgoings.post(id).then(() => {
        runInAction(() => {
          const document = this.documentRegistry.get(id);
          if (document) {
            document.posted = !document.posted;
            this.documentRegistry.set(id, document);
          }
        });
      });
      return "Document is posted!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  private sortDetails = (details: StockOutgoingDetail[]) => {
    return details.sort((a, b) => {
      if (a.itemCode > b.itemCode) return 1;
      if (b.itemCode > a.itemCode) return -1;
      return 0;
    });
  };
}
