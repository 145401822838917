export interface Unit {
  id: string;
  unitDescription: string;
}

export interface Photo {
  id: string;
  url: string;
}

export interface ItemDocument {
  itemDescription: string;
  itemCode: string;
  itemPrice: number;
  unitDescription: string;
  imageUrl: string;
  showStock: boolean;
}

export interface Item extends ItemDocument {
  id: string;
  unitId: string;
  price: number;
  imageId: string;
  isSalesItem: boolean;
  isActive: boolean;
}

export class ItemFormValues {
  id?: string = undefined;
  unitId: string = "";
  itemDescription: string = "";
  itemCode: string = "";
  price: number = 0;
  imageId?: string = undefined;
  isSalesItem: boolean = true;
  isActive: boolean = true;
  showStock: boolean = true;

  constructor(item?: ItemFormValues) {
    if (item) {
      this.id = item.id;
      this.unitId = item.unitId;
      this.itemDescription = item.itemDescription;
      this.itemCode = item.itemCode;
      this.price = item.price;
      this.imageId = item.imageId;
      this.isSalesItem = item.isSalesItem;
      this.isActive = item.isActive;
      this.showStock = item.showStock;
    }
  }
}
