import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { PaginatedResult } from "../models/pagination";
import { Vendor, VendorFormValues } from "../models/vendor";
import { store } from "./store";
import { TableStore } from "./tableStore";

export default class VendorStore extends TableStore<Vendor> {
  vendorRegistry = new Map<string, Vendor>();
  vendorDropDown: Vendor[] = [];

  constructor() {
    super();
    makeObservable(this, {
      vendorRegistry: observable,
      vendorDropDown: observable,
      loadVendors: action,
      loadVendor: action,
      vendorList: computed,
      createVendor: action,
      updateVendor: action,
      deleteVendor: action,
    });

    this.setPageNumber(1);
    this.setSortBy(null);
    this.setOrderBy("asc");
    this.sortByElement = [{ id: "name", label: "Vendor Name" }];

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadVendors();
      }
    );
  }

  private loadVendorProcess = (result: PaginatedResult<Vendor[]>) => {
    runInAction(() => {
      this.vendorRegistry.clear();
      result.data.forEach((vendor) => {
        let customerResult = {
          ...vendor,
          items: store.customerStore.sortDetails(vendor.items),
        };
        this.vendorRegistry.set(vendor.id, customerResult);
      });
      this.pagination = result.pagination;
    });
  };

  loadVendors = async () => {
    this.loading = true;

    try {
      const result = await agent.Vendors.list(this.axiosParams);
      this.loadVendorProcess(result);
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get vendorList() {
    return Array.from(this.vendorRegistry);
  }

  loadVendor = async (id: string) => {
    this.loading = true;

    try {
      const result = await agent.Vendors.details(id);
      return {
        ...result,
        items: store.customerStore.sortDetails(result.items),
      };
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  loadDropDown = async () => {
    this.setLoading(true);

    try {
      const dropdown = await agent.Vendors.dropdown();
      runInAction(() => {
        this.vendorDropDown = dropdown;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  createVendor = async (vendor: VendorFormValues) => {
    try {
      if (vendor.items) {
        for await (const x of vendor.items) {
          x.vendorId = vendor.id;
        }
      }
      await agent.Vendors.create(vendor);
      const result = await agent.Vendors.list(this.axiosParams);
      this.loadVendorProcess(result);
      return "Create vendor success!";
    } catch (error) {
      throw error;
    }
  };

  updateVendor = async (vendor: VendorFormValues) => {
    try {
      await agent.Vendors.update(vendor).then((vendor) => {
        runInAction(() => {
          this.vendorRegistry.set(vendor.id, vendor);
        });
      });
      return "Update vendor success!";
    } catch (error) {
      throw error;
    }
  };

  deleteVendor = async (id: string) => {
    this.loading = true;

    try {
      await agent.Vendors.delete(id);
      const result = await agent.Vendors.list(this.axiosParams);
      this.loadVendorProcess(result);
      return "Delete vendor success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };
}
