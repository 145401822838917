import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import moment from "moment";
import {
  StyledTableCell,
  StyledTableCellTotal,
  StyledTableRow,
} from "../../../app/common/table/EnhancedTableHead";
import { useStyles } from "../../../app/layout/style";
import { StockHistory } from "../../../app/models/report";

interface Props {
  stockHistory: StockHistory[] | undefined;
}

export default observer(function ReportStockHistoryTable({
  stockHistory,
}: Props) {
  const classes = useStyles();
  let inQty = 0;
  let outQty = 0;
  let totalQty = 0;
  let count = 0;
  let templateSubTotal: JSX.Element | null = null;

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            size="medium"
            aria-label="customized table"
          >
            {stockHistory !== undefined && stockHistory?.length > 0 ? (
              <>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Date</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell>Item Description</StyledTableCell>
                    <StyledTableCell align="center">Unit</StyledTableCell>
                    <StyledTableCell align="center">Code</StyledTableCell>
                    <StyledTableCell align="center">IN</StyledTableCell>
                    <StyledTableCell align="center">OUT</StyledTableCell>
                    <StyledTableCell align="center">Total</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stockHistory.map((row, index) => {
                    let result = [];
                    //count all Qty
                    inQty = inQty + row.in;
                    outQty = outQty + row.out;
                    totalQty = totalQty + row.total;

                    result.push(
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {row.date === null
                            ? ""
                            : moment(row.date).format("DD-MMM-yyyy")}
                        </StyledTableCell>
                        <StyledTableCell>{row.description}</StyledTableCell>
                        <StyledTableCell>{row.itemDescription}</StyledTableCell>
                        <StyledTableCell align="center">
                          {row.unitDescription}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.itemCode}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.in.toLocaleString("en-US")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.out.toLocaleString("en-US")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.total.toLocaleString("en-US")}
                        </StyledTableCell>
                      </StyledTableRow>
                    );

                    if (index < stockHistory.length - 1) {
                      const nextCode = stockHistory[index + 1].itemCode;
                      if (row.itemCode !== nextCode) {
                        count++;
                        templateSubTotal = (
                          <StyledTableRow key={`${index}_${count}`}>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="right"
                              colSpan={5}
                            >
                              {`Total for "${row.itemCode}" :`}
                            </StyledTableCellTotal>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {inQty.toLocaleString("en-US")}
                            </StyledTableCellTotal>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {outQty.toLocaleString("en-US")}
                            </StyledTableCellTotal>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {totalQty.toLocaleString("en-US")}
                            </StyledTableCellTotal>
                          </StyledTableRow>
                        );
                        inQty = 0;
                        outQty = 0;
                        totalQty = 0;
                        result.push(templateSubTotal);
                      }
                    } else {
                      count++;
                      templateSubTotal = (
                        <StyledTableRow key={`${index}_${count}`}>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="right"
                            colSpan={5}
                          >
                            {`Total for "${row.itemCode}" :`}
                          </StyledTableCellTotal>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {inQty.toLocaleString("en-US")}
                          </StyledTableCellTotal>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {outQty.toLocaleString("en-US")}
                          </StyledTableCellTotal>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {totalQty.toLocaleString("en-US")}
                          </StyledTableCellTotal>
                        </StyledTableRow>
                      );
                      result.push(templateSubTotal);
                    }
                    return result;
                  })}
                </TableBody>
              </>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell colSpan={8}>No Data.</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
});
