import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableCellTotal,
} from "../../../app/common/table/EnhancedTableHead";
import { useStyles } from "../../../app/layout/style";
import { CreditPayment } from "../../../app/models/report";
import { PaymentType } from "../../../app/models/sale";
import { useStore } from "../../../app/stores/store";
import CardDeleteDialog from "../../../app/layout/CardDeleteDialog";

interface Props {
  creditPayment: CreditPayment[] | undefined;
  paymentType: PaymentType;
}

export default observer(function ReportCreditPaymentTable({
  creditPayment,
  paymentType,
}: Props) {
  const classes = useStyles();
  const { userStore, dialogStore, snackbarStore, creditPaymentStore } =
    useStore();
  const { restricted } = userStore;
  const { deletePayment } = creditPaymentStore;
  const { openSnackbar } = snackbarStore;
  const { openDialogDelete, closeDialog } = dialogStore;
  let amount = 0;
  let count = 0;
  let templateSubTotal: JSX.Element | null = null;

  const handleDeletePayment = (id: string) => {
    openDialogDelete(
      <CardDeleteDialog
        title="Are you sure want to delete this payment?"
        id={id}
      />,
      handleDelete
    );
  };

  const handleDelete = (id: string) => {
    deletePayment(paymentType, id)
      .catch((error) => {
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        closeDialog();
      });
  };

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            size="medium"
            aria-label="customized table"
            style={{ fontSize: 12 }}
          >
            {creditPayment !== undefined && creditPayment?.length > 0 ? (
              <>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">
                      Payment Date
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Invoice Date
                    </StyledTableCell>
                    <StyledTableCell>Invoice</StyledTableCell>
                    <StyledTableCell>Customer Code</StyledTableCell>
                    <StyledTableCell>Customer Name</StyledTableCell>
                    <StyledTableCell align="center">
                      Bank Account
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      Payment Amount
                    </StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {creditPayment.map((row, index) => {
                    let result = [];
                    amount = amount + row.paymentAmount;

                    result.push(
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {moment(row.paymentDate).format("DD-MMM-yyyy")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {moment(row.salesDate).format("DD-MMM-yyyy")}
                        </StyledTableCell>
                        <StyledTableCell>{row.documentNo}</StyledTableCell>
                        <StyledTableCell>{row.customerCode}</StyledTableCell>
                        <StyledTableCell>{row.customerName}</StyledTableCell>
                        <StyledTableCell align="center">
                          {row.bankAccount
                            ? `${row.bankAccount?.owner} - ${row.bankAccount?.bankName} - ${row.bankAccount?.accountNo}`
                            : ""}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.paymentAmount.toLocaleString("en-US")}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Tooltip title="Delete">
                            <IconButton
                              color="default"
                              disabled={restricted(row.paymentDate)}
                              onClick={() => handleDeletePayment(row.id)}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </StyledTableRow>
                    );

                    if (index < creditPayment.length - 1) {
                      const nextCustomer =
                        creditPayment[index + 1].customerName;
                      count++;

                      if (row.customerName !== nextCustomer) {
                        templateSubTotal = (
                          <StyledTableRow key={`${index}_${count}`}>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="right"
                              colSpan={6}
                            >
                              {`Total for "${row.customerName}" :`}
                            </StyledTableCellTotal>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="right"
                            >
                              {amount.toLocaleString("en-US")}
                            </StyledTableCellTotal>
                            <StyledTableCell></StyledTableCell>
                          </StyledTableRow>
                        );
                        if (count > 1) result.push(templateSubTotal);
                        count = 0;
                        amount = 0;
                      }
                    } else {
                      templateSubTotal = (
                        <StyledTableRow key={`${index}_${count}`}>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="right"
                            colSpan={6}
                          >
                            {`Total for "${row.customerName}" :`}
                          </StyledTableCellTotal>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="right"
                          >
                            {amount.toLocaleString("en-US")}
                          </StyledTableCellTotal>
                          <StyledTableCell></StyledTableCell>
                        </StyledTableRow>
                      );
                    }
                    return result;
                  })}
                  {count > 0 && templateSubTotal}
                </TableBody>
              </>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell colSpan={8}>No Data.</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
});
