import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStyles } from "../../app/layout/style";
import { CustomerItem } from "../../app/models/customer";
import {
  Vendor,
  VendorFormValues,
  VendorItemsValues,
} from "../../app/models/vendor";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Form, Formik } from "formik";
import Grid from "@material-ui/core/Grid";
import InputField from "../../app/common/form/InputField";
import MySwitch from "../../app/common/form/MySwitch";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dropdown from "../../app/common/form/Dropdown";
import VendorFormAddItem from "./VendorFormAddItem";

export default observer(function VendorForm() {
  const classes = useStyles();
  const { userStore, snackbarStore, vendorStore, itemStore } = useStore();
  const { loadDropDown } = itemStore;
  const { stockLocationGranted } = userStore;
  const { createVendor, loading, loadVendor, updateVendor } = vendorStore;
  const { openSnackbar } = snackbarStore;
  const [vendor, setVendor] = useState<VendorFormValues>(
    new VendorFormValues()
  );
  const [items, setItems] = useState<CustomerItem[]>([]);
  const [disable, setDisable] = useState(false);
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadDropDown();
    if (id) {
      loadVendor(id).then((result) => {
        loadVendorResult(result);
      });
    }
  }, [id, loadDropDown, loadVendor]);

  const loadVendorResult = (result: Vendor | undefined) => {
    if (result) {
      setItems(result.items);
      setVendor(
        new VendorFormValues({
          id: result.id,
          stockLocationId:
            result.locationName === "" ? "" : result.stockLocationId,
          name: result.name,
          displayOnCashflow: result.displayOnCashflow,
          isActive: result.isActive,
          items: result.items.map((x) => {
            return {
              itemId: x.id,
              vendorId: result.id,
              price: x.price,
            } as VendorItemsValues;
          }),
        })
      );
      setDisable(result.locationName !== "");
    }
  };

  const schema = Yup.object({
    name: Yup.string().required("Vendor name is required."),
    items: Yup.array().of(
      Yup.object().shape({
        price: Yup.number()
          .typeError("Price is must be a number.")
          .positive("Price is must be greater than zero.")
          .required("Price is required."),
      })
    ),
  });

  const handleStockLocationChange = (
    e: React.ChangeEvent<{ value: unknown }>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const location = stockLocationGranted.find((x) => x.id === e.target.value);
    if (location) {
      setFieldValue("name", location.name);
      setDisable(true);
    } else {
      setFieldValue("name", "");
      setDisable(false);
    }
  };

  const handleFormSubmit = (
    vendor: VendorFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!vendor.id) {
      let newVendor = {
        ...vendor,
        id: uuid(),
        stockLocationId:
          vendor.stockLocationId === "" ? undefined : vendor.stockLocationId,
      };
      createVendor(newVendor)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
          setDisable(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setVendor(new VendorFormValues());
            setItems([]);
            resetForm();
            setDisable(false);
          }
        });
    } else {
      let newVendor = {
        ...vendor,
        stockLocationId:
          vendor.stockLocationId === "" ? undefined : vendor.stockLocationId,
      };
      updateVendor(newVendor)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/vendors");
          }
        });
    }
  };

  return (
    <>
      <Paper className={classes.form}>
        <Typography variant="h5">Form {id ? "Update" : "Create"}</Typography>
        <Divider />
        <Formik
          validationSchema={schema}
          enableReinitialize
          initialValues={vendor}
          onSubmit={(values, { resetForm, setSubmitting }) =>
            handleFormSubmit(values, resetForm, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldValue,
            values,
            validateForm,
          }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Dropdown
                    options={stockLocationGranted
                      .filter((x) => !x.isSPBE)
                      .map((x) => {
                        return { text: x.name, value: x.id };
                      })}
                    name="stockLocationId"
                    placeholder="Stock Location"
                    label="Stock Location"
                    disabled={isSubmitting || loading}
                    onChange={(e) =>
                      handleStockLocationChange(e, setFieldValue)
                    }
                  />
                  <InputField
                    variant="standard"
                    label="Name"
                    name="name"
                    placeholder="Please input vendor name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loading || disable}
                  />
                  <MySwitch
                    name="displayOnCashflow"
                    label="Display on Cashflow"
                    disabled={isSubmitting || loading}
                  />
                  {id && (
                    <MySwitch
                      name="isActive"
                      label="Active Vendor"
                      checked
                      disabled={isSubmitting || loading}
                    />
                  )}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <VendorFormAddItem
                    vendor={values}
                    setVendor={setVendor}
                    items={items.filter((x) => x.showStock)}
                    setItems={setItems}
                    isSubmitting={isSubmitting}
                    loading={loading}
                    validateForm={validateForm}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="stretch"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || !isValid || values.items.length === 0}
                  >
                    {isSubmitting && (
                      <CircularProgress
                        className={classes.progress}
                        size={16}
                        color="inherit"
                      />
                    )}
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    component={Link}
                    to="/vendors"
                    disabled={isSubmitting || loading}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
});
