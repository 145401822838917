import {
  Grid,
  Typography,
  IconButton,
  Paper,
  Chip,
  Avatar,
  Tooltip,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { QtyInputField } from "../../app/common/form/NumberInputField";
import { useStyles } from "../../app/layout/style";
import {
  AssetLendingDetail,
  AssetLendingDetailValues,
  AssetLendingFormValues,
} from "../../app/models/assetLending";
import { CustomerItem } from "../../app/models/customer";
import { StockTransactionType } from "../../app/models/stockControl";
import { useStore } from "../../app/stores/store";
import ItemsAutoComplete from "../Customers/ItemsAutoComplete";
import StockControlAutoComplete from "../StockControls/StockControlAutoComplete";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { v4 as uuid } from "uuid";

interface Props {
  assetLending: AssetLendingFormValues;
  setAssetLending: (assetLending: AssetLendingFormValues) => void;
  details: AssetLendingDetail[];
  setDetails: (detail: AssetLendingDetail[]) => void;
  isSubmitting: boolean;
  loading: boolean;
  validateForm: (values?: any) => void;
  valueType: StockTransactionType | null;
  setValueType: (valueType: StockTransactionType | null) => void;
  valueItem: CustomerItem | null;
  setValueItem: (valueItem: CustomerItem | null) => void;
}

export default observer(function AssetLendingFormAddDetails({
  assetLending,
  setAssetLending,
  details,
  setDetails,
  isSubmitting,
  loading,
  validateForm,
  valueType,
  setValueType,
  valueItem,
  setValueItem,
}: Props) {
  const classes = useStyles();
  const { stockControlStore, itemStore } = useStore();
  const { stockTransactionType } = stockControlStore;
  const { itemsDropDown } = itemStore;

  const handleAddItems = () => {
    if (valueType && valueItem) {
      const id = uuid();
      setDetails(
        details.concat({
          id: id,
          itemId: valueItem.id,
          stockTransactionType: valueType,
          itemCode: valueItem.itemCode,
          itemDescription: valueItem.itemDescription,
          unitDescription: valueItem.unitDescription,
          imageUrl: valueItem.imageUrl,
        } as AssetLendingDetail)
      );
      setAssetLending(
        new AssetLendingFormValues({
          ...assetLending,
          details: assetLending.details.concat(
            new AssetLendingDetailValues({
              id: id,
              assetLendingId: assetLending.id,
              stockTransactionTypeId: valueType.id,
              itemId: valueItem.id,
            })
          ),
        })
      );
      setValueItem(null);
    }
  };

  const handleDeleteItem = (detail: AssetLendingDetail) => {
    setDetails(details.filter((x) => x.id !== detail.id));
    setAssetLending({
      ...assetLending,
      details: assetLending.details.filter((x) => x.id !== detail.id),
    });
    validateForm();
  };

  return (
    <>
      <div className={classes.paper}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item xl={4} lg={2} md={2} xs={12}>
            <Typography variant="h6" component="p">
              Add Details
            </Typography>
          </Grid>
          <Grid item xl={4} lg={4} md={4} xs={12}>
            <StockControlAutoComplete
              options={stockTransactionType}
              value={valueType}
              setValue={setValueType}
              loading={isSubmitting || loading}
            />
          </Grid>
          <Grid item xl={4} lg={6} md={6} xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs>
                <ItemsAutoComplete
                  name="item"
                  options={itemsDropDown.filter((x) =>
                    x.itemCode.includes("(K)")
                  )}
                  value={valueItem}
                  setValue={setValueItem}
                  loading={isSubmitting || loading}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Add Items">
                  <IconButton size="small" onClick={handleAddItems}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {details.map((detail, index) => (
        <Paper key={index} className={classes.itemCard} variant="outlined">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Chip
                variant="outlined"
                color="primary"
                label={detail.stockTransactionType.type}
              />
            </Grid>
            <Grid item>
              <Avatar
                alt={detail.itemCode}
                src={detail.imageUrl ? detail.imageUrl : "/no-image.png"}
              />
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1" component="p">
                {detail.itemDescription}
              </Typography>
              <Typography variant="caption" component="p">
                {detail.unitDescription}
              </Typography>
            </Grid>
            <Grid item>
              <QtyInputField
                variant="outlined"
                label="Quantity"
                name={`details[${index}].qty`}
                margin="dense"
                andorment={detail.unitDescription}
                disabled={isSubmitting || loading}
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
              />
            </Grid>
            <Grid item>
              <Tooltip title="Delete Item">
                <IconButton
                  size="small"
                  onClick={() => handleDeleteItem(detail)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </>
  );
});
