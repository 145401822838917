import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../app/common/table/EnhancedTableHead";
import { useStyles } from "../../../app/layout/style";
import { SalesByQuantity } from "../../../app/models/report";

interface Props {
  salesByQuantity?: SalesByQuantity[] | undefined;
  dateHeader: Date[];
}

export default observer(function ReportSalesByQuantityTable({
  salesByQuantity,
  dateHeader,
}: Props) {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table_small}
            size="medium"
            aria-label="customized table"
          >
            {salesByQuantity !== undefined && salesByQuantity?.length > 0 ? (
              <>
                <TableHead>
                  <TableRow>
                    {/* <StyledTableCell>Customer Code</StyledTableCell> */}
                    <StyledTableCell>Customer Name</StyledTableCell>
                    {dateHeader.map((date, index) => (
                      <StyledTableCell key={index}>
                        {date.getDate()}
                      </StyledTableCell>
                    ))}
                    <StyledTableCell>Total</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {salesByQuantity.map((row, index) => (
                    <StyledTableRow key={index}>
                      {/* <StyledTableCell>{row.customerCode}</StyledTableCell> */}
                      <StyledTableCell>{row.customerName}</StyledTableCell>
                      {dateHeader.map((date, index) => {
                        const detail = row.details.find(
                          (x) => x.date === date.getDate()
                        );
                        const qty = detail?.qty;

                        if (qty !== undefined) {
                          return (
                            <StyledTableCell key={index} align="center">
                              {qty.toLocaleString()}
                            </StyledTableCell>
                          );
                        } else
                          return (
                            <StyledTableCell
                              key={index}
                              align="center"
                            ></StyledTableCell>
                          );
                      })}
                      <StyledTableCell align="center">
                        {row.details.reduce(
                          (total, currentData) =>
                            (total = total + currentData.qty),
                          0
                        ) || 0}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell colSpan={8}>No Data.</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
});
