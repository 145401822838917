import { Typography, Chip } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { Order, SortBy } from "../models/table";
import { useStyles } from "./style";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

interface Props<T> {
  sortByElement: SortBy<T>[];
  sortBy: Extract<keyof T, string> | null;
  orderBy: Order;
  handleSortByClick: (value: Extract<keyof T, string>) => void;
}

export default observer(function SortByChip<T>(props: Props<T>) {
  const classes = useStyles();
  const { sortByElement, sortBy, orderBy, handleSortByClick } = props;

  return (
    <div className={classes.sort}>
      <div className={classes.sort_item}>
        <Typography variant="subtitle1" component="span">
          Sort by
        </Typography>
        {sortByElement.map((value) => (
          <Chip
            key={value.id}
            icon={
              value.id === sortBy ? (
                orderBy === "asc" ? (
                  <ArrowUpwardIcon />
                ) : (
                  <ArrowDownwardIcon />
                )
              ) : undefined
            }
            label={value.label}
            clickable
            color={value.id === sortBy ? "primary" : "default"}
            onClick={() => handleSortByClick(value.id)}
          />
        ))}
      </div>
    </div>
  );
});
