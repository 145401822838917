import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import { TablePagination } from "@material-ui/core";
import VendorCard from "./VendorCard";
import UserSkeleton from "../Users/UserSkeleton";
import CardDeleteDialog from "../../app/layout/CardDeleteDialog";
import CardHeaderList from "../../app/common/card/CardHeaderList";

export default observer(function VendorCardList() {
  const classes = useStyles();
  const { vendorStore, snackbarStore, dialogStore } = useStore();
  const {
    pageNumber,
    pageSize,
    vendorList,
    pagination,
    loading,
    deleteVendor,
    rowsOptions,
    setPageNumber,
    setPageSize,
    initCardRowsOptionsAndPageSize,
    skeletonCardArray,
  } = vendorStore;
  const { openSnackbar } = snackbarStore;
  const { openDialogDelete, closeDialog } = dialogStore;

  useEffect(() => {
    initCardRowsOptionsAndPageSize();
  }, [initCardRowsOptionsAndPageSize]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  const handleDeleteVendor = (id: string) => {
    openDialogDelete(
      <CardDeleteDialog
        title="Are you sure want to delete this vendor?"
        id={id}
      />,
      handleDelete
    );
  };

  const handleDelete = (id: string) => {
    deleteVendor(id)
      .catch((error) => {
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        closeDialog();
      });
  };

  return (
    <div className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CardHeaderList
            title="Vendor List"
            link="/vendors/form"
            loading={loading}
          />
        </Grid>
        {loading
          ? skeletonCardArray().map((item, index) => (
              <Grid key={index} item xl={3} lg={4} md={6} sm={6} xs={12}>
                <UserSkeleton />
              </Grid>
            ))
          : vendorList.map(([id, vendor]) => (
              <Grid key={id} item xl={3} lg={4} md={6} sm={12} xs={12}>
                <VendorCard vendor={vendor} deleteVendor={handleDeleteVendor} />
              </Grid>
            ))}
        <Grid item xs={12}>
          <Paper>
            <TablePagination
              rowsPerPageOptions={rowsOptions}
              component="div"
              count={pagination === null ? 0 : pagination.totalItems}
              rowsPerPage={pageSize}
              page={pageNumber}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
});
