import React, { useState } from "react";
import createTheme from "@material-ui/core/styles/createTheme";
import {
  CssBaseline,
  makeStyles,
  Theme,
  ThemeProvider,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import Sidebar from "./Sidebar";
import Content from "./Content";
import Appbar from "./Appbar";
import { observer } from "mobx-react-lite";
import SnackbarContainer from "./SnackbarContainer";
import { Route, Switch } from "react-router-dom";
import LoginForm from "../../features/Users/LoginForm";
import { useStore } from "../stores/store";
import { useEffect } from "react";
import LoadingIndicator from "./LoadingIndicator";
import FormDialog from "./FormDialog";
import ScrollToTop from "./ScrollTop";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
}));

const styles = (theme: Theme) => ({
  "@global": {
    // MUI typography elements use REMs, so you can scale the global
    // font size by setting the font-size on the <html> element.
    html: {
      [theme.breakpoints.down("md")]: {
        fontSize: 12,
      },
    },
  },
});

function App() {
  const classes = useStyles();
  const { commonStore, userStore } = useStore();
  const [mobileOpen, setMobileOpen] = useState(false);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  let theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          background: {
            default: prefersDarkMode ? "#303030" : "#fafafa",
          },
          primary: {
            light: "#33c9dc",
            main: "#00bcd4",
            dark: "#008394",
            contrastText: "#fff",
          },
          secondary: {
            light: "#ff7961",
            main: "#f44336",
            dark: "#ba000d",
            contrastText: "#000",
          },
        },
        typography: {
          fontFamily: `"Open Sans", "Helvetica", "Arial", sans-serif`,
          fontWeightRegular: prefersDarkMode ? 300 : 400,
        },
        overrides: {
          MuiCssBaseline: {
            "@global": {
              "*::-webkit-scrollbar": {
                width: "10px",
              },
              "*::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "*::-webkit-scrollbar-thumb": {
                background: "#888",
              },
              "*::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            },
          },
          MuiListItem: {
            root: {
              "&$selected": {
                color: "#00bcd4",
                backgroundColor: "transparent",
                "&:hover": {
                  color: "#00bcd4",
                  backgroundColor: "transparent",
                },
              },
            },
          },
          MuiTableCell: {
            root: {
              fontSize: "inherit",
            },
          },
          MuiDivider: {
            root: {
              margin: "8px 0px 8px 0px",
            },
          },
          MuiInputLabel: {
            outlined: {
              zIndex: 0,
            },
          },
        },
      }),
    [prefersDarkMode]
  );
  const widthSizeMatchXl = useMediaQuery(theme.breakpoints.up("xl"));
  const widthSizeMatchMd = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (commonStore.token) {
      userStore.getUser(widthSizeMatchXl, widthSizeMatchMd).finally(() => {
        commonStore.setAppLoaded();
      });
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, userStore, widthSizeMatchXl, widthSizeMatchMd]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!commonStore.appLoaded)
    return <LoadingIndicator content="Loading app..." />;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarContainer />
      <FormDialog />
      <Switch>
        <Route exact path="/login" component={LoginForm} />
        <Route
          path={"/"}
          render={() => (
            <div className={classes.root}>
              <Appbar handleDrawerToggle={handleDrawerToggle} />
              <Sidebar
                handleDrawerToggle={handleDrawerToggle}
                mobileOpen={mobileOpen}
              />
              <Content />
              <ScrollToTop />
            </div>
          )}
        />
      </Switch>
    </ThemeProvider>
  );
}

export default withStyles(styles)(observer(App));
