import {
  Grid,
  Paper,
  TablePagination,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import UserSkeleton from "../Users/UserSkeleton";
import StockLocationCard from "./StockLocationCard";
import CardHeaderList from "../../app/common/card/CardHeaderList";
import { useStyles } from "../../app/layout/style";

export default observer(function StockLocationCardList() {
  const classes = useStyles();
  const theme = useTheme();
  const widthSizeMatch = useMediaQuery(theme.breakpoints.up("xl"));
  const { stockLocationStore } = useStore();
  const {
    loadStockLocations,
    stockLocationList,
    loading,
    stockLocationRegistry,
  } = stockLocationStore;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rowsOptions, setRowsOptions] = React.useState([2]);
  const skeletonCardArray = Array(rowsPerPage).fill("");

  useEffect(() => {
    setRowsPerPage(widthSizeMatch ? 8 : 6);
    setRowsOptions(
      widthSizeMatch ? [8, 8 * 2, 8 * 4, 8 * 8] : [6, 6 * 2, 6 * 4, 6 * 8]
    );
    if (stockLocationRegistry.size <= 1) loadStockLocations();
  }, [stockLocationRegistry, loadStockLocations, widthSizeMatch]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CardHeaderList
            title="Stock Location List"
            link="/stocklocation/form"
            loading={loading}
          />
        </Grid>
        {loading
          ? skeletonCardArray.map((item, index) => (
              <Grid key={index} item xl={3} lg={4} md={6} sm={6} xs={12}>
                <UserSkeleton />
              </Grid>
            ))
          : stockLocationList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(([id, location]) => (
                <Grid key={id} item xl={3} lg={4} md={6} sm={12} xs={12}>
                  <StockLocationCard location={location} />
                </Grid>
              ))}
        <Grid item xs={12}>
          <Paper>
            <TablePagination
              rowsPerPageOptions={rowsOptions}
              component="div"
              count={stockLocationRegistry.size}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
});
