import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../app/common/table/EnhancedTableHead";
import { useStyles } from "../../../app/layout/style";
import { CustomerPrice } from "../../../app/models/customer";
import moment from "moment";

interface Props {
  customerPrice?: CustomerPrice[] | undefined;
  itemHeader?: string[] | undefined;
}

export default observer(function ReportCustomerPriceTable({
  customerPrice,
  itemHeader,
}: Props) {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table_small}
            size="medium"
            aria-label="customized table"
          >
            {customerPrice !== undefined && customerPrice?.length > 0 ? (
              <>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Customer Code</StyledTableCell>
                    <StyledTableCell>Customer Name</StyledTableCell>
                    {itemHeader?.map((itemCode, index) => {
                      return (
                        <StyledTableCell key={index} align="center">
                          {itemCode}
                        </StyledTableCell>
                      );
                    })}
                    <StyledTableCell>Due Date</StyledTableCell>
                    <StyledTableCell>Address</StyledTableCell>
                    <StyledTableCell>Last Order</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerPrice.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{row.customerCode}</StyledTableCell>
                      <StyledTableCell>
                        {row.fullName.toLocaleUpperCase()}
                      </StyledTableCell>
                      {itemHeader?.map((itemCode, index) => {
                        const details = row.items.filter(
                          (x) => x.itemCode === itemCode
                        );
                        const total = details.reduce(
                          (total, currentData) =>
                            (total = total + currentData.price),
                          0
                        );
                        return (
                          <StyledTableCell key={index} align="center">
                            {total.toLocaleString("en-US")}
                          </StyledTableCell>
                        );
                      })}
                      <StyledTableCell>{row.customerDueDateDescription}</StyledTableCell>
                      <StyledTableCell>{row.address}</StyledTableCell>
                      <StyledTableCell>{row.lastOrder === null  ? "" : moment(row.lastOrder).format("DD-MMM-yyyy")}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell colSpan={8}>No Data.</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
});
