import {
  AppBar,
  Badge,
  Divider,
  FormControlLabel,
  Grid,
  Hidden,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import {
  CustomerGranted,
  CustomerItem,
  Driver,
} from "../../app/models/customer";
import { useStore } from "../../app/stores/store";
import {
  Sale,
  SalesDetail,
  SalesDetailValues,
  SalesFormValues,
  SalesPayment,
  SalesPaymentValues,
} from "../../app/models/sale";
import POSItems from "./POSItems";
import { Helmet } from "react-helmet";
import POSCart from "./POSCart";
import POSTotal from "./POSTotal";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import POSPayment from "./POSPayment";
import PaymentIcon from "@material-ui/icons/Payment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { usePosStyles } from "../../app/layout/pos";
import POSTransaction from "./POSTransaction";
import TabPanel from "../../app/layout/TabPanel";
import POSButton from "./POSButton";
import POSSlip from "./POSSlip";
import { useReactToPrint } from "react-to-print";

export default observer(function POS() {
  const classes = usePosStyles();
  const { customerStore, salesStore, snackbarStore, userStore } = useStore();
  const {
    loadGrantedCustomer,
    loadDrivers,
    loading: loadingCustomer,
  } = customerStore;
  const {
    loadPaymentType,
    loadBankAccount,
    createSales,
    loadDocument,
    updateSales,
    loading: loadingSales,
    defaultPrice,
    setDefaultPrice,
    autoPrint,
    setAutoPrint,
    emptyQty,
    setEmptyQty,
    withTax,
    setWithTax,
  } = salesStore;
  const { restrictedSalesUpdate, user } = userStore;
  const { openSnackbar } = snackbarStore;
  const [customer, setCustomer] = React.useState<CustomerGranted | null>(null);
  const [driver, setDriver] = React.useState<Driver | null>(null);
  const [items, setItems] = React.useState<CustomerItem[]>([]);
  const [store, setStore] = React.useState("Select customer first");
  const [sales, setSales] = React.useState<SalesFormValues>(
    new SalesFormValues(undefined, undefined, new Date(user!.date))
  );
  const [details, setDetails] = React.useState<SalesDetail[]>([]);
  const [open, setOpen] = React.useState(false);
  const [payments, setPayments] = React.useState<SalesPayment[]>([]);
  const [status, setStatus] = React.useState<"save" | "pay" | undefined>(
    undefined
  );
  const [value, setValue] = React.useState(0);
  const [customerDepositUpdate, setCustomerDepositUpdate] = React.useState(0);

  const [slip, setSlip] = React.useState<Sale>();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const componentRef = useRef(null);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  useEffect(() => {
    loadGrantedCustomer();
    loadDrivers();
    loadPaymentType();
    loadBankAccount();

    if (id) {
      loadDocument(id).then((result) => {
        loadDocumentResult(result);
      });
    }
  }, [loadGrantedCustomer, loadDrivers, loadPaymentType, loadBankAccount, id, loadDocument]);

  const loadDocumentResult = (result: Sale | undefined) => {
    if (result) {
      let newSales = new SalesFormValues(undefined, result);
      setSales(newSales);
      setDetails(result.details);
      setCustomer(result.customer);
      setDriver(result.driver);
      setPayments(result.payments);
      setItems(result.customer.items);
      setStore(result.customer.storeName);
      setCustomerDepositUpdate(result.customerDeposit);
    }
  };

  const schema = Yup.object({
    date: Yup.date().required("Date is required."),
    driverId: Yup.string().nullable().required("Driver is required"),
    customerId: Yup.string().nullable().required("Customer is required"),
    details: Yup.array().of(
      Yup.object().shape({
        qty: Yup.number()
          .typeError("Quantity is must be a number.")
          .positive("Quantity is must be greater than zero.")
          .required("Quantity is required."),
      })
    ),
    payments: Yup.array().of(
      Yup.object().shape({
        date: Yup.date().required("Date is required."),
        bankAccountId: Yup.string().when("needAccountNo", {
          is: true,
          then: Yup.string().required("Bank account is required."),
          otherwise: Yup.string().notRequired(),
        }),
        amount: Yup.number()
          .typeError("Payment amount is must be a number.")
          .positive("Payment amount is must be greater than zero.")
          .required("Payment amount is required."),
      })
    ),
  });

  const handleCustomerChange = (
    customer: CustomerGranted | null,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (customer) {
      setCustomer(customer);
      setItems(customer.items);
      setDetails([]);
      setPayments([]);
      setStore(customer.storeName);
      setFieldValue("customerId", customer.id);
      setFieldValue("payments", []);
      setFieldValue("details", []);
    } else {
      setCustomer(null);
      setItems([]);
      setDetails([]);
      setPayments([]);
      setStore("Select customer first");
      setFieldValue("customerId", null);
      setFieldValue("payments", []);
      setFieldValue("details", []);
    }
  };

  const handleDriverChange = (
    driver: Driver | null,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (driver) {
      setDriver(driver);
      setFieldValue("driverId", driver.id);
    } else {
      setDriver(null);
      setFieldValue("driverId", null);
    }
  };

  const handleFormPay = (submitForm: () => void) => {
    setStatus("pay");
    submitForm();
  };

  const handleFormSubmit = (
    sales: SalesFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void,
    status?: "save" | "pay"
  ) => {
    if (status === "pay") {
      setOpen(true);
      setSubmitting(false);
    } else {
      if (!id) {
        const newId = uuid();
        let newSales = new SalesFormValues({
          ...sales,
          id: newId,
          details: sales.details.map(
            (x) =>
              new SalesDetailValues({
                ...x,
                salesId: newId,
              } as SalesDetailValues)
          ),
          payments: sales.payments
            .filter((x) => x.amount > 0)
            .map(
              (x) =>
                new SalesPaymentValues({
                  ...x,
                  salesId: newId,
                  bankAccountId:
                    x.bankAccountId === "" ? undefined : x.bankAccountId,
                })
            ),
        } as SalesFormValues);
        createSales(newSales)
          .catch((error) => {
            openSnackbar(error, "error");
            setSubmitting(false);
            setOpen(false);
            setStatus(undefined);
          })
          .then((result) => {
            if (result !== undefined) {
              openSnackbar(result.message, "success");
              setSlip(result.sales);
              setCustomer(null);
              setDetails([]);
              setPayments([]);
              setOpen(false);
              setItems([]);
              setStore("No Information.");
              setStatus(undefined);
              setSales(
                new SalesFormValues({
                  ...sales,
                  id: undefined,
                  invoiceRef: "",
                  notes: "",
                  customerId: null,
                  details: [],
                  payments: [],
                })
              );
              setSubmitting(false);
              resetForm();
              if (autoPrint) handlePrint();
            }
          });
      } else {
        let updateValue = new SalesFormValues({
          ...sales,
          payments: sales.payments
            .filter((x) => x.amount > 0)
            .map(
              (x) =>
                new SalesPaymentValues({
                  ...x,
                  bankAccountId:
                    x.bankAccountId === "" ? undefined : x.bankAccountId,
                })
            ),
        });
        updateSales(updateValue)
          .catch((error) => {
            openSnackbar(error, "error");
            setSubmitting(false);
            setOpen(false);
            setStatus(undefined);
          })
          .then((result) => {
            if (result !== undefined) {
              openSnackbar(result.message, "success");
              if (query.get("link")) history.push(`/${query.get("link")}`);
              else if (autoPrint) history.push("/invoice?print=1");
              else history.push("/invoice");
            }
          });
      }
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Helmet>
        <title>PANDE GROUP - POINT OF SALES</title>
      </Helmet>
      <Formik
        validationSchema={schema}
        enableReinitialize
        initialValues={sales}
        onSubmit={(values, { resetForm, setSubmitting }) =>
          handleFormSubmit(values, resetForm, setSubmitting, status)
        }
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          submitForm,
          setFieldValue,
          isValid,
          validateForm,
          errors,
          touched,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Hidden smDown implementation="css">
              <Grid container spacing={1} className={classes.pos}>
                <Grid item xl={4} lg={4} md={4} xs={12}>
                  <Paper className={classes.cart}>
                    <Typography align="center" variant="h5">
                      CART
                    </Typography>
                    <Divider />
                    <POSCart
                      sales={values}
                      setSales={setSales}
                      isSubmitting={
                        isSubmitting || restrictedSalesUpdate(values.date)
                      }
                      details={details}
                      setDetails={setDetails}
                    />
                  </Paper>
                </Grid>
                <Grid item xl={4} lg={4} md={4} xs={12}>
                  <div className={classes.panel}>
                    <Paper className={classes.title}>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Typography align="center" variant="h5">
                            POINT OF SALES
                          </Typography>
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={autoPrint}
                                onChange={setAutoPrint}
                                name="autoPrint"
                                disabled={isSubmitting}
                              />
                            }
                            label="Auto Print"
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                    <Paper className={classes.items}>
                      <Typography align="center" variant="h5">
                        ITEMS
                      </Typography>
                      <Divider />
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Switch
                                name="defaultPrice"
                                checked={defaultPrice}
                                onChange={setDefaultPrice}
                                disabled={isSubmitting}
                              />
                            }
                            label="Default Price"
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Switch
                                name="showTax"
                                checked={withTax}
                                onChange={setWithTax}
                                disabled={isSubmitting}
                              />
                            }
                            label="Show Tax"
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Switch
                                name="emptySpace"
                                checked={emptyQty}
                                onChange={setEmptyQty}
                                disabled={isSubmitting}
                              />
                            }
                            label="Empty Qty"
                          />
                        </Grid>
                      </Grid>
                      <POSItems
                        sales={values}
                        setSales={setSales}
                        items={items}
                        details={details}
                        setDetails={setDetails}
                        isSubmitting={
                          isSubmitting || restrictedSalesUpdate(values.date)
                        }
                        defaultPrice={defaultPrice}
                      />
                    </Paper>
                    <div className={classes.button}>
                      <POSButton
                        loading={
                          isSubmitting || details.length === 0 || loadingSales
                        }
                        handleFormPay={handleFormPay}
                        submitForm={submitForm}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xl={4} lg={4} md={4} xs={12}>
                  <div className={classes.panel}>
                    <Paper className={classes.total}>
                      <POSTotal details={details} />
                    </Paper>
                    <Paper className={classes.transaction}>
                      <POSTransaction
                        customer={customer}
                        setCustomer={setCustomer}
                        driver={driver}
                        setDriver={setDriver}
                        handleCustomerChange={handleCustomerChange}
                        handleDriverChange={handleDriverChange}
                        setFieldValue={setFieldValue}
                        store={store}
                        loading={
                          isSubmitting ||
                          loadingCustomer ||
                          loadingSales ||
                          restrictedSalesUpdate(values.date)
                        }
                        errors={errors}
                        touched={touched}
                        setFieldTouched={setFieldTouched}
                        payments={sales.payments}
                      />
                    </Paper>
                  </div>
                </Grid>
              </Grid>
            </Hidden>
            <Hidden mdUp implementation="css">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Paper className={classes.title}>
                    <Typography align="center" variant="h5">
                      POINT OF SALES
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <AppBar
                position="fixed"
                color="default"
                className={classes.appBar}
              >
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab icon={<PaymentIcon />} label="Payment" />
                  <Tab icon={<AssignmentIcon />} label="Items" />
                  <Tab
                    icon={
                      <Badge
                        badgeContent={details.length}
                        overlap="circular"
                        color="secondary"
                      >
                        <ShoppingCartIcon />
                      </Badge>
                    }
                    label="Cart"
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <Paper className={classes.total}>
                  <POSTotal details={details} />
                </Paper>
                <Paper className={classes.transaction}>
                  <POSTransaction
                    customer={customer}
                    setCustomer={setCustomer}
                    driver={driver}
                    setDriver={setDriver}
                    handleCustomerChange={handleCustomerChange}
                    handleDriverChange={handleDriverChange}
                    setFieldValue={setFieldValue}
                    store={store}
                    loading={
                      isSubmitting ||
                      loadingCustomer ||
                      loadingSales ||
                      restrictedSalesUpdate(values.date)
                    }
                    errors={errors}
                    touched={touched}
                    setFieldTouched={setFieldTouched}
                    payments={sales.payments}
                  />
                </Paper>
                <div className={classes.button}>
                  <POSButton
                    loading={
                      isSubmitting || details.length === 0 || loadingSales
                    }
                    handleFormPay={handleFormPay}
                    submitForm={submitForm}
                  />
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className={classes.pos}>
                  <Paper className={classes.items}>
                    <Typography align="center" variant="h5">
                      ITEMS
                    </Typography>
                    <Divider />
                    <POSItems
                      sales={values}
                      setSales={setSales}
                      items={items}
                      details={details}
                      setDetails={setDetails}
                      isSubmitting={
                        isSubmitting || restrictedSalesUpdate(values.date)
                      }
                      defaultPrice={defaultPrice}
                    />
                  </Paper>
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div className={classes.pos}>
                  <Paper className={classes.cart}>
                    <Typography align="center" variant="h5">
                      CART
                    </Typography>
                    <Divider />
                    <POSCart
                      sales={values}
                      setSales={setSales}
                      isSubmitting={
                        isSubmitting || restrictedSalesUpdate(values.date)
                      }
                      details={details}
                      setDetails={setDetails}
                    />
                  </Paper>
                </div>
              </TabPanel>
            </Hidden>
            <POSPayment
              sales={values}
              details={details}
              customer={customer}
              setSales={setSales}
              open={open}
              setOpen={setOpen}
              payments={payments}
              setPayments={setPayments}
              isSubmitting={isSubmitting}
              submitForm={submitForm}
              setStatus={setStatus}
              disabled={!isValid}
              setFieldValue={setFieldValue}
              validateForm={validateForm}
              customerDepositUpdate={customerDepositUpdate}
            />
          </Form>
        )}
      </Formik>
      <div style={{ display: "none" }}>
        <POSSlip
          componentToPrint={componentRef}
          sales={slip}
          emptyQty={emptyQty}
        />
      </div>
    </>
  );
});
