import TextField, { BaseTextFieldProps } from "@material-ui/core/TextField";
import { useField } from "formik";
import React from "react";
import InputMask from "react-input-mask";

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <InputMask
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask="+62-999-999-999-99"
    />
  );
}

interface Props extends BaseTextFieldProps {
  placeholder?: string;
  name: string;
  type?: string;
  label?: string;
  helperText?: string;
}

export default function PhoneNoInputField(props: Props) {
  const [field, meta] = useField(props.name);

  return (
    <TextField
      {...field}
      {...props}
      id={props.name}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error ? meta.error : props.helperText}
      fullWidth
      InputProps={{
        inputComponent: TextMaskCustom as any,
      }}
      margin="normal"
      color="primary"
    />
  );
}