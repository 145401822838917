import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { CustomerGranted } from "../models/customer";
import { PaginatedResult } from "../models/pagination";
import {
  BankAccount,
  InvoiceMassValues,
  InvoiceSearch,
  PaymentType,
  Sale,
  SalesDetail,
  SalesFormValues,
  SalesPayment,
  SalesPaymentValues,
} from "../models/sale";
import { HeadCell } from "../models/table";
import { TableStore } from "./tableStore";

export default class SalesStore extends TableStore<Sale> {
  documentRegistry = new Map<string, Sale>();
  documentsSelected = new Map<string, Sale>();
  headCells: HeadCell<any>[] = [];
  itemHeader: string[] = [];
  paymentHeader: string[] = [];
  paymentTypes: PaymentType[] = [];
  bankAccounts: BankAccount[] = [];
  customerDeposit = 0;
  defaultPrice = false;
  autoPrint = false;
  emptyQty = false;
  withTax = false;
  updatedSale: Sale | undefined = undefined;

  constructor() {
    super();
    makeObservable(this, {
      documentRegistry: observable,
      documentsSelected: observable,
      headCells: observable,
      itemHeader: observable,
      paymentHeader: observable,
      paymentTypes: observable,
      bankAccounts: observable,
      customerDeposit: observable,
      defaultPrice: observable,
      autoPrint: observable,
      updatedSale: observable,
      emptyQty: observable,
      withTax: observable,
      loadPaymentType: action,
      loadBankAccount: action,
      loadDocument: action,
      loadDocuments: action,
      documentList: computed,
      setSaleSelectedItem: action,
      selectSaleItem: action,
      totalAmount: action.bound,
      totalPayment: action.bound,
      totalQty: action.bound,
      changeAmount: action.bound,
      createSales: action,
      updateSales: action,
      deleteSales: action,
      updatePayment: action,
      updateInvoice: action,
      paymentTypesGranted: action,
      getCustomerDeposit: action,
      setDefaultPrice: action.bound,
      setAutoPrint: action.bound,
      setEmptyQty: action.bound,
      setWithTax: action.bound,
    });

    this.setPageNumber(1);
    this.setSortBy(null);
    this.setOrderBy("desc");
    this.sortByElement = [
      { id: "date", label: "Date" },
      { id: "documentNo", label: "Invoice" },
      { id: "driver", label: "Driver" },
      { id: "shift", label: "Shift" },
      { id: "customer", label: "Customer Name" },
    ];

    this.setPredicate(new InvoiceSearch());

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadDocuments();
      }
    );
  }

  setPredicate = (search: InvoiceSearch) => {
    runInAction(() => {
      Object.keys(search).forEach((key) => {
        this.predicate.delete(key);
      });
      this.predicate.set(
        "storeId",
        search.storeId === "All" ? "" : search.storeId
      );
      this.predicate.set(
        "startDate",
        search.startDate.toLocaleDateString("EN-US")
      );
      this.predicate.set("endDate", search.endDate.toLocaleDateString("EN-US"));
      this.predicate.set("customerName", search.customerName);
      this.predicate.set("documentNo", search.documentNo);
      this.predicate.set(
        "driverId",
        search.driverId === "All" ? "" : search.driverId
      );
      this.predicate.set(
        "deliveryStatus",
        search.deliveryStatus === "All" ? "" : search.deliveryStatus
      );
      this.predicate.set("shift", search.shift === "All" ? "" : search.shift);
      this.predicate.set("pageNumber", "1");
    });
  };

  get filter() {
    return new InvoiceSearch({
      storeId:
        this.predicate.get("storeId") === undefined ||
        this.predicate.get("storeId") === ""
          ? "All"
          : this.predicate.get("storeId")!,
      startDate:
        this.predicate.get("startDate") === undefined
          ? new Date()
          : new Date(this.predicate.get("startDate")!),
      endDate:
        this.predicate.get("endDate") === undefined
          ? new Date()
          : new Date(this.predicate.get("endDate")!),
      customerName:
        this.predicate.get("customerName") === undefined
          ? ""
          : this.predicate.get("customerName")!,
      documentNo:
        this.predicate.get("documentNo") === undefined
          ? ""
          : this.predicate.get("documentNo")!,
      driverId:
        this.predicate.get("driverId") === undefined ||
        this.predicate.get("driverId") === ""
          ? "All"
          : this.predicate.get("driverId")!,
      deliveryStatus:
        this.predicate.get("deliveryStatus") === undefined ||
        this.predicate.get("deliveryStatus") === ""
          ? "All"
          : this.predicate.get("deliveryStatus")!,
      shift:
        this.predicate.get("shift") === undefined ||
        this.predicate.get("shift") === ""
          ? "All"
          : this.predicate.get("shift")!,
    });
  }

  loadPaymentType = async () => {
    this.loading = true;

    try {
      const result = await agent.Sales.paymentTypes();
      runInAction(() => {
        this.paymentTypes = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  loadBankAccount = async () => {
    this.loading = true;

    try {
      const result = await agent.Sales.bankAccounts();
      runInAction(() => {
        this.bankAccounts = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  private loadDocumentProcess = (result: PaginatedResult<Sale[]>) => {
    runInAction(() => {
      this.documentRegistry.clear();
      result.data.forEach((item) => {
        let itemResult = {
          ...item,
          details: this.sortDetails(item.details),
        };
        this.documentRegistry.set(item.id, itemResult);
      });
      this.pagination = result.pagination;
    });
  };

  loadDocuments = async () => {
    this.loading = true;

    try {
      const result = await agent.Sales.list(this.axiosParams);
      const resultHeader = await agent.Sales.itemHeader(this.axiosParams);
      const resultPayment = await agent.Sales.paymentHeader(this.axiosParams);
      runInAction(() => {
        this.itemHeader = resultHeader;
        this.paymentHeader = resultPayment;
        this.loadDocumentProcess(result);
        this.setDocumentHeader();
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  private setDocumentHeader() {
    this.headCells = [
      {
        id: "date",
        align: "left",
        disablePadding: false,
        label: "Date",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "documentNo",
        align: "left",
        disablePadding: false,
        label: "Invoice",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "driver",
        align: "left",
        disablePadding: false,
        label: "Driver",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "shift",
        align: "left",
        disablePadding: false,
        label: "Shift",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "customer",
        align: "left",
        disablePadding: false,
        label: "Customer",
        disableSort: false,
        skeletonShape: "text",
      },
    ];
    this.headCells.push({
      id: "itemCode",
      align: "center",
      disablePadding: false,
      label: "Item",
      disableSort: true,
      skeletonShape: "text",
    });
    this.headCells.push({
      id: "qty",
      align: "center",
      disablePadding: false,
      label: "Qty",
      disableSort: true,
      skeletonShape: "text",
    });
    // this.itemHeader.forEach((x) => {
    //   this.headCells.push({
    //     id: x,
    //     align: "center",
    //     disablePadding: false,
    //     label: x,
    //     disableSort: true,
    //     skeletonShape: "text",
    //   });
    // });
    this.headCells.push({
      id: "total_amount",
      align: "right",
      disablePadding: false,
      label: "Total Amount",
      disableSort: true,
      skeletonShape: "text",
    });
    this.headCells.push({
      id: "paymentType",
      align: "center",
      disablePadding: false,
      label: "Payment Type",
      disableSort: true,
      skeletonShape: "text",
    });
    this.headCells.push({
      id: "payment_amount",
      align: "right",
      disablePadding: false,
      label: "Payment Amount",
      disableSort: true,
      skeletonShape: "text",
    });
    // this.paymentHeader.forEach((x) => {
    //   this.headCells.push({
    //     id: x,
    //     align: "right",
    //     disablePadding: false,
    //     label: x,
    //     disableSort: true,
    //     skeletonShape: "text",
    //   });
    // });
    this.headCells.push({
      id: "details",
      align: "center",
      disablePadding: true,
      label: "",
      disableSort: true,
      skeletonShape: "rect",
    });
  }

  get documentList() {
    return Array.from(this.documentRegistry.values());
  }

  get documentSelectedList() {
    return Array.from(this.documentsSelected.values());
  }

  setSaleSelectedItem = (items: Sale[]) => {
    this.documentsSelected.clear();
    items.forEach((sale) => {
      this.documentsSelected.set(sale.id, sale);
    });
  };

  selectSaleItem = (sale: Sale) => {
    if (this.documentsSelected.has(sale.id))
      this.documentsSelected.delete(sale.id);
    else this.documentsSelected.set(sale.id, sale);
  };

  totalQty = (details?: SalesDetail[]) => {
    if (details)
      return (
        details.reduce(
          (total, currentData) => (total = total + currentData.qty),
          0
        ) || 0
      );
    return 0;
  };

  totalAmount = (details?: SalesDetail[]) => {
    if (details) {
      return (
        details.reduce(
          (total, currentData) => (total = total + currentData.totalAmount),
          0
        ) || 0
      );
    }
    return 0;
  };

  totalAmountDefaultPrice = (details?: SalesDetail[]) => {
    if (details) {
      return (
        details.reduce(
          (total, currentData) =>
            (total = total + currentData.totalAmountDefaultPrice),
          0
        ) || 0
      );
    }
    return 0;
  };

  changeAmount = (payments: SalesPayment[]) => {
    return payments.reduce(
      (total, currentData) => (total = total + currentData.amount),
      0
    );
  };

  totalPayment = (payments: SalesPayment[]) => {
    return payments.reduce(
      (total, currentData) => (total = total + currentData.amount),
      0
    );
  };

  loadDocument = async (id: string) => {
    this.loading = true;

    try {
      const result = await agent.Sales.details(id);
      const beginningCustomerDeposit = result.payments
        .filter((x) => x.paymentType.type === "DEPOSIT")
        .reduce(
          (total, currentData) => (total = total + currentData.amount),
          0
        );
      return {
        ...result,
        details: this.sortDetails(result.details),
        customerDeposit: beginningCustomerDeposit,
      };
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  createSales = async (document: SalesFormValues) => {
    try {
      const result = await agent.Sales.create(document);
      return { message: "Create sales document is success!", sales: result };
    } catch (error) {
      throw error;
    }
  };

  updateSales = async (document: SalesFormValues) => {
    try {
      const result = await agent.Sales.update(document);
      runInAction(() => {
        if (this.documentRegistry.size > 0)
          this.documentRegistry.set(result.id, result);
        if (this.documentsSelected.has(result.id))
          this.documentsSelected.set(result.id, result);
        this.updatedSale = result;
      });
      return { message: "Update sales document is success!", sales: result };
    } catch (error) {
      throw error;
    }
  };

  deleteSales = async (ids: string[]) => {
    this.loading = true;

    try {
      await agent.Sales.delete(ids);
      const result = await agent.Sales.list(this.axiosParams);
      this.loadDocumentProcess(result);
      return "Delete document(s) success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  updatePayment = async (ids: string[], payment: SalesPaymentValues) => {
    this.loading = true;

    try {
      await agent.Sales.updatePayment(ids, payment);
      const result = await agent.Sales.list(this.axiosParams);
      const resultHeader = await agent.Sales.itemHeader(this.axiosParams);
      const resultPayment = await agent.Sales.paymentHeader(this.axiosParams);
      runInAction(() => {
        this.itemHeader = resultHeader;
        this.paymentHeader = resultPayment;
        this.loadDocumentProcess(result);
        this.setDocumentHeader();
      });
      return "Update document(s) success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  updateInvoice = async (ids: string[], invoice: InvoiceMassValues) => {
    this.loading = true;

    try {
      await agent.Sales.updateInvoice(ids, invoice);
      const result = await agent.Sales.list(this.axiosParams);
      const resultHeader = await agent.Sales.itemHeader(this.axiosParams);
      const resultPayment = await agent.Sales.paymentHeader(this.axiosParams);
      runInAction(() => {
        this.itemHeader = resultHeader;
        this.paymentHeader = resultPayment;
        this.loadDocumentProcess(result);
        this.setDocumentHeader();
      });
      return "Update document(s) success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  updateDeliveryStatus = async (id: string) => {
    try {
      const result = this.documentRegistry.get(id);
      await agent.Sales.updateDeliveryStatus(id);
      runInAction(() => {
        this.documentRegistry.set(id, {
          ...result!,
          isDelivered: !result!.isDelivered,
        });
      });
    } catch (error) {
      throw error;
    }
  };

  private sortDetails = (details: SalesDetail[]) => {
    return details.sort((a, b) => {
      if (a.itemCode > b.itemCode) return 1;
      if (b.itemCode > a.itemCode) return -1;
      return 0;
    });
  };

  getCustomerDeposit = async (customerId: string) => {
    this.setLoading(true);

    try {
      const result = await agent.Sales.customerDeposit(customerId);
      runInAction(() => (this.customerDeposit = result));
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  paymentTypesGranted = (
    customerDepositUpdate: number,
    customer: CustomerGranted | null
  ) => {
    let result: PaymentType[] = [];

    if (customerDepositUpdate === 0) {
      if (this.customerDeposit > 0)
        result = customer?.isVerified
          ? this.paymentTypes
          : this.paymentTypes.filter((x) => !x.isVerified);
      else
        result = customer?.isVerified
          ? this.paymentTypes.filter((x) => x.type !== "DEPOSIT")
          : this.paymentTypes.filter(
              (x) => !x.isVerified && x.type !== "DEPOSIT"
            );
    } else {
      if (customerDepositUpdate > 0)
        result = customer?.isVerified
          ? this.paymentTypes
          : this.paymentTypes.filter((x) => !x.isVerified);
      else
        result = customer?.isVerified
          ? this.paymentTypes.filter((x) => x.type !== "DEPOSIT")
          : this.paymentTypes.filter(
              (x) => !x.isVerified && x.type !== "DEPOSIT"
            );
    }

    return result;
  };

  setDefaultPrice = () => (this.defaultPrice = !this.defaultPrice);

  setAutoPrint = () => (this.autoPrint = !this.autoPrint);

  setEmptyQty = () => (this.emptyQty = !this.emptyQty);

  setWithTax = () => (this.withTax = !this.withTax);
}
