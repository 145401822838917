import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Title from "../../app/layout/Title";
import LocationStoreAccessList from "./LocationStoreAccessList";
import Divider from "@material-ui/core/Divider";
import LocationStockLocationAccessList from "./LocationStockLocationAccessList";
import { useStyles } from "../../app/layout/style";

export default observer(function LocationAccess() {
  const classes = useStyles();
  const { storeStore, stockLocationStore, locationAccessRightStore } =
    useStore();
  const { storeDropDown, loadDropDown: loadStoreDropdown } = storeStore;
  const { stockLocationDropdown, loadDropDown: loadLocationDropDown } =
    stockLocationStore;
  const {
    store,
    location,
    setStore,
    setLocation,
    loadUsersStoreGranted,
    setUsersStore,
    setUsersStoreGranted,
    loadUsersStockLocationGranted,
    setUsersStockLocation,
    setUsersStockLocationGranted,
  } = locationAccessRightStore;

  useEffect(() => {
    loadStoreDropdown();
    loadLocationDropDown();
  }, [loadStoreDropdown, loadLocationDropDown]);

  const handleStoreChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value as string;
    setStore(value);
    if (value === "0") {
      setUsersStore([]);
      setUsersStoreGranted([]);
    } else loadUsersStoreGranted(value);
  };

  const handleLocationChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value as string;
    setLocation(value);
    if (value === "0") {
      setUsersStockLocation([]);
      setUsersStockLocationGranted([]);
    } else loadUsersStockLocationGranted(value);
  };
  return (
    <div className={classes.transferList}>
      <Title menuName="Location Access Right" />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={5}>
          <FormControl
            color="primary"
            variant="outlined"
            fullWidth
            margin="normal"
          >
            <InputLabel htmlFor="dropdown-store">Store</InputLabel>
            <Select
              onChange={(e) => handleStoreChange(e)}
              color="primary"
              label="Store"
              inputProps={{
                name: "store",
                id: "dropdown-store",
              }}
              value={store}
            >
              <MenuItem value="0">Not Selected</MenuItem>
              {storeDropDown !== undefined &&
                storeDropDown.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <LocationStoreAccessList store={store} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormControl
            color="primary"
            variant="outlined"
            fullWidth
            margin="normal"
          >
            <InputLabel htmlFor="dropdown-location">Stock Location</InputLabel>
            <Select
              onChange={(e) => handleLocationChange(e)}
              color="primary"
              label="Stock Location"
              inputProps={{
                name: "location",
                id: "dropdown-location",
              }}
              value={location}
            >
              <MenuItem value="0">Not Selected</MenuItem>
              {stockLocationDropdown !== undefined &&
                stockLocationDropdown.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <LocationStockLocationAccessList location={location} />
        </Grid>
      </Grid>
    </div>
  );
});
