import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import { observer } from "mobx-react-lite";
import { DropDownProps } from "../../models/dropDownProps";
import { useStore } from "../../stores/store";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

interface Props {
  title: React.ReactNode;
  items: DropDownProps[];
  loading: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      height: "45vh",
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
      flexDirection: "column",
    },
    checkbox: {
      padding: 0,
    },
  })
);

export default observer(function TransferList({
  title,
  items,
  loading,
}: Props) {
  const classes = useStyles();
  const { transferListStore } = useStore();
  const { itemsChecked, setItemsChecked, not, intersection, union } =
    transferListStore;

  const numberOfChecked = (items: DropDownProps[]) =>
    intersection(itemsChecked, items).length;

  const handleToggleAll = (items: DropDownProps[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setItemsChecked(not(itemsChecked, items));
    } else {
      setItemsChecked(union(itemsChecked, items));
    }
  };

  const handleToggle = (value: DropDownProps) => () => {
    const currentIndex = itemsChecked.indexOf(value);
    const newChecked = [...itemsChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setItemsChecked(newChecked);
  };

  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            className={classes.checkbox}
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {loading ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            {items.map((item) => {
              const labelId = `transfer-list-all-item-${item.value}-label`;
              return (
                <ListItem
                  key={item.value}
                  role="listitem"
                  button
                  onClick={handleToggle(item)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={itemsChecked.indexOf(item) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={item.text} />
                </ListItem>
              );
            })}
            <ListItem />
          </>
        )}
      </List>
    </Card>
  );
});
