import { Documents } from "./document";
import { ItemDocument } from "./item";
import { Vendor } from "./vendor";

export interface PurchaseOrderDetail extends ItemDocument {
  id: string;
  itemId: string;
  purchaseOrderId: string;
  qty: number;
  price: number;
  totalAmount: number;
}

export interface PurchaseOrder extends Documents {
  stockLocationId: string;
  stockLocation: string;
  vendor: Vendor;
  inputDate: Date;
  lastUpdatedDate: Date | null;
  lastUpdatedBy: string;
  details: PurchaseOrderDetail[];
}

export class PurchaseOrderDetailValues {
  id?: string = undefined;
  purchaseOrderId?: string = undefined;
  itemId: string = "";
  price?: number = undefined;
  qty?: number = undefined;

  constructor(details?: PurchaseOrderDetailValues) {
    if (details) {
      this.id = details.id;
      this.purchaseOrderId = details.purchaseOrderId;
      this.itemId = details.itemId;
      this.price = details.price;
      this.qty = details.qty;
    }
  }
}

export class PurchaseOrderFormValues {
  id?: string = undefined;
  date: Date = new Date();
  stockLocationId: string = "";
  vendorId: string = "";
  details: PurchaseOrderDetailValues[] = [];

  constructor(purchaseOrder?: PurchaseOrderFormValues) {
    if (purchaseOrder) {
      this.id = purchaseOrder.id;
      this.date = new Date(purchaseOrder.date);
      this.stockLocationId = purchaseOrder.stockLocationId;
      this.vendorId = purchaseOrder.vendorId;
      this.details = purchaseOrder.details;
    }
  }
}
