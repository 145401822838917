import { observer } from "mobx-react-lite";
import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRouteWithMenu from "../../app/layout/PrivateRouteWithMenu";
import Title from "../../app/layout/Title";
import UserForm from "./UserForm";
import UserList from "./UserList";

export default observer(function User() {
  let { path } = useRouteMatch();

  return (
    <>
      <Title menuName="Register User" />
      <Switch>
        <PrivateRouteWithMenu exact path={path} component={UserList} link={path} />
        <PrivateRouteWithMenu path={`${path}/form`} component={UserForm} link={path} />
        <PrivateRouteWithMenu
          path={"/registeruser/:id"}
          component={UserForm}
          link={path}
        />
      </Switch>
    </>
  );
});
