import { observer } from "mobx-react-lite";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRouteWithMenu from "../../app/layout/PrivateRouteWithMenu";
import Title from "../../app/layout/Title";
import ProfitSetupForm from "./ProfitSetupForm";
import ProfitSetupList from "./ProfitSetupList";

export default observer(function ProfitSetup() {
  let { path } = useRouteMatch();

  return (
    <>
      <Title menuName="Profit Setup" />
      <Switch>
        <PrivateRouteWithMenu
          exact
          path={path}
          component={ProfitSetupList}
          link={path}
        />
        <PrivateRouteWithMenu
          path={`${path}/form`}
          component={ProfitSetupForm}
          link={path}
        />
        <PrivateRouteWithMenu
          path={`${path}/:id`}
          component={ProfitSetupForm}
          link={path}
        />
      </Switch>
    </>
  );
});
