import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { ProfitSetup } from "../../app/models/profitSetup";

interface RowProps {
  row: ProfitSetup;
  labelId: string;
  isItemSelected: boolean;
  handleClick: (event: React.MouseEvent<unknown>, name: string) => void;
}

export default function ProfitSetupTableRow({
  row,
  labelId,
  isItemSelected,
  handleClick,
}: RowProps) {
  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            onClick={(event) => handleClick(event, row.id)}
          />
        </TableCell>
        <TableCell padding="checkbox">
          <Tooltip title="Update">
            <IconButton
              component={Link}
              to={`/profitsetup/${row.id}`}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell component="th" id={labelId} scope="row">
          {row.stockLocation}
        </TableCell>
        <TableCell>{row.item.itemCode}</TableCell>
        <TableCell>{row.item.itemDescription}</TableCell>
        <TableCell>{row.item.unitDescription}</TableCell>
        <TableCell>{row.stockTransactionGroup}</TableCell>
        <TableCell align="center">{row.type}</TableCell>
        <TableCell align="right">
          {row.amount.toLocaleString("EN-US")}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
