import {
  Card,
  CardHeader,
  CardContent,
  Chip,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Grid,
  Typography,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { Vendor } from "../../app/models/vendor";
import { useStyles } from "../../app/layout/style";
import { useState } from "react";

interface Props {
  vendor: Vendor;
  deleteVendor: (id: string) => void;
}

const useLocalStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
    },
  })
);

export default observer(function VendorCard({ vendor, deleteVendor }: Props) {
  const classes = useStyles();
  const local = useLocalStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = (id: string) => {
    setAnchorEl(null);
    deleteVendor(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        avatar={
          <Avatar className={local.avatar}>
            {vendor.name.substring(5, 4)}
          </Avatar>
        }
        action={
          <>
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem component={Link} to={`/vendors/${vendor.id}`}>
                Update
              </MenuItem>
              <MenuItem onClick={() => handleDelete(vendor.id)}>
                Delete
              </MenuItem>
            </Menu>
          </>
        }
        title={
          <Typography variant="subtitle1" component="p">
            {vendor.name}
          </Typography>
        }
      />
      <CardContent className={classes.content}>
        <Typography variant="body1" component="p">
          Price List
        </Typography>
        <Divider />
        {vendor.items.map((item, index) => (
          <div key={index}>
            <div className={classes.content}>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <Avatar
                    alt={item.itemCode}
                    src={item.imageUrl ? item.imageUrl : "/no-image.png"}
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle1" component="p">
                    {item.itemCode}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" component="p">
                    {`IDR ${item.price.toLocaleString("en-US")} / ${
                      item.unitDescription
                    }`}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <Divider />
          </div>
        ))}
        <div className={classes.chip}>
          <Chip
            variant="outlined"
            color="primary"
            label={vendor.locationName === "" ? "OUTSOURCE" : "SOURCE"}
          />
          {!vendor.isActive && (
            <Chip
              icon={<SentimentVeryDissatisfiedIcon />}
              variant="outlined"
              color="secondary"
              label="Deleted"
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
});
