import Autocomplete from "@material-ui/lab/Autocomplete";
import { CustomerGranted } from "../../app/models/customer";
import TextField from "@material-ui/core/TextField";
import { CircularProgress, Typography } from "@material-ui/core";
import { AvatarName } from "../../app/models/table";
import { useState } from "react";
import { observer } from "mobx-react-lite";

interface Props {
  name: string;
  options: CustomerGranted[] | undefined;
  value: CustomerGranted | null;
  setValue: (value: CustomerGranted | null) => void;
  optionDisabled?: CustomerGranted[];
  loading: boolean;
  onChange: (
    value: CustomerGranted | null,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  touched: boolean | undefined;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
  error: string | undefined;
  variant: "standard" | "filled" | "outlined" | undefined;
}

export default observer(function CustomerAutoComplete({
  name,
  options,
  value,
  setValue,
  optionDisabled,
  loading,
  onChange,
  setFieldValue,
  touched,
  setFieldTouched,
  error,
  variant,
}: Props) {
  const [open, setOpen] = useState(false);
  const loadingCustomer = open && options?.length === 0;

  const handleOptionDisabled = (item: CustomerGranted) => {
    if (optionDisabled) return optionDisabled.some((x) => x.id === item.id);
    else return false;
  };

  const handleChange = (value: CustomerGranted | null) => {
    setValue(value);
    onChange(value, setFieldValue);
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options === undefined ? [] : options}
      getOptionLabel={(option) => option.fullName.toLocaleUpperCase()}
      getOptionSelected={(option, value) =>
        option.fullName === value.fullName
      }
      getOptionDisabled={(option) => handleOptionDisabled(option)}
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue !== "string") {
          handleChange(newValue);
        }
      }}
      renderOption={(option) => (
        <div style={{ width: "100%" }}>
          <Typography variant="subtitle1" component="p" noWrap>
            {option.fullName}
          </Typography>
          <Typography
            variant="subtitle2"
            component="p"
            color="secondary"
            noWrap
          >
            {new AvatarName(option.storeName).getAvatarName}
          </Typography>
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label="Customer"
          placeholder="Please select customer."
          error={touched && !!error}
          onBlur={() => {
            setFieldTouched(name, true);
          }}
          margin="dense"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadingCustomer ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      loading={loadingCustomer}
      disabled={loading}
    />
  );
});
