import React from "react";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router";
import { useStore } from "../stores/store";

interface Props extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  link: string;
}

export default function PrivateRouteWithMenu({ component: Component, link, ...rest }: Props) {
  const {
    userStore: { isLoggedIn },
    menuStore: { allowAccess },
  } = useStore();

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          allowAccess(link) ? (
            <Component {...props} />
          ) : (
            <Redirect to="/not-found" />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}
