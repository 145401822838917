import { Hidden } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import SearchInput from "../../app/common/form/SearchInput";
import { StockOutgoing } from "../../app/models/stockoutgoing";
import { useStore } from "../../app/stores/store";
import StockOutgoingTable from "./StockOutgoingTable";
import StockOutgoingCardList from "./StockOutgoingCardList";
import SortByChip from "../../app/layout/SortByChip";

export default observer(function StockOutgoingList() {
  const { stockOutgoingStore } = useStore();
  const {
    setPredicate,
    sortByElement,
    setSortBy,
    setOrderBy,
    orderBy,
    sortBy,
    filter,
  } = stockOutgoingStore;
  const [search, setSearch] = useState({
    search: filter,
  });

  const handleSortByClick = (value: Extract<keyof StockOutgoing, string>) => {
    if (sortBy === value) setOrderBy(orderBy === "asc" ? "desc" : "asc");
    else setSortBy(value);
  };

  const handleSearchClick = (values: any) => {
    setPredicate(values.search);
    setSearch({ search: values.search });
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item lg={8} md={6} xs={12}>
          <Hidden lgUp implementation="css">
            <SortByChip
              sortByElement={sortByElement}
              sortBy={sortBy}
              orderBy={orderBy}
              handleSortByClick={handleSortByClick}
            />
          </Hidden>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Formik
            enableReinitialize
            initialValues={search}
            onSubmit={(values) => handleSearchClick(values)}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <SearchInput placeholder="Search..." name="search" autoFocus />
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Hidden lgUp implementation="css">
            <StockOutgoingCardList />
          </Hidden>
          <Hidden mdDown implementation="css">
            <StockOutgoingTable />
          </Hidden>
        </Grid>
      </Grid>
    </>
  );
});
