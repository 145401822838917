import Paper from "@material-ui/core/Paper";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Dropdown from "../../app/common/form/Dropdown";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateInputField from "../../app/common/form/DateInputFIeld";
import {
  PurchaseOrder,
  PurchaseOrderDetail,
  PurchaseOrderDetailValues,
  PurchaseOrderFormValues,
} from "../../app/models/purchaseOrder";
import { CustomerItem } from "../../app/models/customer";
import PurchaseOrderFormAddDetails from "./PurchaseOrderFormAddDetails";

export default observer(function PurchaseOrderForm() {
  const classes = useStyles();
  const { purchaseOrderStore, snackbarStore, userStore, vendorStore } =
    useStore();
  const { stockLocationGranted } = userStore;
  const { loadDropDown, vendorDropDown, loading: loadingVendor } = vendorStore;
  const { openSnackbar } = snackbarStore;
  const { createPurchaseOrder, loading, loadDocument, updatePurchaseOrder } =
    purchaseOrderStore;
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrderFormValues>(
    new PurchaseOrderFormValues()
  );
  const [items, setItems] = useState<CustomerItem[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<CustomerItem[]>([]);
  const [details, setDetails] = useState<PurchaseOrderDetail[]>([]);
  const [stockLocationId, setStockLocationId] = useState<string>();
  const [value, setValue] = useState<CustomerItem | null>(null);
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadDropDown();
    if (id) {
      loadDocument(id).then((result) => {
        loadDocumentResult(result);
      });
    }
  }, [id, loadDropDown, loadDocument]);

  const loadDocumentResult = (result: PurchaseOrder | undefined) => {
    if (result) {
      setItems(result.vendor.items);
      setStockLocationId(result.stockLocationId);
      setDetails(result.details);
      setSelectedGroup(
        result.details.map((x) => {
          return {
            id: x.itemId,
            itemCode: x.itemCode,
            itemDescription: x.itemDescription,
            unitDescription: x.unitDescription,
            price: x.price,
            imageUrl: x.imageUrl,
          } as CustomerItem;
        })
      );
      setPurchaseOrder(
        new PurchaseOrderFormValues({
          id: result.id,
          date: result.date,
          stockLocationId: result.stockLocationId,
          vendorId: result.vendor.id,
          details: result.details.map((x) => {
            return {
              id: x.id,
              purchaseOrderId: x.purchaseOrderId,
              itemId: x.itemId,
              price: x.price,
              qty: x.qty,
            } as PurchaseOrderDetailValues;
          }),
        })
      );
    }
  };

  const handleStockLocationChange = (
    e: React.ChangeEvent<{ value: unknown }>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const location = stockLocationGranted.find((x) => x.id === e.target.value);
    setStockLocationId(location?.id);
    setFieldValue("vendorId", "");
    setValue(null);
    setDetails([]);
    setItems([]);
    setSelectedGroup([]);
    setFieldValue("details", []);
  };

  const handleVendorChange = (
    e: React.ChangeEvent<{ value: unknown }>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const vendor = vendorDropDown.find(
      (x) => x.id === (e.target.value as string)
    );
    vendor ? setItems(vendor.items) : setItems([]);
    setValue(null);
    setDetails([]);
    setSelectedGroup([]);
    setFieldValue("details", []);
  };

  const schema = Yup.object({
    date: Yup.date().required("Date is required."),
    stockLocationId: Yup.string().required("Stock location field is required."),
    vendorId: Yup.string().required("Vendor field is required."),
    details: Yup.array().of(
      Yup.object().shape({
        price: Yup.number()
          .typeError("Price is must be a number.")
          .required("Allocation is required."),
        qty: Yup.number()
          .typeError("Quantity is must be a number.")
          .positive("Quantity is must be greater than zero.")
          .required("Quantity is required."),
      })
    ),
  });

  const handleFormSubmit = (
    purchaseOrder: PurchaseOrderFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!purchaseOrder.id) {
      let newPurchaseOrder = {
        ...purchaseOrder,
        id: uuid(),
      };
      createPurchaseOrder(newPurchaseOrder)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setPurchaseOrder(new PurchaseOrderFormValues());
            setItems([]);
            setDetails([]);
            setSelectedGroup([]);
            resetForm();
          }
        });
    } else {
      updatePurchaseOrder(purchaseOrder)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/purchaseorder");
          }
        });
    }
  };

  return (
    <>
      <Paper className={classes.form}>
        <Typography variant="h5">Form {id ? "Update" : "Create"}</Typography>
        <Divider />
        <Formik
          validationSchema={schema}
          enableReinitialize
          initialValues={purchaseOrder}
          onSubmit={(values, { resetForm, setSubmitting }) =>
            handleFormSubmit(values, resetForm, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isSubmitting,
            isValid,
            values,
            setFieldValue,
            validateForm,
          }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <DateInputField
                    label="Date"
                    name="date"
                    placeholder="Please input Date"
                    disabled={isSubmitting || loading}
                    maxDate={new Date()}
                    inputVariant="standard"
                    margin="normal"
                  />
                  <Dropdown
                    options={stockLocationGranted
                      .filter((x) => !x.isSPBE)
                      .map((x) => {
                        return { text: x.name, value: x.id };
                      })}
                    name="stockLocationId"
                    placeholder="Stock Location"
                    label="Stock Location"
                    onChange={(e) =>
                      handleStockLocationChange(e, setFieldValue)
                    }
                    disabled={isSubmitting || loading}
                  />
                  <Dropdown
                    options={vendorDropDown
                      .filter((x) => x.stockLocationId !== stockLocationId)
                      .map((x) => {
                        return { text: x.name, value: x.id };
                      })}
                    name="vendorId"
                    placeholder="Vendor"
                    label="Vendor"
                    onChange={(e) => handleVendorChange(e, setFieldValue)}
                    disabled={isSubmitting || loading}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <PurchaseOrderFormAddDetails
                    purchaseOrder={values}
                    setPurchaseOrder={setPurchaseOrder}
                    items={items.filter((x) => x.showStock)}
                    details={details}
                    setDetails={setDetails}
                    selectedGroup={selectedGroup}
                    setSelectedGroup={setSelectedGroup}
                    isSubmitting={isSubmitting}
                    loading={loading && loadingVendor}
                    validateForm={validateForm}
                    value={value}
                    setValue={setValue}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="stretch"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || !isValid || details.length === 0}
                  >
                    {isSubmitting && (
                      <CircularProgress
                        className={classes.progress}
                        size={16}
                        color="inherit"
                      />
                    )}
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    component={Link}
                    to="/purchaseorder"
                    disabled={isSubmitting || loading}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
});
