import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableCellTotal,
} from "../../../app/common/table/EnhancedTableHead";
import { useStyles } from "../../../app/layout/style";
import { Credit } from "../../../app/models/report";
import { useStore } from "../../../app/stores/store";

interface Props {
  credit: Credit[] | undefined;
}

export default observer(function ReportCreditTable({ credit }: Props) {
  const classes = useStyles();
  const { creditStore } = useStore();
  const { totalCredit } = creditStore;
  let amount = 0;
  let count = 0;
  let templateSubTotal: JSX.Element | null = null;

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            size="medium"
            aria-label="customized table"
          >
            {credit !== undefined && credit?.length > 0 ? (
              <>
                <TableHead>
                  <TableRow>
                    {/* <StyledTableCell padding="checkbox"></StyledTableCell> */}
                    <StyledTableCell align="center">Date</StyledTableCell>
                    <StyledTableCell align="center">Due Date</StyledTableCell>
                    <StyledTableCell>Invoice</StyledTableCell>
                    <StyledTableCell>Invoice Ref.</StyledTableCell>
                    <StyledTableCell>Customer Name</StyledTableCell>
                    <StyledTableCell align="right">Amount</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {credit.map((row, index) => {
                    let result = [];
                    amount = amount + row.amount;

                    result.push(
                      <StyledTableRow key={index}>
                        {/* <StyledTableCell align="center" padding="checkbox">
                          <Tooltip title="Update">
                            <IconButton
                              component={Link}
                              to={`/invoice/${row.id}?link=report-credit`}
                              color="primary"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell> */}
                        <StyledTableCell align="center">
                          {row.date === null
                            ? ""
                            : moment(row.date).format("DD-MMM-yyyy")}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{
                            color: moment(row.customerDueDate).isBefore(
                              Date.now()
                            )
                              ? "red"
                              : "inherit",
                          }}
                        >
                          {row.customerDueDate === null
                            ? ""
                            : moment(row.customerDueDate).format("DD-MMM-yyyy")}
                        </StyledTableCell>
                        <StyledTableCell>{row.documentNo}</StyledTableCell>
                        <StyledTableCell>{row.invoiceRef}</StyledTableCell>
                        <StyledTableCell>
                          {row.customerName.toLocaleUpperCase()}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.amount.toLocaleString("en-US")}
                        </StyledTableCell>
                      </StyledTableRow>
                    );

                    if (index < credit.length - 1) {
                      const nextCustomer = credit[index + 1].customerName;
                      count++;

                      if (row.customerName !== nextCustomer) {
                        templateSubTotal = (
                          <StyledTableRow key={`${index}_${count}`}>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="right"
                              colSpan={5}
                            >
                              {`Total for "${row.customerName}" :`}
                            </StyledTableCellTotal>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="right"
                            >
                              {amount.toLocaleString("en-US")}
                            </StyledTableCellTotal>
                          </StyledTableRow>
                        );
                        if (count > 1) result.push(templateSubTotal);
                        count = 0;
                        amount = 0;
                      }
                    } else {
                      templateSubTotal = (
                        <StyledTableRow key={`${index}_${count}`}>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="right"
                            colSpan={5}
                          >
                            {`Total for "${row.customerName}" :`}
                          </StyledTableCellTotal>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="right"
                          >
                            {amount.toLocaleString("en-US")}
                          </StyledTableCellTotal>
                        </StyledTableRow>
                      );
                    }
                    return result;
                  })}
                  {count > 0 && count !== credit.length - 1 && templateSubTotal}
                  <StyledTableRow>
                    <StyledTableCellTotal
                      component="th"
                      scope="row"
                      align="right"
                      colSpan={5}
                    >
                      Grand Total :
                    </StyledTableCellTotal>
                    <StyledTableCellTotal
                      component="th"
                      scope="row"
                      align="right"
                    >
                      {totalCredit(credit).toLocaleString("en-US")}
                    </StyledTableCellTotal>
                  </StyledTableRow>
                </TableBody>
              </>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell colSpan={8}>No Data.</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
});
