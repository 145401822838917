import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import {
  AssetLendingBalance,
  AssetLendingBalanceSearch,
} from "../models/report";
import { TableStore } from "./tableStore";

export default class AssetLendingBalanceStore extends TableStore<AssetLendingBalance> {
  assetLendingBalanceRegistry: AssetLendingBalance[] = [];
  totalAssetLendingBalance = new Map<string, number>();

  constructor() {
    super();
    makeObservable(this, {
      assetLendingBalanceRegistry: observable,
      totalAssetLendingBalance: observable,
      loadReportAssetLendingBalance: action,
      totalList: computed,
    });

    this.setPredicate(new AssetLendingBalanceSearch());

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadReportAssetLendingBalance();
      }
    );
  }
  
  setPredicate = (search: AssetLendingBalanceSearch) => {
    runInAction(() => {
      Object.keys(search).forEach((key) => {
        this.predicate.delete(key);
      });
      this.predicate.set("customerName", search.customerName);
      this.predicate.set("date", search.date.toLocaleDateString("EN-US"));
      this.predicate.set("itemId", search.itemId);
    });
  };

  get filter() {
    return new AssetLendingBalanceSearch({
      customerName:
        this.predicate.get("customerName") === undefined
          ? ""
          : this.predicate.get("customerName")!,
      date:
        this.predicate.get("date") === undefined
          ? new Date()
          : new Date(this.predicate.get("date")!),
      itemId:
        this.predicate.get("itemId") === undefined
          ? ""
          : this.predicate.get("itemId")!,
    });
  }

  loadReportAssetLendingBalance = async () => {
    this.loading = true;
    this.totalAssetLendingBalance.clear();

    try {
      const result = await agent.Reports.reportAssetLendingBalance(
        this.axiosParams
      );
      for await (const x of result) {
        runInAction(() => {
          x.details.forEach((detail) => {
            const totalQty =
              x.details
                .filter((x) => x.itemCode === detail.itemCode)
                .reduce(
                  (total, currentData) => (total = total + currentData.qty),
                  0
                ) || 0;
            const lastTotalQty =
              this.totalAssetLendingBalance.get(detail.itemCode) || 0;
            this.totalAssetLendingBalance.set(
              detail.itemCode,
              totalQty + lastTotalQty
            );
          });
        });
      }
      runInAction(() => {
        this.assetLendingBalanceRegistry = result;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get totalList() {
    return Array.from(this.totalAssetLendingBalance.keys()).sort();
  }
}
