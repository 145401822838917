import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { CustomerItem } from "../models/customer";
import { Item, ItemFormValues, Unit } from "../models/item";
import { PaginatedResult } from "../models/pagination";
import { TableStore } from "./tableStore";

export default class ItemStore extends TableStore<Item> {
  itemsRegistry = new Map<string, Item>();
  itemsDropDown: CustomerItem[] = [];
  units: Unit[] = [];

  constructor() {
    super();
    makeObservable(this, {
      itemsRegistry: observable,
      itemsDropDown: observable,
      units: observable,
      loadItem: action,
      itemList: computed,
      loadItems: action,
      loadUnits: action,
      loadDropDown: action,
      createItem: action,
      updateItem: action,
      deleteItem: action,
    });

    this.setPageNumber(1);
    this.setSortBy(null);
    this.setOrderBy("asc");
    this.sortByElement = [
      { id: "itemDescription", label: "Item Description" },
      { id: "itemCode", label: "Item Code" },
      { id: "unitDescription", label: "Unit" },
    ];

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadItems();
      }
    );
  }

  private loadItemsProcess = (result: PaginatedResult<Item[]>) => {
    runInAction(() => {
      this.itemsRegistry.clear();
      result.data.forEach((item) => {
        this.itemsRegistry.set(item.id, item);
      });
      this.pagination = result.pagination;
    });
  };

  loadItems = async () => {
    this.loading = true;

    try {
      const result = await agent.Items.list(this.axiosParams);
      this.loadItemsProcess(result);
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get itemList() {
    return Array.from(this.itemsRegistry);
  }

  loadItem = async (id: string) => {
    this.loading = true;

    try {
      const result = await agent.Items.details(id);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  loadUnits = async () => {
    this.loading = true;

    try {
      const result = await agent.Items.loadUnit();
      runInAction(() => {
        this.units = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  loadDropDown = async () => {
    try {
      const dropdown = await agent.Items.dropdown();
      runInAction(() => {
        this.itemsDropDown = dropdown;
      });
    } catch (error) {
      console.log(error);
    }
  };

  createItem = async (item: ItemFormValues, file: any) => {
    try {
      let newItem = item;
      if (file) {
        const result = await agent.Photos.uploadPhoto(file);
        newItem = { ...item, imageId: result.data.id };
      }
      await agent.Items.create(newItem);
      const result = await agent.Items.list(this.axiosParams);
      this.loadItemsProcess(result);
      return "Create item success!";
    } catch (error) {
      throw error;
    }
  };

  updateItem = async (item: ItemFormValues, file: any) => {
    try {
      const oldImageId = item.imageId;
      let updatedItem = item;

      if (file) {
        const result = await agent.Photos.uploadPhoto(file);
        updatedItem = { ...item, imageId: result.data.id };
      }

      const resultUpdate = await agent.Items.update(updatedItem);
      runInAction(() => {
        this.itemsRegistry.set(resultUpdate.id, resultUpdate);
      });

      if (file) {
        if (oldImageId) {
          await agent.Photos.deletePhoto(oldImageId);
        }
      }
      return "Update item success!";
    } catch (error) {
      throw error;
    }
  };

  deleteItem = async (id: string) => {
    this.loading = true;

    try {
      await agent.Items.delete(id);
      const result = await agent.Items.list(this.axiosParams);
      this.loadItemsProcess(result);
      return "Delete item success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };
}
