import { Documents } from "./document";
import { ItemDocument } from "./item";
import {
  BankAccount,
  InvoiceSearch,
  PaymentType,
  SalesDetail,
  SalesPayment,
} from "./sale";
import { SpendingGroup } from "./spending";

export class StockAllocationSearch {
  stockLocationId: string = "";
  startDate: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  endDate: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  );

  constructor(search?: StockAllocationSearch) {
    if (search) {
      this.stockLocationId = search.stockLocationId;
      this.startDate = search.startDate;
      this.endDate = search.endDate;
    }
  }
}

export interface StockAllocation {
  sourceStockLocationId: string;
  sourceStockLocation: string;
  moveToStockLocationId: string;
  moveToStockLocation: string;
  date?: Date;
  documentNo: string;
  itemDescription: string;
  stockTransactionGroup: string;
  unitDescription: string;
  allocationQty: number;
  realizationQty: number;
  giQty: number;
  totalQty: number;
}

export class CashFlowSearch {
  filterBy: string = "paymentDate";
  storeId: string = "";
  date: Date = new Date();
  onlySales = false;
  driverId: string = "";

  constructor(search?: CashFlowSearch) {
    if (search) {
      this.filterBy = search.filterBy;
      this.storeId = search.storeId;
      this.date = search.date;
      this.onlySales = search.onlySales;
      this.driverId = search.driverId;
    }
  }
}

export interface CashFlow {
  date: Date;
  description: string;
  transactionDate?: Date;
  customerCode: string;
  customerName: string;
  paymentType: PaymentType;
  bankAccount: BankAccount;
  spendingGroup: SpendingGroup;
  amount: number;
}

export interface SalesSummary extends Documents {
  customerCode: string;
  customerName: string;
  driverName: string;
  tax: number;
  disc: number;
  details: SalesDetail[];
  payments: SalesPayment[];
  isDelivered: boolean;
  dueDate: string;
  shift: string;
  invoiceRef: string;
}

export class InvoiceSummarySearch extends InvoiceSearch {
  startDate: Date = new Date();

  constructor(search?: InvoiceSummarySearch) {
    super();

    if (search) {
      this.storeId = search.storeId;
      this.startDate = search.startDate;
      this.endDate = search.endDate;
      this.customerName = search.customerName;
      this.documentNo = search.documentNo;
      this.driverId = search.driverId;
      this.deliveryStatus = search.deliveryStatus;
      this.shift = search.shift;
    }
  }
}

export class InvoiceByCustomerSearch {
  storeId: string = "";
  startDate: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  endDate: Date = new Date();
  customerName: string = "";
  itemId: string = "";

  constructor(search?: InvoiceByCustomerSearch) {
    if (search) {
      this.storeId = search.storeId;
      this.startDate = search.startDate;
      this.endDate = search.endDate;
      this.customerName = search.customerName;
      this.itemId = search.itemId;
    }
  }
}

export interface SalesByCustomer extends ItemDocument {
  storeName: string;
  customerCode: string;
  customerName: string;
  price: number;
  qty: number;
}

export class StockBalanceSearch {
  stockLocationId: string = "";
  date: Date = new Date();

  constructor(search?: StockBalanceSearch) {
    if (search) {
      this.stockLocationId = search.stockLocationId;
      this.date = search.date;
    }
  }
}

export interface StockBalance extends ItemDocument {
  qty: number;
}

export class StockDetailSearch {
  stockLocationId: string = "";
  startDate: Date = new Date();
  endDate: Date = new Date();
  documentNo: string = "";

  constructor(search?: StockDetailSearch) {
    if (search) {
      this.stockLocationId = search.stockLocationId;
      this.startDate = search.startDate;
      this.endDate = search.endDate;
      this.documentNo = search.documentNo;
    }
  }
}

export interface StockDetail extends ItemDocument {
  documentNo: string;
  date: Date;
  qty: number;
  notes: string;
}

export class StockHistorySearch {
  stockLocationId: string = "";
  startDate: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  endDate: Date = new Date();

  constructor(search?: StockHistorySearch) {
    if (search) {
      this.stockLocationId = search.stockLocationId;
      this.startDate = search.startDate;
      this.endDate = search.endDate;
    }
  }
}

export interface StockHistory extends ItemDocument {
  date: Date;
  description: string;
  in: number;
  out: number;
  total: number;
}

export class CustomerDepositSearch {
  customerName: string = "";
  storeId: string = "";

  constructor(search?: CustomerDepositSearch) {
    if (search) {
      this.customerName = search.customerName;
      this.storeId = search.storeId;
    }
  }
}

export interface CustomerDeposit {
  customerName: string;
  customerCode: string;
  storeName: string;
  amount: number;
}

export class CreditSearch {
  customerName: string = "";
  storeId: string = "";
  startDate: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  endDate: Date = new Date();
  documentNo: string = "";

  constructor(search?: CreditSearch) {
    if (search) {
      this.customerName = search.customerName;
      this.storeId = search.storeId;
      this.startDate = search.startDate;
      this.endDate = search.endDate;
      this.documentNo = search.documentNo;
    }
  }
}

export interface Credit {
  id: string;
  date: Date;
  customerDueDate: Date;
  documentNo: string;
  customerName: string;
  customerCode: string;
  invoiceRef: string;
  amount: number;
}

export class CreditPaymentSearch {
  storeId: string = "";
  date: Date = new Date();
  customerName: string = "";

  constructor(search?: CreditPaymentSearch) {
    if (search) {
      this.storeId = search.storeId;
      this.date = search.date;
      this.customerName = search.customerName;
    }
  }
}

export interface CreditPayment {
  id: string;
  salesDate: Date;
  paymentDate: Date;
  paymentType: PaymentType;
  bankAccount?: BankAccount;
  documentNo: string;
  customerCode: string;
  customerName: string;
  salesAmount: number;
  paymentAmount: number;
}

export class AssetLendingBalanceSearch {
  date: Date = new Date();
  customerName: string = "";
  itemId: string = "";

  constructor(search?: AssetLendingBalanceSearch) {
    if (search) {
      this.date = search.date;
      this.customerName = search.customerName;
      this.itemId = search.itemId;
    }
  }
}

export interface AssetLendingBalance extends ItemDocument {
  customerCode: string;
  customerName: string;
  storeName: string;
  details: AssetLendingDetailBalance[];
}

export interface AssetLendingDetailBalance extends ItemDocument {
  qty: number;
}

export class ProfitAndLossSearch {
  startDate: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  endDate: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  );
  storeId: string = "";

  constructor(search?: ProfitAndLossSearch) {
    if (search) {
      this.startDate = search.startDate;
      this.endDate = search.endDate;
      this.storeId = search.storeId;
    }
  }
}

export interface ProfitAndLoss extends ItemDocument {
  description: string;
  qty: number;
  amount: number;
  totalAmount: number;
}

export interface SpendingGroupReport {
  group: string;
  amount: number;
}

export interface CreditGroup {
  customerCode: string;
  customerName: string;
  amount: string;
}

export class SalesByQuantitySearch {
  storeId: string = "";
  month: Date = new Date();
  itemId: string = "";
  isVerified = false;

  constructor(search?: SalesByQuantitySearch) {
    if (search) {
      this.month = search.month;
      this.storeId = search.storeId;
      this.itemId = search.itemId;
      this.isVerified = search.isVerified;
    }
  }
}

export interface SalesByQuantity {
  customerCode: string;
  customerName: string;
  details: SalesByQuantityDetail[];
}

export interface SalesByQuantityDetail {
  date: number;
  qty: number;
}
