import { Documents } from "./document";
import { ItemDocument } from "./item";

export interface StockTransactionType {
  id: string;
  type: string;
  isAdjusment: boolean;
}

export interface Stock {
  size: string;
  fullCode: string;
  qty: number;
}

export interface StockControlDetail extends ItemDocument {
  id: string;
  stockControlId: string;
  itemId: string;
  stockTransactionType: StockTransactionType;
  qty: number;
  notes: string;
}

export interface StockControl extends Documents {
  stockLocationId: string;
  stockLocationName: string;
  inputDate: Date;
  lastUpdatedDate: Date | null;
  lastUpdatedBy: string;
  details: StockControlDetail[];
}

export class StockControlDetailValues {
  id: string = "";
  stockControlId: string = "";
  stockTransactionTypeId: string = "";
  itemId: string = "";
  qty?: number = undefined;
  notes?: string = undefined;

  constructor(details?: StockControlDetailValues) {
    if (details) {
      this.id = details.id;
      this.stockControlId = details.stockControlId;
      this.stockTransactionTypeId = details.stockTransactionTypeId;
      this.itemId = details.itemId;
      this.qty = details.qty;
      this.notes = details.notes;
    }
  }
}

export class StockControlFormValues {
  id: string = "";
  date: Date = new Date();
  stockLocationId: string = "";
  details: StockControlDetailValues[] = [];

  constructor(stockControl?: StockControlFormValues) {
    if (stockControl) {
      this.id = stockControl.id;
      this.date = new Date(stockControl.date);
      this.stockLocationId = stockControl.stockLocationId;
      this.details = stockControl.details;
    }
  }
}
