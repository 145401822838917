export interface MasterMenu {
  id: string;
  parentId?: string;
  menuName: string;
  menuLink: string;
  logo: string;
  isHome: boolean;
  isDefault: boolean;
  childMenus?: MasterMenu[];
  collapse: boolean;
}

export class MasterMenu implements MasterMenu {
  constructor(init?: MasterMenuFormValues) {
    Object.assign(this, init);
    this.childMenus = [];
  }
}

export interface MasterMenuData {
  id: string;
  menuName: string;
  menuLink: string;
  logo: string;
  type: string;
}

export class MasterMenuData implements MasterMenuData {
  constructor(menu?: MasterMenu, parentMenu?: MasterMenu) {
    if (menu) {
      this.id = menu.id;
      this.menuName = parentMenu
        ? `${parentMenu.menuName}/${menu.menuName}`
        : menu.menuName;
      this.menuLink = menu.menuLink;
      this.logo = menu.logo;
      this.type = menu.isDefault ? "Parent" : "Child";
    }
  }
}

export class MasterMenuFormValues {
  id?: string = undefined;
  parentId?: string = "";
  menuName: string = "";
  menuLink: string = "";
  logo: string = "";
  isHome: boolean = false;
  isDefault: boolean = true;
  collapse: boolean = false;

  constructor(menu?: MasterMenuFormValues) {
    if (menu) {
      this.id = menu.id;
      this.parentId = menu.parentId;
      this.menuName = menu.menuName;
      this.menuLink = menu.menuLink;
      this.logo = menu.logo;
      this.isHome = menu.isHome;
      this.isDefault = menu.isDefault;
    }
  }
}
