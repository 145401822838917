import { DatePicker } from "@material-ui/pickers";
import { useField } from "formik";
import { PropTypes } from "@material-ui/core";

interface Props {
  placeholder?: string;
  name: string;
  label?: string;
  helperText?: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  onChange?: (date: any) => void;
  inputVariant: "standard" | "filled" | "outlined" | undefined;
  margin?: PropTypes.Margin;
}

export default function MonthInputField(props: Props) {
  const [field, meta, helpers] = useField(props.name);

  const handleChange = (date: any) => {
    helpers.setValue(date);
    if (props.onChange !== undefined) {
      props.onChange(date);
    }
  };

  return (
    <DatePicker
      {...field}
      {...props}
      autoOk
      inputVariant={props.inputVariant}
      views={["year", "month"]}
      variant="dialog"
      id={props.name}
      format="MMM-yyyy"
      margin={props.margin ? props.margin : "normal"}
      color="primary"
      onChange={handleChange}
      fullWidth
      minDate={props.minDate}
      maxDate={props.maxDate}
      disabled={props.disabled}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error ? meta.error : props.helperText}
      InputLabelProps={{
        shrink: true,
      }}
      emptyLabel=""
    />
  );
}
