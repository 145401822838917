import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Item } from "../../app/models/item";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { useStyles } from "../../app/layout/style";

interface Props {
  item: Item;
  deleteItem: (id: string) => void;
}

export default observer(function ItemCard({ item, deleteItem }: Props) {
  const classes = useStyles();
  
  return (
    <Card>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={item.imageUrl ? item.imageUrl : "/no-image.png"}
          title={item.itemDescription}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {item.itemCode}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            Description : {item.itemDescription}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            Unit : {item.unitDescription}
          </Typography>
          <div className={classes.chip}>
            <Chip
              variant="outlined"
              color="primary"
              label={item.isSalesItem ? "Sales Item" : "Non Sales Item"}
            />
            {!item.isActive && (
              <Chip
                icon={<SentimentVeryDissatisfiedIcon />}
                variant="outlined"
                color="secondary"
                label="Deleted"
              />
            )}
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          component={Link}
          to={`/items/${item.id}`}
          color="primary"
        >
          Update
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={() => deleteItem(item.id)}
        >
          Destroy
        </Button>
      </CardActions>
    </Card>
  );
});
