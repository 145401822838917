import React from "react";
import clsx from "clsx";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Skeleton } from "@material-ui/lab";
import { useStore } from "../../stores/store";

interface EnhancedTableToolbarProps {
  title: string | null;
  numSelected: number;
  handleDelete: (e: React.MouseEvent<unknown>) => void;
  linkAddNew?: string | undefined;
  children?: JSX.Element | undefined;
  loading: boolean;
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      backgroundColor: theme.palette.primary.dark,
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
);

export default observer(function EnhancedTableToolbar({
  title,
  numSelected,
  handleDelete,
  linkAddNew,
  loading = false,
  children,
}: EnhancedTableToolbarProps) {
  const classes = useToolbarStyles();
  const { userStore, salesStore } = useStore();
  const { restricted } = userStore;
  const { documentsSelected } = salesStore;
  let res = React.useMemo(() => {
    let test = 0;
    documentsSelected.forEach((x) => {
      if (restricted(x.date)) test = 1;

      return;
    });
    if (test === 1) return true;
    else return false;
  }, [documentsSelected, restricted]);

  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
        color="primary"
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="subtitle1"
            id="tableTitle"
            component="div"
          >
            {loading ? (
              <Skeleton variant="text" width="30%" />
            ) : (
              title?.toLocaleUpperCase()
            )}
          </Typography>
        )}
        {loading ? (
          <Skeleton variant="circle" width={30} height={30} />
        ) : numSelected > 0 ? (
          <Tooltip title="Delete">
            <>
              {children}
              <IconButton onClick={(e) => handleDelete(e)} disabled={res}>
                <DeleteIcon />
              </IconButton>
            </>
          </Tooltip>
        ) : (
          linkAddNew && (
            <Tooltip title="Create">
              <IconButton
                component={Link}
                to={linkAddNew ? linkAddNew : ""}
                color="default"
                size="medium"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          )
        )}
      </Toolbar>
    </>
  );
});
