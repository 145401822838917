import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  CardContent,
  Menu,
  MenuItem,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Link } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useStyles } from "../../app/layout/style";
import { Customer } from "../../app/models/customer";

interface Props {
  customer: Customer;
  deleteCustomer: (id: string) => void;
}

const useLocalStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
    },
  })
);

export default observer(function CustomerCard(props: Props) {
  const classes = useStyles();
  const local = useLocalStyles();
  const { customer, deleteCustomer } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = (id: string) => {
    setAnchorEl(null);
    deleteCustomer(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        avatar={
          <Avatar className={local.avatar}>
            {customer.customerName.substring(0, 1)}
          </Avatar>
        }
        action={
          <>
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem component={Link} to={`/customers/${customer.id}`}>
                Update
              </MenuItem>
              <MenuItem onClick={() => handleDelete(customer.id)}>
                Delete
              </MenuItem>
            </Menu>
          </>
        }
        title={
          <Typography variant="subtitle1" component="p">
            {customer.fullName}
          </Typography>
        }
        subheader={customer.customerCode}
      />
      <CardContent className={classes.content}>
        <Typography variant="body1" component="p">
          Price List
        </Typography>
        <Divider />
        {customer.items.map((item, index) => (
          <div className={classes.paperCard} key={index}>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <Avatar
                  alt={item.itemCode}
                  src={item.imageUrl ? item.imageUrl : "/no-image.png"}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle1" component="p">
                  {item.itemDescription}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" component="p">
                  {`IDR ${item.price.toLocaleString("en-US")} / ${
                    item.unitDescription
                  }`}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </div>
        ))}
      </CardContent>
    </Card>
  );
});
