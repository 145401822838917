import Paper from "@material-ui/core/Paper";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  StockOutgoing,
  StockOutgoingDetail,
  StockOutgoingDetailValues,
  StockOutgoingFormValues,
  StockTransactionGroup,
} from "../../app/models/stockoutgoing";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Dropdown from "../../app/common/form/Dropdown";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateInputField from "../../app/common/form/DateInputFIeld";
import StockOutgoingFormAddDetails from "./StockOutgoingFormAddDetails";
import { StockLocation } from "../../app/models/location";
import { CustomerItem } from "../../app/models/customer";

export default observer(function StockOutgoingForm() {
  const classes = useStyles();
  const {
    stockOutgoingStore,
    snackbarStore,
    userStore,
    itemStore,
    stockLocationStore,
  } = useStore();
  const { itemsDropDown, loadDropDown: loadDropDownItem } = itemStore;
  const {
    loading,
    loadTransactionGroup,
    loadDocument,
    createStockOutgoing,
    updateStockOutgoing,
  } = stockOutgoingStore;
  const { stockLocationGranted } = userStore;
  const { loadDropDown: loadDropDownStockLocation, stockLocationDropdown } =
    stockLocationStore;
  const { openSnackbar } = snackbarStore;
  const [stockOutgoing, setStockOutgoing] = useState<StockOutgoingFormValues>(
    new StockOutgoingFormValues()
  );
  const [details, setDetails] = useState<StockOutgoingDetail[]>([]);
  const [sourceStockLocation, setSourceStockLocation] =
    useState<StockLocation>();
  const [moveToStockLocation, setMoveToStockLocation] =
    useState<StockLocation>();
  const [valueGroup, setValueGroup] = useState<StockTransactionGroup | null>(
    null
  );
  const [valueItem, setValueItem] = useState<CustomerItem | null>(null);
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadDropDownItem();
    loadDropDownStockLocation();
    loadTransactionGroup();
    if (id) {
      loadDocument(id).then((result) => {
        loadDocumentResult(result);
      });
    }
  }, [id, loadTransactionGroup, loadDropDownItem, loadDropDownStockLocation, loadDocument]);

  const handleChangeSourceStockLocation = (
    e: React.ChangeEvent<{ value: unknown }>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const location = stockLocationGranted.find((x) => x.id === e.target.value);
    setSourceStockLocation(location);
    setFieldValue("moveToStockLocationId", "");
    setMoveToStockLocation(undefined);
    setValueGroup(null);
    setValueItem(null);
    setDetails([]);
    setFieldValue("details", []);
  };

  const handleChangeMoveToStockLocation = (
    e: React.ChangeEvent<{ value: unknown }>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const location = stockLocationDropdown.find((x) => x.id === e.target.value);
    setMoveToStockLocation(location);
    setValueGroup(null);
    setValueItem(null);
    setDetails([]);
    setFieldValue("details", []);
  };

  const loadDocumentResult = (result: StockOutgoing | undefined) => {
    if (result) {
      setDetails(result.details);
      setSourceStockLocation(result.sourceStockLocation);
      setMoveToStockLocation(result.moveToStockLocation);
      setStockOutgoing(
        new StockOutgoingFormValues({
          id: result.id,
          date: result.date,
          sourceStockLocationId: result.sourceStockLocation.id,
          moveToStockLocationId: result.moveToStockLocation.id,
          details: result.details.map(
            (x) =>
              new StockOutgoingDetailValues({
                id: x.id,
                stockOutgoingId: result.id,
                itemId: x.itemId,
                stockTransactionGroupId: x.stockTransactionGroupId,
                allocationQty: x.allocationQty,
                realizationQty: x.realizationQty,
                giQty: x.giQty,
              })
          ),
        })
      );
      setMoveToStockLocation(result.moveToStockLocation);
    }
  };

  const schema = Yup.object({
    date: Yup.date().required("Date is required."),
    sourceStockLocationId: Yup.string().required(
      "Source stock location field is required."
    ),
    moveToStockLocationId: Yup.string().required(
      "Move to stock location field is required."
    ),
    details: Yup.array().of(
      Yup.object().shape({
        allocationQty: Yup.number()
          .typeError("Allocation is must be a number.")
          .required("Allocation is required."),
        realizationQty: Yup.number()
          .typeError("Realization is must be a number.")
          .required("Realization is required."),
        giQty: Yup.number()
          .typeError("GI is must be a number.")
          .required("GI is required."),
      })
    ),
  });

  const handleFormSubmit = (
    stockOutgoing: StockOutgoingFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!stockOutgoing.id) {
      let newStockOutgoing = {
        ...stockOutgoing,
        id: uuid(),
      };
      createStockOutgoing(newStockOutgoing)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setStockOutgoing(new StockOutgoingFormValues());
            setDetails([]);
            resetForm();
          }
        });
    } else {
      updateStockOutgoing(stockOutgoing)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/stockoutgoing");
          }
        });
    }
  };

  return (
    <>
      <Paper className={classes.form}>
        <Typography variant="h5">Form {id ? "Update" : "Create"}</Typography>
        <Divider />
        <Formik
          validationSchema={schema}
          enableReinitialize
          initialValues={stockOutgoing}
          onSubmit={(values, { resetForm, setSubmitting }) =>
            handleFormSubmit(values, resetForm, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isSubmitting,
            isValid,
            values,
            setFieldValue,
            validateForm,
          }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={3}>
                  <DateInputField
                    label="Date"
                    name="date"
                    placeholder="Please input date"
                    disabled={isSubmitting || loading}
                    maxDate={new Date()}
                    inputVariant="standard"
                    margin="normal"
                  />
                  <Dropdown
                    options={stockLocationGranted
                      .filter((x) => x.isSPBE)
                      .map((x) => {
                        return { text: x.name, value: x.id };
                      })}
                    onChange={(e) =>
                      handleChangeSourceStockLocation(e, setFieldValue)
                    }
                    name="sourceStockLocationId"
                    placeholder="Source Stock Location"
                    label="Source to Stock Location"
                    disabled={isSubmitting || loading}
                  />
                  <Dropdown
                    options={stockLocationGranted
                      .filter((x) => !x.isSPBE)
                      .concat(
                        stockLocationDropdown.filter(
                          (x) => x.isSPBE && x.id !== sourceStockLocation?.id
                        )
                      )
                      .map((x) => {
                        return { text: x.name, value: x.id };
                      })}
                    onChange={(e) =>
                      handleChangeMoveToStockLocation(e, setFieldValue)
                    }
                    name="moveToStockLocationId"
                    placeholder="Move to Stock Location"
                    label="Move to Stock Location"
                    disabled={isSubmitting || loading}
                  />
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                  <StockOutgoingFormAddDetails
                    items={itemsDropDown.filter((x) => x.showStock)}
                    stockOutgoing={values}
                    setStockOutgoing={setStockOutgoing}
                    details={details}
                    setDetails={setDetails}
                    isSubmitting={isSubmitting}
                    loading={loading}
                    validateForm={validateForm}
                    sourceStockLocation={sourceStockLocation}
                    stockLocation={moveToStockLocation}
                    valueGroup={valueGroup}
                    setValueGroup={setValueGroup}
                    valueItem={valueItem}
                    setValueItem={setValueItem}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="stretch"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || !isValid || details.length === 0}
                  >
                    {isSubmitting && (
                      <CircularProgress
                        className={classes.progress}
                        size={16}
                        color="inherit"
                      />
                    )}
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    component={Link}
                    to="/stockoutgoing"
                    disabled={isSubmitting || loading}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
});
