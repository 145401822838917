import {
  action,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { StockDetail, StockDetailSearch } from "../models/report";
import { store } from "./store";
import { TableStore } from "./tableStore";

export default class StockDetailStore extends TableStore<StockDetail> {
  stockDetailRegistry: StockDetail[] = [];

  constructor() {
    super();
    makeObservable(this, {
      stockDetailRegistry: observable,
      loadReportStockDetail: action,
    });

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadReportStockDetail();
      }
    );
  }

  setPredicate = (search: StockDetailSearch) => {
    runInAction(() => {
      Object.keys(search).forEach((key) => {
        this.predicate.delete(key);
      });
      this.predicate.set("stockLocationId", search.stockLocationId);
      this.predicate.set(
        "startDate",
        search.startDate.toLocaleDateString("EN-US")
      );
      this.predicate.set("endDate", search.endDate.toLocaleDateString("EN-US"));
      this.predicate.set("documentNo", search.documentNo);
    });
  };

  get filter() {
    return new StockDetailSearch({
      stockLocationId:
        this.predicate.get("stockLocationId") === undefined
          ? store.userStore.storeGranted[0].id
          : this.predicate.get("stockLocationId")!,
      startDate:
        this.predicate.get("startDate") === undefined
          ? new Date()
          : new Date(this.predicate.get("startDate")!),
      endDate:
        this.predicate.get("endDate") === undefined
          ? new Date()
          : new Date(this.predicate.get("endDate")!),
      documentNo:
        this.predicate.get("documentNo") === undefined
          ? ""
          : this.predicate.get("documentNo")!,
    });
  }

  private get params() {
    const params = new URLSearchParams();
    if (this.predicate.get("stockLocationId") === undefined) {
      this.setPredicate({
        stockLocationId: store.userStore.stockLocationGranted.filter(
          (x) => !x.isSPBE
        )[0].id,
        startDate: new Date(),
        endDate: new Date(),
        documentNo: "",
      });
    }
    this.predicate.forEach((value, key) => {
      params.delete(key);
      params.append(key, value);
    });
    return params;
  }

  loadReportStockDetail = async () => {
    this.stockDetailRegistry = [];
    this.loading = true;

    try {
      const result = await agent.Reports.reportStockDetail(this.params);
      runInAction(() => {
        this.stockDetailRegistry = result;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };
}
