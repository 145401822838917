import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  LinearProgress,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableCellTotal,
} from "../../../app/common/table/EnhancedTableHead";
import { useStyles } from "../../../app/layout/style";
import { useStore } from "../../../app/stores/store";

export default observer(function ReportAssetLendingTable() {
  const classes = useStyles();
  const { assetLendingBalanceStore } = useStore();
  const {
    totalList,
    totalAssetLendingBalance,
    assetLendingBalanceRegistry,
    loading,
  } = assetLendingBalanceStore;

  if (loading) return <LinearProgress />;

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            size="medium"
            aria-label="customized table"
          >
            {assetLendingBalanceRegistry.length > 0 ? (
              <>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Store</StyledTableCell>
                    <StyledTableCell>Customer Code</StyledTableCell>
                    <StyledTableCell>Customer Name</StyledTableCell>
                    <>
                      {totalList.map((head, index) => (
                        <StyledTableCell key={index} align="center">
                          {head}
                        </StyledTableCell>
                      ))}
                    </>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assetLendingBalanceRegistry.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{row.storeName}</StyledTableCell>
                      <StyledTableCell>{row.customerCode}</StyledTableCell>
                      <StyledTableCell>{row.customerName}</StyledTableCell>
                      {totalList.map((itemCode, index) => {
                        const details = row.details.filter(
                          (x) => x.itemCode === itemCode
                        );
                        const total = details.reduce(
                          (total, currentData) =>
                            (total = total + currentData.qty),
                          0
                        );
                        if (total < 0)
                          return (
                            <StyledTableCell
                              key={index}
                              align="center"
                              style={{ color: "red" }}
                            >
                              {total.toLocaleString("en-US")}
                            </StyledTableCell>
                          );
                        else
                          return (
                            <StyledTableCell key={index} align="center">
                              {total.toLocaleString("en-US")}
                            </StyledTableCell>
                          );
                      })}
                    </StyledTableRow>
                  ))}
                  <StyledTableRow>
                    <StyledTableCellTotal
                      component="th"
                      scope="row"
                      align="right"
                      colSpan={3}
                    >
                      Grand Total :
                    </StyledTableCellTotal>
                    {totalList?.map((itemCode, index) => (
                      <StyledTableCellTotal
                        component="th"
                        scope="row"
                        key={index}
                        align="center"
                      >
                        {totalAssetLendingBalance
                          .get(itemCode)
                          ?.toLocaleString("en-US")}
                      </StyledTableCellTotal>
                    ))}
                  </StyledTableRow>
                </TableBody>
              </>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell colSpan={totalList.length + 3}>
                    No Data.
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
});
