import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import {
  MasterMenuData,
  MasterMenu,
  MasterMenuFormValues,
} from "../models/masterMenu";
import { PaginatedResult } from "../models/pagination";
import { TableStore } from "./tableStore";

export default class MenuStore extends TableStore<MasterMenuData> {
  menuRegistry = new Map<string, MasterMenu>();
  menuDataTable = new Map<string, MasterMenuData>();
  menus: MasterMenu[] = [];
  menuDropDown: MasterMenu[] = [];
  loadingMenu = false;

  constructor() {
    super();
    makeObservable(this, {
      menuRegistry: observable,
      menuDataTable: observable,
      menus: observable,
      menuDropDown: observable,
      loadingMenu: observable,
      menuList: computed,
      allowAccess: action,
      loadDataTable: action,
      dataTableList: computed,
      loadMenu: action,
      loadDropDown: action,
      setCollapse: action,
      sortMenu: action,
      createMenu: action,
      updateMenu: action,
      deleteDataTable: action,
    });

    this.setPageNumber(1);
    this.setSortBy(null);
    this.setOrderBy("asc");
    this.setRowsOptions([5, 10, 20]);
    this.setPageSize(5);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadDataTable();
      }
    );
  }

  get menuList() {
    return Array.from(this.menuRegistry);
  }

  allowAccess = (location: string | undefined) => {
    let array: MasterMenu[] = [];

    Array.from(this.menuRegistry.values()).forEach((menu) => {
      if (menu.menuLink) array.push(menu);
      else {
        menu.childMenus?.forEach((child) => {
          array.push(child);
        });
      }
    });

    return array.find((x) => x.menuLink === location);
  };

  private loadDataTableProcess = (
    result: PaginatedResult<MasterMenuData[]>
  ) => {
    runInAction(() => {
      this.menuDataTable.clear();
      result.data.forEach((menu) => {
        this.menuDataTable.set(menu.id, menu);
      });
      this.pagination = result.pagination;
    });
  };

  loadDataTable = async () => {
    this.loading = true;

    try {
      const result = await agent.Menus.datatable(this.axiosParams);
      this.loadDataTableProcess(result);
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get dataTableList() {
    return Array.from(this.menuDataTable.values());
  }

  loadMenu = async (id: string) => {
    this.loadingMenu = true;

    try {
      let menu = await agent.Menus.details(id);
      runInAction(() => {
        if (!menu.parentId)
          this.menus = this.menus.filter((z) => z.id !== menu.id);
      });
      return menu;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingMenu = false));
    }
  };

  loadDropDown = async () => {
    this.loadingMenu = true;

    try {
      let menu = await agent.Menus.list();
      runInAction(() => {
        this.menuDropDown = menu;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingMenu = false));
    }
  };

  setCollapse = (menu: MasterMenu) => {
    runInAction(() => {
      menu.collapse = !menu.collapse;
      let menuUpdated = { ...this.menuRegistry.get(menu.id), ...menu };
      this.menuRegistry.set(menu.id, menuUpdated);
    });
  };

  private setMenu = () => {
    let sorted = this.sortMenu(Array.from(this.menuRegistry.values()));
    this.menuRegistry.clear();
    sorted.forEach((menu) => {
      this.menuRegistry.set(menu.id, menu);
      if (menu.childMenus) menu.childMenus = this.sortMenu(menu.childMenus);
    });
    this.menus = this.sortMenu(this.menus);
  };

  sortMenu = (menus: MasterMenu[]) => {
    return menus
      .sort((a, b) => {
        if (a.menuName > b.menuName) return 1;
        if (b.menuName > a.menuName) return -1;
        return 0;
      })
      .sort((x, y) => {
        return x.isHome === y.isHome ? 0 : x.isHome ? -1 : 1;
      });
  };

  createMenu = async (menu: MasterMenuFormValues) => {
    this.loadingMenu = true;

    try {
      await agent.Menus.create(menu).then(() => {
        runInAction(() => {
          const newMenu = new MasterMenu(menu);
          if (menu.parentId) {
            let updatedMenu = this.menuRegistry.get(menu.parentId)?.childMenus;
            if (updatedMenu) {
              updatedMenu.push(newMenu);
            } else {
              updatedMenu = [];
              updatedMenu.push(newMenu);
            }
            updatedMenu = this.sortMenu(updatedMenu);
          } else {
            this.menuRegistry.set(newMenu.id, newMenu);
            this.menus.push(newMenu);
            this.setMenu();
          }
        });
      });
      const result = await agent.Menus.datatable(this.axiosParams);
      this.loadDataTableProcess(result);
      return "Create menu success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingMenu = false));
    }
  };

  updateMenu = async (menu: MasterMenuFormValues) => {
    this.loadingMenu = true;

    try {
      await agent.Menus.update(menu).then(() => {
        runInAction(() => {
          if (menu.id) {
            this.menuRegistry.forEach((x) => {
              x.childMenus = x.childMenus?.filter((x) => x.id !== menu.id);
            });
            if (menu.parentId) {
              const parentMenu = this.menuRegistry.get(menu.parentId);
              let updatedMenu = this.menuRegistry.get(
                menu.parentId
              )?.childMenus;
              if (updatedMenu) {
                updatedMenu.push(menu as MasterMenu);
              } else {
                updatedMenu = [];
                updatedMenu.push(menu as MasterMenu);
              }
              updatedMenu = this.sortMenu(updatedMenu);
              this.menuDataTable.set(
                menu.id,
                new MasterMenuData(menu as MasterMenu, parentMenu)
              );
            } else {
              this.menuRegistry.set(menu.id, menu as MasterMenu);
              this.menus.push(menu as MasterMenu);
              this.setMenu();
              this.menuDataTable.set(
                menu.id,
                new MasterMenuData(menu as MasterMenu)
              );
            }
          }
        });
      });
      return "Update menu success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingMenu = false));
    }
  };

  deleteDataTable = async (data: string[]) => {
    this.loading = true;
    this.loadingMenu = true;
    const length = data.length;
    let i = 0;

    for await (const x of data) {
      try {
        await agent.Menus.delete(x).then(() => {
          runInAction(() => {
            const menu = this.menuRegistry.get(x);
            if (menu) this.menuRegistry.delete(menu.id);
            else {
              this.menuRegistry.forEach((value) => {
                value.childMenus = value.childMenus?.filter((p) => p.id !== x);
              });
            }
            this.menus = this.menus.filter((p) => p.id !== x);
          });
        });
        const result = await agent.Menus.datatable(this.axiosParams);
        this.loadDataTableProcess(result);
      } catch (error) {
        runInAction(() => (this.loading = false));
        throw error;
      } finally {
        i++;
      }
    }

    if (length === i)
      runInAction(() => {
        this.loading = false;
        this.loadingMenu = false;
      });
    return "Delete menu success!";
  };
}
