import { observer } from "mobx-react-lite";
import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRouteWithMenu from "../../app/layout/PrivateRouteWithMenu";
import Title from "../../app/layout/Title";
import StockOutgoingForm from "./StockOutgoingForm";
import StockOutgoingList from "./StockOutgoingList";

export default observer(function StockOutgoing() {
  let { path } = useRouteMatch();

  return (
    <>
      <Title menuName="Stock Outgoing" />
      <Switch>
        <PrivateRouteWithMenu
          exact
          path={path}
          component={StockOutgoingList}
          link={path}
        />
        <PrivateRouteWithMenu
          path={`${path}/form`}
          component={StockOutgoingForm}
          link={path}
        />
        <PrivateRouteWithMenu
          path={`${path}/:id`}
          component={StockOutgoingForm}
          link={path}
        />
      </Switch>
    </>
  );
});
