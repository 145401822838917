import { observer } from "mobx-react-lite";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  Grid,
  Avatar,
  Chip,
} from "@material-ui/core";
import { StockBalance } from "../../../app/models/report";
import { useStyles } from "../../../app/layout/style";
import { StockLocation } from "../../../app/models/location";
import { AvatarName } from "../../../app/models/table";

interface Props {
  stockBalance: StockBalance[] | undefined;
  stockLocation: StockLocation;
}

export default observer(function ReportStockBalanceCard({
  stockBalance,
  stockLocation,
}: Props) {
  const classes = useStyles();
  const avatarName = new AvatarName(stockLocation.name);

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        avatar={
          <Chip
            variant="outlined"
            color="secondary"
            label={avatarName.getAvatarName}
          />
        }
        title={
          <Typography variant="subtitle1" component="p">
            {stockLocation.name}
          </Typography>
        }
        subheader={stockLocation.city}
      />
      <CardContent>
        <Divider />
        {stockBalance?.length === 0 ? (
          <>
            <div className={classes.content}>
              <Typography variant="subtitle1" component="p">
                No Data.
              </Typography>
            </div>
            <Divider />
          </>
        ) : (
          stockBalance?.map((item, index) => (
            <div key={index}>
              <div className={classes.content}>
                <Grid container direction="row" spacing={1} alignItems="center">
                  <Grid item>
                    <Avatar
                      alt={item.itemCode}
                      src={item.imageUrl ? item.imageUrl : "/no-image.png"}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="subtitle1" component="p">
                      {item.itemDescription}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" align="right" component="p">
                      {item.qty.toLocaleString("en-US")} {item.unitDescription}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <Divider />
            </div>
          ))
        )}
      </CardContent>
    </Card>
  );
});
