import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import {
  StyledTableCell,
  StyledTableCellTotal,
  StyledTableRow,
} from "../../../app/common/table/EnhancedTableHead";
import { useStyles } from "../../../app/layout/style";
import { StockDetail } from "../../../app/models/report";

interface Props {
  stockDetail: StockDetail[];
}

export default observer(function ReportStockDetailTable({
  stockDetail,
}: Props) {
  const classes = useStyles();
  let qty = 0;
  let count = 0;
  let templateSubTotal: JSX.Element | null = null;

  return (
    <Paper className={classes.paper}>
      <TableContainer>
        <Table
          className={classes.table}
          size="medium"
          aria-label="customized table"
        >
          {stockDetail !== undefined && stockDetail?.length > 0 ? (
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Date</StyledTableCell>
                  <StyledTableCell>Document</StyledTableCell>
                  <StyledTableCell>Item Description</StyledTableCell>
                  <StyledTableCell align="center">Unit</StyledTableCell>
                  <StyledTableCell align="center">Item Code</StyledTableCell>
                  <StyledTableCell align="center">Qty</StyledTableCell>
                  <StyledTableCell>Notes</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stockDetail.map((row, index) => {
                  let result = [];
                  qty = qty + row.qty;

                  result.push(
                    <React.Fragment key={index}>
                      <StyledTableRow>
                        <StyledTableCell align="center">
                          {moment(row.date).format("DD-MMM-yyyy")}
                        </StyledTableCell>
                        <StyledTableCell>{row.documentNo}</StyledTableCell>
                        <StyledTableCell>{row.itemDescription}</StyledTableCell>
                        <StyledTableCell align="center">
                          {row.unitDescription}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.itemCode}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.qty.toLocaleString("en-US")}
                        </StyledTableCell>
                        <StyledTableCell>{row.notes}</StyledTableCell>
                      </StyledTableRow>
                    </React.Fragment>
                  );

                  if (index < stockDetail.length - 1) {
                    const nextCode = stockDetail[index + 1].itemCode;
                    if (row.itemCode !== nextCode) {
                      count++;
                      templateSubTotal = (
                        <StyledTableRow key={`${index}_${count}`}>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="right"
                            colSpan={5}
                          >
                            {`Total for "${row.itemCode}" :`}
                          </StyledTableCellTotal>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {qty.toLocaleString("en-US")}
                          </StyledTableCellTotal>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="center"
                          ></StyledTableCellTotal>
                        </StyledTableRow>
                      );
                      qty = 0;
                      result.push(templateSubTotal);
                    }
                  } else {
                    count++;
                    templateSubTotal = (
                      <StyledTableRow key={`${index}_${count}`}>
                        <StyledTableCellTotal
                          component="th"
                          scope="row"
                          align="right"
                          colSpan={5}
                        >
                          {`Total for "${row.itemCode}" :`}
                        </StyledTableCellTotal>
                        <StyledTableCellTotal
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {qty.toLocaleString("en-US")}
                        </StyledTableCellTotal>
                        <StyledTableCellTotal
                          component="th"
                          scope="row"
                          align="center"
                        ></StyledTableCellTotal>
                      </StyledTableRow>
                    );
                    result.push(templateSubTotal);
                  }
                  return result;
                })}
              </TableBody>
            </>
          ) : (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell colSpan={6}>No Data.</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
});
