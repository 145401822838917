import React from "react";
import {makeStyles, createStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {observer} from "mobx-react-lite";
import {useStore} from "../../app/stores/store";
import TransferList from "../../app/common/transferlist/TransferList";
import {ManageMenuFormValues} from "../../app/models/manageMenu";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      margin: theme.spacing(0.5, 0),
    },
  })
);

interface Props {
  role: string;
}

export default observer(function ManageMenuList({role}: Props) {
  const classes = useStyles();
  const {transferListStore, manageMenuStore} = useStore();
  const {itemsChecked, setItemsChecked, not, intersection} = transferListStore;
  const {
    menus,
    menusGranted,
    setMenus,
    setMenusGranted,
    createManageMenu,
    deleteManageMenu,
    loadingMenu,
  } = manageMenuStore;

  const leftChecked = intersection(itemsChecked, menus);
  const rightChecked = intersection(itemsChecked, menusGranted);

  const handleCheckedRight = () => {
    let addMenus = leftChecked.map((menu) => {
      return new ManageMenuFormValues({roleId: role, menuId: menu.value});
    });
    createManageMenu(addMenus).then(() => {
      setMenusGranted(menusGranted.concat(leftChecked));
      setMenus(not(menus, leftChecked));
      setItemsChecked(not(itemsChecked, leftChecked));
    });
  };

  const handleCheckedLeft = () => {
    let deleteMenus = rightChecked.map((menu) => {
      return new ManageMenuFormValues({roleId: role, menuId: menu.value});
    });
    deleteManageMenu(deleteMenus).then(() => {
      setMenus(menus.concat(rightChecked));
      setMenusGranted(not(menusGranted, rightChecked));
      setItemsChecked(not(itemsChecked, rightChecked));
    });
  };

  const handleAllRight = () => {
    let addMenus = menus.map((menu) => {
      return new ManageMenuFormValues({roleId: role, menuId: menu.value});
    });
    createManageMenu(addMenus).then(() => {
      setMenusGranted(menusGranted.concat(menus));
      setMenus([]);
    });
  };

  const handleAllLeft = () => {
    let deleteMenus = menusGranted.map((menu) => {
      return new ManageMenuFormValues({roleId: role, menuId: menu.value});
    });
    deleteManageMenu(deleteMenus).then(() => {
      setMenus(menus.concat(menusGranted));
      setMenusGranted([]);
    });
  };

  return (
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
      <Grid item xs>
        <TransferList title="Menu List" items={menus} loading={loadingMenu} />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={menus.length === 0}
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={menusGranted.length === 0}
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item xs>
        <TransferList
          title="Menu List Granted"
          items={menusGranted}
          loading={loadingMenu}
        />
      </Grid>
    </Grid>
  );
});
