import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Skeleton } from "@material-ui/lab";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";
import { useStore } from "../stores/store";
import { useStyles } from "./style";

interface Props {
  menuName: string;
  children?: JSX.Element | undefined;
}

export default observer(function Title({ menuName, children }: Props) {
  const classes = useStyles();
  const { menuStore } = useStore();
  const { loadingMenu } = menuStore;

  return (
    <>
      {loadingMenu ? (
        <>
          <Typography variant="h4">
            <Skeleton width="50%" />
          </Typography>
          <Divider />
        </>
      ) : (
        <>
          <Helmet>
            <title>PANDE GROUP - {menuName}</title>
          </Helmet>
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Grid item xs>
              <Typography variant="h5">{menuName}</Typography>
            </Grid>
            <Grid item>
              <div className={classes.filter_item}>{children}</div>
            </Grid>
          </Grid>
          <Divider />
        </>
      )}
    </>
  );
});
