import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import {
  AssetLending,
  AssetLendingDetail,
  AssetLendingFormValues,
} from "../models/assetLending";
import { PaginatedResult } from "../models/pagination";
import { TableStore } from "./tableStore";

export default class AssetLendingStore extends TableStore<AssetLending> {
  documentRegistry = new Map<string, AssetLending>();

  constructor() {
    super();
    makeObservable(this, {
      documentRegistry: observable,
      loadDocuments: action,
      documentList: computed,
      loadDocument: action,
      createAssetLending: action,
      updateAssetLending: action,
      deleteAssetLending: action,
      postAssetLending: action,
    });

    this.setPageNumber(1);
    this.setSortBy(null);
    this.setOrderBy("desc");
    this.sortByElement = [
      { id: "date", label: "Date" },
      { id: "documentNo", label: "Document" },
      { id: "customer", label: "Customer" },
    ];

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadDocuments();
      }
    );
  }

  private loadDocumentProcess = (result: PaginatedResult<AssetLending[]>) => {
    runInAction(() => {
      this.documentRegistry.clear();
      result.data.forEach((document) => {
        let documentResult = {
          ...document,
          items: this.sortDetails(document.details),
        };
        this.documentRegistry.set(document.id, documentResult);
      });
      this.pagination = result.pagination;
    });
  };

  loadDocuments = async () => {
    this.loading = true;

    try {
      const result = await agent.AssetLendings.list(this.axiosParams);
      this.loadDocumentProcess(result);
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get documentList() {
    return Array.from(this.documentRegistry.values());
  }

  loadDocument = async (id: string) => {
    this.loading = true;

    try {
      const result = await agent.AssetLendings.details(id);
      return {
        ...result,
        details: this.sortDetails(result.details),
      };
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  createAssetLending = async (document: AssetLendingFormValues) => {
    try {
      if (document.details) {
        for await (const x of document.details) {
          x.assetLendingId = document.id;
        }
      }
      await agent.AssetLendings.create(document);
      const result = await agent.AssetLendings.list(this.axiosParams);
      this.loadDocumentProcess(result);
      return "Create asset lending document is success!";
    } catch (error) {
      throw error;
    }
  };

  updateAssetLending = async (document: AssetLendingFormValues) => {
    try {
      await agent.AssetLendings.update(document).then((document) => {
        runInAction(() => {
          this.documentRegistry.set(document.id, document);
        });
      });
      return "Update asset lending document is success!";
    } catch (error) {
      throw error;
    }
  };

  deleteAssetLending = async (ids: string[]) => {
    this.loading = true;

    try {
      await agent.AssetLendings.delete(ids);
      const result = await agent.AssetLendings.list(this.axiosParams);
      this.loadDocumentProcess(result);
      return "Delete asset lending(s) document is success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  postAssetLending = async (id: string) => {
    this.loading = true;

    try {
      await agent.AssetLendings.post(id).then(() => {
        runInAction(() => {
          const document = this.documentRegistry.get(id);
          if (document) {
            document.posted = !document.posted;
            this.documentRegistry.set(id, document);
          }
        });
      });
      return "Document is posted!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  massPostSpending = async (ids: string[]) => {
    this.loading = true;

    try {
      await agent.AssetLendings.massPost(ids);
      const result = await agent.AssetLendings.list(this.axiosParams);
      this.loadDocumentProcess(result);
      return "Post spending(s) success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  private sortDetails = (details: AssetLendingDetail[]) => {
    return details.sort((a, b) => {
      if (a.itemCode > b.itemCode) return 1;
      if (b.itemCode > a.itemCode) return -1;
      return 0;
    });
  };
}
