import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { StockLocation } from "../models/location";
import { StockBalance, StockBalanceSearch } from "../models/report";
import { store } from "./store";
import { TableStore } from "./tableStore";

export default class StockBalanceStore extends TableStore<StockBalance> {
  stockBalanceRegistry = new Map<StockLocation, StockBalance[]>();

  constructor() {
    super();
    makeObservable(this, {
      stockBalanceRegistry: observable,
      loadReportStockBalance: action,
      stockLocationList: computed,
    });

    this.setPredicate(new StockBalanceSearch());

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadReportStockBalance();
      }
    );
  }

  setPredicate = (search: StockBalanceSearch) => {
    runInAction(() => {
      Object.keys(search).forEach((key) => {
        this.predicate.delete(key);
      });
      this.predicate.set(
        "stockLocationId",
        search.stockLocationId === "All" ? "" : search.stockLocationId
      );
      this.predicate.set("date", search.date.toLocaleDateString("EN-US"));
    });
  };

  get filter() {
    return new StockBalanceSearch({
      stockLocationId:
        this.predicate.get("stockLocationId") === undefined ||
        this.predicate.get("stockLocationId") === ""
          ? "All"
          : this.predicate.get("stockLocationId")!,
      date:
        this.predicate.get("date") === undefined
          ? new Date()
          : new Date(this.predicate.get("date")!),
    });
  }

  loadReportStockBalance = async () => {
    this.clearStockBalance();
    this.loading = true;

    try {
      let locations: StockLocation[] = [];
      const stockLocationId = this.predicate.get("stockLocationId");

      if (stockLocationId === "")
        locations = store.userStore.stockLocationGranted.filter(
          (x) => !x.isSPBE
        );
      else
        locations = store.userStore.stockLocationGranted.filter(
          (x) => x.id === stockLocationId
        );
      for await (const location of locations) {
        const result = await agent.Reports.reportStockBalance(
          this.stockLocationParams(location)
        );
        runInAction(() => {
          this.stockBalanceRegistry.set(location, result);
        });
      }
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get stockLocationList() {
    return store.stockAllocationStore.sortStockLocation(
      Array.from(this.stockBalanceRegistry.keys())
    );
  }

  private clearStockBalance = () => {
    this.stockBalanceRegistry.clear();
  };
}
