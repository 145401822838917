import { Button, Theme, withStyles } from "@material-ui/core";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ExplicitIcon from "@material-ui/icons/Explicit";
import { green } from "@material-ui/core/colors";

interface Props {
  fileName: string;
  sheetName: string;
  componentToExport: React.MutableRefObject<HTMLDivElement | null>;
  disabled: boolean;
}

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: green[500],
  },
}))(Button);

export default function ButtonExcel({
  fileName,
  sheetName,
  componentToExport,
  disabled,
}: Props) {
  return (
    <DownloadTableExcel
      filename={fileName}
      sheet={sheetName}
      currentTableRef={componentToExport.current}
    >
      <ColorButton size="small" disabled={disabled}>
        Excel <ExplicitIcon />
      </ColorButton>
    </DownloadTableExcel>
  );
}
