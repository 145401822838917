import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react-lite";
import { useStyles } from "../../app/layout/style";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { CustomerItem } from "../../app/models/customer";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { v4 as uuid } from "uuid";
import PriceInputField, {
  QtyInputField,
} from "../../app/common/form/NumberInputField";
import ItemsAutoComplete from "../Customers/ItemsAutoComplete";
import {
  PurchaseOrderDetail,
  PurchaseOrderDetailValues,
  PurchaseOrderFormValues,
} from "../../app/models/purchaseOrder";

interface Props {
  purchaseOrder: PurchaseOrderFormValues;
  setPurchaseOrder: (vendor: PurchaseOrderFormValues) => void;
  items: CustomerItem[];
  details: PurchaseOrderDetail[];
  setDetails: (items: PurchaseOrderDetail[]) => void;
  selectedGroup: CustomerItem[];
  setSelectedGroup: (items: CustomerItem[]) => void;
  isSubmitting: boolean;
  loading: boolean;
  validateForm: (values?: any) => void;
  value: CustomerItem | null;
  setValue: (value: CustomerItem | null) => void;
}

export default observer(function PurchaseOrderFormAddDetails({
  purchaseOrder,
  setPurchaseOrder,
  items,
  details,
  setDetails,
  selectedGroup,
  setSelectedGroup,
  isSubmitting,
  loading,
  validateForm,
  value,
  setValue,
}: Props) {
  const classes = useStyles();

  const handleAddItems = () => {
    if (value) {
      const id = uuid();
      setSelectedGroup(selectedGroup.concat(value));
      setDetails(
        details.concat({
          id: id,
          itemId: value.id,
          itemCode: value.itemCode,
          itemDescription: value.itemDescription,
          unitDescription: value.unitDescription,
          price: value.price,
          imageUrl: value.imageUrl,
        } as PurchaseOrderDetail)
      );
      setPurchaseOrder(
        new PurchaseOrderFormValues({
          ...purchaseOrder,
          details: purchaseOrder.details.concat(
            new PurchaseOrderDetailValues({
              id: id,
              itemId: value.id,
              purchaseOrderId: purchaseOrder?.id,
              price: value.price,
            })
          ),
        })
      );
      setValue(null);
    }
  };

  const handleDeleteItem = (detail: PurchaseOrderDetail) => {
    setSelectedGroup(selectedGroup.filter((x) => x.id !== detail.itemId));
    setDetails(details.filter((x) => x.id !== detail.id));
    setPurchaseOrder({
      ...purchaseOrder,
      details: purchaseOrder.details.filter((x) => x.id !== detail.id),
    });
    validateForm();
  };

  return (
    <>
      <div className={classes.paper}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item xl={6} lg={4} md={6} xs={12}>
            <Typography variant="h6" component="p">
              Add Items
            </Typography>
          </Grid>
          <Grid item xl={6} lg={8} md={6} xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs>
                <ItemsAutoComplete
                  name="item"
                  options={items}
                  value={value}
                  setValue={setValue}
                  optionDisabled={selectedGroup}
                  loading={isSubmitting || loading}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Add Items">
                  <IconButton size="small" onClick={handleAddItems}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {details.map((item, index) => (
        <Paper key={index} className={classes.itemCard} variant="outlined">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Avatar
                alt={item.itemCode}
                src={item.imageUrl ? item.imageUrl : "/no-image.png"}
              />
            </Grid>
            <Grid item md xs={9}>
              <Typography variant="subtitle1" component="p">
                {item.itemDescription}
              </Typography>
              <Typography variant="caption" component="p">
                {item.unitDescription}
              </Typography>
            </Grid>
            <Grid item xs>
              <PriceInputField
                variant="outlined"
                label="Price"
                name={`details[${index}].price`}
                margin="dense"
                disabled
              />
            </Grid>
            <Grid item xs>
              <QtyInputField
                variant="outlined"
                label="Quantity"
                name={`details[${index}].qty`}
                margin="dense"
                andorment={item.unitDescription}
                disabled={isSubmitting || loading}
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
              />
            </Grid>
            <Grid item>
              <Tooltip title="Delete Item">
                <IconButton size="small" onClick={() => handleDeleteItem(item)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </>
  );
});
