import { ItemDocument } from "./item";

export interface CustomerItem extends ItemDocument {
  id: string;
  price: number;
}

export interface Driver {
  id: string;
  name: string;
}

export interface CustomerDueDate {
  id: number;
  description: string;
  days: number;
}

export interface Customer {
  id: string;
  storeId: string;
  storeName: string;
  customerName: string;
  customerCode: string;
  group: string;
  fullName: string;
  address: string;
  phoneNo: string;
  isVerified: boolean;
  isActive: boolean;
  items: CustomerItem[];
  customerDueDateId: number;
  customerDueDateDescription: string;
}

export interface CustomerGranted {
  id: string;
  storeName: string;
  customerName: string;
  fullName: string;
  isVerified: boolean;
  isActive: boolean;
  items: CustomerItem[];
}

export class CustomerItemsValues {
  itemId: string = "";
  customerId?: string = undefined;
  price?: number = undefined;

  constructor(items?: CustomerItemsValues) {
    if (items) {
      this.itemId = items.itemId;
      this.customerId = items.customerId;
      this.price = items.price;
    }
  }
}

export class CustomerFormValues {
  id?: string = undefined;
  storeId: string = "";
  customerName: string = "";
  customerCode: string = "";
  group: string = "";
  address: string = "";
  phoneNo: string = "";
  isVerified: boolean = false;
  isActive: boolean = true;
  items: CustomerItemsValues[] = [];
  customerDueDateId: number = 1;

  constructor(customer?: CustomerFormValues) {
    if (customer) {
      this.id = customer.id;
      this.storeId = customer.storeId;
      this.customerName = customer.customerName;
      this.customerCode = customer.customerCode;
      this.group = customer.group;
      this.address = customer.address;
      this.phoneNo = customer.phoneNo;
      this.isVerified = customer.isVerified;
      this.isActive = customer.isActive;
      this.items = customer.items;
      this.customerDueDateId = customer.customerDueDateId;
    }
  }
}

export class CustomerPriceSearch {
  storeId: string = "";
  customerName: string = "";
  itemId: string = "";

  constructor(search?: CustomerPriceSearch) {
    if (search) {
      this.storeId = search.storeId;
      this.customerName = search.customerName;
      this.itemId = search.itemId;
    }
  }
}

export interface CustomerPrice extends Customer {
  lastOrder: Date;
}
