import {
  action,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { StockLocation } from "../models/location";
import { StockHistory, StockHistorySearch } from "../models/report";
import { store } from "./store";
import { TableStore } from "./tableStore";

export default class StockHistoryStore extends TableStore<StockHistory> {
  stockHistoryRegistry = new Map<StockLocation, StockHistory[]>();

  constructor() {
    super();
    makeObservable(this, {
      stockHistoryRegistry: observable,
      loadReportStockHistory: action,
    });

    this.setPredicate(new StockHistorySearch());

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadReportStockHistory();
      }
    );
  }

  setPredicate = (search: StockHistorySearch) => {
    runInAction(() => {
      Object.keys(search).forEach((key) => {
        this.predicate.delete(key);
      });
      this.predicate.set(
        "stockLocationId",
        search.stockLocationId === "All" ? "" : search.stockLocationId
      );
      this.predicate.set(
        "startDate",
        search.startDate.toLocaleDateString("EN-US")
      );
      this.predicate.set("endDate", search.endDate.toLocaleDateString("EN-US"));
    });
  };

  get filter() {
    return new StockHistorySearch({
      stockLocationId:
        this.predicate.get("stockLocationId") === undefined ||
        this.predicate.get("stockLocationId") === ""
          ? "All"
          : this.predicate.get("stockLocationId")!,
      startDate:
        this.predicate.get("startDate") === undefined
          ? new Date()
          : new Date(this.predicate.get("startDate")!),
      endDate:
        this.predicate.get("endDate") === undefined
          ? new Date()
          : new Date(this.predicate.get("endDate")!),
    });
  }

  loadReportStockHistory = async () => {
    this.clearStockBalance();
    this.loading = true;

    try {
      let locations: StockLocation[] = [];
      const stockLocationId = this.predicate.get("stockLocationId");

      if (stockLocationId === "")
        locations = store.userStore.stockLocationGranted.filter(
          (x) => !x.isSPBE
        );
      else
        locations = store.userStore.stockLocationGranted.filter(
          (x) => x.id === stockLocationId
        );
      for await (const location of locations) {
        const result = await agent.Reports.reportStockHistory(
          this.stockLocationParams(location)
        );
        runInAction(() => {
          this.stockHistoryRegistry.set(location, result);
        });
      }
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get stockLocationList() {
    return store.stockAllocationStore.sortStockLocation(
      Array.from(this.stockHistoryRegistry.keys())
    );
  }

  private clearStockBalance = () => {
    this.stockHistoryRegistry.clear();
  };
}
