import { Grid, Paper, TablePagination } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import CustomerCard from "./CustomerCard";
import CardDeleteDialog from "../../app/layout/CardDeleteDialog";
import CardHeaderList from "../../app/common/card/CardHeaderList";
import UserSkeleton from "../Users/UserSkeleton";

export default observer(function CustomerCardList() {
  const classes = useStyles();
  const { customerStore, snackbarStore, dialogStore } = useStore();
  const {
    setPageNumber,
    customerList,
    setPageSize,
    loading,
    rowsOptions,
    pagination,
    pageNumber,
    pageSize,
    deleteCustomer,
    initRowsOptionsAndPageSize,
    skeletonCardArray,
  } = customerStore;
  const { openSnackbar } = snackbarStore;
  const { openDialogDelete, closeDialog } = dialogStore;

  useEffect(() => {
    initRowsOptionsAndPageSize();
  }, [initRowsOptionsAndPageSize]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  const handleDeleteCustomer = (id: string) => {
    openDialogDelete(
      <CardDeleteDialog
        title="Are you sure want to delete this customer?"
        id={id}
      />,
      handleDelete
    );
  };

  const handleDelete = (id: string) => {
    deleteCustomer([id])
      .catch((error) => {
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        closeDialog();
      });
  };

  return (
    <div className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CardHeaderList
            title="Customer List"
            link="/customers/form"
            loading={loading}
          />
        </Grid>
        {loading
          ? skeletonCardArray().map((item, index) => (
              <Grid key={index} item md={4} sm={6} xs={12}>
                <UserSkeleton />
              </Grid>
            ))
          : customerList.map((customer) => (
              <Grid key={customer.id} item md={4} sm={6} xs={12}>
                <CustomerCard
                  customer={customer}
                  deleteCustomer={handleDeleteCustomer}
                />
              </Grid>
            ))}
        <Grid item xs={12}>
          <Paper>
            <TablePagination
              rowsPerPageOptions={rowsOptions}
              component="div"
              count={pagination === null ? 0 : pagination.totalItems}
              page={pageNumber}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
});
