import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableCellTotal,
} from "../../../app/common/table/EnhancedTableHead";
import { useStyles } from "../../../app/layout/style";
import { Spending } from "../../../app/models/spending";

interface Props {
  spending: Spending[] | undefined;
}

export default observer(function ReportSpendingTable({ spending }: Props) {
  const classes = useStyles();
  const totalAmount = spending?.reduce(
    (total, currentData) => (total = total + currentData.amount),
    0
  );
  let amount = 0;
  let count = 0;
  let templateSubTotal: JSX.Element | null = null;

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            size="medium"
            aria-label="customized table"
          >
            {spending !== undefined && spending?.length > 0 ? (
              <>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Date</StyledTableCell>
                    <StyledTableCell>Group</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell align="right">Amount</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {spending.map((row, index) => {
                    let result = [];
                    //count amount
                    amount = amount + row.amount;

                    result.push(
                      <React.Fragment key={index}>
                        <StyledTableRow>
                          <StyledTableCell align="center">
                            {moment(row.date).format("DD-MMM-yyyy")}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.spendingGroup.groupName}
                          </StyledTableCell>
                          <StyledTableCell>{row.description}</StyledTableCell>
                          <StyledTableCell align="right">
                            {row.amount.toLocaleString("en-US")}
                          </StyledTableCell>
                        </StyledTableRow>
                      </React.Fragment>
                    );

                    if (index < spending.length - 1) {
                      const nextItem =
                        spending[index + 1].spendingGroup.groupName;
                      count++;

                      if (row.spendingGroup.groupName !== nextItem) {
                        templateSubTotal = (
                          <StyledTableRow key={`${index}_${count}`}>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="right"
                              colSpan={3}
                            >
                              {`Total for "${row.spendingGroup.groupName}" :`}
                            </StyledTableCellTotal>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="right"
                            >
                              {amount.toLocaleString("en-US")}
                            </StyledTableCellTotal>
                          </StyledTableRow>
                        );
                        if (count > 1) result.push(templateSubTotal);
                        count = 0;
                        amount = 0;
                      }
                    } else {
                      templateSubTotal = (
                        <StyledTableRow key={`${index}_${count}`}>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="right"
                            colSpan={3}
                          >
                            {`Total for "${row.spendingGroup.groupName}" :`}
                          </StyledTableCellTotal>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="right"
                          >
                            {amount.toLocaleString("en-US")}
                          </StyledTableCellTotal>
                        </StyledTableRow>
                      );
                    }
                    return result;
                  })}
                  {count > 0 &&
                    count !== spending.length - 1 &&
                    templateSubTotal}
                  <StyledTableRow>
                    <StyledTableCellTotal
                      component="th"
                      scope="row"
                      align="right"
                      colSpan={3}
                    >
                      Grand Total :
                    </StyledTableCellTotal>
                    <StyledTableCellTotal
                      component="th"
                      scope="row"
                      align="right"
                    >
                      {totalAmount?.toLocaleString("en-US")}
                    </StyledTableCellTotal>
                  </StyledTableRow>
                </TableBody>
              </>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell colSpan={4}>No Data.</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
});
