import { createStyles, Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

export const usePosStyles = makeStyles((theme: Theme) =>
  createStyles({
    pos: {
      [theme.breakpoints.only("xl")]: {
        height: "99ch",
      },
      [theme.breakpoints.down("lg")]: {
        height: "70ch",
      },
      [theme.breakpoints.down("sm")]: {
        height: "80ch",
      },
      marginBottom: theme.spacing(1),
    },
    cart: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflow: "auto",
      width: "100%",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    panel: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
    },
    items: {
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      height: "100%",
      width: "100%",
      padding: theme.spacing(2),
    },
    title: {
      backgroundColor: "#007bb2",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(1),
    },
    transaction: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
      height: "100%",
      overflow: "auto",
      width: "100%",
      [theme.breakpoints.only("lg")]: {
        fontSize: 10,
        height: "50vh",
      },
    },
    total: {
      display: "flex",
      flexDirection: "column",
      height: "auto",
      width: "100%",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    save: {
      [theme.breakpoints.only("xl")]: {
        height: "7ch",
        fontSize: 20,
      },
      [theme.breakpoints.only("lg")]: {
        height: "5ch",
        fontSize: 20,
      },
      [theme.breakpoints.down("md")]: {
        height: "5ch",
        fontSize: 20,
      },
    },
    pay: {
      [theme.breakpoints.only("xl")]: {
        height: "14ch",
        fontSize: 20,
      },
      [theme.breakpoints.only("lg")]: {
        height: "5ch",
        fontSize: 20,
      },
      [theme.breakpoints.down("md")]: {
        height: "5ch",
        fontSize: 20,
      },
    },
    card: {
      padding: theme.spacing(2, 2, 2, 2),
    },
    grid: {
      marginBottom: theme.spacing(1),
    },
    cart_price: {
      margin: theme.spacing(2, 0),
    },
    appBar: {
      top: "auto",
      bottom: 0,
    },
    slip: {
      width: "100%",
      padding: theme.spacing(4),
      color: "black",
    },
    table: {
      width: "100%",
      border: "1px solid",
      borderCollapse: "collapse",
      margin: theme.spacing(2, 0, 2, 0),
    },
    tr: {
      border: "1px solid",
      padding: theme.spacing(1),
    },
    td: {
      border: "1px solid",
      padding: theme.spacing(1),
    },
    bankAccount: {
      margin: theme.spacing(8, 0, 0, 0),
    },
  })
);
