import { observer } from "mobx-react-lite";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  Grid,
  Avatar,
  Chip,
} from "@material-ui/core";
import { AssetLendingBalance } from "../../../app/models/report";
import { useStyles } from "../../../app/layout/style";

interface Props {
  assetLendingBalance: AssetLendingBalance;
}

export default observer(function ReportAssetLendingBalanceCard({
  assetLendingBalance,
}: Props) {
  const classes = useStyles();

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        avatar={
          <Chip
            variant="outlined"
            color="secondary"
            label={assetLendingBalance.customerName.substring(0, 1)}
          />
        }
        title={
          <Typography variant="subtitle1" component="p">
            {assetLendingBalance.customerName}
          </Typography>
        }
        subheader={assetLendingBalance.storeName}
      />
      <CardContent>
        <Divider />
        {assetLendingBalance.details.map((item, index) => (
          <div key={index}>
            <div className={classes.content}>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <Avatar
                    alt={item.itemCode}
                    src={item.imageUrl ? item.imageUrl : "/no-image.png"}
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle1" component="p">
                    {item.itemCode}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" align="right" component="p">
                    {item.qty.toLocaleString("en-US")} {item.unitDescription}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <Divider />
          </div>
        ))}
      </CardContent>
    </Card>
  );
});
