import React from "react";
import {useField} from "formik";
import {FormControlLabel} from "@material-ui/core";
import Switch, { SwitchProps } from "@material-ui/core/Switch";

interface Props extends SwitchProps {
  name: string;
  label?: string;
}

export default function MySwitch(props: Props) {
  const [field] = useField(props.name);

  return (
    <FormControlLabel
      control={<Switch {...field} {...props} checked={field.value} />}
      label={props.label}
    />
  );
}
