import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStyles } from "../../app/layout/style";
import { ItemFormValues } from "../../app/models/item";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Form, Formik } from "formik";
import Dropdown from "../../app/common/form/Dropdown";
import InputField from "../../app/common/form/InputField";
import MySwitch from "../../app/common/form/MySwitch";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";
import PriceInputField from "../../app/common/form/NumberInputField";

export default observer(function ItemForm() {
  const classes = useStyles();
  const { itemStore, snackbarStore } = useStore();
  const { createItem, updateItem, loadItem, units, loadUnits, loading } =
    itemStore;
  const { openSnackbar } = snackbarStore;
  const [selectedImage, setSelectedImage] = useState<any>([]);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [item, setItem] = useState<ItemFormValues>(new ItemFormValues());
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadUnits();

    if (selectedImage[0]) {
      setImageUrl(URL.createObjectURL(selectedImage[0]));
    }

    if (id) {
      loadItem(id).then((item) => {
        setItem(new ItemFormValues(item));
      });
    }
  }, [id, loadItem, loadUnits, selectedImage]);

  const schema = Yup.object({
    unitId: Yup.string().required("Unit is required."),
    price: Yup.number().required("Price is required"),
    itemDescription: Yup.string().required("Item description is required."),
    itemCode: Yup.string().required("Item Code is required."),
  });

  const handleUploadImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedImage(e.target.files);
  };

  const handleFormSubmit = (
    item: ItemFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!item.id) {
      let newItem = {
        ...item,
        id: uuid(),
      };

      createItem(newItem, selectedImage[0])
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setItem(new ItemFormValues());
            setImageUrl(null);
            resetForm();
          }
        });
    } else {
      updateItem(item, selectedImage[0])
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/items");
          }
        });
    }
  };

  return (
    <>
      <Paper className={classes.form}>
        <Typography variant="h5">Form {id ? "Update" : "Create"}</Typography>
        <Divider />
        <Formik
          validationSchema={schema}
          enableReinitialize
          initialValues={item}
          onSubmit={(values, { resetForm, setSubmitting }) =>
            handleFormSubmit(values, resetForm, setSubmitting)
          }
        >
          {({ handleSubmit, isSubmitting, isValid }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    options={units.map((x) => {
                      return { text: x.unitDescription, value: x.id };
                    })}
                    name="unitId"
                    placeholder="Unit"
                    label="Unit"
                    disabled={isSubmitting || loading}
                  />
                  <InputField
                    variant="standard"
                    label="Item Code"
                    name="itemCode"
                    placeholder="Please input item code"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loading}
                  />
                  <InputField
                    variant="standard"
                    label="Item Description"
                    name="itemDescription"
                    placeholder="Please input item description"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loading}
                  />
                  <PriceInputField
                    variant="standard"
                    label="Price"
                    name="price"
                    margin="dense"
                    disabled={isSubmitting || loading}
                  />
                  <MySwitch name="isSalesItem" label="Sales Item" checked />
                  <MySwitch name="showStock" label="Show Stock" checked />
                  {id && (
                    <MySwitch name="isActive" label="Active Item" checked />
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {imageUrl && selectedImage && (
                    <Box mt={2} textAlign="center">
                      <Typography
                        style={{ marginBottom: "5px" }}
                        component="h5"
                      >
                        Image Preview
                      </Typography>
                      <img
                        src={imageUrl}
                        alt={selectedImage.name}
                        height="200px"
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="stretch"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || !isValid || loading}
                  >
                    {isSubmitting && (
                      <CircularProgress
                        className={classes.progress}
                        size={16}
                        color="inherit"
                      />
                    )}
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    component="label"
                    disabled={isSubmitting || !isValid || loading}
                  >
                    Upload Image
                    <input
                      accept="image/*"
                      type="file"
                      hidden
                      onChange={(e) => handleUploadImageChange(e)}
                    />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    component={Link}
                    to="/items"
                    disabled={isSubmitting || loading}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
});
