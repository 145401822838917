import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import moment from "moment";
import {
  StyledTableCell,
  StyledTableCellTotal,
  StyledTableRow,
} from "../../../app/common/table/EnhancedTableHead";
import { useStyles } from "../../../app/layout/style";
import { CashFlow } from "../../../app/models/report";

interface Props {
  cashFlow: CashFlow[] | undefined;
}

export default observer(function ReportCashFlowTable({ cashFlow }: Props) {
  const classes = useStyles();
  const totalAmount = cashFlow?.reduce(
    (total, currentData) => (total = total + currentData.amount),
    0
  );
  let amount = 0;
  let count = 0;
  let templateSubTotal: JSX.Element | null = null;

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            size="medium"
            aria-label="customized table"
            style={{ fontSize: 12 }}
          >
            {cashFlow !== undefined && cashFlow?.length > 0 ? (
              <>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">
                      Payment Date
                    </StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell align="center">
                      Transaction Date
                    </StyledTableCell>
                    <StyledTableCell>Customer Code</StyledTableCell>
                    <StyledTableCell>Customer Name</StyledTableCell>
                    <StyledTableCell align="center">Notes</StyledTableCell>
                    <StyledTableCell align="right">Amount</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cashFlow.map((row, index) => {
                    let result = [];
                    //count amount
                    amount = amount + row.amount;
                    const bankAccount = row.bankAccount
                      ? `${row.bankAccount?.owner} - ${row.bankAccount?.bankName} - ${row.bankAccount?.accountNo}`
                      : "";
                    const spendingGroup = row.spendingGroup
                      ? row.spendingGroup.groupName
                      : "";

                    result.push(
                      <React.Fragment key={index}>
                        <StyledTableRow>
                          <StyledTableCell align="center">
                            {moment(row.date).format("DD-MMM-yyyy")}
                          </StyledTableCell>
                          <StyledTableCell>{row.description}</StyledTableCell>
                          <StyledTableCell align="center">
                            {row.transactionDate === null
                              ? ""
                              : moment(row.transactionDate).format(
                                  "DD-MMM-yyyy"
                                )}
                          </StyledTableCell>
                          <StyledTableCell>{row.customerCode}</StyledTableCell>
                          <StyledTableCell>{row.customerName}</StyledTableCell>
                          <StyledTableCell align="center">
                            {bankAccount}
                            {spendingGroup}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.amount.toLocaleString("en-US")}
                          </StyledTableCell>
                        </StyledTableRow>
                      </React.Fragment>
                    );

                    if (index < cashFlow.length - 1) {
                      const nextItem = cashFlow[index + 1].bankAccount?.id;
                      count++;

                      if (row.bankAccount?.id !== nextItem) {
                        templateSubTotal = (
                          <StyledTableRow key={`${index}_${count}`}>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="right"
                              colSpan={6}
                            >
                              {`Total for "${bankAccount}" :`}
                            </StyledTableCellTotal>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="right"
                            >
                              {amount.toLocaleString("en-US")}
                            </StyledTableCellTotal>
                          </StyledTableRow>
                        );
                        if (count > 1) result.push(templateSubTotal);
                        count = 0;
                        amount = 0;
                      }
                    } else {
                      templateSubTotal = (
                        <StyledTableRow key={`${index}_${count}`}>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="right"
                            colSpan={6}
                          >
                            {`Total for "${bankAccount}" :`}
                          </StyledTableCellTotal>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="right"
                          >
                            {amount.toLocaleString("en-US")}
                          </StyledTableCellTotal>
                        </StyledTableRow>
                      );
                    }
                    return result;
                  })}
                  {count > 0 &&
                    count !== cashFlow.length - 1 &&
                    templateSubTotal}
                  <StyledTableRow>
                    <StyledTableCellTotal
                      component="th"
                      scope="row"
                      align="right"
                      colSpan={6}
                    >
                      Grand Total :
                    </StyledTableCellTotal>
                    <StyledTableCellTotal
                      component="th"
                      scope="row"
                      align="right"
                    >
                      {totalAmount?.toLocaleString("en-US")}
                    </StyledTableCellTotal>
                  </StyledTableRow>
                </TableBody>
              </>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell colSpan={7}>No Data.</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
});
