import { Customer } from "./customer";
import { Documents } from "./document";
import { ItemDocument } from "./item";
import { StockTransactionType } from "./stockControl";

export interface AssetLending extends Documents {
  customer: Customer;
  notes: string;
  details: AssetLendingDetail[];
}

export interface AssetLendingDetail extends ItemDocument {
  id: string;
  assetLendingId: string;
  stockTransactionType: StockTransactionType;
  itemId: string;
  qty: number;
}

export class AssetLendingDetailValues {
  id?: string = undefined;
  assetLendingId?: string = undefined;
  stockTransactionTypeId: string = "";
  itemId: string = "";
  qty?: number = undefined;

  constructor(details?: AssetLendingDetailValues) {
    if (details) {
      this.id = details.id;
      this.assetLendingId = details.assetLendingId;
      this.stockTransactionTypeId = details.stockTransactionTypeId;
      this.itemId = details.itemId;
      this.qty = details.qty;
    }
  }
}

export class AssetLendingFormValues {
  id?: string = undefined;
  date: Date = new Date();
  customerId: string = "";
  notes: string = "";
  details: AssetLendingDetailValues[] = [];

  constructor(asset?: AssetLendingFormValues) {
    if (asset) {
      this.id = asset.id;
      this.date = new Date(asset.date);
      this.customerId = asset.customerId;
      this.notes = asset.notes;
      this.details = asset.details;
    }
  }
}
