import { Grid, Paper, Typography } from "@material-ui/core";
import { useStyles } from "../../../app/layout/style";
import { ProfitAndLoss, SpendingGroupReport, Credit } from "../../../app/models/report";

interface Props {
  profit: ProfitAndLoss[] | undefined;
  loss: ProfitAndLoss[] | undefined;
  spending: SpendingGroupReport[] | undefined;
  credit: Credit[] | undefined;
}

export default function ReportProfitAndLossPerStore({
  profit,
  loss,
  spending,
  credit,
}: Props) {
  const classes = useStyles();
  const totalProfit =
    profit?.reduce(
      (total, currentData) => (total = total + currentData.totalAmount),
      0
    ) || 0;
  const totalLoss =
    loss?.reduce(
      (total, currentData) => (total = total + currentData.totalAmount),
      0
    ) || 0;
  const totalSpending =
    spending?.reduce(
      (total, currentData) => (total = total + currentData.amount),
      0
    ) || 0;
  const totalCredit =
    credit?.reduce(
      (total, currentData) => (total = total + currentData.amount),
      0
    ) || 0;

  return (
    <div className={classes.paperOnlyBottom}>
      <Grid container spacing={1}>
        <Grid item xs>
          <Paper className={classes.form} style={{ height: "100%" }}>
            <Typography variant="subtitle1" component="p">
              {`IDR ${totalProfit.toLocaleString("EN-US")}`}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              gutterBottom
              component="p"
            >
              Total Gross Profit
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.form} style={{ height: "100%" }}>
            <Typography variant="subtitle1" component="p">
              {`IDR ${totalLoss.toLocaleString("EN-US")}`}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              gutterBottom
              component="p"
            >
              Total Loss
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.form} style={{ height: "100%" }}>
            <Typography variant="subtitle1" component="p">
              {`IDR ${totalSpending.toLocaleString("EN-US")}`}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              gutterBottom
              component="p"
            >
              Total Spending
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.form} style={{ height: "100%" }}>
            <Typography variant="subtitle1" component="p">
              {`IDR ${totalCredit.toLocaleString("EN-US")}`}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              gutterBottom
              component="p"
            >
              Total Customer Debt
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.form} style={{ height: "100%" }}>
            <Typography variant="subtitle1" component="p">
              {`IDR ${(totalProfit - totalLoss - totalSpending).toLocaleString(
                "EN-US"
              )}`}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              gutterBottom
              component="p"
            >
              Net Profit
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
