import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { Store } from "../models/location";
import { ProfitAndLossSearch } from "../models/report";
import { Spending } from "../models/spending";
import { store } from "./store";
import { TableStore } from "./tableStore";

export default class SpendingListStore extends TableStore<Spending> {
  spendingRegistry = new Map<Store, Spending[]>();

  constructor() {
    super();
    makeObservable(this, {
      spendingRegistry: observable,
      loadSpending: action,
      storeList: computed,
    });

    this.setPredicate(new ProfitAndLossSearch());

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadSpending();
      }
    );
  }

  setPredicate = (value: ProfitAndLossSearch) => {
    runInAction(() => {
      Object.keys(value).forEach((key) => {
        this.predicate.delete(key);
      });
      this.predicate.set(
        "startDate",
        value.startDate.toLocaleDateString("EN-US")
      );
      this.predicate.set("endDate", value.endDate.toLocaleDateString("EN-US"));
      this.predicate.set(
        "storeId",
        value.storeId === "All" ? "" : value.storeId
      );
    });
  };

  get filter() {
    return new ProfitAndLossSearch({
      storeId:
        this.predicate.get("storeId") === ""
          ? "All"
          : this.predicate.get("storeId")!,
      startDate: new Date(this.predicate.get("startDate")!),
      endDate: new Date(this.predicate.get("endDate")!),
    });
  }

  loadSpending = async () => {
    this.clearSpending();
    this.loading = true;

    try {
      let locations: Store[] = [];
      const storeId = this.predicate.get("storeId");

      if (storeId === "") locations = store.userStore.storeGranted;
      else
        locations = store.userStore.storeGranted.filter(
          (x) => x.id === storeId
        );

      for await (const location of locations) {
        const result = await agent.Reports.reportSpending(
          this.storeParams(location)
        );
        runInAction(() => {
          this.spendingRegistry.set(location, result);
        });
      }
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get storeList() {
    return Array.from(this.spendingRegistry.keys());
  }

  private clearSpending = () => {
    this.spendingRegistry.clear();
  };
}
