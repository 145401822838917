import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { PaginatedResult } from "../models/pagination";
import {
  PurchaseOrder,
  PurchaseOrderDetail,
  PurchaseOrderFormValues,
} from "../models/purchaseOrder";
import { TableStore } from "./tableStore";

export default class PurchaseOrderStore extends TableStore<PurchaseOrder> {
  documentRegistry = new Map<string, PurchaseOrder>();

  constructor() {
    super();
    makeObservable(this, {
      documentRegistry: observable,
      getTotalQty: action,
      getGrandTotal: action,
      loadDocuments: action,
      documentList: computed,
      loadDocument: action,
      createPurchaseOrder: action,
      updatePurchaseOrder: action,
      deletePurchaseOrder: action,
      postPurchaseOrder: action,
    });

    this.setPageNumber(1);
    this.setSortBy(null);
    this.setOrderBy("desc");
    this.sortByElement = [
      { id: "date", label: "Date" },
      { id: "documentNo", label: "Document" },
      { id: "stockLocation", label: "Stock Location" },
      { id: "vendor", label: "Vendor" },
    ];

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadDocuments();
      }
    );
  }

  getTotalQty = (details?: PurchaseOrderDetail[]) => {
    if (details === undefined) return 0;
    return details?.reduce(
      (total, currentData) => (total = total + currentData.qty),
      0
    );
  };

  getGrandTotal = (details?: PurchaseOrderDetail[]) => {
    if (details === undefined) return 0;
    return details?.reduce(
      (total, currentData) => (total = total + currentData.totalAmount),
      0
    );
  };

  private loadDocumentProcess = (result: PaginatedResult<PurchaseOrder[]>) => {
    runInAction(() => {
      this.documentRegistry.clear();
      result.data.forEach((document) => {
        let documentResult = {
          ...document,
          items: this.sortDetails(document.details),
        };
        this.documentRegistry.set(document.id, documentResult);
      });
      this.pagination = result.pagination;
    });
  };

  loadDocuments = async () => {
    this.loading = true;

    try {
      const result = await agent.PurchaseOrders.list(this.axiosParams);
      this.loadDocumentProcess(result);
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get documentList() {
    return Array.from(this.documentRegistry.values());
  }

  loadDocument = async (id: string) => {
    this.loading = true;

    try {
      const result = await agent.PurchaseOrders.details(id);
      return {
        ...result,
        details: this.sortDetails(result.details),
      };
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  createPurchaseOrder = async (document: PurchaseOrderFormValues) => {
    try {
      if (document.details) {
        for await (const x of document.details) {
          x.purchaseOrderId = document.id;
        }
      }
      await agent.PurchaseOrders.create(document);
      const result = await agent.PurchaseOrders.list(this.axiosParams);
      this.loadDocumentProcess(result);
      return "Create stock outgoing document is success!";
    } catch (error) {
      throw error;
    }
  };

  updatePurchaseOrder = async (document: PurchaseOrderFormValues) => {
    try {
      await agent.PurchaseOrders.update(document).then((document) => {
        runInAction(() => {
          this.documentRegistry.set(document.id, document);
        });
      });
      return "Update purchase order document is success!";
    } catch (error) {
      throw error;
    }
  };

  deletePurchaseOrder = async (ids: string[]) => {
    this.loading = true;

    try {
      await agent.PurchaseOrders.delete(ids);
      const result = await agent.PurchaseOrders.list(this.axiosParams);
      this.loadDocumentProcess(result);
      return "Delete purchase order(s) success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  postPurchaseOrder = async (id: string) => {
    this.loading = true;

    try {
      await agent.PurchaseOrders.post(id).then(() => {
        runInAction(() => {
          const document = this.documentRegistry.get(id);
          if (document) {
            document.posted = !document.posted;
            this.documentRegistry.set(id, document);
          }
        });
      });
      return "Document is posted!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  private sortDetails = (details: PurchaseOrderDetail[]) => {
    return details.sort((a, b) => {
      if (a.itemCode > b.itemCode) return 1;
      if (b.itemCode > a.itemCode) return -1;
      return 0;
    });
  };
}
