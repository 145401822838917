import { observer } from "mobx-react-lite";
import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRouteWithMenu from "../../app/layout/PrivateRouteWithMenu";
import Title from "../../app/layout/Title";
import StoreForm from "./StoreForm";
import StoreList from "./StoreList";

export default observer(function Store() {
  let { path } = useRouteMatch();

  return (
    <>
      <Title menuName="Store" />
      <Switch>
        <PrivateRouteWithMenu exact path={path} component={StoreList} link={path} />
        <PrivateRouteWithMenu path={`${path}/form`} component={StoreForm} link={path} />
        <PrivateRouteWithMenu path={`${path}/:id`} component={StoreForm} link={path} />
      </Switch>
    </>
  );
});
