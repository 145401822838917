import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import { TablePagination } from "@material-ui/core";
import ItemCard from "./ItemCard";
import CardDeleteDialog from "../../app/layout/CardDeleteDialog";
import ItemCardSkeleton from "./ItemCardSkeleton";
import CardHeaderList from "../../app/common/card/CardHeaderList";

export default observer(function ItemCardList() {
  const classes = useStyles();
  const { itemStore, dialogStore, snackbarStore } = useStore();
  const {
    deleteItem,
    itemList,
    pagination,
    loading,
    pageNumber,
    pageSize,
    setPageNumber,
    setPageSize,
    rowsOptions,
    initCardRowsOptionsAndPageSize,
    skeletonCardArray,
  } = itemStore;
  const { openSnackbar } = snackbarStore;
  const { openDialogDelete, closeDialog } = dialogStore;

  useEffect(() => {
    initCardRowsOptionsAndPageSize();
  }, [initCardRowsOptionsAndPageSize]);

  const handleDeleteItem = (id: string) => {
    openDialogDelete(
      <CardDeleteDialog
        title="Are you sure want to delete this item?"
        id={id}
      />,
      handleDelete
    );
  };

  const handleDelete = (id: string) => {
    deleteItem(id)
      .catch((error) => {
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        closeDialog();
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  return (
    <div className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CardHeaderList
            title="Item List"
            link="/items/form"
            loading={loading}
          />
        </Grid>
        {loading
          ? skeletonCardArray().map((item, index) => (
              <Grid key={index} item xl={3} lg={4} md={6} sm={6} xs={12}>
                <ItemCardSkeleton />
              </Grid>
            ))
          : itemList.map(([id, item]) => (
              <Grid key={id} item xl={3} lg={4} md={6} sm={12} xs={12}>
                <ItemCard item={item} deleteItem={handleDeleteItem} />
              </Grid>
            ))}
        <Grid item xs={12}>
          <Paper>
            <TablePagination
              rowsPerPageOptions={rowsOptions}
              component="div"
              count={pagination === null ? 0 : pagination.totalItems}
              page={pageNumber}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
});
