import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { Store } from "../models/location";
import {
  Credit,
  ProfitAndLoss,
  ProfitAndLossSearch,
  SpendingGroupReport,
} from "../models/report";
import { store } from "./store";
import { TableStore } from "./tableStore";

export default class ProfitAndLossStore extends TableStore<ProfitAndLoss> {
  profitRegistry = new Map<Store, ProfitAndLoss[]>();
  lossRegistry = new Map<Store, ProfitAndLoss[]>();
  spendingRegistry = new Map<Store, SpendingGroupReport[]>();
  creditRegistry = new Map<Store, Credit[]>();

  constructor() {
    super();
    makeObservable(this, {
      profitRegistry: observable,
      lossRegistry: observable,
      spendingRegistry: observable,
      creditRegistry: observable,
      loadReportProfitAndLoss: action,
      storeList: computed,
      totalProfit: computed,
      totalLoss: computed,
      totalSpending: computed,
      totalCredit: computed,
    });

    this.setPredicate(new ProfitAndLossSearch());

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadReportProfitAndLoss();
      }
    );
  }

  setPredicate = (value: ProfitAndLossSearch) => {
    runInAction(() => {
      Object.keys(value).forEach((key) => {
        this.predicate.delete(key);
      });
      this.predicate.set(
        "startDate",
        value.startDate.toLocaleDateString("EN-US")
      );
      this.predicate.set("endDate", value.endDate.toLocaleDateString("EN-US"));
      this.predicate.set(
        "storeId",
        value.storeId === "All" ? "" : value.storeId
      );
    });
  };

  get filter() {
    return new ProfitAndLossSearch({
      storeId:
        this.predicate.get("storeId") === ""
          ? "All"
          : this.predicate.get("storeId")!,
      startDate: new Date(this.predicate.get("startDate")!),
      endDate: new Date(this.predicate.get("endDate")!),
    });
  }

  loadReportProfitAndLoss = async () => {
    this.clearProfitAndLoss();
    this.loading = true;

    try {
      let locations: Store[] = [];
      const storeId = this.predicate.get("storeId");

      if (storeId === "") locations = store.userStore.storeGranted;
      else
        locations = store.userStore.storeGranted.filter(
          (x) => x.id === storeId
        );

      for await (const location of locations) {
        const profit = await agent.Reports.profit(this.storeParams(location));
        const loss = await agent.Reports.loss(this.storeParams(location));
        const spending = await agent.Reports.spending(
          this.storeParams(location)
        );
        const credit = await agent.Reports.credit(this.storeParams(location));
        runInAction(() => {
          this.profitRegistry.set(location, profit);
          this.lossRegistry.set(location, loss);
          this.spendingRegistry.set(location, spending);
          this.creditRegistry.set(location, credit);
        });
      }
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get storeList() {
    return Array.from(this.profitRegistry.keys());
  }

  get totalProfit() {
    let total = 0;
    this.profitRegistry.forEach((value) => {
      total =
        total +
          value.reduce(
            (total, currentData) => (total = total + currentData.totalAmount),
            0
          ) || 0;
    });
    return total;
  }

  get totalLoss() {
    let total = 0;
    this.lossRegistry.forEach((value) => {
      total =
        total +
          value.reduce(
            (total, currentData) => (total = total + currentData.totalAmount),
            0
          ) || 0;
    });
    return total;
  }

  get totalSpending() {
    let total = 0;
    this.spendingRegistry.forEach((value) => {
      total =
        total +
          value.reduce(
            (total, currentData) => (total = total + currentData.amount),
            0
          ) || 0;
    });
    return total;
  }

  get totalCredit() {
    let total = 0;
    this.creditRegistry.forEach((value) => {
      total =
        total +
          value.reduce(
            (total, currentData) => (total = total + currentData.amount),
            0
          ) || 0;
    });
    return total;
  }

  private clearProfitAndLoss = () => {
    this.profitRegistry.clear();
    this.lossRegistry.clear();
    this.spendingRegistry.clear();
    this.creditRegistry.clear();
  };
}
