import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import { history } from "../..";
import agent from "../api/agent";
import { StockLocation, Store } from "../models/location";
import { MasterMenu } from "../models/masterMenu";
import {
  LoginFormValues,
  ProfileFormValues,
  Role,
  RoleForm,
  User,
  UserFormValues,
} from "../models/user";
import killAllRegistry, { store } from "./store";
import { TableStore } from "./tableStore";

export default class UserStore extends TableStore<User> {
  userRegistry = new Map<string, User>();
  user: User | null = null;
  roles: Role[] = [];
  stockLocationGranted: StockLocation[] = [];
  storeGranted: Store[] = [];

  constructor() {
    super();
    makeObservable(this, {
      userRegistry: observable,
      user: observable,
      roles: observable,
      stockLocationGranted: observable,
      storeGranted: observable,
      isLoggedIn: computed,
      login: action,
      logout: action,
      getUser: action,
      loadUsers: action,
      userList: computed,
      loadUser: action,
      getRoles: action,
      roleCreate: action,
      register: action,
      update: action,
      deleteUser: action,
      changeProfile: action,
      role: computed,
      restricted: action,
    });

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadUsers();
      }
    );
  }

  get isLoggedIn() {
    return !!this.user;
  }

  login = async (creds: LoginFormValues) => {
    try {
      const user = await agent.Account.login(creds);
      store.commonStore.setToken(user.token);
      let result = await agent.Menus.list();
      const stockLocationGranted = await agent.Location.granted();
      const storeGranted = await agent.Stores.granted();
      runInAction(() => {
        this.user = user;
        store.menuStore.menuRegistry.clear();
        result = store.menuStore.sortMenu(result);
        result.forEach((menu) => {
          let menuResult = new MasterMenu();
          if (menu.childMenus) {
            menuResult = {
              ...menu,
              childMenus: store.menuStore.sortMenu(menu.childMenus),
            } as MasterMenu;
          } else {
            menuResult = menu;
          }
          store.menuStore.menuRegistry.set(menu.id, menuResult);
        });
        this.stockLocationGranted = stockLocationGranted;
        this.storeGranted = storeGranted;
      });
      history.push("/");
    } catch (error) {
      throw error;
    }
  };

  logout = () => {
    store.commonStore.setToken(null);
    window.localStorage.removeItem("jwt");
    this.user = null;
    killAllRegistry();
    history.push("/");
  };

  getUser = async (upXl: boolean, downMd: boolean) => {
    try {
      const user = await agent.Account.current();
      let result = await agent.Menus.list();
      const stockLocationGranted = await agent.Location.granted();
      const storeGranted = await agent.Stores.granted();
      runInAction(() => {
        this.user = user;
        store.menuStore.menuRegistry.clear();
        result = store.menuStore.sortMenu(result);
        result.forEach((menu) => {
          let menuResult = new MasterMenu();
          if (menu.childMenus) {
            menuResult = {
              ...menu,
              childMenus: store.menuStore.sortMenu(menu.childMenus),
            } as MasterMenu;
          } else {
            menuResult = menu;
          }
          store.menuStore.menuRegistry.set(menu.id, menuResult);
        });
        this.stockLocationGranted = stockLocationGranted;
        this.storeGranted = storeGranted;
        store.commonStore.setWidthSize(upXl, downMd);
      });
    } catch (error) {
      console.log(error);
    }
  };

  loadUsers = async () => {
    this.loading = true;

    try {
      const result = await agent.Account.list(this.axiosParams);
      runInAction(() => {
        result.forEach((user) => {
          this.userRegistry.set(user.id, user);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get userList() {
    return Array.from(this.userRegistry);
  }

  loadUser = async (username: string) => {
    this.loading = true;

    try {
      const user = await agent.Account.user(username);
      return user;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  getRoles = async () => {
    try {
      const roles = await agent.Account.roles();
      runInAction(() => (this.roles = roles));
    } catch (error) {
      console.log(error);
    }
  };

  roleCreate = async (role: RoleForm) => {
    try {
      await agent.Account.rolecreate(role).then((value) => {
        runInAction(() => {
          this.roles.push(value as Role);
        });
      });
      return "Role created success!";
    } catch (error) {
      throw error;
    }
  };

  register = async (creds: UserFormValues) => {
    try {
      await agent.Account.register(creds).then((values) => {
        runInAction(() => {
          this.userRegistry.set(values.id, values);
        });
      });
      return "User created success!";
    } catch (error) {
      throw error;
    }
  };

  update = async (username: string, creds: UserFormValues) => {
    try {
      await agent.Account.update(username, creds).then((values) => {
        runInAction(() => {
          this.userRegistry.set(values.id, values);
        });
      });
      return "User updated success!";
    } catch (error) {
      throw error;
    }
  };

  changeProfile = async (creds: ProfileFormValues) => {
    try {
      await agent.Account.changeProfile(creds).then((values) => {
        runInAction(() => {
          this.user = values;
          this.userRegistry.set(values.userName, values);
        });
      });
      return this.user;
    } catch (error) {
      throw error;
    }
  };

  deleteUser = async (id: string) => {
    this.loading = true;

    try {
      await agent.Account.delete(id).then(() => {
        runInAction(() => this.userRegistry.delete(id));
      });
      return "Delete user success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get role() {
    return this.user?.roles.find((x) => x.name === "Developer");
  }

  restricted = (date: Date) => {
    return this.role
      ? false
      : new Date(date).setHours(0, 0, 0, 0) <
          new Date(this.user!.date).setHours(0, 0, 0, 0);
  };

  restrictedSalesUpdate = (date: Date) => {
    let limitDay = new Date(this.user!.date);
    limitDay.setDate(new Date(this.user!.date).getDate() - 3);
    return this.role
      ? false
      : new Date(date).setHours(0, 0, 0, 0) <
          new Date(limitDay).setHours(0, 0, 0, 0);
  };

  restrictedSalesDate = () => {
    let limitDay = new Date(this.user!.date);
    limitDay.setDate(new Date(this.user!.date).getDate() - 3);
    return this.role ? undefined : limitDay;
  };

  restrictedPaymentDate = () => {
    let limitDay = new Date(this.user!.date);
    limitDay.setDate(
      this.role
        ? new Date(this.user!.date).getDate() - 90
        : new Date(this.user!.date).getDate() - 7
    );
    return limitDay;
  };
}
