import {
  Grid,
  Button,
  Divider,
  LinearProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import ButtonExcel from "../../../app/common/export/ButtonExcel";
import Filter from "../../../app/common/filter/Filter";
import DateInputField from "../../../app/common/form/DateInputFIeld";
import Dropdown2 from "../../../app/common/form/Dropdown2";
import SearchFilter from "../../../app/common/form/SearchFilter";
import ButtonPrint from "../../../app/common/print/ButtonPrint";
import ComponentToPrint from "../../../app/common/print/ComponentToPrint";
import { useStyles } from "../../../app/layout/style";
import Title from "../../../app/layout/Title";
import { InvoiceSummarySearch } from "../../../app/models/report";
import { useStore } from "../../../app/stores/store";
import ReportSalesSummaryTable from "./ReportSalesSummaryTable";

export default observer(function ReportSalesSummary() {
  const classes = useStyles();
  const { userStore, summaryStore, customerStore } = useStore();
  const { storeGranted } = userStore;
  const {
    setPredicate,
    summaryList,
    loadReportSummary,
    salesSummaryRegistry,
    itemHeaderRegistry,
    paymentHeaderRegistry,
    loading,
    filter,
  } = summaryStore;
  const { loadDrivers, drivers } = customerStore;
  const [search, setSearch] = useState(new InvoiceSummarySearch());
  const [open, setOpen] = useState(false);
  let componentRef = useRef(null);

  useEffect(() => {
    if (drivers.length < 1) loadDrivers();
    loadReportSummary();
  }, [drivers.length, loadReportSummary, loadDrivers]);

  const handleSetSearch = () => {
    setSearch(filter);
  };

  const handleSearchClick = (values: InvoiceSummarySearch) => {
    setPredicate(values);
  };

  return (
    <>
      <Title menuName="Report Sales Summary">
        <>
          <ButtonExcel
            fileName="Sales Summary"
            sheetName="Sales Summary"
            componentToExport={componentRef}
            disabled={loading || salesSummaryRegistry.size === 0}
          />
          <ButtonPrint
            componentToPrint={componentRef}
            disabled={loading || salesSummaryRegistry.size === 0}
          />
          <Filter open={open} setOpen={setOpen} setSearch={handleSetSearch}>
            <div className={classes.filter}>
              <Formik
                enableReinitialize
                initialValues={search}
                onSubmit={(values) => handleSearchClick(values)}
              >
                {({ handleSubmit, values }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-end"
                      spacing={1}
                    >
                      <Grid item>
                        <Dropdown2
                          name="storeId"
                          options={storeGranted.map((x) => {
                            return { text: x.name, value: x.id };
                          })}
                          label="Store"
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          titleDefaultSelected="All Selected"
                          displayDefaultSelected
                        />
                      </Grid>
                      <Grid item>
                        <Dropdown2
                          name="driverId"
                          options={drivers.map((x) => {
                            return { text: x.name, value: x.id };
                          })}
                          label="Driver"
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          titleDefaultSelected="All Selected"
                          displayDefaultSelected
                        />
                      </Grid>
                      <Grid item>
                        <Dropdown2
                          name="shift"
                          options={[
                            { text: "SHIFT 1", value: "SHIFT 1" },
                            {
                              text: "SHIFT 2",
                              value: "SHIFT 2",
                            },
                            {
                              text: "SHIFT 3",
                              value: "SHIFT 3",
                            },
                          ]}
                          label="Shift"
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          titleDefaultSelected="All Selected"
                          displayDefaultSelected
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="Start Date"
                          name="startDate"
                          maxDate={values.endDate}
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="End Date"
                          name="endDate"
                          minDate={values.startDate}
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <SearchFilter
                          name="customerName"
                          label="Customer Name"
                          variant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <SearchFilter
                          name="documentNo"
                          label="Invoice No."
                          variant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <Dropdown2
                          name="deliveryStatus"
                          options={[
                            { text: "Delivered", value: "true" },
                            { text: "Not Delivery Yet", value: "false" },
                          ]}
                          label="Delivery Status"
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          titleDefaultSelected="All Selected"
                          displayDefaultSelected
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </Filter>
        </>
      </Title>
      <>
        {salesSummaryRegistry.size > 0 &&
          summaryList.map((location) => (
            <Paper className={classes.form} key={location.id}>
              <Typography variant="subtitle1">{location.name}</Typography>
              <Divider />
              {loading ? (
                <LinearProgress />
              ) : (
                <ReportSalesSummaryTable
                  salesSummary={salesSummaryRegistry.get(location)}
                  itemHeader={itemHeaderRegistry.get(location)}
                  paymentHeader={paymentHeaderRegistry.get(location)}
                  disableSort={false}
                />
              )}
            </Paper>
          ))}
        <ComponentToPrint
          title="Report Sales By Summary"
          componentToPrint={componentRef}
        >
          <>
            {salesSummaryRegistry.size > 0 &&
              summaryList.map((location) => (
                <div key={location.id}>
                  <Typography variant="subtitle1">{location.name}</Typography>
                  <Divider />
                  <ReportSalesSummaryTable
                    salesSummary={salesSummaryRegistry.get(location)}
                    itemHeader={itemHeaderRegistry.get(location)}
                    paymentHeader={paymentHeaderRegistry.get(location)}
                    disableSort={true}
                  />
                </div>
              ))}
          </>
        </ComponentToPrint>
      </>
    </>
  );
});
