import { observer } from "mobx-react-lite";
import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRouteWithMenu from "../../app/layout/PrivateRouteWithMenu";
import Title from "../../app/layout/Title";
import StockControlForm from "./StockControlForm";
import StockControlList from "./StockControlList";

export default observer(function StockControl() {
  let { path } = useRouteMatch();

  return (
    <>
      <Title menuName="Stock Control" />
      <Switch>
        <PrivateRouteWithMenu
          exact
          path={path}
          component={StockControlList}
          link={path}
        />
        <PrivateRouteWithMenu
            path={`${path}/form`}
            component={StockControlForm}
            link={path}
          />
          <PrivateRouteWithMenu
            path={`${path}/:id`}
            component={StockControlForm}
            link={path}
          />
      </Switch>
    </>
  );
});
