import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { PaginatedResult } from "../models/pagination";
import {
  Spending,
  SpendingFormValues,
  SpendingGroup,
} from "../models/spending";
import { TableStore } from "./tableStore";

export default class SpendingStore extends TableStore<Spending> {
  documentRegistry = new Map<string, Spending>();
  spendingGroups: SpendingGroup[] = [];

  constructor() {
    super();
    makeObservable(this, {
      documentRegistry: observable,
      spendingGroups: observable,
      loadDocuments: action,
      documentList: computed,
      loadDocument: action,
      createSpending: action,
      updateSpending: action,
      deleteSpending: action,
      postSpending: action,
      massPostSpending: action,
    });

    this.setPageNumber(1);
    this.setSortBy(null);
    this.setOrderBy("desc");
    this.sortByElement = [
      { id: "date", label: "Date" },
      { id: "documentNo", label: "Document" },
      { id: "store", label: "Store" },
      { id: "spendingGroup", label: "Group" },
      { id: "description", label: "Description" },
    ];

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadDocuments();
      }
    );
  }

  private loadDocumentProcess = (result: PaginatedResult<Spending[]>) => {
    runInAction(() => {
      this.documentRegistry.clear();
      result.data.forEach((item) => {
        this.documentRegistry.set(item.id, item);
      });
      this.pagination = result.pagination;
    });
  };

  loadDocuments = async () => {
    this.loading = true;

    try {
      const result = await agent.Spendings.list(this.axiosParams);
      this.loadDocumentProcess(result);
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  loadDocument = async (id: string) => {
    this.loading = true;

    try {
      const result = await agent.Spendings.details(id);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get documentList() {
    return Array.from(this.documentRegistry.values());
  }

  loadSpendingGroup = async () => {
    this.setLoading(true);

    try {
      const result = await agent.Spendings.groups();
      runInAction(() => {
        this.spendingGroups = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  createSpending = async (document: SpendingFormValues) => {
    try {
      await agent.Spendings.create(document);
      const result = await agent.Spendings.list(this.axiosParams);
      this.loadDocumentProcess(result);
      return "Create spending document is success!";
    } catch (error) {
      throw error;
    }
  };

  updateSpending = async (document: SpendingFormValues) => {
    try {
      await agent.Spendings.update(document).then((document) => {
        runInAction(() => {
          this.documentRegistry.set(document.id, document);
        });
      });
      return "Update spending document is success!";
    } catch (error) {
      throw error;
    }
  };

  deleteSpending = async (ids: string[]) => {
    this.loading = true;

    try {
      await agent.Spendings.delete(ids);
      const result = await agent.Spendings.list(this.axiosParams);
      this.loadDocumentProcess(result);
      return "Delete spending(s) success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  postSpending = async (id: string) => {
    this.loading = true;

    try {
      await agent.Spendings.post(id);
      const result = await agent.Spendings.list(this.axiosParams);
      this.loadDocumentProcess(result);
      return "Document is posted!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  massPostSpending = async (ids: string[]) => {
    this.loading = true;

    try {
      await agent.Spendings.massPost(ids);
      const result = await agent.Spendings.list(this.axiosParams);
      this.loadDocumentProcess(result);
      return "Post spending(s) success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };
}
