import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { Store, StoreFormValues } from "../models/location";
import { TableStore } from "./tableStore";

export default class StoreStore extends TableStore<Store> {
  storeRegistry = new Map<string, Store>();
  storeDropDown: Store[] = [];

  constructor() {
    super();
    makeObservable(this, {
      storeRegistry: observable,
      storeDropDown: observable,
      loadStores: action,
      loadStore: action,
      loadDropDown: action,
      storeList: computed,
      createStore: action,
      updateStore: action,
    });

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadStores();
      }
    );
  }

  loadStores = async () => {
    this.loading = true;

    try {
      const result = await agent.Stores.list(this.axiosParams);
      runInAction(() => {
        this.storeRegistry.clear();
        result.forEach((location) => {
          this.storeRegistry.set(location.id, location);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  loadStore = async (id: string) => {
    this.loading = true;

    try {
      const result = await agent.Stores.details(id);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  loadDropDown = async () => {
    try {
      const dropdown = await agent.Stores.dropdown();
      runInAction(() => {
        this.storeDropDown = dropdown;
      });
    } catch (error) {
      console.log(error);
    }
  };

  get storeList() {
    return Array.from(this.storeRegistry);
  }

  createStore = async (store: StoreFormValues) => {
    try {
      await agent.Stores.create(store).then(() => {
        runInAction(() => {
          let newStore = new Store(store);
          this.storeRegistry.set(newStore.id, newStore);
        });
      });
      return "Create store success!";
    } catch (error) {
      throw error;
    }
  };

  updateStore = async (store: StoreFormValues) => {
    try {
      await agent.Stores.update(store).then(() => {
        runInAction(() => {
          if (store.id) {
            this.storeRegistry.set(store.id, store as Store);
          }
        });
      });
      return "Store updated!";
    } catch (error) {
      throw error;
    }
  };
}
