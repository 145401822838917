import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { useStore } from "../stores/store";

interface Props {
  title: string;
}

export default function DeleteDialog({title}: Props) {
  const {dialogStore} = useStore();
  const {closeDialog, dialog} = dialogStore;

  const handleClick = () => {
    closeDialog();
    if (dialog.action) dialog.action();
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Delete Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {title}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClick} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </>
  );
}
