import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ManageMenuList from "./ManageMenuList";
import Title from "../../app/layout/Title";
import { useStyles } from "../../app/layout/style";

export default observer(function ManageMenu() {
  const classes = useStyles();
  const { userStore, manageMenuStore } = useStore();
  const { roles, getRoles } = userStore;
  const { role, setRole, loadMenusGranted, setMenus, setMenusGranted } =
    manageMenuStore;

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  const handleRolesChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value as string;
    setRole(value);
    if (value === "0") {
      setMenus([]);
      setMenusGranted([]);
    } else loadMenusGranted(value);
  };

  return (
    <div className={classes.transferList}>
      <Title menuName="Manage Menu" />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={5}>
          <FormControl
            color="primary"
            variant="outlined"
            fullWidth
            margin="normal"
          >
            <InputLabel htmlFor="dropdown-label">Role</InputLabel>
            <Select
              onChange={(e) => handleRolesChange(e)}
              color="primary"
              label="Role"
              inputProps={{
                name: "role",
                id: "dropdown-label",
              }}
              value={role}
            >
              <MenuItem value="0">Not Selected</MenuItem>
              {roles !== undefined &&
                roles.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <ManageMenuList role={role} />
        </Grid>
      </Grid>
    </div>
  );
});
