import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { StockLocation } from "../../app/models/location";
import EditIcon from "@material-ui/icons/Edit";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { useStyles } from "../../app/layout/style";
import { AvatarName } from "../../app/models/table";

interface Props {
  location: StockLocation;
}

export default observer(function StockLocationCard({ location }: Props) {
  const classes = useStyles();
  const avatarName = new AvatarName(location.name);

  return (
    <Card>
      <CardHeader
        avatar={
          <Chip
            variant="outlined"
            color="secondary"
            label={avatarName.getAvatarName}
          />
        }
        action={
          <IconButton component={Link} to={`/stocklocation/${location.id}`}>
            <EditIcon />
          </IconButton>
        }
        title={location.name}
        subheader={<SubHeaderCard location={location} />}
      />
      <CardContent className={classes.content}>
        <div className={classes.chip}>
          <Chip
            variant="outlined"
            color="primary"
            label={location.isSPBE ? "SPBE" : "AGEN ELPIJI"}
          />
          <Chip
            variant="outlined"
            color="primary"
            label={location.isSubsidy ? "PSO" : "NON PSO"}
          />
          {!location.isActive && (
            <Chip
              icon={<SentimentVeryDissatisfiedIcon />}
              variant="outlined"
              color="secondary"
              label="Deleted"
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
});

function SubHeaderCard({ location }: Props) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: "8px",
      }}
    >
      <LocationCityIcon />
      <span style={{ marginLeft: "8px" }}>{location.city}</span>
    </div>
  );
}
