import { Typography, Grid, Paper } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useStyles } from "../../../app/layout/style";
import { useStore } from "../../../app/stores/store";

export default observer(function ReportProfitAndLossTotalAllStore() {
  const classes = useStyles();
  const { profitAndLossStore } = useStore();
  const {
    storeList,
    totalCredit,
    totalLoss,
    totalProfit,
    totalSpending,
    filter,
  } = profitAndLossStore;

  return (
    <>
      <Typography variant="subtitle1" component="p" align="center" gutterBottom>
        {`Date: ${moment(filter.startDate).format("DD-MMM-yyyy")} - ${moment(
          filter.endDate
        ).format("DD-MMM-yyyy")}`}
      </Typography>
      {storeList.length > 1 && (
        <>
          <Typography variant="subtitle1" component="p" gutterBottom>
            Total All Store
          </Typography>
          <div className={classes.paperOnlyBottom}>
            <Grid container spacing={1}>
              <Grid item xs>
                <Paper className={classes.form} style={{ height: "100%" }}>
                  <Typography variant="subtitle1" component="p">
                    {`IDR ${totalProfit.toLocaleString("EN-US")}`}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                    component="p"
                  >
                    Total Gross Profit
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs>
                <Paper className={classes.form} style={{ height: "100%" }}>
                  <Typography variant="subtitle1" component="p">
                    {`IDR ${totalLoss.toLocaleString("EN-US")}`}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                    component="p"
                  >
                    Total Loss
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs>
                <Paper className={classes.form} style={{ height: "100%" }}>
                  <Typography variant="subtitle1" component="p">
                    {`IDR ${totalSpending.toLocaleString("EN-US")}`}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                    component="p"
                  >
                    Total Spending
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs>
                <Paper className={classes.form} style={{ height: "100%" }}>
                  <Typography variant="subtitle1" component="p">
                    {`IDR ${totalCredit.toLocaleString("EN-US")}`}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                    component="p"
                  >
                    Total Customer Debt
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs>
                <Paper className={classes.form} style={{ height: "100%" }}>
                  <Typography variant="subtitle1" component="p">
                    {`IDR ${(
                      totalProfit -
                      totalLoss -
                      totalSpending
                    ).toLocaleString("EN-US")}`}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                    component="p"
                  >
                    Net Profit
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
});
