import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import { Button, Chip } from "@material-ui/core";
import { useStore } from "../../app/stores/store";
import { CashIN } from "../../app/models/cashIN";

interface RowProps {
  row: CashIN;
  labelId: string;
  isItemSelected: boolean;
  handleClick: (event: React.MouseEvent<unknown>, id: string) => void;
  handlePost: (
    event: React.MouseEvent<unknown>,
    id: string,
    posted: boolean
  ) => void;
}

export default function CashINTableRow({
  row,
  labelId,
  isItemSelected,
  handleClick,
  handlePost,
}: RowProps) {
  const { userStore } = useStore();
  const { role, restricted } = userStore;

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          {!row.posted && (
            <Checkbox
              checked={isItemSelected}
              onClick={(event) => handleClick(event, row.id)}
            />
          )}
        </TableCell>
        <TableCell padding="checkbox">
          <Tooltip title="Update">
            <IconButton
              component={Link}
              to={`/cash-in/${row.id}`}
              color="primary"
              disabled={row.posted}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell component="th" id={labelId} scope="row">
          {moment(row.date).format("DD-MMM-yyyy")}
        </TableCell>
        <TableCell>{row.documentNo}</TableCell>
        <TableCell>{row.store.name}</TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell align="right">
          {row.amount.toLocaleString("en-US")}
        </TableCell>
        <TableCell align="center">
          {!role ? (
            row.posted && restricted(row.date) ? (
              <Chip variant="outlined" color="secondary" label="Posted" />
            ) : (
              <Button
                onClick={(e) => handlePost(e, row.id, row.posted)}
                variant="outlined"
                color={row.posted ? "secondary" : "primary"}
              >
                {row.posted ? "Unpost" : "Post"}
              </Button>
            )
          ) : (
            <Button
              onClick={(e) => handlePost(e, row.id, row.posted)}
              variant="outlined"
              color={row.posted ? "secondary" : "primary"}
            >
              {row.posted ? "Unpost" : "Post"}
            </Button>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
