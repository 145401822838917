import {
  Grid,
  Paper,
  Typography,
  Divider,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Dropdown from "../../app/common/form/Dropdown";
import InputField from "../../app/common/form/InputField";
import { useStyles } from "../../app/layout/style";
import { MasterMenuFormValues } from "../../app/models/masterMenu";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";

export default observer(function MasterMenuForm() {
  const classes = useStyles();
  const { menuStore, snackbarStore } = useStore();
  const {
    menuDropDown,
    loadDropDown,
    loadMenu,
    createMenu,
    updateMenu,
    loadingMenu,
  } = menuStore;
  const { openSnackbar } = snackbarStore;
  const history = useHistory();
  let { id } = useParams<{ id: string }>();
  const [menu, setMenu] = useState<MasterMenuFormValues>(
    new MasterMenuFormValues()
  );

  useEffect(() => {
    loadDropDown();
    if (id) {
      loadMenu(id).then((x) => {
        setMenu(new MasterMenuFormValues(x));
      });
    }
  }, [loadDropDown, id, loadMenu]);

  const schema = Yup.object({
    menuName: Yup.string().required("Menu Name is required."),
    logo: Yup.string().when("parentId", {
      is: (val: string) => {
        if (val === undefined) return true;
        if (val === null) return true;
        if (val === "") return true;
        return false;
      },
      then: Yup.string().required("Icon is required."),
      otherwise: Yup.string().notRequired(),
    }),
    menuLink: Yup.string().when("parentId", {
      is: (val: string) => {
        if (val === undefined) return true;
        if (val === null) return true;
        if (val === "") return true;
        return false;
      },
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("Link is required."),
    }),
  });

  const handleFormSubmit = (
    menu: MasterMenuFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!menu.id) {
      let newMenu = {
        ...menu,
        id: uuid(),
        parentId: menu.parentId === "" ? undefined : menu.parentId,
        isDefault: menu.parentId === "" || menu.parentId === null,
      };
      createMenu(newMenu)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setMenu(new MasterMenuFormValues());
            resetForm();
          }
        });
    } else {
      let newMenuUpdate = {
        ...menu,
        parentId: menu.parentId === "" ? undefined : menu.parentId,
        isDefault: menu.parentId === "" || menu.parentId === null,
      };
      updateMenu(newMenuUpdate)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/mastermenu");
          }
        });
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.form}>
            <Typography variant="h5">
              Form {id ? "Update" : "Create"}
            </Typography>
            <Divider />
            <Formik
              validationSchema={schema}
              enableReinitialize
              initialValues={menu}
              onSubmit={(values, { resetForm, setSubmitting }) =>
                handleFormSubmit(values, resetForm, setSubmitting)
              }
            >
              {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <Dropdown
                    options={menuDropDown.map((x) => {
                      return { text: x.menuName, value: x.id };
                    })}
                    name="parentId"
                    placeholder="Parent Menu"
                    label="Parent Menu"
                    disabled={isSubmitting || loadingMenu}
                  />
                  <InputField
                    variant="standard"
                    label="Menu Name"
                    name="menuName"
                    placeholder="Please input menu name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loadingMenu}
                  />
                  <InputField
                    variant="standard"
                    label="Icon"
                    name="logo"
                    placeholder="Please input an icon"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loadingMenu}
                  />
                  <InputField
                    variant="standard"
                    label="Link"
                    name="menuLink"
                    placeholder="Please input link"
                    helperText="Add '/' to first letter"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loadingMenu}
                  />
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="stretch"
                    spacing={1}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting || !isValid || !dirty}
                      >
                        {isSubmitting && (
                          <CircularProgress
                            className={classes.progress}
                            size={16}
                            color="inherit"
                          />
                        )}
                        Save
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="default"
                        component={Link}
                        to="/mastermenu"
                        disabled={isSubmitting || loadingMenu}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
});
