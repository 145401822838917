import { observer } from "mobx-react-lite";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { useStore } from "../stores/store";
import { useMediaQuery, useTheme } from "@material-ui/core";

export default observer(function FormDialog() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { dialogStore } = useStore();
  const { closeDialogForm, dialog } = dialogStore;

  return (
    <Dialog
      open={dialog.open}
      onClose={closeDialogForm}
      maxWidth={dialog.width}
      fullScreen={fullScreen && dialog.fullScreen}
      fullWidth
    >
      {dialog.component}
    </Dialog>
  );
});
