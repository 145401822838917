import {
  Grid,
  Button,
  LinearProgress,
  Paper,
  Typography,
  Divider,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import Filter from "../../../app/common/filter/Filter";
import DateInputField from "../../../app/common/form/DateInputFIeld";
import Dropdown2 from "../../../app/common/form/Dropdown2";
import ButtonPrint from "../../../app/common/print/ButtonPrint";
import ComponentToPrint from "../../../app/common/print/ComponentToPrint";
import { useStyles } from "../../../app/layout/style";
import Title from "../../../app/layout/Title";
import { StockHistorySearch } from "../../../app/models/report";
import { useStore } from "../../../app/stores/store";
import ReportStockHistoryTable from "./ReportStockHistoryTable";

export default observer(function ReportStockHistory() {
  const classes = useStyles();
  const { userStore, stockHistoryStore } = useStore();
  const { stockLocationGranted } = userStore;
  const {
    setPredicate,
    loadReportStockHistory,
    stockLocationList,
    stockHistoryRegistry,
    loading,
    filter,
  } = stockHistoryStore;
  const [search, setSearch] = useState(new StockHistorySearch());
  const [open, setOpen] = useState(false);
  let componentRef = useRef(null);

  useEffect(() => {
    loadReportStockHistory();
  }, [loadReportStockHistory]);

  const handleSetSearch = () => {
    setSearch(filter);
  };

  const handleSearchClick = (values: StockHistorySearch) => {
    setPredicate(values);
  };

  return (
    <>
      <Title menuName="Report Stock History">
        <>
          <ButtonPrint
            componentToPrint={componentRef}
            disabled={loading || stockHistoryRegistry.size === 0}
          />
          <Filter open={open} setOpen={setOpen} setSearch={handleSetSearch}>
            <div className={classes.filter}>
              <Formik
                enableReinitialize
                initialValues={search}
                onSubmit={(values) => handleSearchClick(values)}
              >
                {({ handleSubmit, values }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-end"
                      spacing={1}
                    >
                      <Grid item>
                        <Dropdown2
                          name="stockLocationId"
                          options={stockLocationGranted
                            .filter((x) => !x.isSPBE)
                            .map((x) => {
                              return { text: x.name, value: x.id };
                            })}
                          label="Stock Location"
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          titleDefaultSelected="All Selected"
                          displayDefaultSelected
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="Start Date"
                          name="startDate"
                          maxDate={values.endDate}
                          placeholder="Please input Start Date"
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="End Date"
                          name="endDate"
                          minDate={values.startDate}
                          placeholder="Please input End Date"
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </Filter>
        </>
      </Title>
      <>
        {stockHistoryRegistry.size > 0 &&
          stockLocationList.map((location) => (
            <Paper className={classes.form} key={location.id}>
              <Typography variant="subtitle1">{location.name}</Typography>
              <Divider />
              {loading ? (
                <LinearProgress />
              ) : (
                <ReportStockHistoryTable
                  stockHistory={stockHistoryRegistry.get(location)}
                />
              )}
            </Paper>
          ))}
        <ComponentToPrint
          title="Report Stock History"
          componentToPrint={componentRef}
        >
          <>
            {stockHistoryRegistry.size > 0 &&
              stockLocationList.map((location) => (
                <div key={location.id}>
                  <Typography variant="subtitle1">{location.name}</Typography>
                  <Divider />
                  <ReportStockHistoryTable
                    stockHistory={stockHistoryRegistry.get(location)}
                  />
                </div>
              ))}
          </>
        </ComponentToPrint>
      </>
    </>
  );
});
