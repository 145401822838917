export type Order = "asc" | "desc";

export type Shape = "rect" | "text" | "circle";

type Align = "center" | "left" | "right";

export interface HeadCell<T> {
  disablePadding: boolean;
  id: Extract<keyof T, string>;
  label: string;
  align: Align;
  disableSort: boolean;
  skeletonShape: Shape;
  isImage?: boolean;
}

export interface SortBy<T> {
  id: Extract<keyof T, string>;
  label: string;
}

export class AvatarName {
  name: string = "";

  constructor(name: string) {
    this.name = name;
  }

  get getAvatarName() {
    let result = "";
    this.name.split(" ").forEach((x, index) => {
      if (index > 0) result = result.concat(x.substring(0, 1));
    });
    return result;
  }
}