import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import React from "react";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useField } from "formik";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

interface Props extends AutocompleteProps<any, true, undefined, undefined> {
  name: string;
}

export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
export const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function DropDownAutoComplete(props: Props) {
  const [field, meta, helpers] = useField(props.name);

  return (
    <FormControl fullWidth>
      <Autocomplete
        {...props}
        id={props.name}
        options={props.options}
        value={field.value}
        onChange={(event, value) => helpers.setValue(value)}
      />
      {meta.touched && meta.error ? (
        <FormHelperText>{meta.error}</FormHelperText>
      ) : null}
    </FormControl>
  );
}
