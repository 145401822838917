import Paper from "@material-ui/core/Paper";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Dropdown from "../../app/common/form/Dropdown";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateInputField from "../../app/common/form/DateInputFIeld";
import {
  StockControl,
  StockControlDetail,
  StockControlDetailValues,
  StockControlFormValues,
  StockTransactionType,
} from "../../app/models/stockControl";
import StockControlFormAddDetails from "./StockControlFormAddDetails";
import { CustomerItem } from "../../app/models/customer";

export default observer(function StockControlForm() {
  const classes = useStyles();
  const { stockControlStore, snackbarStore, userStore, itemStore } = useStore();
  const { loadDropDown: loadDropDownItem } = itemStore;
  const {
    loading,
    loadTransactionType,
    loadDocument,
    createStockControl,
    updateStockControl,
  } = stockControlStore;
  const { stockLocationGranted } = userStore;
  const { openSnackbar } = snackbarStore;
  const [stockControl, setStockControl] = useState<StockControlFormValues>(
    new StockControlFormValues()
  );
  const [details, setDetails] = useState<StockControlDetail[]>([]);
  const [valueType, setValueType] = useState<StockTransactionType | null>(null);
  const [valueItem, setValueItem] = useState<CustomerItem | null>(null);
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadDropDownItem();
    loadTransactionType();
    if (id) {
      loadDocument(id).then((result) => {
        loadDocumentResult(result);
      });
    }
  }, [id, loadTransactionType, loadDropDownItem, loadDocument]);

  const loadDocumentResult = (result: StockControl | undefined) => {
    if (result) {
      setDetails(result.details);
      setStockControl(
        new StockControlFormValues({
          id: result.id,
          date: result.date,
          stockLocationId: result.stockLocationId,
          details: result.details.map((x) => {
            return {
              id: x.id,
              stockControlId: result.id,
              itemId: x.itemId,
              stockTransactionTypeId: x.stockTransactionType.id,
              qty: x.qty,
              notes: x.notes,
            } as StockControlDetailValues;
          }),
        })
      );
    }
  };

  const schema = Yup.object({
    date: Yup.date().required("Date is required."),
    stockLocationId: Yup.string().required(
      "Source stock location field is required."
    ),
    details: Yup.array().of(
      Yup.object().shape({
        qty: Yup.number()
          .typeError("Allocation is must be a number.")
          .required("Allocation is required."),
      })
    ),
  });

  const handleFormSubmit = (
    stockControl: StockControlFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!stockControl.id) {
      let newStockControl = {
        ...stockControl,
        id: uuid(),
      };
      createStockControl(newStockControl)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setStockControl(new StockControlFormValues());
            setDetails([]);
            setValueType(null);
            setValueItem(null);
            resetForm();
          }
        });
    } else {
      updateStockControl(stockControl)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/stockcontrol");
          }
        });
    }
  };

  return (
    <>
      <Paper className={classes.form}>
        <Typography variant="h5">Form {id ? "Update" : "Create"}</Typography>
        <Divider />
        <Formik
          validationSchema={schema}
          enableReinitialize
          initialValues={stockControl}
          onSubmit={(values, { resetForm, setSubmitting }) =>
            handleFormSubmit(values, resetForm, setSubmitting)
          }
        >
          {({ handleSubmit, isSubmitting, isValid, values, validateForm }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <DateInputField
                    label="Date"
                    name="date"
                    placeholder="Please input Date"
                    disabled={isSubmitting || loading}
                    maxDate={new Date()}
                    inputVariant="standard"
                    margin="normal"
                  />
                  <Dropdown
                    options={stockLocationGranted
                      .filter((x) => !x.isSPBE)
                      .map((x) => {
                        return { text: x.name, value: x.id };
                      })}
                    name="stockLocationId"
                    placeholder="Stock Location"
                    label="Stock Location"
                    disabled={isSubmitting || loading}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <StockControlFormAddDetails
                    stockControl={values}
                    setStockControl={setStockControl}
                    details={details}
                    setDetails={setDetails}
                    isSubmitting={isSubmitting}
                    loading={loading}
                    validateForm={validateForm}
                    valueItem={valueItem}
                    setValueItem={setValueItem}
                    valueType={valueType}
                    setValueType={setValueType}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="stretch"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || !isValid || details.length === 0}
                  >
                    {isSubmitting && (
                      <CircularProgress
                        className={classes.progress}
                        size={16}
                        color="inherit"
                      />
                    )}
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    component={Link}
                    to="/stockcontrol"
                    disabled={isSubmitting || loading}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
});
