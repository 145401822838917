import { Divider, FormControl, Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import StoreIcon from "@material-ui/icons/Store";
import FaceIcon from "@material-ui/icons/Face";
import TodayIcon from "@material-ui/icons/Today";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import DescriptionIcon from "@material-ui/icons/Description";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import NoteIcon from "@material-ui/icons/Note";
import CustomerAutoComplete from "./CustomerAutoComplete";
import DateInputField from "../../app/common/form/DateInputFIeld";
import { CustomerGranted, Driver } from "../../app/models/customer";
import DriverAutoComplete from "./DriverAutoComplete";
import { FormikErrors, FormikTouched } from "formik";
import { SalesFormValues, SalesPaymentValues } from "../../app/models/sale";
import InputField from "../../app/common/form/InputField";
import Dropdown2 from "../../app/common/form/Dropdown2";

interface Props {
  customer: CustomerGranted | null;
  setCustomer: (customer: CustomerGranted | null) => void;
  driver: Driver | null;
  setDriver: (driver: Driver | null) => void;
  handleCustomerChange: (
    customer: CustomerGranted | null,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => void;
  handleDriverChange: (
    driver: Driver | null,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  store: string;
  loading: boolean;
  errors: FormikErrors<SalesFormValues>;
  touched: FormikTouched<SalesFormValues>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
  payments: SalesPaymentValues[];
}

export default observer(function POSTransaction(props: Props) {
  const { userStore, customerStore } = useStore();
  const { customerGranted, drivers } = customerStore;
  const { restrictedSalesDate } = userStore;
  const {
    customer,
    setCustomer,
    driver,
    setDriver,
    handleCustomerChange,
    handleDriverChange,
    setFieldValue,
    store,
    loading,
    errors,
    touched,
    setFieldTouched,
    payments,
  } = props;

  return (
    <>
      <Typography align="center" variant="h5">
        DETAIL
      </Typography>
      <Divider />
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <NoteIcon />
        </Grid>
        <Grid item xs>
          <InputField
            variant="outlined"
            label="Invoice Ref."
            name="invoiceRef"
            placeholder="Please input invoice ref."
            InputLabelProps={{
              shrink: true,
            }}
            disabled={loading}
            margin="dense"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <TodayIcon />
        </Grid>
        <Grid item xs>
          <DateInputField
            label="Transaction Date"
            inputVariant="outlined"
            name="date"
            margin="dense"
            disabled={loading}
            minDate={restrictedSalesDate()}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <LocalShippingIcon />
        </Grid>
        <Grid item xs>
          <DriverAutoComplete
            name="driverId"
            options={drivers}
            value={driver}
            setValue={setDriver}
            loading={loading}
            onChange={handleDriverChange}
            setFieldValue={setFieldValue}
            error={errors.driverId}
            touched={touched.driverId}
            setFieldTouched={setFieldTouched}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <WbSunnyIcon />
        </Grid>
        <Grid item xs>
          <Dropdown2
            name="shift"
            options={[
              { text: "SHIFT 1", value: "SHIFT 1" },
              {
                text: "SHIFT 2",
                value: "SHIFT 2",
              },
              {
                text: "SHIFT 3",
                value: "SHIFT 3",
              },
            ]}
            label="Shift"
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <FaceIcon />
        </Grid>
        <Grid item xs>
          <CustomerAutoComplete
            name="customerId"
            options={customerGranted}
            value={customer}
            setValue={setCustomer}
            onChange={handleCustomerChange}
            setFieldValue={setFieldValue}
            loading={loading || payments.length > 0}
            variant="outlined"
            error={errors.customerId}
            touched={touched.customerId}
            setFieldTouched={setFieldTouched}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <DescriptionIcon />
        </Grid>
        <Grid item xs>
          <InputField
            variant="outlined"
            label="Notes"
            name="notes"
            multiline
            minRows={4}
            placeholder="Please input notes"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={loading}
            margin="dense"
          />
        </Grid>
      </Grid>
      <FormControl margin="dense" fullWidth>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item>
            <StoreIcon />
          </Grid>
          <Grid item xs>
            <Typography variant="subtitle1" component="p">
              {store}
            </Typography>
          </Grid>
        </Grid>
      </FormControl>
    </>
  );
});
