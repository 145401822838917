import {
  Paper,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { useStyles } from "../../layout/style";
import AddIcon from "@material-ui/icons/Add";

interface Props {
  title: string;
  link: string;
  loading: boolean;
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.dark,
    },
  })
);

export default function CardHeaderList(props: Props) {
  const classes = useStyles();
  const local = useToolbarStyles();
  const { title, link, loading } = props;
  return (
    <Paper>
      <Toolbar className={local.root} color="primary">
        <Typography
          className={classes.title}
          variant="subtitle1"
          id="tableTitle"
          component="div"
        >
          {loading ? (
            <Skeleton variant="text" width="30%" />
          ) : (
            title.toLocaleUpperCase()
          )}
        </Typography>
        {loading ? (
          <Skeleton variant="circle" width={30} height={30} />
        ) : (
          <Tooltip title="Create">
            <IconButton
              component={Link}
              to={link}
              color="default"
              size="medium"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    </Paper>
  );
}
