import { Documents } from "./document";
import { Store } from "./location";

export interface SpendingGroup {
  id: string;
  groupName: string;
}

export interface Spending extends Documents {
  storeId: string;
  store: Store;
  spendingGroupId: string;
  spendingGroup: SpendingGroup;
  description: string;
  amount: number;
}

export class SpendingFormValues {
  id?: string = undefined;
  date: Date = new Date();
  storeId: string = "";
  spendingGroupId: string = "";
  description: string = "";
  amount: number = parseInt("");

  constructor(spending?: SpendingFormValues) {
    if (spending) {
      this.id = spending.id;
      this.date = new Date(spending.date);
      this.storeId = spending.storeId;
      this.spendingGroupId = spending.spendingGroupId;
      this.description = spending.description;
      this.amount = spending.amount;
    }
  }
}
