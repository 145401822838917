import { DialogProps } from "@material-ui/core";
import { makeAutoObservable } from "mobx";

interface Dialog {
  open: boolean;
  component: JSX.Element | undefined;
  width: DialogProps["maxWidth"];
  action?: () => void | undefined;
  actionDelete?: (id: string) => void | undefined;
  fullScreen?: boolean;
}

export default class DialogStore {
  dialog: Dialog = {
    open: false,
    component: undefined,
    width: "sm",
    action: () => {},
    actionDelete: (id: string) => {},
    fullScreen: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  openDialog = (
    component: JSX.Element,
    width: DialogProps["maxWidth"],
    action?: () => void,
    fullScreen?: boolean
  ) => {
    this.dialog.open = true;
    this.dialog.component = component;
    this.dialog.width = width;
    this.dialog.action = action;
    this.dialog.fullScreen = fullScreen ? fullScreen : false;
  };

  openDialogDelete = (
    component: JSX.Element,
    action?: (id: string) => void
  ) => {
    this.dialog.open = true;
    this.dialog.component = component;
    this.dialog.actionDelete = action;
    this.dialog.fullScreen = false;
  };

  closeDialog = () => {
    this.dialog.open = false;
  };

  closeDialogForm = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason) {
      this.dialog.open = true;
    } else {
      this.dialog.open = false;
    }
  };
}
