import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import React, { useRef } from "react";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import StoreIcon from "@material-ui/icons/Store";
import Box from "@material-ui/core/Box";
import TableHead from "@material-ui/core/TableHead";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ReceiptIcon from "@material-ui/icons/Receipt";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import { Chip, Grid, Typography } from "@material-ui/core";
import { Sale } from "../../app/models/sale";
import { observer } from "mobx-react-lite";
import { HeadCell } from "../../app/models/table";
import { useStore } from "../../app/stores/store";
import { useStyles } from "../../app/layout/style";
import ReactToPrint from "react-to-print";
import POSSlip from "./POSSlip";

interface RowProps {
  row: Sale;
  headCells: HeadCell<any>[];
  labelId: string;
  isItemSelected: boolean;
  handleClick: (
    event: React.MouseEvent<unknown>,
    id: string,
    sale: Sale
  ) => void;
  handleDelivery: (event: React.MouseEvent<unknown>, id: string) => void;
}

export default observer(function InvoiceTableRow({
  row,
  headCells,
  labelId,
  isItemSelected,
  handleClick,
  handleDelivery,
}: RowProps) {
  const classes = useStyles();
  const { salesStore } = useStore();
  const { totalQty, totalAmount, totalPayment } = salesStore;
  const [open, setOpen] = React.useState(false);
  const notPaidYet = totalAmount(row.details) - totalPayment(row.payments);
  const totalPayments =
    notPaidYet > 0
      ? totalPayment(row.payments) + notPaidYet
      : totalPayment(row.payments);
  const componentRef = useRef(null);

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            onClick={(event) => handleClick(event, row.id, row)}
          />
        </TableCell>
        <TableCell padding="checkbox">
          <Tooltip
            title={
              row.isDelivered ? "Set As Not Delivered Yet" : "Set As Delivered"
            }
          >
            <IconButton
              onClick={(e) => handleDelivery(e, row.id)}
              color={row.isDelivered ? "primary" : "default"}
            >
              <LocalShippingIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell padding="checkbox">
          <Tooltip title="Update">
            <IconButton
              component={Link}
              to={`/invoice/${row.id}`}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell padding="checkbox">
          <ReactToPrint
            trigger={() => (
              <Tooltip title="Print">
                <IconButton>
                  <ReceiptIcon />
                </IconButton>
              </Tooltip>
            )}
            content={() => componentRef.current}
          />
          <div style={{ display: "none" }}>
            <POSSlip
              componentToPrint={componentRef}
              sales={row}
              emptyQty={false}
            />
          </div>
        </TableCell>
        <TableCell component="th" id={labelId} scope="row">
          {moment(row.date).format("DD-MMM-yyyy")}
        </TableCell>
        <TableCell>
          <Grid container direction="column" spacing={2}>
            <Grid item>{row.documentNo}</Grid>
            {row.invoiceRef && (
              <Grid item>{`INVOICE REF: ${row.invoiceRef}`}</Grid>
            )}
          </Grid>
        </TableCell>
        <TableCell>{row.driver.name}</TableCell>
        <TableCell>{row.shift}</TableCell>
        <TableCell>
          <Grid container direction="column" spacing={2}>
            <Grid item>{row.customer.fullName.toLocaleUpperCase()}</Grid>
            {row.notes && <Grid item>{`NOTES: ${row.notes}`}</Grid>}
          </Grid>
        </TableCell>
        <TableCell
          align="center"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          <Grid container direction="column" spacing={2}>
            {row.details.map((detail, index) => {
              return (
                <Grid item key={index}>
                  {detail.itemCode}
                </Grid>
              );
            })}
          </Grid>
        </TableCell>
        <TableCell align="center">
          <Grid container direction="column" spacing={2}>
            {row.details.map((detail) => {
              return (
                <Grid item key={detail.id}>
                  {detail.qty.toLocaleString("en-US")}
                </Grid>
              );
            })}
          </Grid>
        </TableCell>
        {/* {itemDynamicRow.map((itemCode, index) => {
          const details = row.details.filter((x) => x.itemCode === itemCode);
          const total = details.reduce(
            (total, currentData) => (total = total + currentData.qty),
            0
          );
          return (
            <TableCell key={index} align="center">
              {total.toLocaleString("en-US")}
            </TableCell>
          );
        })} */}
        <TableCell align="right">
          {totalAmount(row.details).toLocaleString("en-US")}
        </TableCell>
        {/* {dynamicRow.map((payment, index) => {
          const payments = row.payments.filter(
            (x) => x.paymentType.type === payment
          );
          const total = payments.reduce(
            (total, currentData) => (total = total + currentData.amount),
            0
          );
          return (
            <TableCell key={index} align="right" >
              {total.toLocaleString("en-US")}
            </TableCell>
          );
        })} */}
        <TableCell align="center">
          <Grid container direction="column" spacing={2}>
            {row.payments.map((payment, index) => {
              return (
                <Grid item key={index}>
                  {payment.paymentType.type}
                </Grid>
              );
            })}
          </Grid>
        </TableCell>
        <TableCell align="right">
          <Grid container direction="column" spacing={2}>
            {row.payments.map((payment) => {
              return (
                <Grid item key={payment.id}>
                  {payment.amount.toLocaleString("en-US")}
                </Grid>
              );
            })}
          </Grid>
        </TableCell>
        {row.details.length > 0 ? (
          <TableCell>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell></TableCell>
        )}
      </TableRow>
      {row.details.length > 0 && (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={headCells.length + 3}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                marginLeft={5}
                marginTop={1}
                marginRight={5}
                marginBottom={2}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                  className={classes.table_detail_store}
                >
                  <Grid item>
                    <Typography variant="h6" gutterBottom component="div">
                      Details
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <StoreIcon />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          align="right"
                          component="div"
                        >
                          {row.customer.storeName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        align="right"
                        component="div"
                      >
                        Last Updated By: {row.lastUpdatedBy}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell align="center">Item Code</TableCell>
                      <TableCell align="left">Item Description</TableCell>
                      <TableCell align="center">Unit</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="center">Qty</TableCell>
                      <TableCell align="right">Total Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.details.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell align="center">
                          <Avatar
                            alt={item.itemCode}
                            src={
                              item.imageUrl ? item.imageUrl : "/no-image.png"
                            }
                          />
                        </TableCell>
                        <TableCell align="center">{item.itemCode}</TableCell>
                        <TableCell align="left">
                          {item.itemDescription}
                        </TableCell>
                        <TableCell align="center">
                          {item.unitDescription}
                        </TableCell>
                        <TableCell align="right">
                          {item.price.toLocaleString("en-US")}
                        </TableCell>
                        <TableCell align="center">
                          {item.qty.toLocaleString("en-US")}
                        </TableCell>
                        <TableCell align="right">
                          {item.totalAmount.toLocaleString("en-US")}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell align="right" colSpan={5}>
                        Grand Total :
                      </TableCell>
                      <TableCell align="center">
                        {totalQty(row.details).toLocaleString("en-US")}
                      </TableCell>
                      <TableCell align="right">
                        {totalAmount(row.details).toLocaleString("en-US")}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Box
                marginLeft={5}
                marginTop={1}
                marginRight={5}
                marginBottom={2}
              >
                <div className={classes.table_detail_store}>
                  <Typography variant="h6" gutterBottom component="div">
                    Payments
                  </Typography>
                </div>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Bank Name</TableCell>
                      <TableCell align="center">Account No.</TableCell>
                      <TableCell align="left">Owner</TableCell>
                      <TableCell align="right">Total Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.payments.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>
                          <Chip
                            variant="outlined"
                            color="primary"
                            label={item.paymentType.type}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {moment(item.date).format("DD-MMM-yyyy")}
                        </TableCell>
                        <TableCell align="center">
                          {item.bankAccount?.bankName}
                        </TableCell>
                        <TableCell align="center">
                          {item.bankAccount?.accountNo}
                        </TableCell>
                        <TableCell align="left">
                          {item.bankAccount?.owner}
                        </TableCell>
                        <TableCell align="right">
                          {item.amount.toLocaleString("en-US")}
                        </TableCell>
                      </TableRow>
                    ))}
                    {notPaidYet > 0 && (
                      <TableRow>
                        <TableCell>
                          <Chip
                            variant="outlined"
                            color="primary"
                            label="NOT PAID YET"
                          />
                        </TableCell>
                        <TableCell colSpan={4}></TableCell>
                        <TableCell align="right">
                          {notPaidYet.toLocaleString("en-US")}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell align="right" colSpan={5}>
                        Grand Total :
                      </TableCell>
                      <TableCell align="right">
                        {totalPayments.toLocaleString("en-US")}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
});
