import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { DropDownProps } from "../models/dropDownProps";
import { ManageMenuFormValues } from "../models/manageMenu";

export default class ManageMenuStore {
  role: string = "";
  menus: DropDownProps[] = [];
  menusGranted: DropDownProps[] = [];
  loadingMenu = false;

  constructor() {
    makeAutoObservable(this);
  }

  setRole = (role: string) => {
    this.role = role;
  };

  setMenus = (menus: DropDownProps[]) => {
    this.menus = menus;
  };

  setMenusGranted = (menus: DropDownProps[]) => {
    this.menusGranted = menus;
  };

  loadMenusGranted = async (id: string) => {
    this.loadingMenu = true;

    try {
      const menusGranted = this.sortMenu(
        await agent.ManageMenu.loadMenusGranted(id)
      );
      let menusNotGranted = this.sortMenu(
        await agent.ManageMenu.loadMenusNotGranted()
      );
      runInAction(() => {
        menusGranted.forEach((x) => {
          menusNotGranted = menusNotGranted.filter((y) => y.value !== x.value);
        });
        this.menusGranted = menusGranted;
        this.menus = menusNotGranted;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingMenu = false;
      });
    }
  };

  createManageMenu = async (menus: ManageMenuFormValues[]) => {
    this.loadingMenu = true;

    try {
      await agent.ManageMenu.create(menus);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingMenu = false));
    }
  };

  deleteManageMenu = async (menus: ManageMenuFormValues[]) => {
    this.loadingMenu = true;

    try {
      await agent.ManageMenu.delete(menus);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingMenu = false));
    }
  };

  private sortMenu = (menus: DropDownProps[]) => {
    return menus.sort((a, b) => {
      if (a.text > b.text) return 1;
      if (b.text > a.text) return -1;
      return 0;
    });
  };
}
