import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../app/stores/store";

export default observer(function DashboardInfos() {
  const { dashboardStore } = useStore();
  const { loadingDashboardInfo, info, loadDashboardInfo } = dashboardStore;

  useEffect(() => {
    loadDashboardInfo();
  }, [loadDashboardInfo]);

  return (
    <Grid container direction="row" alignItems="stretch" spacing={1}>
      <Grid item md xs={6}>
        <Card>
          <CardContent>
            <Typography
              variant="body1"
              color="primary"
              style={{ fontWeight: "bold" }}
              component="h6"
            >
              {loadingDashboardInfo ? (
                <Skeleton variant="text" />
              ) : (
                `IDR ${info?.dailyRevenue.toLocaleString("EN-US")}`
              )}
            </Typography>
            <Typography variant="caption">Daily Revenue</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md xs={6}>
        <Card>
          <CardContent>
            <Typography
              variant="body1"
              color="primary"
              style={{ fontWeight: "bold" }}
              component="h6"
            >
              {loadingDashboardInfo ? (
                <Skeleton variant="text" />
              ) : (
                `IDR ${info?.spendingDaily.toLocaleString("EN-US")}`
              )}
            </Typography>
            <Typography variant="caption">Daily Spending</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md xs={6}>
        <Card>
          <CardContent>
            <Typography
              variant="body1"
              color="primary"
              style={{ fontWeight: "bold" }}
              component="h2"
            >
              {loadingDashboardInfo ? (
                <Skeleton variant="text" />
              ) : (
                `IDR ${info?.monthlyRevenue.toLocaleString("EN-US")}`
              )}
            </Typography>
            <Typography variant="caption">Monthly Revenue</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md xs={6}>
        <Card>
          <CardContent>
            <Typography
              variant="body1"
              color="primary"
              style={{ fontWeight: "bold" }}
              component="h2"
            >
              {loadingDashboardInfo ? (
                <Skeleton variant="text" />
              ) : (
                `IDR ${info?.customerCredit.toLocaleString("EN-US")}`
              )}
            </Typography>
            <Typography variant="caption">Customer Debt</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});
