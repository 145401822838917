import { Chip, LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import ButtonExcel from "../../../app/common/export/ButtonExcel";
import Filter from "../../../app/common/filter/Filter";
import DateInputField from "../../../app/common/form/DateInputFIeld";
import Dropdown2 from "../../../app/common/form/Dropdown2";
import ButtonPrint from "../../../app/common/print/ButtonPrint";
import ComponentToPrint from "../../../app/common/print/ComponentToPrint";
import { useStyles } from "../../../app/layout/style";
import Title from "../../../app/layout/Title";
import { StockAllocationSearch } from "../../../app/models/report";
import { useStore } from "../../../app/stores/store";
import ReportStockAllocationTable from "./ReportStockAllocationTable";

export default observer(function ReportStockAllocation() {
  const classes = useStyles();
  const { userStore, stockAllocationStore } = useStore();
  const { stockLocationGranted } = userStore;
  const {
    setPredicate,
    loadReportAllocation,
    stockLocationList,
    stockAllocationRegistry,
    loading,
    filter,
  } = stockAllocationStore;
  const [search, setSearch] = useState(new StockAllocationSearch());
  const [open, setOpen] = useState(false);
  let componentRef = useRef(null);

  useEffect(() => {
    loadReportAllocation();
  }, [loadReportAllocation]);

  const handleSetSearch = () => {
    setSearch(filter);
  };

  const handleSearchClick = (values: StockAllocationSearch) => {
    setPredicate(values);
  };

  return (
    <>
      <Title menuName="Report Stock Allocation">
        <>
          <ButtonExcel
            fileName="Report Stock Allocation"
            sheetName="Report Stock Allocation"
            componentToExport={componentRef}
            disabled={loading || stockAllocationRegistry.size === 0}
          />
          <ButtonPrint
            componentToPrint={componentRef}
            disabled={loading || stockAllocationRegistry.size === 0}
          />
          <Filter open={open} setOpen={setOpen} setSearch={handleSetSearch}>
            <div className={classes.filter}>
              <Formik
                enableReinitialize
                initialValues={search}
                onSubmit={(values) => handleSearchClick(values)}
              >
                {({ handleSubmit, values }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-end"
                      spacing={1}
                    >
                      <Grid item>
                        <Dropdown2
                          name="stockLocationId"
                          options={stockLocationGranted.map((x) => {
                            return { text: x.name, value: x.id };
                          })}
                          label="Stock Location"
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          titleDefaultSelected="All Selected"
                          displayDefaultSelected
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="Start Date"
                          name="startDate"
                          maxDate={values.endDate}
                          placeholder="Please input Start Date"
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="End Date"
                          name="endDate"
                          minDate={values.startDate}
                          placeholder="Please input End Date"
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </Filter>
        </>
      </Title>
      <>
        {stockAllocationRegistry.size > 0 &&
          stockLocationList.map((location) => (
            <Paper className={classes.form} key={location.id}>
              <Typography variant="subtitle1">
                {location.name}{" "}
                <Chip
                  variant="outlined"
                  color="primary"
                  label={location.isSPBE ? "SPBE" : "AGEN"}
                />
              </Typography>

              <Divider />
              {loading ? (
                <LinearProgress />
              ) : (
                <ReportStockAllocationTable
                  stockAllocation={stockAllocationRegistry.get(location)}
                />
              )}
            </Paper>
          ))}
        <ComponentToPrint
          title="Report Stock Allocation"
          componentToPrint={componentRef}
        >
          <>
            {stockAllocationRegistry.size > 0 &&
              stockLocationList.map((location) => (
                <div key={location.id}>
                  <Typography variant="subtitle1" component="span">
                    {location.name}{" "}
                    <Chip
                      variant="outlined"
                      color="secondary"
                      label={location.isSPBE ? "SPBE" : "AGEN"}
                    />
                  </Typography>

                  <Divider />
                  <ReportStockAllocationTable
                    stockAllocation={stockAllocationRegistry.get(location)}
                  />
                </div>
              ))}
          </>
        </ComponentToPrint>
      </>
    </>
  );
});
