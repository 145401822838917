import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import SalesRevenueAndCredit from "./SalesRevenueAndCredit";
import { Helmet } from "react-helmet";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import { useStore } from "../../app/stores/store";
import FilterDashboard from "./FilterDashboard";
import DashboardInfos from "./DashboardInfos";
import Payment from "./Payment";
import { useStyles } from "../../app/layout/style";

export default observer(function Dashboard() {
  const classes = useStyles();
  const { dashboardStore } = useStore();
  const { date, loadingDashboardInfo } = dashboardStore;

  return (
    <div className={classes.dashboard}>
      <Helmet>
        <title>PANDE GROUP</title>
      </Helmet>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item>
          <Typography variant="h5" component="h2">
            Dashboard
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" component="h2">
            {loadingDashboardInfo ? (
              <Skeleton variant="text" width={100} />
            ) : (
              moment(date).format("DD-MMM-yyyy")
            )}
          </Typography>
        </Grid>
        <Grid item>
          <FilterDashboard />
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DashboardInfos />
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.form}>
                <Typography variant="subtitle2" component="h6">
                  Sales Revenue, Customer Debt And Spending
                </Typography>
                <Divider />
                <SalesRevenueAndCredit />
                <Payment />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});
