export interface StockLocationChild {
  id: string;
  name: string;
}

export interface StockLocation {
  id: string;
  name: string;
  city: string;
  isSPBE: boolean;
  isSubsidy: boolean;
  isActive: boolean;
}

export interface Store {
  id: string;
  stockLocationId: string;
  name: string;
  city: string;
  isSubsidy: boolean;
  isActive: boolean;
  stockLocation: StockLocationChild;
}

export interface StoreChild {
  id: string;
  name: string;
}

export class Store implements Store {
  constructor(init?: StoreFormValues) {
    Object.assign(this, init);
  }
}

export class StockLocation implements StockLocation {
  constructor(init?: StockLocationFormValues) {
    Object.assign(this, init);
  }
}

export class StoreFormValues {
  id?: string = "";
  stockLocationId: string = "";
  name: string = "";
  city: string = "";
  isSubsidy: boolean = false;
  isActive: boolean = true;

  constructor(location?: StoreFormValues) {
    if (location) {
      this.id = location.id;
      this.stockLocationId = location.stockLocationId;
      this.name = location.name;
      this.city = location.city;
      this.isSubsidy = location.isSubsidy;
      this.isActive = location.isActive;
    }
  }
}

export class StockLocationFormValues {
  id?: string = undefined;
  name: string = "";
  city: string = "";
  isSPBE: boolean = false;
  isSubsidy: boolean = false;
  isActive: boolean = true;

  constructor(location?: StockLocationFormValues) {
    if (location) {
      this.id = location.id;
      this.name = location.name;
      this.city = location.city;
      this.isSPBE = location.isSPBE;
      this.isSubsidy = location.isSubsidy;
      this.isActive = location.isActive;
    }
  }
}
