import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { StockLocation } from "../models/location";
import { StockAllocation, StockAllocationSearch } from "../models/report";
import { store } from "./store";
import { TableStore } from "./tableStore";

export default class StockAllocationStore extends TableStore<StockAllocation> {
  stockAllocationRegistry = new Map<StockLocation, StockAllocation[]>();

  constructor() {
    super();
    makeObservable(this, {
      stockAllocationRegistry: observable,
      loadReportAllocation: action,
      stockLocationList: computed,
      sortStockLocation: action,
    });

    this.setPredicate(new StockAllocationSearch());

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadReportAllocation();
      }
    );
  }

  setPredicate = (search: StockAllocationSearch) => {
    runInAction(() => {
      Object.keys(search).forEach((key) => {
        this.predicate.delete(key);
      });
      this.predicate.set(
        "stockLocationId",
        search.stockLocationId === "All" ? "" : search.stockLocationId
      );
      this.predicate.set(
        "startDate",
        search.startDate.toLocaleDateString("EN-US")
      );
      this.predicate.set("endDate", search.endDate.toLocaleDateString("EN-US"));
    });
  };

  get filter() {
    return new StockAllocationSearch({
      stockLocationId:
        this.predicate.get("stockLocationId") === ""
          ? "All"
          : this.predicate.get("stockLocationId")!,
      startDate: new Date(this.predicate.get("startDate")!),
      endDate: new Date(this.predicate.get("endDate")!),
    });
  }

  loadReportAllocation = async () => {
    this.clearStockAllocation();
    this.loading = true;

    try {
      let locations: StockLocation[] = [];
      const stockLocationId = this.predicate.get("stockLocationId");

      if (stockLocationId === "")
        locations = store.userStore.stockLocationGranted;
      else
        locations = store.userStore.stockLocationGranted.filter(
          (x) => x.id === stockLocationId
        );
      for await (const location of locations) {
        const result = await agent.Reports.reportStockAllocation(
          this.stockLocationParams(location)
        );
        runInAction(() => {
          this.stockAllocationRegistry.set(location, result);
        });
      }
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get stockLocationList() {
    return this.sortStockLocation(
      Array.from(this.stockAllocationRegistry.keys())
    );
  }

  private clearStockAllocation = () => {
    this.stockAllocationRegistry.clear();
  };

  sortStockLocation = (location: StockLocation[]) => {
    return location
      .sort((a, b) => {
        if (a.name > b.name) return 1;
        if (b.name > a.name) return -1;
        return 0;
      })
      .sort((a, b) => {
        if (a.city > b.city) return 1;
        if (b.city > a.city) return -1;
        return 0;
      });
  };
}
