import {
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  TableCell,
  Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import EnhancedTableHead from "../../app/common/table/EnhancedTableHead";
import EnhancedTableToolbar from "../../app/common/table/EnhancedTableToolbar";
import DeleteDialog from "../../app/layout/DeleteDialog";
import { useStyles } from "../../app/layout/style";
import { Sale } from "../../app/models/sale";
import { useStore } from "../../app/stores/store";
import InvoiceTableRow from "./InvoiceTableRow";
import UpdateMassPayment from "./UpdateMassPayment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import UpdateMassInvoice from "./UpdateMassInvoice";

export default observer(function StockOutgoingTable() {
  const classes = useStyles();
  const { salesStore, snackbarStore, dialogStore } = useStore();
  const {
    pageNumber,
    pageSize,
    documentList,
    headCells,
    loading,
    itemHeader,
    deleteSales,
    setPageNumber,
    setPageSize,
    pagination,
    setSortBy,
    setOrderBy,
    sortBy,
    orderBy,
    rowsOptions,
    selectedItem,
    setSelectedItem,
    selectItem,
    isSelected,
    setSaleSelectedItem,
    selectSaleItem,
    documentSelectedList,
    updateDeliveryStatus,
    totalAmount,
    totalQty,
  } = salesStore;
  const { openSnackbar } = snackbarStore;
  const { openDialog, closeDialog } = dialogStore;
  const [openPayment, setOpenPayment] = React.useState(false);
  const [openInvoice, setOpenInvoice] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const totalQtyInvoiceSelected = useMemo(() => {
    const result = itemHeader.map((itemCode) => {
      let total = 0;
      documentSelectedList.forEach((sales) => {
        const details = sales.details.filter((x) => x.itemCode === itemCode);
        total = total + totalQty(details);
      });
      return { itemCode: itemCode, total: total };
    });
    return result;
  }, [documentSelectedList, itemHeader, totalQty]);
  const totalAmountInvoiceSelected = useMemo(() => {
    return documentSelectedList.reduce(
      (total, currentData) =>
        (total = total + totalAmount(currentData.details)),
      0
    );
  }, [documentSelectedList, totalAmount]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    if (sortBy === property) setOrderBy(orderBy === "asc" ? "desc" : "asc");
    else setSortBy(property as keyof Sale);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (selectedItem.length === 0) {
        const newSelecteds = documentList.map((document) => document.id);
        setSelectedItem(newSelecteds);
        setSaleSelectedItem(documentList);
        return;
      }
    }
    setSelectedItem([]);
    setSaleSelectedItem([]);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    id: string,
    sale: Sale
  ) => {
    selectItem(id);
    selectSaleItem(sale);
  };

  const handleClickDelete = (event: React.MouseEvent<unknown>) => {
    openDialog(
      <DeleteDialog title={"Are you sure want to delete this document(s)"} />,
      "xs",
      handleDelete
    );
  };

  const handleDelete = () => {
    deleteSales(selectedItem)
      .catch((error) => {
        setSelectedItem([]);
        setSaleSelectedItem([]);
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        setSelectedItem([]);
        setSaleSelectedItem([]);
        closeDialog();
      });
  };

  const handleDelivery = (event: React.MouseEvent<unknown>, id: string) => {
    updateDeliveryStatus(id).catch((error) => {
      openSnackbar(error, "error");
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickUpdatePayment = (event: React.MouseEvent<unknown>) => {
    setAnchorEl(null);
    setOpenPayment(true);
  };

  const handleClickUpdateInvoice = (event: React.MouseEvent<unknown>) => {
    setAnchorEl(null);
    setOpenInvoice(true);
  };

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <Paper className={classes.paperOnlyBottom}>
          <EnhancedTableToolbar
            title="Invoice List"
            numSelected={selectedItem.length}
            handleDelete={handleClickDelete}
            loading={loading}
          >
            <>
              {documentSelectedList.length > 0 && (
                <div className={classes.root}>
                  <Typography color="inherit" variant="subtitle1" align="right">
                    {totalQtyInvoiceSelected.map((x) => {
                      return `${x.itemCode} : ${x.total.toLocaleString(
                        "en-US"
                      )} | `;
                    })}
                    Total Amount : {totalAmountInvoiceSelected.toLocaleString()}
                  </Typography>
                </div>
              )}
              <>
                <IconButton aria-label="settings" onClick={handleMenuClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={(e) => handleClickUpdateInvoice(e)}>
                    Update Invoice
                  </MenuItem>
                  <MenuItem onClick={(e) => handleClickUpdatePayment(e)}>
                    Update Payment
                  </MenuItem>
                </Menu>
              </>
            </>
          </EnhancedTableToolbar>
          <TableContainer className={classes.table_sticky}>
            <Table
              className={classes.table_small}
              size="medium"
              component="table"
              stickyHeader
            >
              <EnhancedTableHead
                headCells={headCells}
                numSelected={selectedItem.length}
                orderBy={orderBy}
                sortBy={sortBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={documentList.length}
                loading={loading}
                data={documentList}
              >
                <>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                </>
              </EnhancedTableHead>
              <TableBody>
                {documentList.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <InvoiceTableRow
                      key={index}
                      headCells={headCells}
                      row={row}
                      labelId={labelId}
                      isItemSelected={isItemSelected}
                      handleClick={handleClick}
                      handleDelivery={handleDelivery}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsOptions}
            component="div"
            count={pagination === null ? 0 : pagination.totalItems}
            rowsPerPage={pageSize}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <UpdateMassPayment
            open={openPayment}
            setOpen={setOpenPayment}
            ids={selectedItem}
          />
          <UpdateMassInvoice
            open={openInvoice}
            setOpen={setOpenInvoice}
            ids={selectedItem}
          />
        </Paper>
      )}
    </>
  );
});
