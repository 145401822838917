import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { PaginatedResult } from "../models/pagination";
import { ProfitSetup, ProfitSetupFormValues } from "../models/profitSetup";
import { TableStore } from "./tableStore";

export default class ProfitSetupStore extends TableStore<ProfitSetup> {
  profitSetupRegistry = new Map<string, ProfitSetup>();

  constructor() {
    super();
    makeObservable(this, {
      profitSetupRegistry: observable,
      loadSetups: action,
      setupList: computed,
      loadSetup: action,
      createSetup: action,
      updateSetup: action,
      deleteSetup: action,
    });

    this.setPageNumber(1);
    this.setSortBy(null);
    this.setOrderBy("asc");

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadSetups();
      }
    );
  }

  private loadSetupProcess = (result: PaginatedResult<ProfitSetup[]>) => {
    runInAction(() => {
      this.profitSetupRegistry.clear();
      result.data.forEach((setup) => {
        this.profitSetupRegistry.set(setup.id, setup);
      });
      this.pagination = result.pagination;
    });
  };

  loadSetups = async () => {
    this.loading = true;

    try {
      const result = await agent.ProfitSetups.list(this.axiosParams);
      this.loadSetupProcess(result);
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get setupList() {
    return Array.from(this.profitSetupRegistry.values());
  }

  loadSetup = async (id: string) => {
    this.loading = true;

    try {
      const result = await agent.ProfitSetups.details(id);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  createSetup = async (setup: ProfitSetupFormValues) => {
    try {
      await agent.ProfitSetups.create(setup);
      const result = await agent.ProfitSetups.list(this.axiosParams);
      this.loadSetupProcess(result);
      return "Create setup success!";
    } catch (error) {
      throw error;
    }
  };

  updateSetup = async (setup: ProfitSetupFormValues) => {
    try {
      const result = await agent.ProfitSetups.update(setup);
      runInAction(() => {
        this.profitSetupRegistry.set(result.id, result);
      });
      return "Update setup success!";
    } catch (error) {
      throw error;
    }
  };

  deleteSetup = async (ids: string[]) => {
    this.loading = true;

    try {
      await agent.ProfitSetups.delete(ids);
      const result = await agent.ProfitSetups.list(this.axiosParams);
      this.loadSetupProcess(result);
      return "Delete setup(s) success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };
}
