import {
  Paper,
  Typography,
  Divider,
  Grid,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import DateInputField from "../../app/common/form/DateInputFIeld";
import { useStyles } from "../../app/layout/style";
import {
  AssetLending,
  AssetLendingDetail,
  AssetLendingDetailValues,
  AssetLendingFormValues,
} from "../../app/models/assetLending";
import { useStore } from "../../app/stores/store";
import CustomerAutoComplete from "../Sales/CustomerAutoComplete";
import { CustomerGranted, CustomerItem } from "../../app/models/customer";
import InputField from "../../app/common/form/InputField";
import AssetLendingFormAddDetails from "./AssetLendingFormAddDetails";
import { StockTransactionType } from "../../app/models/stockControl";

export default observer(function AssetLendingForm() {
  const classes = useStyles();
  const {
    assetLendingStore,
    stockControlStore,
    snackbarStore,
    itemStore,
    customerStore,
  } = useStore();
  const { loadDropDown: loadDropDownItem } = itemStore;
  const { loading, loadDocument, createAssetLending, updateAssetLending } =
    assetLendingStore;
  const { loadTransactionType } = stockControlStore;
  const { loadGrantedCustomer, customerGranted } = customerStore;
  const { openSnackbar } = snackbarStore;
  const [assetLending, setAssetLending] = useState<AssetLendingFormValues>(
    new AssetLendingFormValues()
  );
  const [customer, setCustomer] = useState<CustomerGranted | null>(null);
  const [details, setDetails] = useState<AssetLendingDetail[]>([]);
  const [valueType, setValueType] = useState<StockTransactionType | null>(null);
  const [valueItem, setValueItem] = useState<CustomerItem | null>(null);
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadDropDownItem();
    loadTransactionType();
    loadGrantedCustomer();
    if (id) {
      loadDocument(id).then((result) => {
        loadDocumentResult(result);
      });
    }
  }, [id, loadDocument, loadTransactionType, loadDropDownItem, loadGrantedCustomer]);

  const loadDocumentResult = (result: AssetLending | undefined) => {
    if (result) {
      setAssetLending(
        new AssetLendingFormValues({
          id: result.id,
          date: result.date,
          customerId: result.customer.id,
          notes: result.notes,
          details: result.details.map((x) => {
            return {
              id: x.id,
              assetLendingId: result.id,
              itemId: x.itemId,
              stockTransactionTypeId: x.stockTransactionType.id,
              qty: x.qty,
            } as AssetLendingDetailValues;
          }),
        })
      );
      setDetails(result.details);
      setCustomer(result.customer);
    }
  };

  const schema = Yup.object({
    date: Yup.date().required("Date is required."),
    customerId: Yup.string().required("Customer field is required."),
    details: Yup.array().of(
      Yup.object().shape({
        qty: Yup.number()
          .typeError("Allocation is must be a number.")
          .required("Allocation is required."),
      })
    ),
  });

  const handleCustomerChange = (
    customer: CustomerGranted | null,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (customer) {
      setCustomer(customer);
      setFieldValue("customerId", customer.id);
    } else {
      setCustomer(null);
      setFieldValue("customerId", null);
    }
  };

  const handleFormSubmit = (
    assetLending: AssetLendingFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!assetLending.id) {
      let newAssetLending = {
        ...assetLending,
        id: uuid(),
      };
      createAssetLending(newAssetLending)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setAssetLending({
              ...assetLending,
              customerId: "",
              notes: "",
              details: [],
            });
            setCustomer(null);
            setDetails([]);
            setValueItem(null);
            setValueType(null);
            resetForm();
          }
        });
    } else {
      updateAssetLending(assetLending)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/asset-lending");
          }
        });
    }
  };

  return (
    <>
      <Paper className={classes.form}>
        <Typography variant="h5">Form {id ? "Update" : "Create"}</Typography>
        <Divider />
        <Formik
          validationSchema={schema}
          enableReinitialize
          initialValues={assetLending}
          onSubmit={(values, { resetForm, setSubmitting }) =>
            handleFormSubmit(values, resetForm, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isSubmitting,
            isValid,
            values,
            validateForm,
            setFieldValue,
            errors,
            setFieldTouched,
            touched,
          }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <DateInputField
                    label="Date"
                    name="date"
                    placeholder="Please input Date"
                    disabled={isSubmitting || loading}
                    maxDate={new Date()}
                    inputVariant="standard"
                    margin="normal"
                  />
                  <CustomerAutoComplete
                    name="customerId"
                    options={customerGranted}
                    value={customer}
                    setValue={setCustomer}
                    loading={isSubmitting || loading}
                    onChange={handleCustomerChange}
                    setFieldValue={setFieldValue}
                    variant="standard"
                    error={errors.customerId}
                    touched={touched.customerId}
                    setFieldTouched={setFieldTouched}
                  />
                  <InputField
                    label="Notes"
                    name="notes"
                    multiline
                    minRows={4}
                    placeholder="Please input notes"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loading}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <AssetLendingFormAddDetails
                    assetLending={values}
                    setAssetLending={setAssetLending}
                    details={details}
                    setDetails={setDetails}
                    isSubmitting={isSubmitting}
                    loading={loading}
                    validateForm={validateForm}
                    valueItem={valueItem}
                    setValueItem={setValueItem}
                    valueType={valueType}
                    setValueType={setValueType}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="stretch"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || !isValid || details.length === 0}
                  >
                    {isSubmitting && (
                      <CircularProgress
                        className={classes.progress}
                        size={16}
                        color="inherit"
                      />
                    )}
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    component={Link}
                    to="/asset-lending"
                    disabled={isSubmitting || loading}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
});
