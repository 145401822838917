import {
  Typography,
  makeStyles,
  Theme,
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";

interface Props {
  title: string;
  componentToPrint: React.MutableRefObject<HTMLDivElement | null>;
  children?: JSX.Element | undefined;
}

const useStyles = makeStyles<Theme>((theme) => ({
  body: {
    color: "black",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

export default observer(function ComponentToPrint(props: Props) {
  const classes = useStyles();
  const { title, componentToPrint, children } = props;
  let theme = createTheme({
    typography: {
      fontFamily: `"Open Sans", "Helvetica", "Arial", sans-serif`,
      fontWeightRegular: 400,
    },
  });
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: "none" }}>
        <div
          ref={(el) => (componentToPrint.current = el)}
          className={classes.body}
        >
          {/* <Typography variant="h3" align="center" className={classes.title}>
            PANDE GROUP
          </Typography> */}
          <Typography variant="h5" align="center" className={classes.title}>
            {title.toLocaleUpperCase()}
          </Typography>
          {children}
        </div>
      </div>
    </ThemeProvider>
  );
});
