import React from "react";
import { StockTransactionGroup } from "../../app/models/stockoutgoing";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import { useStyles } from "../../app/layout/style";

interface Props {
  options: StockTransactionGroup[];
  value: StockTransactionGroup | null;
  setValue: (value: StockTransactionGroup | null) => void;
  optionDisabled?: StockTransactionGroup[];
  loading: boolean;
}

export default function StockTransactionGroupAutoComplete({
  options,
  value,
  setValue,
  optionDisabled,
  loading,
}: Props) {
  const classes = useStyles();
  const handleOptionDisabled = (group: StockTransactionGroup) => {
    if (optionDisabled) return optionDisabled.some((x) => x.id === group.id);
    else return false;
  };

  return (
    <FormControl fullWidth className={classes.icon}>
      <Autocomplete
        id="search-groups"
        options={options}
        getOptionLabel={(option) => option.code}
        getOptionDisabled={(option) => handleOptionDisabled(option)}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue !== "string") {
            setValue(newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Stock Transaction Group"
            placeholder="Please select any transaction group."
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        disabled={loading}
      />
    </FormControl>
  );
}
