import axios, { AxiosError, AxiosResponse } from "axios";
import { history } from "../..";
import {
  Customer,
  CustomerDueDate,
  CustomerFormValues,
  CustomerGranted,
  CustomerItem,
  CustomerPrice,
  Driver,
} from "../models/customer";
import { DropDownProps } from "../models/dropDownProps";
import { Item, ItemFormValues, Photo, Unit } from "../models/item";
import {
  StockLocation,
  StockLocationFormValues,
  Store,
  StoreFormValues,
} from "../models/location";
import { ManageMenuFormValues } from "../models/manageMenu";
import {
  MasterMenuData,
  MasterMenu,
  MasterMenuFormValues,
} from "../models/masterMenu";
import { PaginatedResult } from "../models/pagination";
import {
  UserLocationAccessRightFormValues,
  UserStoreAccessRightFormValues,
} from "../models/locationAccessRight";
import {
  LoginFormValues,
  ProfileFormValues,
  Role,
  RoleForm,
  User,
  UserFormValues,
} from "../models/user";
import { store } from "../stores/store";
import {
  StockOutgoing,
  StockOutgoingFormValues,
  StockTransactionGroup,
} from "../models/stockoutgoing";
import { Vendor, VendorFormValues } from "../models/vendor";
import {
  PurchaseOrder,
  PurchaseOrderFormValues,
} from "../models/purchaseOrder";
import {
  StockControl,
  StockControlFormValues,
  StockTransactionType,
} from "../models/stockControl";
import {
  BankAccount,
  InvoiceMassValues,
  PaymentType,
  Sale,
  SalesFormValues,
  SalesPaymentValues,
} from "../models/sale";
import { CashIN, CashINFormValues } from "../models/cashIN";
import {
  CashFlow,
  StockAllocation,
  SalesByCustomer,
  StockBalance,
  StockDetail,
  CustomerDeposit,
  Credit,
  CreditPayment,
  StockHistory,
  AssetLendingBalance,
  ProfitAndLoss,
  SpendingGroupReport,
  SalesSummary,
  SalesByQuantity,
} from "../models/report";
import {
  Spending,
  SpendingFormValues,
  SpendingGroup,
} from "../models/spending";
import { URLSearchParams } from "url";
import {
  DashboardInfo,
  SalesPaymentChart,
  SalesRevenueAndCredit,
} from "../models/dashboard";
import { AssetLending, AssetLendingFormValues } from "../models/assetLending";
import { ProfitSetupFormValues, ProfitSetup } from "../models/profitSetup";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    const pagination = response.headers["pagination"];
    if (pagination) {
      response.data = new PaginatedResult(
        response.data,
        JSON.parse(pagination)
      );
      return response as AxiosResponse<PaginatedResult<any>>;
    }
    return response;
  },
  (error: AxiosError) => {
    const { data, status, config } = error.response!;
    switch (status) {
      case 400:
        if (typeof data === "string") {
          throw data;
        }
        if (config.method === "get" && data.errors.hasOwnProperty("id")) {
          history.push("/not-found");
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(data.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        }
        if (
          data[0].hasOwnProperty("code") &&
          data[0].hasOwnProperty("description")
        ) {
          const modalStateErrors = [];
          for (const key in data) {
            if (data[key].description) {
              modalStateErrors.push(data[key].description);
            }
          }
          throw modalStateErrors.flat();
        }
        break;
      case 401:
        if (status === 401 && typeof data === "string") {
          history.push("/login");
          throw data;
        }
        break;
      case 403:
        if (status === 403) {
          return "Forbidden";
        }
        break;
      case 404:
        history.push("/not-found");
        break;
      case 500:
        if (data !== undefined) throw data;
        break;
    }
    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Menus = {
  list: () => requests.get<MasterMenu[]>("/menus"),
  create: (menu: MasterMenuFormValues) => requests.post<void>("/menus", menu),
  update: (menu: MasterMenuFormValues) =>
    requests.put<void>(`/menus/${menu.id}`, menu),
  details: (id: string) => requests.get<MasterMenu>(`/menus/details/${id}`),
  datatable: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<MasterMenuData[]>>("/menus/datatable", { params })
      .then(responseBody),
  delete: (id: string) => requests.del<void>(`/menus/${id}`),
};

const Account = {
  current: () => requests.get<User>("/accounts"),
  login: (user: LoginFormValues) =>
    requests.post<User>("/accounts/login", user),
  register: (user: UserFormValues) =>
    requests.post<User>("/accounts/register", user),
  update: (username: string, user: UserFormValues) =>
    requests.put<User>(`/accounts/${username}`, user),
  user: (username: string) =>
    requests.get<UserFormValues>(`/accounts/user/${username}`),
  roles: () => requests.get<Role[]>("/accounts/roles"),
  list: (params: URLSearchParams) =>
    axios.get<User[]>("/accounts/list", { params }).then(responseBody),
  dropdown: () => requests.get<DropDownProps[]>("/accounts/dropdown"),
  rolecreate: (role: RoleForm) =>
    requests.post<RoleForm>("/accounts/create-role", role),
  changeProfile: (user: ProfileFormValues) =>
    requests.put<User>("/accounts/profile", user),
  delete: (id: string) => requests.del<void>(`/accounts/${id}`),
};

const Location = {
  list: (params: URLSearchParams) =>
    axios
      .get<StockLocation[]>("/stocklocations", { params })
      .then(responseBody),
  details: (id: string) =>
    requests.get<StockLocation>(`/stocklocations/details/${id}`),
  create: (location: StockLocationFormValues) =>
    requests.post<void>("/stocklocations", location),
  update: (location: StockLocationFormValues) =>
    requests.put<void>(`/stocklocations/${location.id}`, location),
  dropdown: () => requests.get<StockLocation[]>("/stocklocations/dropdown"),
  granted: () => requests.get<StockLocation[]>("/stocklocations/granted"),
};

const Stores = {
  list: (params: URLSearchParams) =>
    axios.get<Store[]>("/stores", { params }).then(responseBody),
  details: (id: string) => requests.get<Store>(`/stores/details/${id}`),
  create: (store: StoreFormValues) => requests.post<void>("/stores", store),
  update: (store: StoreFormValues) =>
    requests.put<void>(`/stores/${store.id}`, store),
  dropdown: () => requests.get<Store[]>("/stores/dropdown"),
  granted: () => requests.get<Store[]>("/stores/granted"),
};

const ManageMenu = {
  loadMenusNotGranted: () =>
    requests.get<DropDownProps[]>("/managemenus/notgranted/"),
  loadMenusGranted: (id: string) =>
    requests.get<DropDownProps[]>(`/managemenus/granted/${id}`),
  create: (menus: ManageMenuFormValues[]) =>
    requests.post<void>("/managemenus/", menus),
  delete: (menus: ManageMenuFormValues[]) =>
    requests.post<void>("/managemenus/delete", menus),
};

const Items = {
  list: (params: URLSearchParams) =>
    axios.get<PaginatedResult<Item[]>>("/items", { params }).then(responseBody),
  details: (id: string) => requests.get<Item>(`/items/${id}`),
  loadUnit: () => requests.get<Unit[]>("/items/units"),
  create: (item: ItemFormValues) => requests.post<void>("/items", item),
  update: (item: ItemFormValues) =>
    requests.put<Item>(`/items/${item.id}`, item),
  delete: (id: string) => requests.del<void>(`/items/${id}`),
  dropdown: () => requests.get<CustomerItem[]>("/items/dropdown"),
};

const Photos = {
  uploadPhoto: (file: any) => {
    let formData = new FormData();
    formData.append("File", file);
    return axios.post<Photo>("/photos", formData, {
      headers: { "Content-type": "multipart/form-data" },
    });
  },
  deletePhoto: (id: string) => requests.del<void>(`/photos/${id}`),
};

const Customers = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<Customer[]>>("/customers", { params })
      .then(responseBody),
  generateCode: () => requests.get<string>("/customers/generatecode"),
  details: (id: string) => requests.get<Customer>(`/customers/${id}`),
  create: (customer: CustomerFormValues) =>
    requests.post<void>("/customers", customer),
  update: (customer: CustomerFormValues) =>
    requests.put<Customer>(`/customers/${customer.id}`, customer),
  delete: (ids: string[]) => requests.post<void>("/customers/delete", ids),
  granted: () => requests.get<CustomerGranted[]>("/customers/granted"),
  drivers: () => requests.get<Driver[]>("/customers/drivers"),
  dueDate: () => requests.get<CustomerDueDate[]>("/customers/duedate"),
  itemheader: (params: URLSearchParams) =>
    axios.get<string[]>("/customers/itemheader", { params }).then(responseBody),
};

const StoreAccessRights = {
  loadUsersGranted: (id: string) =>
    requests.get<DropDownProps[]>(`/storeaccessrights/granted/${id}`),
  create: (users: UserStoreAccessRightFormValues[]) =>
    requests.post<void>("/storeaccessrights", users),
  delete: (users: UserStoreAccessRightFormValues[]) =>
    requests.post<void>("/storeaccessrights/delete", users),
};

const StockLocationAccessRights = {
  loadUsersGranted: (id: string) =>
    requests.get<DropDownProps[]>(`/stocklocationaccessrights/granted/${id}`),
  create: (users: UserLocationAccessRightFormValues[]) =>
    requests.post<void>("/stocklocationaccessrights", users),
  delete: (users: UserLocationAccessRightFormValues[]) =>
    requests.post<void>("/stocklocationaccessrights/delete", users),
};

const StockOutgoings = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<StockOutgoing[]>>("/stockoutgoings", { params })
      .then(responseBody),
  loadTransactionGroup: () =>
    requests.get<StockTransactionGroup[]>("/stockoutgoings/transactiongroup"),
  create: (document: StockOutgoingFormValues) =>
    requests.post<void>("/stockoutgoings", document),
  details: (id: string) => requests.get<StockOutgoing>(`/stockoutgoings/${id}`),
  update: (document: StockOutgoingFormValues) =>
    requests.put<StockOutgoing>(`/stockoutgoings/${document.id}`, document),
  delete: (ids: string[]) => requests.post<void>("/stockoutgoings/delete", ids),
  post: (id: string) => requests.post<void>(`/stockoutgoings/post/${id}`, {}),
};

const Vendors = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<Vendor[]>>("/vendors", { params })
      .then(responseBody),
  create: (vendor: VendorFormValues) => requests.post<void>("/vendors", vendor),
  details: (id: string) => requests.get<Vendor>(`/vendors/${id}`),
  update: (vendor: VendorFormValues) =>
    requests.put<Vendor>(`/vendors/${vendor.id}`, vendor),
  delete: (id: string) => requests.del<void>(`/vendors/${id}`),
  dropdown: () => requests.get<Vendor[]>("/vendors/dropdown"),
};

const PurchaseOrders = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<PurchaseOrder[]>>("/purchaseorders", { params })
      .then(responseBody),
  create: (document: PurchaseOrderFormValues) =>
    requests.post<void>("/purchaseorders", document),
  details: (id: string) => requests.get<PurchaseOrder>(`/purchaseorders/${id}`),
  update: (document: PurchaseOrderFormValues) =>
    requests.put<PurchaseOrder>(`/purchaseorders/${document.id}`, document),
  delete: (ids: string[]) => requests.post<void>("/purchaseorders/delete", ids),
  post: (id: string) => requests.post<void>(`/purchaseorders/post/${id}`, {}),
};

const StockControls = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<StockControl[]>>("/stockcontrols", { params })
      .then(responseBody),
  loadTransactionType: () =>
    requests.get<StockTransactionType[]>("/stockcontrols/transactiontype"),
  create: (document: StockControlFormValues) =>
    requests.post<void>("/stockcontrols", document),
  details: (id: string) => requests.get<StockControl>(`/stockcontrols/${id}`),
  update: (document: StockControlFormValues) =>
    requests.put<StockControl>(`/stockcontrols/${document.id}`, document),
  delete: (ids: string[]) => requests.post<void>("/stockcontrols/delete", ids),
  post: (id: string) => requests.post<void>(`/stockcontrols/post/${id}`, {}),
};

const Sales = {
  paymentTypes: () => requests.get<PaymentType[]>("sales/paymenttypes"),
  bankAccounts: () => requests.get<BankAccount[]>("sales/bankaccounts"),
  list: (params: URLSearchParams) =>
    axios.get<PaginatedResult<Sale[]>>("/sales", { params }).then(responseBody),
  itemHeader: (params: URLSearchParams) =>
    axios.get<string[]>("/sales/itemheader", { params }).then(responseBody),
  paymentHeader: (params: URLSearchParams) =>
    axios.get<string[]>("/sales/paymentHeader", { params }).then(responseBody),
  details: (id: string) => requests.get<Sale>(`/sales/${id}`),
  create: (document: SalesFormValues) => {
    return requests.post<Sale>("/sales", document);
  },
  update: (document: SalesFormValues) =>
    requests.put<Sale>(`/sales/${document.id}`, document),
  delete: (ids: string[]) => requests.post<void>("/sales/delete", ids),
  updatePayment: (ids: string[], payment: SalesPaymentValues) =>
    requests.post<void>("/sales/updatepayment", { ids, payment }),
  updateInvoice: (ids: string[], invoice: InvoiceMassValues) =>
    requests.post<void>("/sales/updateinvoice", { ids, invoice }),
  updateDeliveryStatus: (id: string) =>
    requests.post<void>(`/sales/deliverystatus/${id}`, {}),
  customerDeposit: (id: string | null) =>
    requests.get<number>(`/sales/deposit/${id}`),
};

const Spendings = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<Spending[]>>("/spendings", { params })
      .then(responseBody),
  details: (id: string) => requests.get<Spending>(`/spendings/${id}`),
  groups: () => requests.get<SpendingGroup[]>("/spendings/groups"),
  create: (document: SpendingFormValues) => {
    return requests.post<void>("spendings", document);
  },
  update: (document: SpendingFormValues) =>
    requests.put<Spending>(`/spendings/${document.id}`, document),
  delete: (ids: string[]) => requests.post<void>("/spendings/delete", ids),
  post: (id: string) => requests.post<void>(`/spendings/post/${id}`, {}),
  massPost: (ids: string[]) => requests.post<void>("/spendings/masspost", ids),
};

const CashIns = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<CashIN[]>>("/cashins", { params })
      .then(responseBody),
  details: (id: string) => requests.get<CashIN>(`/cashins/${id}`),
  create: (document: CashINFormValues) => {
    return requests.post<void>("cashins", document);
  },
  update: (document: CashINFormValues) =>
    requests.put<CashIN>(`/cashins/${document.id}`, document),
  delete: (ids: string[]) => requests.post<void>("/cashins/delete", ids),
  post: (id: string) => requests.post<void>(`/cashins/post/${id}`, {}),
};

const Reports = {
  reportStockAllocation: (params: URLSearchParams) =>
    axios
      .get<StockAllocation[]>("/inventories/stockallocation", {
        params,
      })
      .then(responseBody),
  reportCashFlow: (params: URLSearchParams) =>
    axios
      .get<CashFlow[]>("/finances/cashflow", {
        params,
      })
      .then(responseBody),
  reportSalesSummary: (params: URLSearchParams) =>
    axios
      .get<SalesSummary[]>("/sales/summary", {
        params,
      })
      .then(responseBody),
  reportStockBalance: (params: URLSearchParams) =>
    axios
      .get<StockBalance[]>("/inventories/stockbalance", {
        params,
      })
      .then(responseBody),
  reportStockDetail: (params: URLSearchParams) =>
    axios
      .get<StockDetail[]>("/inventories/stockdetail", {
        params,
      })
      .then(responseBody),
  reportStockHistory: (params: URLSearchParams) =>
    axios
      .get<StockHistory[]>("/inventories/stockhistory", {
        params,
      })
      .then(responseBody),
  reportAssetLendingBalance: (params: URLSearchParams) =>
    axios
      .get<AssetLendingBalance[]>("/inventories/assetlendingbalance", {
        params,
      })
      .then(responseBody),
  reportByCustomer: (params: URLSearchParams) =>
    axios
      .get<SalesByCustomer[]>("/sales/customer", { params })
      .then(responseBody),
  reportCustomerDeposit: (params: URLSearchParams) =>
    axios
      .get<CustomerDeposit[]>("/sales/deposit", {
        params,
      })
      .then(responseBody),
  reportCredit: (params: URLSearchParams) =>
    axios
      .get<Credit[]>("/sales/credit", {
        params,
      })
      .then(responseBody),
  reportCreditPayment: (params: URLSearchParams) =>
    axios
      .get<CreditPayment[]>("/finances/creditpayment", {
        params,
      })
      .then(responseBody),
  deletePayment: (id: string) => requests.del<void>(`finances/payment/${id}`),
  profit: (params: URLSearchParams) =>
    axios
      .get<ProfitAndLoss[]>("/finances/profit", { params })
      .then(responseBody),
  loss: (params: URLSearchParams) =>
    axios.get<ProfitAndLoss[]>("/finances/loss", { params }).then(responseBody),
  spending: (params: URLSearchParams) =>
    axios
      .get<SpendingGroupReport[]>("/finances/spending", { params })
      .then(responseBody),
  credit: (params: URLSearchParams) =>
    axios.get<Credit[]>("/finances/credit", { params }).then(responseBody),
  reportSpending: (params: URLSearchParams) =>
    axios
      .get<Spending[]>("/finances/spendinglist", { params })
      .then(responseBody),
  reportSalesByQuantity: (params: URLSearchParams) =>
    axios.get<SalesByQuantity[]>("/sales/qty", { params }).then(responseBody),
  reportCustomerPrice: (params: URLSearchParams) =>
    axios
      .get<CustomerPrice[]>("/customers/price", { params })
      .then(responseBody),
};

const Dashboard = {
  info: (params: URLSearchParams) =>
    axios
      .get<DashboardInfo>("/finances/dashboard_info", {
        params,
      })
      .then(responseBody),
  revenue_credit: (params: URLSearchParams) =>
    axios
      .get<SalesRevenueAndCredit[]>("/finances/revenue_credit", {
        params,
      })
      .then(responseBody),
  salesPaymentChart: (params: URLSearchParams) =>
    axios
      .get<SalesPaymentChart[]>("/finances/payment_chart", { params })
      .then(responseBody),
};

const AssetLendings = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<AssetLending[]>>("/assetlendings", { params })
      .then(responseBody),
  details: (id: string) => requests.get<AssetLending>(`/assetlendings/${id}`),
  create: (document: AssetLendingFormValues) => {
    return requests.post<void>("assetlendings", document);
  },
  update: (document: AssetLendingFormValues) =>
    requests.put<AssetLending>(`/assetlendings/${document.id}`, document),
  delete: (ids: string[]) => requests.post<void>("/assetlendings/delete", ids),
  post: (id: string) => requests.post<void>(`/assetlendings/post/${id}`, {}),
  massPost: (ids: string[]) =>
    requests.post<void>("/assetlendings/masspost", ids),
};

const ProfitSetups = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<ProfitSetup[]>>("/profitsetups", { params })
      .then(responseBody),
  details: (id: string) => requests.get<ProfitSetup>(`/profitsetups/${id}`),
  create: (setup: ProfitSetupFormValues) => {
    return requests.post<void>("profitsetups", setup);
  },
  update: (setup: ProfitSetupFormValues) =>
    requests.put<ProfitSetup>(`/profitsetups/${setup.id}`, setup),
  delete: (ids: string[]) => requests.post<void>("/profitsetups/delete", ids),
};

const agent = {
  Menus,
  Account,
  Location,
  Stores,
  ManageMenu,
  Items,
  Photos,
  Customers,
  StoreAccessRights,
  StockLocationAccessRights,
  StockOutgoings,
  Vendors,
  PurchaseOrders,
  StockControls,
  Sales,
  Spendings,
  CashIns,
  Reports,
  Dashboard,
  AssetLendings,
  ProfitSetups,
};

export default agent;
