import {
  createTheme,
  Divider,
  Grid,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { usePosStyles } from "../../app/layout/pos";
import { useStyles } from "../../app/layout/style";
import { Sale } from "../../app/models/sale";
import { useStore } from "../../app/stores/store";

type Props = {
  componentToPrint: React.MutableRefObject<HTMLDivElement | null>;
  sales?: Sale | undefined;
  emptyQty: boolean;
};

export default observer(function POSSlip(props: Props) {
  const { componentToPrint, sales, emptyQty } = props;
  const classes = useStyles();
  const posStyles = usePosStyles();
  const { salesStore } = useStore();
  const { totalAmount, totalAmountDefaultPrice, defaultPrice, withTax } =
    salesStore;
  const subTotal = defaultPrice
    ? (totalAmountDefaultPrice(sales?.details) * 100) / 111
    : (totalAmount(sales?.details) * 100) / 111;
  const tax = (subTotal * 11) / 100;
  // const notPaidYet =
  //   totalAmount(sales!.details) - totalPayment(sales!.payments);
  // const totalPayments =
  //   notPaidYet > 0
  //     ? totalPayment(sales!.payments) + notPaidYet
  //     : totalPayment(sales!.payments);
  const details =
    sales !== undefined
      ? sales.details.slice().sort((a, b) => {
          if (a.itemCode > b.itemCode) return 1;
          if (b.itemCode > a.itemCode) return -1;
          return 0;
        })
      : undefined;

  let theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: "light",
        },
        typography: {
          fontFamily: `"Arial"`,
          fontSize: 12,
          fontWeightRegular: 400,
        },
        overrides: {
          MuiDivider: {
            root: {
              margin: "8px 0px 8px 0px",
            },
          },
        },
      }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <div
        ref={(el) => (componentToPrint.current = el)}
        className={posStyles.slip}
      >
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <img
              src="/PandeGroup.png"
              alt="logo"
              className={classes.logo_slip}
            />
          </Grid>
          <Grid item>
            <Typography variant="h5">{sales?.storeName}</Typography>
            <Typography variant="body2">AGEN LPG PERTAMINA</Typography>
            <Typography variant="body1">
              JL. BY PASS NGURAH RAI, DS. KERTA RAHARJA, SIDAKARYA
            </Typography>
            <Typography variant="body1">DENPASAR - BALI</Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          spacing={1}
          justifyContent="space-between"
        >
          <Grid item>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Typography variant="h6">CUSTOMER</Typography>
                  </td>
                  <td>
                    <Typography variant="h6">:</Typography>
                  </td>
                  <td>
                    <Typography variant="h6">
                      {sales?.customer.customerName.toLocaleUpperCase()}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <Typography variant="h6">
                      {sales?.customer.address.toLocaleUpperCase()}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">NOTES</Typography>
                  </td>
                  <td>:</td>
                  <td>
                    <Typography variant="h6">
                      {sales?.notes.toLocaleUpperCase()}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Typography variant="h6">INVOICE</Typography>
                  </td>
                  <td>
                    <Typography variant="h6">:</Typography>
                  </td>
                  <td>
                    <Typography variant="h6">{sales?.documentNo}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">REF INVOICE NO.</Typography>
                  </td>
                  <td>
                    <Typography variant="h6">:</Typography>
                  </td>
                  <td>
                    <Typography variant="h6">{sales?.invoiceRef}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">DATE</Typography>
                  </td>
                  <td>
                    <Typography variant="h6">:</Typography>
                  </td>
                  <td>
                    <Typography variant="h6">
                      {moment(sales?.date).format("DD-MMM-yyyy")}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">DUE DATE</Typography>
                  </td>
                  <td>
                    <Typography variant="h6">:</Typography>
                  </td>
                  <td>
                    <Typography variant="h6">
                      {moment(sales?.customerDueDate).format("DD-MMM-yyyy")}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
        <table className={posStyles.table}>
          <thead>
            <tr className={posStyles.tr}>
              {/* <TableCell padding="checkbox"></TableCell> */}
              {/* <TableCell align="center">ITEM CODE</TableCell> */}
              <td align="center" className={posStyles.tr}>
                <Typography variant="h6">ITEM DESCRIPTION</Typography>
              </td>
              <td align="center" className={posStyles.tr}>
                <Typography variant="h6">UNIT</Typography>
              </td>
              <td align="center" className={posStyles.tr}>
                <Typography variant="h6">PRICE</Typography>
              </td>
              <td align="center" className={posStyles.tr}>
                <Typography variant="h6">QTY</Typography>
              </td>
              <td align="right" className={posStyles.tr}>
                <Typography variant="h6">TOTAL AMOUNT</Typography>
              </td>
            </tr>
          </thead>
          <tbody>
            {details?.map((detail, index) => {
              const price = withTax
                ? defaultPrice
                  ? (detail.itemPrice * 100) / 111
                  : (detail.price * 100) / 111
                : defaultPrice
                ? detail.itemPrice
                : detail.price;

              const totalAmount = withTax
                ? price * detail.qty
                : defaultPrice
                ? detail.totalAmountDefaultPrice
                : detail.totalAmount;
              return (
                <tr key={index} className={posStyles.tr}>
                  {/* <TableCell align="center">
                    <Avatar
                      alt={detail.itemCode}
                      src={detail.imageUrl ? detail.imageUrl : "/no-image.png"}
                    />
                  </TableCell> */}
                  {/* <TableCell align="center">{detail.itemCode}</TableCell> */}
                  <td align="center" className={posStyles.tr}>
                    <Typography variant="h6">
                      {detail.itemDescription}
                    </Typography>
                  </td>
                  <td align="center" className={posStyles.tr}>
                    <Typography variant="h6">
                      {detail.unitDescription}
                    </Typography>
                  </td>
                  <td align="center" className={posStyles.tr}>
                    <Typography variant="h6">
                      {withTax
                        ? price.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                        : price.toLocaleString()}
                    </Typography>
                  </td>
                  <td align="center" className={posStyles.tr}>
                    {!emptyQty && (
                      <Typography variant="h6">
                        {detail.qty.toLocaleString()}
                      </Typography>
                    )}
                  </td>
                  <td align="right" className={posStyles.tr}>
                    {!emptyQty && (
                      <Typography variant="h6">
                        {totalAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </Typography>
                    )}
                  </td>
                </tr>
              );
            })}
            {withTax && (
              <>
                <tr className={posStyles.tr}>
                  <td colSpan={4} align="right" className={posStyles.tr}>
                    <Typography variant="h6">SUB TOTAL</Typography>
                  </td>
                  <td align="right" className={posStyles.tr}>
                    {!emptyQty && (
                      <Typography variant="h6">
                        {subTotal.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </Typography>
                    )}
                  </td>
                </tr>
                <tr className={posStyles.tr}>
                  <td colSpan={4} align="right" className={posStyles.tr}>
                    <Typography variant="h6">PPN 11%</Typography>
                  </td>
                  <td align="right" className={posStyles.tr}>
                    {!emptyQty && (
                      <Typography variant="h6">
                        {tax.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </Typography>
                    )}
                  </td>
                </tr>
              </>
            )}
            <tr className={posStyles.tr}>
              <td colSpan={4} align="right" className={posStyles.tr}>
                <Typography variant="h6">GRAND TOTAL</Typography>
              </td>
              <td align="right" className={posStyles.tr}>
                {!emptyQty && (
                  <Typography variant="h6">
                    {defaultPrice
                      ? totalAmountDefaultPrice(sales?.details).toLocaleString()
                      : totalAmount(sales?.details).toLocaleString()}
                  </Typography>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        {/* <Typography variant="h6">Payments</Typography>
        <TableContainer
          component={Paper}
          style={{ margin: theme.spacing(1, 0), width: "50%" }}
        >
          <Table className={posStyles.table} size="small">
            <TableBody>
              {sales?.payments.map((payment, index) => (
                <TableRow key={index}>
                  <TableCell>{payment.paymentType.type}</TableCell>
                  <TableCell>
                    {moment(payment.date).format("DD-MMM-yyyy")}
                  </TableCell>
                  <TableCell align="right">
                    {payment.amount.toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
              {notPaidYet > 0 && (
                <TableRow>
                  <TableCell colSpan={2}>NOT PAID YET</TableCell>
                  <TableCell align="right">
                    {notPaidYet.toLocaleString("en-US")}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={2}>GRAND TOTAL:</TableCell>
                <TableCell align="right">
                  {totalPayments.toLocaleString()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer> */}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={1}
          className={classes.slipFooter}
        >
          <Grid item>
            <Typography variant="body1" align="center">
              RECIEVED BY,
            </Typography>
            <div className={classes.ttd}></div>
            <Typography variant="body1" className={classes.space}>
              (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center">
              REGARDS,
            </Typography>
            <div className={classes.ttd}></div>
            <Typography variant="body1" align="center">
              (AYU PUTRI)
            </Typography>
          </Grid>
        </Grid>
        <div className={posStyles.bankAccount}>
          <Typography variant="body1">BANK ACCOUNT - MANDIRI (CAB. VETERAN DENPASAR)</Typography>
          <Typography variant="body1">
            PT PANDE AGUNG SEGARA DEWATA - 1450002777882
          </Typography>
        </div>
      </div>
    </ThemeProvider>
  );
});
