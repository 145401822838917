import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStyles } from "../../app/layout/style";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import { useStore } from "../../app/stores/store";
import {
  Customer,
  CustomerFormValues,
  CustomerItem,
  CustomerItemsValues,
} from "../../app/models/customer";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Form, Formik } from "formik";
import Grid from "@material-ui/core/Grid";
import InputField from "../../app/common/form/InputField";
import MySwitch from "../../app/common/form/MySwitch";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import PhoneNoInputField from "../../app/common/form/PhoneNoInputField";
import Dropdown from "../../app/common/form/Dropdown";
import CustomerFormAddItem from "./CustomerFormAddItem";

export default observer(function CustomerForm() {
  const classes = useStyles();
  const { customerStore, snackbarStore, userStore, itemStore } = useStore();
  const { loadDropDown: loadDropDownItem } = itemStore;
  const {
    createCustomer,
    updateCustomer,
    generateCode,
    loadCustomer,
    loading,
    loadCustomerDueDate,
    customerDueDate,
  } = customerStore;
  const { storeGranted } = userStore;
  const { openSnackbar } = snackbarStore;
  const [customer, setCustomer] = useState<CustomerFormValues>(
    new CustomerFormValues()
  );
  const [items, setItems] = useState<CustomerItem[]>([]);
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadDropDownItem();
    loadCustomerDueDate();

    if (id) {
      loadCustomer(id).then((result) => {
        loadCustomerResult(result);
      });
    }
  }, [id, loadCustomer, loadDropDownItem, loadCustomerDueDate]);

  const loadCustomerResult = (result: Customer | undefined) => {
    if (result) {
      setItems(result.items);
      setCustomer(
        new CustomerFormValues({
          id: result.id,
          storeId: result.storeId,
          customerName: result.customerName,
          customerCode: result.customerCode,
          group: result.group,
          address: result.address,
          phoneNo: result.phoneNo,
          isVerified: result.isVerified,
          isActive: result.isActive,
          items: result.items.map((x) => {
            return {
              itemId: x.id,
              customerId: result.id,
              price: x.price,
            } as CustomerItemsValues;
          }),
          customerDueDateId: result.customerDueDateId,
        })
      );
    }
  };

  const schema = Yup.object({
    storeId: Yup.string().required("Store is required."),
    customerName: Yup.string().required("Customer name is required."),
    customerCode: Yup.string().required("Customer code is required."),
    items: Yup.array().of(
      Yup.object().shape({
        price: Yup.number()
          .typeError("Price is must be a number.")
          .positive("Price is must be greater than zero.")
          .required("Price is required."),
      })
    ),
  });

  const handleGenerate = (
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean
    ) => void
  ) => {
    generateCode().then((result) => {
      if (result) setFieldValue("customerCode", result, true);
    });
  };

  const handleFormSubmit = (
    customer: CustomerFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!customer.id) {
      let newCustomer = {
        ...customer,
        id: uuid(),
      };
      createCustomer(newCustomer)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setCustomer(
              new CustomerFormValues({
                ...customer,
                customerCode: "",
                customerName: "",
                phoneNo: "",
                address: "",
                items: [],
              })
            );
            setItems([]);
            resetForm();
          }
        });
    } else {
      updateCustomer(customer)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/customers");
          }
        });
    }
  };

  return (
    <>
      <Paper className={classes.form}>
        <Typography variant="h5">Form {id ? "Update" : "Create"}</Typography>
        <Divider />
        <Formik
          validationSchema={schema}
          enableReinitialize
          initialValues={customer}
          onSubmit={(values, { resetForm, setSubmitting }) =>
            handleFormSubmit(values, resetForm, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldValue,
            values,
            validateForm,
          }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Dropdown
                    options={storeGranted.map((x) => {
                      return { text: x.name, value: x.id };
                    })}
                    name="storeId"
                    placeholder="Store"
                    label="Store"
                    disabled={isSubmitting || loading}
                    
                  />
                  <Dropdown
                    options={customerDueDate.map((x) => {
                      return { text: x.description, value: x.id.toString() };
                    })}
                    name="customerDueDateId"
                    placeholder="Due Date"
                    label="Due Date"
                    disabled={isSubmitting || loading}
                    displayDefaultSelected={false}
                  />
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs>
                      <InputField
                        variant="standard"
                        label="Customer Code"
                        name="customerCode"
                        placeholder="Please input customer code"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={isSubmitting || loading}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                        onClick={() => handleGenerate(setFieldValue)}
                      >
                        Generate
                      </Button>
                    </Grid>
                  </Grid>
                  <InputField
                    variant="standard"
                    label="Name"
                    name="customerName"
                    placeholder="Please input customer name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loading}
                  />
                  <InputField
                    variant="standard"
                    label="Group"
                    name="group"
                    placeholder="Please input group"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loading}
                  />
                  <PhoneNoInputField
                    variant="standard"
                    label="Phone Number"
                    name="phoneNo"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loading}
                  />
                  <InputField
                    variant="standard"
                    label="Address"
                    name="address"
                    multiline
                    minRows={4}
                    placeholder="Please input customer address"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loading}
                  />
                  <MySwitch
                    name="isVerified"
                    label="Verified"
                    disabled={isSubmitting || loading}
                  />
                  {id && (
                    <MySwitch
                      name="isActive"
                      label="Active Customer"
                      disabled={isSubmitting || loading}
                      checked
                    />
                  )}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CustomerFormAddItem
                    customer={values}
                    setCustomer={setCustomer}
                    items={items}
                    setItems={setItems}
                    isSubmitting={isSubmitting}
                    loading={loading}
                    validateForm={validateForm}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="stretch"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={
                      isSubmitting || !isValid || values.items.length === 0
                    }
                  >
                    {isSubmitting && (
                      <CircularProgress
                        className={classes.progress}
                        size={16}
                        color="inherit"
                      />
                    )}
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    component={Link}
                    to="/customers"
                    disabled={isSubmitting || loading}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
});
