import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  Avatar,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { CustomerDeposit } from "../../../app/models/report";

interface Props {
  customerDeposit: CustomerDeposit;
}

const useLocalStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
    },
  })
);

export default observer(function ReportCustomerDepositCard({
  customerDeposit,
}: Props) {
  const local = useLocalStyles();

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        avatar={
          <Avatar className={local.avatar}>
            {customerDeposit.customerName.substring(0, 1)}
          </Avatar>
        }
        title={
          <>
            <Typography variant="subtitle1" component="p">
              {customerDeposit.customerName}
            </Typography>
            <Typography variant="body2" color={"textSecondary"} component="p">
              {customerDeposit.customerCode}
            </Typography>
          </>
        }
        subheader={customerDeposit.storeName}
      />
      <CardContent>
        <Typography
          variant="subtitle1"
          color="primary"
          align="right"
          component="p"
        >
          {`IDR ${customerDeposit.amount.toLocaleString("EN-US")}`}
        </Typography>
      </CardContent>
    </Card>
  );
});
