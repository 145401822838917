import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import TransferList from "../../app/common/transferlist/TransferList";
import { UserStoreAccessRightFormValues } from "../../app/models/locationAccessRight";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      margin: theme.spacing(0.5, 0),
    },
  })
);

interface Props {
  store: string;
}

export default observer(function LocationStoreAccessList({ store }: Props) {
  const classes = useStyles();
  const { transferListStore, locationAccessRightStore } = useStore();
  const { itemsChecked, setItemsChecked, not, intersection } =
    transferListStore;
  const {
    usersStore,
    usersStoreGranted,
    setUsersStore,
    setUsersStoreGranted,
    createStoreAccessRight,
    deleteStoreAccessRight,
    loadingStore,
  } = locationAccessRightStore;

  const leftChecked = intersection(itemsChecked, usersStore);
  const rightChecked = intersection(itemsChecked, usersStoreGranted);

  const handleCheckedRight = () => {
    let addUsers = leftChecked.map((user) => {
      return new UserStoreAccessRightFormValues({
        userId: user.value,
        storeId: store,
      });
    });
    createStoreAccessRight(addUsers).then(() => {
      setUsersStoreGranted(usersStoreGranted.concat(leftChecked));
      setUsersStore(not(usersStore, leftChecked));
      setItemsChecked(not(itemsChecked, leftChecked));
    });
  };

  const handleCheckedLeft = () => {
    let deleteUsers = rightChecked.map((user) => {
      return new UserStoreAccessRightFormValues({
        userId: user.value,
        storeId: store,
      });
    });
    deleteStoreAccessRight(deleteUsers).then(() => {
      setUsersStore(usersStore.concat(rightChecked));
      setUsersStoreGranted(not(usersStoreGranted, rightChecked));
      setItemsChecked(not(itemsChecked, rightChecked));
    });
  };

  const handleAllRight = () => {
    let addUsers = usersStore.map((user) => {
      return new UserStoreAccessRightFormValues({
        userId: user.value,
        storeId: store,
      });
    });
    createStoreAccessRight(addUsers).then(() => {
      setUsersStoreGranted(usersStoreGranted.concat(usersStore));
      setUsersStore([]);
    });
  };

  const handleAllLeft = () => {
    let deleteUsers = usersStoreGranted.map((user) => {
      return new UserStoreAccessRightFormValues({
        userId: user.value,
        storeId: store,
      });
    });
    deleteStoreAccessRight(deleteUsers).then(() => {
      setUsersStore(usersStore.concat(usersStoreGranted));
      setUsersStoreGranted([]);
    });
  };

  return (
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
      <Grid item xs>
        <TransferList
          title="User List"
          items={usersStore}
          loading={loadingStore}
        />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={usersStore.length === 0}
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={usersStoreGranted.length === 0}
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item xs>
        <TransferList
          title="User List Granted"
          items={usersStoreGranted}
          loading={loadingStore}
        />
      </Grid>
    </Grid>
  );
});
