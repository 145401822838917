import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import InputField from "../../app/common/form/InputField";
import { useStyles } from "../../app/layout/style";
import { RoleForm } from "../../app/models/user";
import { useStore } from "../../app/stores/store";

export default observer(function RoleForm(): JSX.Element {
  const classes = useStyles();
  const { dialogStore, userStore, snackbarStore } = useStore();
  const { roleCreate } = userStore;
  const { closeDialog } = dialogStore;
  const { openSnackbar } = snackbarStore;
  const [role, setRole] = useState<RoleForm>({ name: "" });

  const handleFormSubmit = (value: RoleForm, resetForm: () => void) => {
    roleCreate(value)
      .catch((error) => {
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) {
          openSnackbar(message, "success");
          setRole(value);
          closeDialog();
        } else {
          setRole({ name: "" });
          resetForm();
        }
      });
  };

  return (
    <>
      <Formik
        initialValues={role}
        onSubmit={(value, { resetForm }) => handleFormSubmit(value, resetForm)}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <DialogTitle id="form-dialog-title">Add Role</DialogTitle>
            <DialogContent>
              <InputField
                required
                variant="standard"
                name="name"
                label="Role Name"
                placeholder="Please input role name"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={isSubmitting}
                autoFocus
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isSubmitting}>
                {isSubmitting && (
                  <CircularProgress
                    className={classes.progress}
                    size={16}
                    color="inherit"
                  />
                )}
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
});
