import { Divider, Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { SalesDetail } from "../../app/models/sale";
import { useStore } from "../../app/stores/store";

interface Props {
  details: SalesDetail[];
}

export default observer(function POSTotal({ details }: Props) {
  const { salesStore } = useStore();
  const { totalQty, totalAmount } = salesStore;

  return (
    <>
      <Typography align="center" variant="h5">
        TOTAL
      </Typography>
      <Divider />
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Typography variant="body1" component="p">
            Sub Total ({totalQty(details)}) :
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography align="right" variant="body1" component="p">
            {totalAmount(details).toLocaleString("en-US")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Typography variant="body2" component="p">
            Discount :
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography align="right" variant="body2" component="p">
            0
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Typography variant="body2" component="p">
            Tax :
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography align="right" variant="body2" component="p">
            0
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Typography variant="h5" component="p">
            Total Amount :
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography align="right" variant="h5" component="p">
            {totalAmount(details).toLocaleString("en-US")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
});
