import {
  Popper,
  Grow,
  Paper,
  Button,
  Typography,
  Divider,
  ClickAwayListener,
} from "@material-ui/core";
import React from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import { observer } from "mobx-react-lite";
import { useStyles } from "../../layout/style";

interface Props {
  children?: JSX.Element | undefined;
  open: boolean;
  setOpen: (flag: boolean) => void;
  setSearch: () => void;
}

export default observer(function Filter(props: Props) {
  const classes = useStyles();
  const { children, open, setOpen, setSearch } = props;
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
    setSearch();
  };

  return (
    <ClickAwayListener
      onClickAway={handleClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div ref={anchorRef}>
        <Button color="primary" size="small" onClick={handleToggle}>
          Filter <FilterListIcon />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          placement="bottom-end"
          style={{ zIndex: 3 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-end" ? "right top" : "right bottom",
              }}
            >
              <Paper className={classes.form}>
                <Typography variant="subtitle2">Filter</Typography>
                <Divider />
                {children}
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
});
