import {
  Grid,
  Avatar,
  Typography,
  CardActionArea,
  Card,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { v4 as uuid } from "uuid";
import { useStyles } from "../../app/layout/style";
import { usePosStyles } from "../../app/layout/pos";
import { CustomerItem } from "../../app/models/customer";
import {
  SalesDetail,
  SalesDetailValues,
  SalesFormValues,
} from "../../app/models/sale";

interface Props {
  sales: SalesFormValues;
  setSales: (sale: SalesFormValues) => void;
  items: CustomerItem[];
  details: SalesDetail[];
  setDetails: (details: SalesDetail[]) => void;
  isSubmitting: boolean;
  defaultPrice: boolean;
}

export default observer(function POSItems({
  sales,
  setSales,
  items,
  details,
  setDetails,
  isSubmitting,
  defaultPrice,
}: Props) {
  const classes = useStyles();
  const local = usePosStyles();

  const handleClick = (item: CustomerItem) => {
    const id = uuid();
    let detail = details.find((x) => x.itemId === item.id);
    if (!detail) {
      let newSalesDetail = new SalesDetail(id, item, sales.id);
      setDetails(details.concat(newSalesDetail));

      let newSalesDetailValues = new SalesDetailValues();
      newSalesDetailValues.init(id, item, sales.id);
      setSales(
        new SalesFormValues({
          ...sales,
          details: sales.details.concat(newSalesDetailValues),
        })
      );
    }
  };

  return (
    <>
      {items.map((item, index) => (
        <Grid key={index} container spacing={2}>
          <Grid item xs={12} className={local.grid}>
            <Card variant="outlined">
              <CardActionArea
                className={local.card}
                onClick={() => handleClick(item)}
                disabled={isSubmitting}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Avatar
                      alt={item.itemCode}
                      src={item.imageUrl ? item.imageUrl : "/no-image.png"}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="subtitle1" component="p">
                      {item.itemDescription}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={classes.price}
                      align="right"
                      variant="subtitle2"
                      component="p"
                    >
                      {defaultPrice
                        ? `${item.itemPrice.toLocaleString("en-US")} / ${
                            item.unitDescription
                          }`
                        : `${item.price.toLocaleString("en-US")} / ${
                            item.unitDescription
                          }`}
                    </Typography>
                  </Grid>
                </Grid>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      ))}
    </>
  );
});
