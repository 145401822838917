import { observer } from "mobx-react-lite";
import React from "react";
import Title from "../../app/layout/Title";
import ChangeProfileForm from "./ChangeProfileForm";

export default observer(function ChangeProfile() {
  return (
    <>
      <Title menuName="Change Profile" />
      <ChangeProfileForm />
    </>
  );
});
