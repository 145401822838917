import {
  action,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { CreditPayment, CreditPaymentSearch } from "../models/report";
import { PaymentType } from "../models/sale";
import { store } from "./store";
import { TableStore } from "./tableStore";

export default class CreditPaymentStore extends TableStore<CreditPayment> {
  creditPaymentRegistry = new Map<PaymentType, CreditPayment[]>();
  filterPaymentType: string = "All";

  constructor() {
    super();

    makeObservable(this, {
      creditPaymentRegistry: observable,
      filterPaymentType: observable,
      setFilterPaymentType: action,
      loadReportCreditPayment: action,
    });

    this.setPredicate(new CreditPaymentSearch());

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadReportCreditPayment();
      }
    );
  }

  setFilterPaymentType = (id: string) => {
    runInAction(() => {
      this.filterPaymentType = id;
    });
  };

  setPredicate = (value: CreditPaymentSearch) => {
    runInAction(() => {
      Object.keys(value).forEach((key) => {
        this.predicate.delete(key);
      });
      this.predicate.set(
        "storeId",
        value.storeId === "All" ? "" : value.storeId
      );
      this.predicate.set("date", value.date.toLocaleDateString("EN-US"));
      this.predicate.set("customerName", value.customerName);
    });
  };

  get filter() {
    return new CreditPaymentSearch({
      storeId:
        this.predicate.get("storeId") === undefined ||
        this.predicate.get("storeId") === ""
          ? "All"
          : this.predicate.get("storeId")!,
      date:
        this.predicate.get("date") === undefined
          ? new Date()
          : new Date(this.predicate.get("date")!),
      customerName:
        this.predicate.get("customerName") === undefined
          ? ""
          : this.predicate.get("customerName")!,
    });
  }

  loadReportCreditPayment = async () => {
    this.clearCreditPayment();
    this.loading = true;

    try {
      const result = await agent.Reports.reportCreditPayment(this.axiosParams);
      runInAction(() => {
        store.salesStore.paymentTypes
          .filter((x) => x.type !== "DEPOSIT")
          .forEach((paymentType) => {
            let resultByPaymentType = result.filter(
              (x) => x.paymentType.id === paymentType.id
            );
            this.creditPaymentRegistry.set(paymentType, resultByPaymentType);
          });
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  deletePayment = async (paymentType: PaymentType, id: string) => {
    this.loading = true;

    try {
      await agent.Reports.deletePayment(id);
      runInAction(() => {
        let creditPayment = this.creditPaymentRegistry.get(paymentType);
        creditPayment = creditPayment?.filter((x) => x.id !== id);
        if (creditPayment)
          this.creditPaymentRegistry.set(paymentType, creditPayment);
      });
      return "Delete payment success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  private clearCreditPayment = () => {
    this.creditPaymentRegistry.clear();
  };
}
