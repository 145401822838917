import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import { Customer } from "../../app/models/customer";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Box from "@material-ui/core/Box";
import TableHead from "@material-ui/core/TableHead";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Avatar from "@material-ui/core/Avatar";

interface RowProps {
  row: Customer;
  labelId: string;
  isItemSelected: boolean;
  handleClick: (event: React.MouseEvent<unknown>, name: string) => void;
}

export default function CustomerTableRow({
  row,
  labelId,
  isItemSelected,
  handleClick,
}: RowProps) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            onClick={(event) => handleClick(event, row.id)}
          />
        </TableCell>
        <TableCell padding="checkbox">
          <Tooltip title="Update">
            <IconButton
              component={Link}
              to={`/customers/${row.id}`}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell component="th" id={labelId} scope="row">
          {row.fullName}
        </TableCell>
        <TableCell>{row.customerCode}</TableCell>
        <TableCell>{row.storeName}</TableCell>
        <TableCell padding="none" align="center">
          {row.isVerified ? (
            <CheckIcon color="primary" />
          ) : (
            <ClearIcon color="error" />
          )}
        </TableCell>
        <TableCell padding="none" align="center">
          {row.isActive ? (
            <CheckIcon color="primary" />
          ) : (
            <ClearIcon color="error" />
          )}
        </TableCell>
        {row.items.length > 0 ? (
          <TableCell>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell></TableCell>
        )}
      </TableRow>
      {row.items.length > 0 && (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                marginLeft={5}
                marginTop={1}
                marginRight={5}
                marginBottom={2}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell>Item Code</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Unit</TableCell>
                      <TableCell align="right">Price (IDR)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.items.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>
                          <Avatar
                            alt={item.itemCode}
                            src={
                              item.imageUrl ? item.imageUrl : "/no-image.png"
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item.itemCode}
                        </TableCell>
                        <TableCell>{item.itemDescription}</TableCell>
                        <TableCell>{item.unitDescription}</TableCell>
                        <TableCell align="right">
                          {item.price.toLocaleString("en-US")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
