import { CustomerItem } from "./customer";

export interface Vendor {
  id: string;
  stockLocationId: string;
  locationName: string;
  name: string;
  displayOnCashflow: boolean;
  isActive: boolean;
  items: CustomerItem[];
}

export class VendorItemsValues {
  itemId: string = "";
  vendorId?: string = undefined;
  price?: number = undefined;

  constructor(items?: VendorItemsValues) {
    if (items) {
      this.itemId = items.itemId;
      this.vendorId = items.vendorId;
      this.price = items.price;
    }
  }
}

export class VendorFormValues {
  id?: string = undefined;
  stockLocationId?: string = undefined;
  name: string = "";
  displayOnCashflow: boolean = false;
  isActive: boolean = true;
  items: VendorItemsValues[] = [];

  constructor(vendor?: VendorFormValues) {
    if (vendor) {
      this.id = vendor.id;
      this.stockLocationId = vendor.stockLocationId;
      this.name = vendor.name;
      this.displayOnCashflow = vendor.displayOnCashflow;
      this.isActive = vendor.isActive;
      this.items = vendor.items;
    }
  }
}
