import {
  Grid,
  Button,
  LinearProgress,
  Paper,
  Typography,
  Divider,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import Filter from "../../../app/common/filter/Filter";
import DateInputField from "../../../app/common/form/DateInputFIeld";
import Dropdown2 from "../../../app/common/form/Dropdown2";
import SearchFilter from "../../../app/common/form/SearchFilter";
import { useStyles } from "../../../app/layout/style";
import Title from "../../../app/layout/Title";
import { CustomerItem } from "../../../app/models/customer";
import { InvoiceByCustomerSearch } from "../../../app/models/report";
import { useStore } from "../../../app/stores/store";
import ItemsAutoComplete from "../../Customers/ItemsAutoComplete";
import ReportSalesByCustomerTable from "./ReportSalesByCustomerTable";
import ComponentToPrint from "../../../app/common/print/ComponentToPrint";
import ButtonPrint from "../../../app/common/print/ButtonPrint";
import ButtonExcel from "../../../app/common/export/ButtonExcel";

export default observer(function ReportSalesByCustomer() {
  const classes = useStyles();
  const { userStore, salesByCustomerStore, itemStore } = useStore();
  const { loadDropDown: loadDropDownItem, itemsDropDown } = itemStore;
  const { storeGranted } = userStore;
  const {
    setPredicate,
    reportList,
    loadReportByCustomer,
    salesByCustomerRegistry,
    loading,
    filter,
  } = salesByCustomerStore;
  const [search, setSearch] = useState(new InvoiceByCustomerSearch());
  const [value, setValue] = useState<CustomerItem | null>(null);
  const [open, setOpen] = useState(false);
  let componentRef = useRef(null);

  useEffect(() => {
    if (itemsDropDown.length < 1) loadDropDownItem();
    loadReportByCustomer();
  }, [loadDropDownItem, itemsDropDown.length, loadReportByCustomer]);

  const handleSetSearch = () => {
    const item = itemsDropDown.find((x) => x.id === filter.itemId);
    setSearch(filter);
    setValue(item === undefined ? null : item);
  };

  const handleSearchClick = (values: InvoiceByCustomerSearch) => {
    const newValues = {
      ...values,
      itemId: value === null ? "" : value.id,
    };
    setPredicate(newValues);
  };

  const handleItemChange = (
    item: CustomerItem | null,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (item) {
      setValue(item);
      setFieldValue("itemId", item.id);
    } else {
      setValue(null);
      setFieldValue("itemId", "");
    }
  };

  return (
    <>
      <Title menuName="Report Sales By Customer">
        <>
          <ButtonExcel
            fileName="Sales By Customer"
            sheetName="Sales By Customer"
            componentToExport={componentRef}
            disabled={loading || salesByCustomerRegistry.size === 0}
          />
          <ButtonPrint
            componentToPrint={componentRef}
            disabled={loading || salesByCustomerRegistry.size === 0}
          />
          <Filter open={open} setOpen={setOpen} setSearch={handleSetSearch}>
            <div className={classes.filter}>
              <Formik
                enableReinitialize
                initialValues={search}
                onSubmit={(values) => handleSearchClick(values)}
              >
                {({ handleSubmit, values, setFieldValue }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-end"
                      spacing={1}
                    >
                      <Grid item>
                        <Dropdown2
                          name="storeId"
                          options={storeGranted.map((x) => {
                            return { text: x.name, value: x.id };
                          })}
                          label="Store"
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          titleDefaultSelected="All Selected"
                          displayDefaultSelected
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="Start Date"
                          name="startDate"
                          maxDate={values.endDate}
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="End Date"
                          name="endDate"
                          minDate={values.startDate}
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <SearchFilter
                          name="customerName"
                          label="Customer Name"
                          variant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <ItemsAutoComplete
                          name="itemId"
                          options={itemsDropDown}
                          value={value}
                          setValue={setValue}
                          onChange={handleItemChange}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </Filter>
        </>
      </Title>
      <>
        {salesByCustomerRegistry.size > 0 &&
          reportList.map((location) => (
            <Paper className={classes.form} key={location.id}>
              <Typography variant="subtitle1">{location.name}</Typography>
              <Divider />
              {loading ? (
                <LinearProgress />
              ) : (
                <ReportSalesByCustomerTable
                  salesByCustomer={salesByCustomerRegistry.get(location)}
                />
              )}
            </Paper>
          ))}
        <ComponentToPrint
          title="Report Sales By Customer"
          componentToPrint={componentRef}
        >
          <>
            {salesByCustomerRegistry.size > 0 &&
              reportList.map((location) => (
                <div key={location.id}>
                  <Typography variant="subtitle1">{location.name}</Typography>
                  <Divider />
                  <ReportSalesByCustomerTable
                    salesByCustomer={salesByCustomerRegistry.get(location)}
                  />
                </div>
              ))}
          </>
        </ComponentToPrint>
      </>
    </>
  );
});
