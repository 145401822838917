import { Documents } from "./document";
import { ItemDocument } from "./item";
import { StockLocation } from "./location";

export interface StockTransactionGroup {
  id: string;
  code: string;
  isSubsidy: boolean;
  isSPBE: boolean;
}

export interface StockOutgoingDetail extends ItemDocument {
  id: string;
  itemId: string;
  stockTransactionGroupId: string;
  stockTransactionGroup: string;
  allocationQty: number;
  realizationQty: number;
  giQty: number;
  price: number;
}

export interface StockOutgoing extends Documents {
  sourceStockLocation: StockLocation;
  moveToStockLocation: StockLocation;
  details: StockOutgoingDetail[];
}

export class StockOutgoingDetailValues {
  id?: string = undefined;
  stockOutgoingId?: string = undefined;
  itemId: string = "";
  stockTransactionGroupId = "";
  allocationQty?: number = undefined;
  realizationQty?: number = undefined;
  giQty?: number = undefined;
  price?: number = undefined;

  constructor(details?: StockOutgoingDetailValues) {
    if (details) {
      this.id = details.id;
      this.stockOutgoingId = details.stockOutgoingId;
      this.itemId = details.itemId;
      this.stockTransactionGroupId = details.stockTransactionGroupId;
      this.allocationQty = details.allocationQty;
      this.realizationQty = details.realizationQty;
      this.giQty = details.giQty;
      this.price = details.price;
    }
  }
}

export class StockOutgoingFormValues {
  id?: string = undefined;
  date: Date = new Date();
  sourceStockLocationId: string = "";
  moveToStockLocationId: string = "";
  details: StockOutgoingDetailValues[] = [];

  constructor(stockOutgoing?: StockOutgoingFormValues) {
    if (stockOutgoing) {
      this.id = stockOutgoing.id;
      this.date = new Date(stockOutgoing.date);
      this.sourceStockLocationId = stockOutgoing.sourceStockLocationId;
      this.moveToStockLocationId = stockOutgoing.moveToStockLocationId;
      this.details = stockOutgoing.details;
    }
  }
}
