import {
  CircularProgress,
  createTheme,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect } from "react";
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  TooltipProps,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";

export default observer(function SalesRevenueAndCredit() {
  const classes = useStyles();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );
  const { dashboardStore } = useStore();
  const { loadSalesRevenueAndCredit, revenueAndCredit, loadingSalesRevenue } =
    dashboardStore;

  useEffect(() => {
    loadSalesRevenueAndCredit();
  }, [loadSalesRevenueAndCredit]);

  const formatCash = (n: number) => {
    if (n < 1e3) return n.toLocaleString("EN-US");
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    return "";
  };

  const YFormater = (number: number) => {
    return formatCash(number);
  };

  const XFormater = (date: Date) => {
    return moment(date).format("D");
  };

  return (
    <div className={classes.dashboard_sales_revenue}>
      {loadingSalesRevenue ? (
        <div className={classes.center_div}>
          <CircularProgress />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height="98%">
          <AreaChart
            data={revenueAndCredit}
            margin={{
              top: 10,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id="revenue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#00bcd4" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#00bcd4" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="spending" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#f50057" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#f50057" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="credit" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#ffc658" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="date"
              stroke={theme.palette.text.primary}
              tickFormatter={XFormater}
            />
            <YAxis
              type="number"
              stroke={theme.palette.text.primary}
              tickFormatter={YFormater}
            />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="monotone"
              dataKey="revenue"
              name="Revenue"
              stroke="#00bcd4"
              fillOpacity={1}
              fill="url(#revenue)"
            />
            <Area
              type="monotone"
              dataKey="credit"
              name="Customer Debt"
              stroke="#f50057"
              fillOpacity={1}
              fill="url(#spending)"
            />
            <Area
              type="monotone"
              dataKey="spending"
              name="Spending"
              stroke="#ffc658"
              fillOpacity={1}
              fill="url(#credit)"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
});

function CustomTooltip({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) {
  const classes = useStyles();

  if (active) {
    return (
      <Paper className={classes.form}>
        <Typography variant="subtitle2" component="h6">
          {`Sales Revenue, Customer Debt And Spending ${moment(label).format(
            "DD-MMM-yyyy"
          )}`}
        </Typography>
        {payload && (
          <>
            <Typography variant="subtitle2" component="p" color="primary">
              {`Revenue: IDR ${payload[0].value?.toLocaleString("en-US")}`}
            </Typography>
            <Typography variant="subtitle2" component="p" color="primary">
              {`Customer Debt: IDR ${payload[1].value?.toLocaleString(
                "en-US"
              )}`}
            </Typography>
            <Typography variant="subtitle2" component="p" color="primary">
              {`Spending: IDR ${payload[2].value?.toLocaleString("en-US")}`}
            </Typography>
          </>
        )}
      </Paper>
    );
  }

  return null;
}
