import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useStyles } from "../../app/layout/style";
import { CustomerItem } from "../../app/models/customer";

interface Props {
  name: string;
  options: CustomerItem[] | undefined;
  value: CustomerItem | null;
  setValue: (value: CustomerItem | null) => void;
  optionDisabled?: CustomerItem[];
  loading?: boolean | undefined;
  onChange?: (
    value: CustomerItem | null,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => void | undefined;
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void | undefined;
  touched?: boolean | undefined;
  setFieldTouched?: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void | undefined;
  error?: string | undefined;
  variant?: "standard" | "filled" | "outlined" | undefined;
}

export default function ItemsAutoComplete({
  name,
  options,
  value,
  setValue,
  optionDisabled,
  loading,
  onChange,
  setFieldValue,
  touched,
  setFieldTouched,
  error,
  variant,
}: Props) {
  const classes = useStyles();

  const handleOptionDisabled = (item: CustomerItem) => {
    if (optionDisabled) return optionDisabled.some((x) => x.id === item.id);
    else return false;
  };

  const handleChange = (value: CustomerItem | null) => {
    setValue(value);
    if (onChange && setFieldValue) onChange(value, setFieldValue);
  };

  return (
    <FormControl fullWidth className={classes.icon}>
      <Autocomplete
        options={options === undefined ? [] : options}
        getOptionLabel={(option) => option.itemDescription}
        getOptionDisabled={(option) => handleOptionDisabled(option)}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue !== "string") {
            handleChange(newValue);
          }
        }}
        renderOption={(option) => (
          <CardHeader
            avatar={
              <Avatar
                alt={option.itemCode}
                src={option.imageUrl ? option.imageUrl : "/no-image.png"}
              />
            }
            title={option.itemDescription}
            subheader={option.unitDescription}
          />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Item"
            placeholder="Please select any items."
            variant={variant === undefined ? "outlined" : variant}
            margin="dense"
            error={touched && !!error}
            helperText={touched && error ? error : false}
            onBlur={() => {
              if (setFieldTouched) setFieldTouched(name, true);
            }}
            autoComplete="false"
            inputProps={{
              ...params.inputProps,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={loading}
          />
        )}
      />
    </FormControl>
  );
}
