import Grid from "@material-ui/core/Grid";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import UserCard from "./UserCard";
import UserSkeleton from "./UserSkeleton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Paper, TablePagination, useTheme } from "@material-ui/core";
import CardHeaderList from "../../app/common/card/CardHeaderList";
import { useStyles } from "../../app/layout/style";
import CardDeleteDialog from "../../app/layout/CardDeleteDialog";

export default observer(function UserCardList() {
  const classes = useStyles();
  const theme = useTheme();
  const widthSizeMatch = useMediaQuery(theme.breakpoints.up("xl"));
  const { userStore, snackbarStore, dialogStore } = useStore();
  const { loadUsers, userRegistry, userList, deleteUser, loading } = userStore;
  const { openSnackbar } = snackbarStore;
  const { openDialogDelete, closeDialog } = dialogStore;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rowsOptions, setRowsOptions] = React.useState([2]);
  const skeletonCardArray = Array(rowsPerPage).fill("");

  useEffect(() => {
    setRowsPerPage(widthSizeMatch ? 8 : 6);
    setRowsOptions(
      widthSizeMatch ? [8, 8 * 2, 8 * 4, 8 * 8] : [6, 6 * 2, 6 * 4, 6 * 8]
    );
    if (userRegistry.size <= 1) loadUsers();
  }, [userRegistry.size, loadUsers, widthSizeMatch]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteUser = (id: string) => {
    openDialogDelete(
      <CardDeleteDialog
        title="Are you sure want to delete this user?"
        id={id}
      />,
      handleDelete
    );
  };

  const handleDelete = (id: string) => {
    deleteUser(id)
      .catch((error) => {
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        closeDialog();
      });
  };

  return (
    <div className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CardHeaderList
            title="User List"
            link="/registeruser/form"
            loading={loading}
          />
        </Grid>
        {loading
          ? skeletonCardArray.map((item, index) => (
              <Grid key={index} item xl={3} lg={4} md={6} sm={6} xs={12}>
                <UserSkeleton />
              </Grid>
            ))
          : userList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(([id, user]) => (
                <Grid key={id} item xl={3} lg={4} md={6} sm={12} xs={12}>
                  <UserCard user={user} deleteUser={handleDeleteUser} />
                </Grid>
              ))}
        <Grid item xs={12}>
          <Paper>
            <TablePagination
              rowsPerPageOptions={rowsOptions}
              component="div"
              count={userRegistry.size}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
});
