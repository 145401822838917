import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  Grid,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export default function ReportStockBalanceSkeleton() {
  return (
    <Card>
      <CardHeader
        avatar={<Skeleton variant="circle" width={40} height={40} />}
        title={
          <Typography variant="subtitle1" component="p">
            <Skeleton variant="text" animation="wave" />
          </Typography>
        }
        subheader={<Skeleton variant="text" width="40%" />}
      />
      <CardContent>
        <Divider />
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Skeleton variant="circle" width={40} height={40} />
          </Grid>
          <Grid item xs>
            <Typography variant="subtitle1" component="p">
              <Skeleton variant="text" width="80%" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" align="right" component="p">
              <Skeleton variant="text" width={20} />
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Skeleton variant="circle" width={40} height={40} />
          </Grid>
          <Grid item xs>
            <Typography variant="subtitle1" component="p">
              <Skeleton variant="text" width="80%" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" align="right" component="p">
              <Skeleton variant="text" width={20} />
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </CardContent>
    </Card>
  );
}
