import {
  Grid,
  Button,
  LinearProgress,
  Typography,
  Paper,
  Divider,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useRef, useState } from "react";
import ButtonExcel from "../../../app/common/export/ButtonExcel";
import Filter from "../../../app/common/filter/Filter";
import DateInputField from "../../../app/common/form/DateInputFIeld";
import Dropdown2 from "../../../app/common/form/Dropdown2";
import SearchFilter from "../../../app/common/form/SearchFilter";
import ButtonPrint from "../../../app/common/print/ButtonPrint";
import ComponentToPrint from "../../../app/common/print/ComponentToPrint";
import { useStyles } from "../../../app/layout/style";
import Title from "../../../app/layout/Title";
import { StockDetailSearch } from "../../../app/models/report";
import { useStore } from "../../../app/stores/store";
import ReportStockDetailTable from "./ReportStockDetailTable";

export default observer(function ReportStockDetail() {
  const classes = useStyles();
  const { userStore, stockDetailStore } = useStore();
  const { stockLocationGranted } = userStore;
  const {
    setPredicate,
    loadReportStockDetail,
    stockDetailRegistry,
    loading,
    filter,
  } = stockDetailStore;
  const [search, setSearch] = useState(filter);
  const [open, setOpen] = useState(false);
  let componentRef = useRef(null);
  let stockLocation = useMemo(
    () => stockLocationGranted.filter((x) => !x.isSPBE),
    [stockLocationGranted]
  );
  let stockLocationSelected = useMemo(
    () =>
      stockLocationGranted.find((x) => x.id === filter.stockLocationId)?.name,
    [filter.stockLocationId, stockLocationGranted]
  );

  useEffect(() => {
    loadReportStockDetail();
  }, [loadReportStockDetail]);

  const handleSetSearch = () => {
    setSearch(filter);
  };

  const handleSearchClick = (values: StockDetailSearch) => {
    setPredicate(values);
  };

  return (
    <>
      <Title menuName="Report Stock Detail">
        <>
          <ButtonExcel
            fileName="Report Stock Detail"
            sheetName="Report Stock Detail"
            componentToExport={componentRef}
            disabled={loading || stockDetailRegistry.length === 0}
          />
          <ButtonPrint
            componentToPrint={componentRef}
            disabled={loading || stockDetailRegistry.length === 0}
          />
          <Filter open={open} setOpen={setOpen} setSearch={handleSetSearch}>
            <div className={classes.filter}>
              <Formik
                enableReinitialize
                initialValues={search}
                onSubmit={(values) => handleSearchClick(values)}
              >
                {({ handleSubmit, values }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-end"
                      spacing={1}
                    >
                      <Grid item>
                        <Grid item>
                          <Dropdown2
                            name="stockLocationId"
                            options={stockLocation.map((x) => {
                              return { text: x.name, value: x.id };
                            })}
                            label="Stock Location"
                            variant="outlined"
                            margin="dense"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <DateInputField
                            label="Start Date"
                            name="startDate"
                            maxDate={values.endDate}
                            inputVariant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item>
                          <DateInputField
                            label="End Date"
                            name="endDate"
                            minDate={values.startDate}
                            inputVariant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item>
                          <SearchFilter
                            name="documentNo"
                            label="Document No."
                            variant="outlined"
                            margin="dense"
                          />
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </Filter>
        </>
      </Title>
      <>
        {stockLocationSelected && (
          <Paper className={classes.form}>
            <Typography variant="subtitle1">{stockLocationSelected}</Typography>
            <Divider />
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                <ReportStockDetailTable stockDetail={stockDetailRegistry} />
                <ComponentToPrint
                  title="Report Stock Detail"
                  componentToPrint={componentRef}
                >
                  <ReportStockDetailTable stockDetail={stockDetailRegistry} />
                </ComponentToPrint>
              </>
            )}
          </Paper>
        )}
      </>
    </>
  );
});
