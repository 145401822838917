import { Documents } from "./document";
import { Store } from "./location";

export interface CashIN extends Documents {
  storeId: string;
  store: Store;
  description: string;
  amount: number;
}

export class CashINFormValues {
  id?: string = undefined;
  date: Date = new Date();
  storeId: string = "";
  description: string = "";
  amount: number = parseInt("");

  constructor(cashin?: CashINFormValues) {
    if (cashin) {
      this.id = cashin.id;
      this.date = new Date(cashin.date);
      this.storeId = cashin.storeId;
      this.description = cashin.description;
      this.amount = cashin.amount;
    }
  }
}
