export class UserStoreAccessRightFormValues {
  userId: string = "";
  storeId: string = "";

  constructor(user?: UserStoreAccessRightFormValues) {
    if (user) {
      this.userId = user.userId;
      this.storeId = user.storeId;
    }
  }
}

export class UserLocationAccessRightFormValues {
  userId: string = "";
  stockLocationId: string = "";

  constructor(user?: UserLocationAccessRightFormValues) {
    if (user) {
      this.userId = user.userId;
      this.stockLocationId = user.stockLocationId;
    }
  }
}
