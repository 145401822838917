import TableCell from "@material-ui/core/TableCell/TableCell";
import TableRow from "@material-ui/core/TableRow/TableRow";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";
import { observer } from "mobx-react-lite";
import React from "react";
import { HeadCell } from "../../models/table";

interface Props<T> {
  row: number;
  cellArray: HeadCell<T>[];
}

export default observer(function EnhancedTableSkeleton({
  row,
  cellArray,
}: Props<any>) {
  const skeletonRowArray = Array(row).fill("");

  return (
    <>
      {cellArray.length !== 0 &&
        skeletonRowArray.map((item, index) => (
          <TableRow key={index}>
            <TableCell>
              <Skeleton variant="rect" />
            </TableCell>
            <TableCell>
              <Skeleton variant="rect" />
            </TableCell>
            {cellArray.map((x, index) => (
              <TableCell key={index}>
                <Skeleton variant={x.skeletonShape} />
              </TableCell>
            ))}
          </TableRow>
        ))}
    </>
  );
});
