import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { DropDownProps } from "../models/dropDownProps";
import {
  UserLocationAccessRightFormValues,
  UserStoreAccessRightFormValues,
} from "../models/locationAccessRight";

export default class LocationAccessRightStore {
  store: string = "0";
  location: string = "0";
  usersStore: DropDownProps[] = [];
  usersStoreGranted: DropDownProps[] = [];
  usersStockLocation: DropDownProps[] = [];
  usersStockLocationGranted: DropDownProps[] = [];
  loadingStore = false;
  loadingLocation = false;

  constructor() {
    makeAutoObservable(this);
  }

  setStore = (store: string) => {
    this.store = store;
  };

  setLocation = (location: string) => {
    this.location = location;
  };

  setUsersStore = (users: DropDownProps[]) => {
    this.usersStore = users;
  };

  setUsersStoreGranted = (users: DropDownProps[]) => {
    this.usersStoreGranted = users;
  };

  setUsersStockLocation = (users: DropDownProps[]) => {
    this.usersStockLocation = users;
  };

  setUsersStockLocationGranted = (users: DropDownProps[]) => {
    this.usersStockLocationGranted = users;
  };

  loadUsersStoreGranted = async (id: string) => {
    this.loadingStore = true;

    try {
      const usersGranted = this.sortUser(
        await agent.StoreAccessRights.loadUsersGranted(id)
      );
      let usersNotGranted = this.sortUser(await agent.Account.dropdown());
      runInAction(() => {
        usersGranted.forEach((x) => {
          usersNotGranted = usersNotGranted.filter((y) => y.value !== x.value);
        });
        this.usersStoreGranted = usersGranted;
        this.usersStore = usersNotGranted;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingStore = false));
    }
  };

  createStoreAccessRight = async (users: UserStoreAccessRightFormValues[]) => {
    this.loadingStore = true;

    try {
      await agent.StoreAccessRights.create(users);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingStore = false));
    }
  };

  deleteStoreAccessRight = async (users: UserStoreAccessRightFormValues[]) => {
    this.loadingStore = true;

    try {
      await agent.StoreAccessRights.delete(users);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingStore = false));
    }
  };

  loadUsersStockLocationGranted = async (id: string) => {
    this.loadingLocation = true;

    try {
      const usersGranted = this.sortUser(
        await agent.StockLocationAccessRights.loadUsersGranted(id)
      );
      let usersNotGranted = this.sortUser(await agent.Account.dropdown());
      runInAction(() => {
        usersGranted.forEach((x) => {
          usersNotGranted = usersNotGranted.filter((y) => y.value !== x.value);
        });
        this.usersStockLocationGranted = usersGranted;
        this.usersStockLocation = usersNotGranted;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingLocation = false));
    }
  };

  createStockLocationAccessRight = async (
    users: UserLocationAccessRightFormValues[]
  ) => {
    this.loadingLocation = true;

    try {
      await agent.StockLocationAccessRights.create(users);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingLocation = false));
    }
  };

  deleteStockLocationAccessRight = async (
    users: UserLocationAccessRightFormValues[]
  ) => {
    this.loadingLocation = true;

    try {
      await agent.StockLocationAccessRights.delete(users);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingLocation = false));
    }
  };

  private sortUser = (users: DropDownProps[]) => {
    return users.sort((a, b) => {
      if (a.text > b.text) return 1;
      if (b.text > a.text) return -1;
      return 0;
    });
  };
}
