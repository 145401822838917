import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import {
  StyledTableCell,
  StyledTableCellTotal,
  StyledTableRow,
} from "../../../app/common/table/EnhancedTableHead";
import { useStyles } from "../../../app/layout/style";
import { SalesByCustomer } from "../../../app/models/report";

interface Props {
  salesByCustomer?: SalesByCustomer[] | undefined;
}

export default observer(function ReportSalesByCustomerTable({
  salesByCustomer,
}: Props) {
  const classes = useStyles();
  let totalQty = 0;
  let count = 0;
  let templateSubTotal: JSX.Element | null = null;

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            size="medium"
            aria-label="customized table"
          >
            {salesByCustomer !== undefined && salesByCustomer?.length > 0 ? (
              <>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Customer Code</StyledTableCell>
                    <StyledTableCell>Customer Name</StyledTableCell>
                    <StyledTableCell>Item Description</StyledTableCell>
                    <StyledTableCell align="center">Item Code</StyledTableCell>
                    <StyledTableCell align="center">Unit</StyledTableCell>
                    <StyledTableCell align="right">Price</StyledTableCell>
                    <StyledTableCell align="center">Quantity</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {salesByCustomer.map((row, index) => {
                    let result = [];
                    //count all Qty
                    totalQty = totalQty + row.qty;

                    result.push(
                      <StyledTableRow key={index}>
                        <StyledTableCell>{row.customerCode}</StyledTableCell>
                        <StyledTableCell>
                          {row.customerName.toLocaleUpperCase()}
                        </StyledTableCell>
                        <StyledTableCell>{row.itemDescription}</StyledTableCell>
                        <StyledTableCell align="center">
                          {row.itemCode}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.unitDescription}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.price.toLocaleString("en-US")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.qty.toLocaleString("en-US")}
                        </StyledTableCell>
                      </StyledTableRow>
                    );

                    if (index < salesByCustomer.length - 1) {
                      const nextCustomer =
                        salesByCustomer[index + 1].customerName;
                      count++;

                      if (row.customerName !== nextCustomer) {
                        templateSubTotal = (
                          <StyledTableRow key={`${index}_${count}`}>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="right"
                              colSpan={6}
                            >
                              {`Total for "${row.customerName}" :`}
                            </StyledTableCellTotal>
                            <StyledTableCellTotal
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {totalQty.toLocaleString("en-US")}
                            </StyledTableCellTotal>
                          </StyledTableRow>
                        );
                        if (count > 1) result.push(templateSubTotal);
                        count = 0;
                        totalQty = 0;
                      }
                    } else {
                      templateSubTotal = (
                        <StyledTableRow key={`${index}_${count}`}>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="right"
                            colSpan={6}
                          >
                            {`Total for "${row.customerName}" :`}
                          </StyledTableCellTotal>
                          <StyledTableCellTotal
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {totalQty.toLocaleString("en-US")}
                          </StyledTableCellTotal>
                        </StyledTableRow>
                      );
                    }
                    return result;
                  })}
                  {count > 0 &&
                    count !== salesByCustomer.length - 1 &&
                    templateSubTotal}
                </TableBody>
              </>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell colSpan={8}>No Data.</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
});
