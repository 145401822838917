import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../../features/Dashboard/Dashboard";
import { observer } from "mobx-react-lite";
import PrivateRouteWithMenu from "./PrivateRouteWithMenu";
import NotFound from "../../features/Errors/NotFound";
import Container from "@material-ui/core/Container/Container";
import Footer from "./Footer";
import MasterMenu from "../../features/MasterMenu/MasterMenu";
import User from "../../features/Users/User";
import Store from "../../features/Stores/Store";
import StockLocation from "../../features/StockLocations/StockLocation";
import ManageMenu from "../../features/ManageMenu/ManageMenu";
import Item from "../../features/Items/Item";
import Customer from "../../features/Customers/Customer";
import LocationAccess from "../../features/LocationAccess/LocationAccess";
import PrivateRoute from "./PrivateRoute";
import ChangeProfile from "../../features/Profiles/ChangeProfile";
import StockOutgoing from "../../features/StockOutgoings/StockOutgoing";
import ReportStockAllocation from "../../features/Reports/Inventory/ReportStockAllocation";
import PurchaseOrder from "../../features/PurchaseOrders/PurchaseOrder";
import PurchaseOrderSetup from "../../features/Vendors/Vendor";
import StockControl from "../../features/StockControls/StockControl";
import POS from "../../features/Sales/POS";
import Invoice from "../../features/Sales/Invoice";
import Spending from "../../features/Spendings/Spendings";
import CashIN from "../../features/CashINs/CashIN";
import ReportCashFlow from "../../features/Reports/Finance/ReportCashFlow";
import ReportSalesSummary from "../../features/Reports/Sales/ReportSalesSummary";
import ReportSalesByCustomer from "../../features/Reports/Sales/ReportSalesByCustomer";
import ReportStockBalance from "../../features/Reports/Inventory/ReportStockBalance";
import ReportStockDetail from "../../features/Reports/Inventory/ReportStockDetail";
import ReportCustomerDeposit from "../../features/Reports/Sales/ReportCustomerDeposit";
import ReportCredit from "../../features/Reports/Sales/ReportCredit";
import ReportCreditPayment from "../../features/Reports/Finance/ReportCreditPayment";
import ReportStockHistory from "../../features/Reports/Inventory/ReportStockHistory";
import AssetLending from "../../features/AssetLendings/AssetLending";
import ReportAssetLendingBalance from "../../features/Reports/Inventory/ReportAssetLendingBalance";
import ProfitSetup from "../../features/ProfitSetups/ProfitSetup";
import ReportProfitAndLoss from "../../features/Reports/Finance/ReportProfitAndLoss";
import ReportSpending from "../../features/Reports/Finance/ReportSpending";
import ReportSalesByQuantity from "../../features/Reports/Sales/ReportSalesByQuantity";
import ReportCustomerPrice from "../../features/Reports/Sales/ReportCustomerPrice";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    width: "100%",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 3, 0, 3),
  },
}));

export default observer(function Content() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container component="main" className={classes.content} maxWidth="xl">
        <div className={classes.toolbar} />
        <Switch>
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRouteWithMenu
            path="/registeruser"
            component={User}
            link="/registeruser"
          />
          <PrivateRouteWithMenu
            path="/mastermenu"
            component={MasterMenu}
            link="/mastermenu"
          />
          <PrivateRouteWithMenu path="/store" component={Store} link="/store" />
          <PrivateRouteWithMenu
            path="/stocklocation"
            component={StockLocation}
            link="/stocklocation"
          />
          <PrivateRouteWithMenu
            path="/managemenu"
            component={ManageMenu}
            link="/managemenu"
          />
          <PrivateRouteWithMenu path="/items" component={Item} link="/items" />
          <PrivateRouteWithMenu
            path="/customers"
            component={Customer}
            link="/customers"
          />
          <PrivateRouteWithMenu
            path="/location-access"
            component={LocationAccess}
            link="/location-access"
          />
          <PrivateRouteWithMenu
            path="/stockoutgoing"
            component={StockOutgoing}
            link="/stockoutgoing"
          />
          <PrivateRouteWithMenu
            path="/report-stockallocation"
            component={ReportStockAllocation}
            link="/report-stockallocation"
          />
          <PrivateRouteWithMenu
            path="/purchaseorder"
            component={PurchaseOrder}
            link="/purchaseorder"
          />
          <PrivateRouteWithMenu
            path="/vendors"
            component={PurchaseOrderSetup}
            link="/vendors"
          />
          <PrivateRouteWithMenu
            path="/stockcontrol"
            component={StockControl}
            link="/stockcontrol"
          />
          <PrivateRouteWithMenu path="/pos" component={POS} link="/pos" />
          <PrivateRouteWithMenu
            path={"pos/:id"}
            component={StockControl}
            link={"pos/:id"}
          />
          <PrivateRouteWithMenu
            path="/invoice"
            component={Invoice}
            link="/invoice"
          />
          <PrivateRouteWithMenu
            path="/spending"
            component={Spending}
            link="/spending"
          />
          <PrivateRouteWithMenu
            path="/cash-in"
            component={CashIN}
            link="/cash-in"
          />
          <PrivateRouteWithMenu
            path="/report-cashflow"
            component={ReportCashFlow}
            link="/report-cashflow"
          />
          <PrivateRouteWithMenu
            path="/report-summary"
            component={ReportSalesSummary}
            link="/report-summary"
          />
          <PrivateRouteWithMenu
            path="/report-sales-customer"
            component={ReportSalesByCustomer}
            link="/report-sales-customer"
          />
          <PrivateRouteWithMenu
            path="/report-sales-customer"
            component={ReportSalesByCustomer}
            link="/report-sales-customer"
          />
          <PrivateRouteWithMenu
            path="/report-stock-balance"
            component={ReportStockBalance}
            link="/report-stock-balance"
          />
          <PrivateRouteWithMenu
            path="/report-stock-detail"
            component={ReportStockDetail}
            link="/report-stock-detail"
          />
          <PrivateRouteWithMenu
            path="/report-stock-history"
            component={ReportStockHistory}
            link="/report-stock-history"
          />
          <PrivateRouteWithMenu
            path="/report-customer-deposit"
            component={ReportCustomerDeposit}
            link="/report-customer-deposit"
          />
          <PrivateRouteWithMenu
            path="/report-credit"
            component={ReportCredit}
            link="/report-credit"
          />
          <PrivateRouteWithMenu
            path="/report-credit-payment"
            component={ReportCreditPayment}
            link="/report-credit-payment"
          />
          <PrivateRouteWithMenu
            path="/asset-lending"
            component={AssetLending}
            link="/asset-lending"
          />
          <PrivateRouteWithMenu
            path="/report-asset-lending-balance"
            component={ReportAssetLendingBalance}
            link="/report-asset-lending-balance"
          />
          <PrivateRouteWithMenu
            path="/profitsetup"
            component={ProfitSetup}
            link="/profitsetup"
          />
          <PrivateRouteWithMenu
            path="/report-profit-and-loss"
            component={ReportProfitAndLoss}
            link="/report-profit-and-loss"
          />
          <PrivateRouteWithMenu
            path="/report-spending"
            component={ReportSpending}
            link="/report-spending"
          />
          <PrivateRouteWithMenu
            path="/report-sales-quantity"
            component={ReportSalesByQuantity}
            link="/report-sales-quantity"
          />
          <PrivateRouteWithMenu
            path="/customer_price"
            component={ReportCustomerPrice}
            link="/customer_price"
          />
          <PrivateRoute path="/profile" component={ChangeProfile} />
          <Route component={NotFound} />
        </Switch>
      </Container>
      <Footer />
    </div>
  );
});
