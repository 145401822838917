import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { CashIN, CashINFormValues } from "../models/cashIN";
import { PaginatedResult } from "../models/pagination";
import { TableStore } from "./tableStore";

export default class CashINStore extends TableStore<CashIN> {
  documentRegistry = new Map<string, CashIN>();

  constructor() {
    super();
    makeObservable(this, {
      documentRegistry: observable,
      loadDocuments: action,
      loadDocument: action,
      documentList: computed,
      createCashIN: action,
      updateCashIN: action,
      deleteCashIN: action,
      postCashIN: action,
    });

    this.setPageNumber(1);
    this.setSortBy(null);
    this.setOrderBy("desc");
    this.sortByElement = [
      { id: "date", label: "Date" },
      { id: "documentNo", label: "Document" },
      { id: "store", label: "Store" },
      { id: "description", label: "Description" },
    ];

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadDocuments();
      }
    );
  }

  private loadDocumentProcess = (result: PaginatedResult<CashIN[]>) => {
    runInAction(() => {
      this.documentRegistry.clear();
      result.data.forEach((document) => {
        this.documentRegistry.set(document.id, document);
      });
      this.pagination = result.pagination;
    });
  };

  loadDocuments = async () => {
    this.loading = true;

    try {
      const result = await agent.CashIns.list(this.axiosParams);
      this.loadDocumentProcess(result);
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  loadDocument = async (id: string) => {
    this.loading = true;

    try {
      const result = await agent.CashIns.details(id);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get documentList() {
    return Array.from(this.documentRegistry.values());
  }

  createCashIN = async (document: CashINFormValues) => {
    try {
      await agent.CashIns.create(document);
      const result = await agent.CashIns.list(this.axiosParams);
      this.loadDocumentProcess(result);
      return "Create cash in document is success!";
    } catch (error) {
      throw error;
    }
  };

  updateCashIN = async (document: CashINFormValues) => {
    try {
      await agent.CashIns.update(document).then((document) => {
        runInAction(() => {
          this.documentRegistry.set(document.id, document);
        });
      });
      return "Update cash in document is success!";
    } catch (error) {
      throw error;
    }
  };

  deleteCashIN = async (ids: string[]) => {
    this.loading = true;

    try {
      await agent.CashIns.delete(ids);
      const result = await agent.CashIns.list(this.axiosParams);
      this.loadDocumentProcess(result);
      return "Delete cash in(s) success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  postCashIN = async (id: string) => {
    this.loading = true;

    try {
      await agent.CashIns.post(id);
      const result = await agent.CashIns.list(this.axiosParams);
      this.loadDocumentProcess(result);
      return "Document is posted!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };
}
