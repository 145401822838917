import { Customer, CustomerItem, Driver } from "./customer";
import { Documents } from "./document";
import { ItemDocument } from "./item";

export interface PaymentType {
  id: string;
  type: string;
  needAccountNo: boolean;
  isVerified: boolean;
  isMandatory: boolean;
}

export interface BankAccount {
  id: string;
  accountNo: string;
  bankName: string;
  owner: string;
}

export interface SalesByPaymentType {
  paymentTypeId: string;
  paymentType: string;
  amount: number;
}

export class InvoiceSearch {
  storeId: string = "";
  startDate: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  endDate: Date = new Date();
  customerName: string = "";
  documentNo: string = "";
  driverId: string = "";
  deliveryStatus: string = "";
  shift: string = "";

  constructor(search?: InvoiceSearch) {
    if (search) {
      this.storeId = search.storeId;
      this.startDate = search.startDate;
      this.endDate = search.endDate;
      this.customerName = search.customerName;
      this.documentNo = search.documentNo;
      this.driverId = search.driverId;
      this.deliveryStatus = search.deliveryStatus;
      this.shift = search.shift;
    }
  }
}

export interface SalesDetail extends ItemDocument {
  id: string;
  salesId?: string;
  itemId: string;
  disc: number;
  qty: number;
  price: number;
  totalAmount: number;
  totalAmountDefaultPrice: number;
}

export class SalesDetail implements SalesDetail {
  constructor(id?: string, item?: CustomerItem, salesId?: string) {
    if (id) {
      if (item) {
        this.id = id;
        this.salesId = salesId;
        this.itemId = item.id;
        this.itemDescription = item.itemDescription;
        this.itemCode = item.itemCode;
        this.unitDescription = item.unitDescription;
        this.imageUrl = item.imageUrl;
        this.disc = 0;
        this.qty = Number.NaN;
        this.price = item.price;
        this.totalAmount = Number.NaN;
      }
    }
  }
}

export interface SalesPayment {
  id: string;
  salesId: string;
  paymentType: PaymentType;
  bankAccount: BankAccount | null;
  date: Date;
  amount: number;
  inputDate: Date;
  lastUpdatedBy: string;
}

export interface Sale extends Documents {
  invoiceRef: string;
  customer: Customer;
  customerDueDate: Date;
  driver: Driver;
  storeName: string;
  tax: number;
  disc: number;
  notes: string;
  shift: string;
  isDelivered: boolean;
  details: SalesDetail[];
  payments: SalesPayment[];
  customerDeposit: number;
}

export class SalesDetailValues {
  id?: string = undefined;
  salesId?: string = "";
  itemId: string = "";
  disc?: number = undefined;
  qty?: number = undefined;
  price?: number = undefined;

  constructor(detail?: SalesDetailValues) {
    if (detail) {
      this.id = detail.id;
      this.salesId = detail.salesId;
      this.itemId = detail.itemId;
      this.disc = detail.disc;
      this.qty = detail.qty;
      this.price = detail.price;
    }
  }

  init(id?: string, item?: CustomerItem, salesId?: string) {
    if (id) {
      if (item) {
        this.id = id;
        this.salesId = salesId;
        this.itemId = item.id;
        this.disc = 0;
        this.qty = undefined;
        this.price = item.price;
      }
    }
  }
}

export class SalesPaymentValues {
  id?: string = undefined;
  salesId?: string = undefined;
  paymentTypeId: string = "";
  bankAccountId?: string = "";
  date: Date = new Date();
  amount: number = 0;
  inputDate?: Date = new Date();
  lastUpdatedBy: string = "";

  constructor(payment?: SalesPaymentValues) {
    if (payment) {
      this.id = payment.id;
      this.salesId = payment.salesId;
      this.paymentTypeId = payment.paymentTypeId;
      this.bankAccountId = payment.bankAccountId;
      this.date = new Date(payment.date);
      this.amount = payment.amount;
      this.inputDate = payment.inputDate;
      this.lastUpdatedBy = payment.lastUpdatedBy;
    }
  }
}

export class SalesFormValues {
  id?: string = undefined;
  invoiceRef: string = "";
  date: Date = new Date();
  driverId: string | null = null;
  customerId: string | null = null;
  tax?: number = undefined;
  disc?: number = undefined;
  notes: string = "";
  shift: string = "SHIFT 1";
  details: SalesDetailValues[] = [];
  payments: SalesPaymentValues[] = [];

  constructor(sales?: SalesFormValues, result?: Sale, date?: Date) {
    if (sales) {
      this.id = sales.id;
      this.invoiceRef = sales.invoiceRef;
      this.date = new Date(sales.date);
      this.driverId = sales.driverId;
      this.customerId = sales.customerId;
      this.tax = sales.tax;
      this.disc = sales.disc;
      this.notes = sales.notes;
      this.shift = sales.shift;
      this.details = sales.details;
      this.payments = sales.payments;
    }
    if (result) {
      this.id = result.id;
      this.invoiceRef = result.invoiceRef;
      this.date = result.date;
      this.driverId = result.driver.id;
      this.customerId = result.customer.id;
      this.tax = result.tax;
      this.disc = result.disc;
      this.notes = result.notes;
      this.shift = result.shift;
      this.details = result.details.map(
        (x) =>
          new SalesDetailValues({
            id: x.id,
            salesId: x.salesId,
            itemId: x.itemId,
            disc: x.disc,
            qty: x.qty,
            price: x.price,
          } as SalesDetailValues)
      );
      this.payments = result.payments.map(
        (payment) =>
          new SalesPaymentValues({
            id: payment.id,
            salesId: payment.salesId,
            paymentTypeId: payment.paymentType.id,
            bankAccountId:
              payment.bankAccount === null ? "" : payment.bankAccount.id,
            date: payment.date,
            amount: payment.amount,
            inputDate: payment.inputDate,
            lastUpdatedBy: payment.lastUpdatedBy,
          })
      );
    }
    if (date) {
      this.date = date;
    }
  }
}

export class InvoiceMassValues {
  date: Date | null = new Date();
  driverId: string | null = null;
  shift: string | null = "SHIFT 1";
  constructor(invoice?: InvoiceMassValues) {
    if (invoice) {
      this.date = invoice.date;
      this.driverId = invoice.driverId;
      this.shift = invoice.shift;
    }
  }
}
