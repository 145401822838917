import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { observer } from "mobx-react-lite";
import { useStyles } from "../../app/layout/style";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { useStore } from "../../app/stores/store";
import { v4 as uuid } from "uuid";
import { Avatar, Chip, Paper, Typography } from "@material-ui/core";
import {
  StockOutgoingDetail,
  StockOutgoingDetailValues,
  StockOutgoingFormValues,
  StockTransactionGroup,
} from "../../app/models/stockoutgoing";
import { QtyInputField } from "../../app/common/form/NumberInputField";
import StockTransactionGroupAutoComplete from "./StockTransactionGroupAutoComplete";
import { CustomerItem } from "../../app/models/customer";
import { StockLocation } from "../../app/models/location";
import { ChangeEvent } from "react";
import ItemsAutoComplete from "../Customers/ItemsAutoComplete";

interface Props {
  items: CustomerItem[];
  stockOutgoing: StockOutgoingFormValues;
  setStockOutgoing: (stockOutgoing: StockOutgoingFormValues) => void;
  details: StockOutgoingDetail[];
  setDetails: (detail: StockOutgoingDetail[]) => void;
  isSubmitting: boolean;
  loading: boolean;
  validateForm: (values?: any) => void;
  sourceStockLocation: StockLocation | undefined;
  stockLocation: StockLocation | undefined;
  valueGroup: StockTransactionGroup | null;
  setValueGroup: (valueGroup: StockTransactionGroup | null) => void;
  valueItem: CustomerItem | null;
  setValueItem: (valueItem: CustomerItem | null) => void;
  setFieldValue: (
    field: string,
    valueGroup: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export default observer(function StockOutgoingFormAddDetails({
  items,
  stockOutgoing,
  setStockOutgoing,
  details,
  setDetails,
  isSubmitting,
  loading,
  validateForm,
  sourceStockLocation,
  stockLocation,
  valueGroup,
  setValueGroup,
  valueItem,
  setValueItem,
  setFieldValue,
}: Props) {
  const classes = useStyles();
  const { stockOutgoingStore } = useStore();
  const { stockTransactionGroup } = stockOutgoingStore;

  const handleAddItems = () => {
    if (valueGroup && valueItem) {
      const id = uuid();
      let newStockOutgoing = {
        ...stockOutgoing,
        details: stockOutgoing.details.concat(
          new StockOutgoingDetailValues({
            id: id,
            stockOutgoingId: stockOutgoing.id,
            stockTransactionGroupId: valueGroup.id,
            itemId: valueItem.id,
            allocationQty:
              sourceStockLocation?.isSubsidy && valueGroup.code === "DO"
                ? undefined
                : 0,
            giQty:
              sourceStockLocation?.isSubsidy && valueGroup.code === "DO"
                ? undefined
                : 0,
          })
        ),
      };
      setStockOutgoing(newStockOutgoing);
      setDetails(
        details.concat({
          id: id,
          itemId: valueItem.id,
          itemDescription: valueItem.itemDescription,
          itemCode: valueItem.itemCode,
          unitDescription: valueItem.unitDescription,
          imageUrl: valueItem.imageUrl,
          stockTransactionGroup: valueGroup.code,
        } as StockOutgoingDetail)
      );
      setValueGroup(null);
      setValueItem(null);
    }
  };

  const handleDeleteItem = (detail: StockOutgoingDetail) => {
    setDetails(details.filter((x) => x.id !== detail.id));
    setStockOutgoing({
      ...stockOutgoing,
      details: stockOutgoing.details.filter((x) => x.id !== detail.id),
    });
    validateForm();
  };

  const handleChangeRealization = (
    e: ChangeEvent<{
      value: unknown;
    }>,
    index: number
  ) => {
    const qty = Number(e.target.value);
    if (
      stockOutgoing.details[index].stockTransactionGroupId !==
      stockTransactionGroup.find(
        (x) => sourceStockLocation?.isSubsidy && x.code === "DO"
      )?.id
    ) {
      setFieldValue(`details[${index}].allocationQty`, qty);
    }
  };

  return (
    <>
      <div className={classes.paper}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item xl={2} lg={2} md={4} xs={12}>
            <Typography variant="h6" component="p">
              Add Details
            </Typography>
          </Grid>
          <Grid item xl={4} lg={4} md={4} xs={12}>
            <StockTransactionGroupAutoComplete
              options={stockTransactionGroup.filter((x) => {
                if (sourceStockLocation?.isSubsidy) {
                  if (stockLocation?.isSPBE)
                    return x.isSPBE === stockLocation?.isSPBE;
                  else {
                    if (stockLocation?.isSubsidy)
                      return x.isSubsidy && x.isSPBE === stockLocation?.isSPBE;
                    else
                      return !x.isSubsidy && x.isSPBE === stockLocation?.isSPBE;
                  }
                } else {
                  if (stockLocation?.isSPBE)
                    return x.isSPBE === stockLocation?.isSPBE;
                  else {
                    if (stockLocation?.isSubsidy) return null;
                    else return x.isSPBE === stockLocation?.isSPBE;
                  }
                }
              })}
              value={valueGroup}
              setValue={setValueGroup}
              loading={isSubmitting || loading}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={4} xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs>
                <ItemsAutoComplete
                  name="item"
                  options={items.filter((x) => {
                    if (sourceStockLocation && valueGroup) {
                      if (sourceStockLocation?.isSubsidy) {
                        if (valueGroup.isSubsidy) return x.itemCode === "3KG";
                        else return x.itemCode === "SKIND";
                      } else {
                        if (valueGroup.isSubsidy)
                          return x.itemCode !== "3KG" &&  !x.itemCode.includes("(K)") && x.itemCode !== "SKIND";
                        else return x.itemCode === "SKIND";
                      }
                    }
                    return null;
                  })}
                  value={valueItem}
                  setValue={setValueItem}
                  loading={isSubmitting || loading}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Add Items">
                  <IconButton size="small" onClick={handleAddItems}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {details.map((detail, index) => (
        <Paper key={index} className={classes.itemCard} variant="outlined">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item lg md xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Chip
                    variant="outlined"
                    color="primary"
                    label={detail.stockTransactionGroup}
                  />
                </Grid>
                <Grid item>
                  <Avatar
                    alt={detail.itemCode}
                    src={detail.imageUrl ? detail.imageUrl : "/no-image.png"}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" component="p">
                    {detail.itemDescription}
                  </Typography>
                  <Typography variant="caption" component="p">
                    {detail.unitDescription}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2} md xs={6}>
              <QtyInputField
                variant="outlined"
                label="Allocation"
                name={`details[${index}].allocationQty`}
                margin="dense"
                andorment={detail.unitDescription}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={
                  isSubmitting ||
                  loading ||
                  !sourceStockLocation?.isSubsidy ||
                  detail.stockTransactionGroup !== "DO"
                }
                autoFocus={
                  sourceStockLocation?.isSubsidy &&
                  detail.stockTransactionGroup === "DO"
                }
              />
            </Grid>
            <Grid item lg={2} md xs={6}>
              <QtyInputField
                variant="outlined"
                label="Realization"
                name={`details[${index}].realizationQty`}
                margin="dense"
                andorment={detail.unitDescription}
                onChange={(e) => handleChangeRealization(e, index)}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={isSubmitting || loading}
                autoFocus={
                  !sourceStockLocation?.isSubsidy ||
                  detail.stockTransactionGroup !== "DO"
                }
              />
            </Grid>
            <Grid item lg={2} md xs={6}>
              <QtyInputField
                variant="outlined"
                label="GI"
                name={`details[${index}].giQty`}
                margin="dense"
                andorment={detail.unitDescription}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={
                  isSubmitting ||
                  loading ||
                  !sourceStockLocation?.isSubsidy ||
                  detail.stockTransactionGroup !== "DO"
                }
              />
            </Grid>
            <Grid item>
              <Tooltip title="Delete Item">
                <IconButton
                  size="small"
                  onClick={() => handleDeleteItem(detail)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </>
  );
});
