import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import {
  Customer,
  CustomerDueDate,
  CustomerFormValues,
  CustomerGranted,
  CustomerItem,
  Driver,
} from "../models/customer";
import { PaginatedResult } from "../models/pagination";
import { TableStore } from "./tableStore";

export default class CustomerStore extends TableStore<Customer> {
  customerRegistry = new Map<string, Customer>();
  customerGranted: CustomerGranted[] = [];
  drivers: Driver[] = [];
  customerDueDate: CustomerDueDate[] = [];

  constructor() {
    super();
    makeObservable(this, {
      customerRegistry: observable,
      customerGranted: observable,
      drivers: observable,
      customerDueDate: observable,
      loadCustomers: action,
      loadCustomer: action,
      loadGrantedCustomer: action,
      customerList: computed,
      createCustomer: action,
      updateCustomer: action,
      deleteCustomer: action,
      sortDetails: action,
      loadDrivers: action,
      loadCustomerDueDate: action,
    });

    this.setPageNumber(1);
    this.setSortBy(null);
    this.setOrderBy("asc");
    this.sortByElement = [
      { id: "customerCode", label: "Customer Code" },
      { id: "customerName", label: "Customer Name" },
      { id: "storeName", label: "Store Name" },
    ];

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadCustomers();
      }
    );
  }

  private loadCustomerProcess = (result: PaginatedResult<Customer[]>) => {
    runInAction(() => {
      this.customerRegistry.clear();
      result.data.forEach((customer) => {
        let customerResult = {
          ...customer,
          items: this.sortDetails(customer.items),
        };
        this.customerRegistry.set(customer.id, customerResult);
      });
      this.pagination = result.pagination;
    });
  };

  loadCustomers = async () => {
    this.loading = true;

    try {
      const result = await agent.Customers.list(this.axiosParams);
      this.loadCustomerProcess(result);
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  get customerList() {
    return Array.from(this.customerRegistry.values());
  }

  generateCode = async () => {
    this.loading = true;

    try {
      const result = await agent.Customers.generateCode();
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  loadCustomer = async (id: string) => {
    this.loading = true;

    try {
      const result = await agent.Customers.details(id);
      return {
        ...result,
        items: this.sortDetails(result.items),
      };
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  loadGrantedCustomer = async () => {
    this.loading = true;

    try {
      const result = await agent.Customers.granted();
      runInAction(() => {
        this.customerGranted = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  createCustomer = async (customer: CustomerFormValues) => {
    try {
      if (customer.items) {
        for await (const x of customer.items) {
          x.customerId = customer.id;
        }
      }
      await agent.Customers.create(customer);
      const result = await agent.Customers.list(this.axiosParams);
      this.loadCustomerProcess(result);
      return "Create customer success!";
    } catch (error) {
      throw error;
    }
  };

  updateCustomer = async (customer: CustomerFormValues) => {
    try {
      const result = await agent.Customers.update(customer);
      runInAction(() => {
        let customerResult = {
          ...result,
          items: this.sortDetails(result.items),
        };
        this.customerRegistry.set(result.id, customerResult);
      });
      return "Update customer success!";
    } catch (error) {
      throw error;
    }
  };

  deleteCustomer = async (ids: string[]) => {
    this.loading = true;

    try {
      await agent.Customers.delete(ids);
      const result = await agent.Customers.list(this.axiosParams);
      this.loadCustomerProcess(result);
      return "Delete customer(s) success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  sortDetails = (details: CustomerItem[]) => {
    return details.sort((a, b) => {
      if (a.itemCode > b.itemCode) return 1;
      if (b.itemCode > a.itemCode) return -1;
      return 0;
    });
  };

  loadDrivers = async () => {
    this.setLoading(true);

    try {
      const result = await agent.Customers.drivers();
      runInAction(() => {
        this.drivers = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  loadCustomerDueDate = async () => {
    this.setLoading(true);

    try {
      const result = await agent.Customers.dueDate();
      runInAction(() => {
        this.customerDueDate = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };
}
