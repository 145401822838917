import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { observer } from "mobx-react-lite";
import React from "react";
import EnhancedTableHead from "../../app/common/table/EnhancedTableHead";
import EnhancedTableSkeleton from "../../app/common/table/EnhancedTableSkeleton";
import EnhancedTableToolbar from "../../app/common/table/EnhancedTableToolbar";
import DeleteDialog from "../../app/layout/DeleteDialog";
import PostDialog from "../../app/layout/PostDialog";
import { useStyles } from "../../app/layout/style";
import { Spending } from "../../app/models/spending";
import { HeadCell } from "../../app/models/table";
import { useStore } from "../../app/stores/store";
import SpendingsTableRow from "./SpendingsTableRow";
import { Button } from "@material-ui/core";

export default observer(function SpendingsTable() {
  const classes = useStyles();
  const { spendingStore, snackbarStore, dialogStore } = useStore();
  const {
    pageNumber,
    pageSize,
    documentList,
    loading,
    deleteSpending,
    postSpending,
    massPostSpending,
    setPageNumber,
    setPageSize,
    pagination,
    setSortBy,
    setOrderBy,
    sortBy,
    orderBy,
    rowsOptions,
    selectedItem,
    setSelectedItem,
    selectItem,
    isSelected,
  } = spendingStore;
  const { openSnackbar } = snackbarStore;
  const { openDialog, openDialogDelete, closeDialog } = dialogStore;
  const headCells: HeadCell<Spending>[] = [
    {
      id: "date",
      align: "left",
      disablePadding: false,
      label: "Date",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "documentNo",
      align: "left",
      disablePadding: false,
      label: "Document",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "store",
      align: "left",
      disablePadding: false,
      label: "Store",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "spendingGroup",
      align: "left",
      disablePadding: false,
      label: "Group",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "description",
      align: "left",
      disablePadding: false,
      label: "Description",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "amount",
      align: "right",
      disablePadding: false,
      label: "Amount",
      disableSort: true,
      skeletonShape: "text",
    },
    {
      id: "posted",
      align: "center",
      disablePadding: false,
      label: "",
      disableSort: false,
      skeletonShape: "rect",
    },
  ];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    if (sortBy === property) setOrderBy(orderBy === "asc" ? "desc" : "asc");
    else setSortBy(property as keyof Spending);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (selectedItem.length === 0) {
        const newSelecteds = documentList
          .filter((x) => !x.posted)
          .map((document) => document.id);
        setSelectedItem(newSelecteds);
        return;
      }
    }
    setSelectedItem([]);
  };

  const handleClickPost = (
    event: React.MouseEvent<unknown>,
    id: string,
    posted: boolean
  ) => {
    openDialogDelete(
      <PostDialog
        title={`Are you sure to ${posted ? "unpost" : "post"} this document`}
        id={id}
      />,
      handlePost
    );
  };

  const handlePost = (id: string) => {
    postSpending(id)
      .catch((error) => {
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        closeDialog();
      });
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    selectItem(id);
  };

  const handleClickDelete = (event: React.MouseEvent<unknown>) => {
    openDialog(
      <DeleteDialog title={"Are you sure want to delete this document(s)"} />,
      "xs",
      handleDelete
    );
  };

  const handleDelete = () => {
    deleteSpending(selectedItem)
      .catch((error) => {
        setSelectedItem([]);
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        setSelectedItem([]);
        closeDialog();
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageNumber(1);
    setPageSize(parseInt(event.target.value, 10));
  };

  const handleClickMassPost = (event: React.MouseEvent<unknown>) => {
    openDialog(
      <DeleteDialog title={"Are you sure to post this document(s)"} />,
      "xs",
      handleMassPost
    );
  };

  const handleMassPost = () => {
    massPostSpending(selectedItem)
      .catch((error) => {
        setSelectedItem([]);
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        setSelectedItem([]);
        closeDialog();
      });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          title="Document List"
          numSelected={selectedItem.length}
          handleDelete={handleClickDelete}
          linkAddNew="/spending/form"
          loading={loading}
        >
          <Button
            onClick={handleClickMassPost}
            variant="contained"
            color={"primary"}
          >
            Post
          </Button>
        </EnhancedTableToolbar>
        <TableContainer>
          <Table className={classes.table} size="medium" component="table">
            <EnhancedTableHead
              headCells={headCells}
              numSelected={selectedItem.length}
              orderBy={orderBy}
              sortBy={sortBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={documentList.length}
              loading={loading}
              data={documentList}
            />
            <TableBody>
              {loading ? (
                <EnhancedTableSkeleton row={6} cellArray={headCells} />
              ) : (
                documentList.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <SpendingsTableRow
                      key={index}
                      row={row}
                      labelId={labelId}
                      isItemSelected={isItemSelected}
                      handleClick={handleClick}
                      handlePost={handleClickPost}
                    />
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsOptions}
          component="div"
          count={pagination === null ? 0 : pagination.totalItems}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
});
