import { Grid, Button, createStyles, makeStyles } from "@material-ui/core";
import { useState } from "react";
import Filter from "../../app/common/filter/Filter";
import TodayIcon from "@material-ui/icons/Today";
import SearchIcon from "@material-ui/icons/Search";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { DatePicker } from "@material-ui/pickers";

const useLocalStyles = makeStyles(() =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        width: "25ch",
      },
    },
  })
);

export default observer(function FilterDashboard() {
  const classes = useLocalStyles();
  const { dashboardStore } = useStore();
  const { filter, setDate } = dashboardStore;
  const [search, SetSearch] = useState(filter);
  const [open, setOpen] = useState(false);

  const handleSetSearch = () => {
    setDate(filter);
  };

  const handleDateChange = (date: any) => {
    SetSearch(date);
  };

  const handleSearchClick = () => {
    setDate(search);
  };

  return (
    <Filter open={open} setOpen={setOpen} setSearch={handleSetSearch}>
      <div className={classes.root}>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <TodayIcon />
          </Grid>
          <Grid item>
            <DatePicker
              format="dd-MMM-yyyy"
              inputVariant="outlined"
              margin="dense"
              label="Value"
              value={search}
              onChange={handleDateChange}
              variant="inline"
              autoOk
              disableToolbar
              disableFuture
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearchClick}
            >
              <SearchIcon />
            </Button>
          </Grid>
        </Grid>
      </div>
    </Filter>
  );
});
