import { createContext, useContext } from "react";
import CashINStore from "./cashINStore";
import CommonStore from "./commonStore";
import CustomerStore from "./customerStore";
import DialogStore from "./dialogStore";
import CashFlowStore from "./cashFlowStore";
import StockAllocationStore from "./stockAllocationStore";
import ItemStore from "./itemStore";
import LocationAccessRightStore from "./locationAccessRightStore";
import ManageMenuStore from "./manageStore";
import MenuStore from "./menuStore";
import PurchaseOrderStore from "./purchaseOrderStore";
import SalesStore from "./salesStore";
import SnackbarStore from "./snackbarStore";
import SpendingStore from "./spendingStore";
import StockControlStore from "./stockControlStore";
import StockLocationStore from "./stockLocationStore";
import StockOutgoingStore from "./stockOutgoingStore";
import StoreStore from "./storeStore";
import SummaryStore from "./salesSummaryStore";
import TransferListStore from "./transferListStore";
import UserStore from "./userStore";
import VendorStore from "./vendorStore";
import SalesByCustomerStore from "./salesByCustomerStore";
import StockBalanceStore from "./stockBalanceStore";
import StockDetailStore from "./stockDetailStore";
import CustomerDepositStore from "./customerDepositStore";
import CreditStore from "./creditStore";
import CreditPaymentStore from "./creditPaymentStore";
import DashboardStore from "./dashboardStore";
import StockHistoryStore from "./stockHistoryStore";
import AssetLendingStore from "./assetLendingStore";
import AssetLendingBalanceStore from "./assetLendingBalanceStore";
import ProfitSetupStore from "./profitSetupStore";
import ProfitAndLossStore from "./profitAndLossStore";
import SpendingListStore from "./spendingListStore";
import SalesByQuantityStore from "./salesByQuantityStore";
import CustomerPriceStore from "./customerPriceStore";

interface Store {
  menuStore: MenuStore;
  transferListStore: TransferListStore;
  snackbarStore: SnackbarStore;
  dialogStore: DialogStore;
  userStore: UserStore;
  commonStore: CommonStore;
  stockLocationStore: StockLocationStore;
  storeStore: StoreStore;
  manageMenuStore: ManageMenuStore;
  itemStore: ItemStore;
  customerStore: CustomerStore;
  locationAccessRightStore: LocationAccessRightStore;
  stockOutgoingStore: StockOutgoingStore;
  vendorStore: VendorStore;
  purchaseOrderStore: PurchaseOrderStore;
  stockControlStore: StockControlStore;
  salesStore: SalesStore;
  spendingStore: SpendingStore;
  cashINStore: CashINStore;
  stockAllocationStore: StockAllocationStore;
  cashFlowStore: CashFlowStore;
  summaryStore: SummaryStore;
  salesByCustomerStore: SalesByCustomerStore;
  stockBalanceStore: StockBalanceStore;
  stockDetailStore: StockDetailStore;
  customerDepositStore: CustomerDepositStore;
  creditStore: CreditStore;
  creditPaymentStore: CreditPaymentStore;
  dashboardStore: DashboardStore;
  stockHistoryStore: StockHistoryStore;
  assetLendingStore: AssetLendingStore;
  assetLendingBalanceStore: AssetLendingBalanceStore;
  profitSetupStore: ProfitSetupStore;
  profitAndLossStore: ProfitAndLossStore;
  spendingListStore: SpendingListStore;
  salesByQuantityStore: SalesByQuantityStore;
  customerPriceStore: CustomerPriceStore;
}

export const store: Store = {
  menuStore: new MenuStore(),
  transferListStore: new TransferListStore(),
  snackbarStore: new SnackbarStore(),
  dialogStore: new DialogStore(),
  userStore: new UserStore(),
  commonStore: new CommonStore(),
  stockLocationStore: new StockLocationStore(),
  storeStore: new StoreStore(),
  manageMenuStore: new ManageMenuStore(),
  itemStore: new ItemStore(),
  customerStore: new CustomerStore(),
  locationAccessRightStore: new LocationAccessRightStore(),
  stockOutgoingStore: new StockOutgoingStore(),
  vendorStore: new VendorStore(),
  purchaseOrderStore: new PurchaseOrderStore(),
  stockControlStore: new StockControlStore(),
  salesStore: new SalesStore(),
  spendingStore: new SpendingStore(),
  cashINStore: new CashINStore(),
  stockAllocationStore: new StockAllocationStore(),
  cashFlowStore: new CashFlowStore(),
  summaryStore: new SummaryStore(),
  salesByCustomerStore: new SalesByCustomerStore(),
  stockBalanceStore: new StockBalanceStore(),
  stockDetailStore: new StockDetailStore(),
  customerDepositStore: new CustomerDepositStore(),
  creditStore: new CreditStore(),
  creditPaymentStore: new CreditPaymentStore(),
  dashboardStore: new DashboardStore(),
  stockHistoryStore: new StockHistoryStore(),
  assetLendingStore: new AssetLendingStore(),
  assetLendingBalanceStore: new AssetLendingBalanceStore(),
  profitSetupStore: new ProfitSetupStore(),
  profitAndLossStore: new ProfitAndLossStore(),
  spendingListStore: new SpendingListStore(),
  salesByQuantityStore: new SalesByQuantityStore(),
  customerPriceStore: new CustomerPriceStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}

export default function killAllRegistry() {
  store.menuStore.menuRegistry.clear();
  store.transferListStore.itemsChecked = [];
  store.userStore.userRegistry.clear();
  store.stockLocationStore.stockLocationRegistry.clear();
  store.storeStore.storeRegistry.clear();
  store.manageMenuStore.menus = [];
  store.manageMenuStore.menusGranted = [];
  store.itemStore.itemsRegistry.clear();
  store.customerStore.customerRegistry.clear();
  store.locationAccessRightStore.usersStore = [];
  store.locationAccessRightStore.usersStoreGranted = [];
  store.locationAccessRightStore.usersStockLocation = [];
  store.locationAccessRightStore.usersStockLocationGranted = [];
  store.stockOutgoingStore.documentRegistry.clear();
  store.vendorStore.vendorRegistry.clear();
  store.purchaseOrderStore.documentRegistry.clear();
  store.stockControlStore.documentRegistry.clear();
  store.salesStore.documentRegistry.clear();
  store.spendingStore.documentRegistry.clear();
  store.cashINStore.documentRegistry.clear();
  store.stockAllocationStore.stockAllocationRegistry.clear();
  store.cashFlowStore.cashflowRegistry.clear();
  store.summaryStore.salesSummaryRegistry.clear();
  store.salesByCustomerStore.salesByCustomerRegistry.clear();
  store.stockBalanceStore.stockBalanceRegistry.clear();
  store.stockDetailStore.stockDetailRegistry = [];
  store.stockHistoryStore.stockHistoryRegistry.clear();
  store.customerDepositStore.customerDepositRegistry = [];
  store.creditStore.creditRegistry.clear();
  store.creditPaymentStore.creditPaymentRegistry.clear();
  store.dashboardStore.info = undefined;
  store.dashboardStore.revenueAndCredit = [];
  store.dashboardStore.paymentChart = [];
  store.assetLendingStore.documentRegistry.clear();
  store.assetLendingBalanceStore.assetLendingBalanceRegistry = [];
  store.assetLendingBalanceStore.totalAssetLendingBalance.clear();
  store.profitSetupStore.profitSetupRegistry.clear();
  store.profitAndLossStore.profitRegistry.clear();
  store.profitAndLossStore.lossRegistry.clear();
  store.profitAndLossStore.spendingRegistry.clear();
  store.profitAndLossStore.creditRegistry.clear();
  store.spendingListStore.spendingRegistry.clear();
  store.salesByQuantityStore.salesByQuantityRegistry.clear();
}
