import {
  Card,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  CardContent,
  Grid,
  Divider,
  Chip,
  Avatar,
  Typography,
  Button,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { AssetLending } from "../../app/models/assetLending";

interface Props {
  document: AssetLending;
  deleteDocument: (id: string) => void;
  handlePost: (
    event: React.MouseEvent<unknown>,
    id: string,
    posted: boolean
  ) => void;
}

export default observer(function AssetLendingCard(props: Props) {
  const classes = useStyles();
  const { userStore } = useStore();
  const { restricted } = userStore;
  const { document, deleteDocument, handlePost } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = (id: string) => {
    setAnchorEl(null);
    deleteDocument(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card>
      <CardHeader
        action={
          <>
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                disabled={document.posted}
                component={Link}
                to={`/asset-lending/${document.id}`}
              >
                Update
              </MenuItem>
              <MenuItem
                disabled={document.posted}
                onClick={() => handleDelete(document.id)}
              >
                Delete
              </MenuItem>
            </Menu>
          </>
        }
        title={document.documentNo}
        subheader={moment(document.date).format("MMMM DD, YYYY")}
      />
      <CardContent>
        <Typography variant="subtitle1" component="h5">
          {document.customer.fullName}
        </Typography>
        <Divider />
        {document.details.map((detail, index) => (
          <div className={classes.paperCard} key={index}>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <Chip
                  variant="outlined"
                  color="primary"
                  label={detail.stockTransactionType.type}
                />
              </Grid>
              <Grid item>
                <Avatar
                  alt={detail.itemCode}
                  src={detail.imageUrl ? detail.imageUrl : "/no-image.png"}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle1" component="p">
                  {detail.itemDescription}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" align="right" component="p">
                  {detail.qty.toLocaleString("en-US")} {detail.unitDescription}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </div>
        ))}
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            {restricted(document.date) ? (
              <Chip variant="outlined" color="secondary" label="Posted" />
            ) : (
              <Button
                onClick={(e) => handlePost(e, document.id, document.posted)}
                variant="outlined"
                color={document.posted ? "secondary" : "primary"}
              >
                {document.posted ? "Unpost" : "Post"}
              </Button>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});
