import {
  Card,
  CardContent,
  Typography,
  CardHeader,
  CardActions,
  IconButton,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useStyles } from "../../app/layout/style";

export default function InvoiceCardSkeleton() {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        action={
          <Skeleton animation="wave" variant="circle" width={40} height={40} />
        }
        title={<Skeleton animation="wave" width="80%" />}
        subheader={<Skeleton animation="wave" width="40%" />}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          <Skeleton width="50%" />
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <Skeleton width="80%" />
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <Skeleton width="50%" />
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Skeleton animation="wave" variant="circle" width={40} height={40} />
        <IconButton className={classes.expand}>
          <Skeleton animation="wave" variant="circle" width={40} height={40} />
        </IconButton>
      </CardActions>
    </Card>
  );
}