import Paper from "@material-ui/core/Paper";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Dropdown from "../../app/common/form/Dropdown";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CashINFormValues } from "../../app/models/cashIN";
import InputField from "../../app/common/form/InputField";
import PriceInputField from "../../app/common/form/NumberInputField";

export default observer(function CashINForm() {
  const classes = useStyles();
  const { cashINStore, snackbarStore, userStore } = useStore();
  const { loading, loadDocument, createCashIN, updateCashIN } = cashINStore;
  const { storeGranted } = userStore;
  const { openSnackbar } = snackbarStore;
  const [cashIN, setCashin] = useState<CashINFormValues>(
    new CashINFormValues()
  );
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      loadDocument(id).then((result) => {
        setCashin(new CashINFormValues(result));
      });
    }
  }, [id, loadDocument]);

  const schema = Yup.object({
    date: Yup.date().required("Date is required."),
    storeId: Yup.string().required("Store field is required."),
    description: Yup.string().required("Cash in description is required."),
    amount: Yup.number()
      .typeError("Cash in amount is must be a number.")
      .positive("Cash in amount is must be greater than zero.")
      .required("Cash in amount is required."),
  });

  const handleFormSubmit = (
    cashIN: CashINFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!cashIN.id) {
      let newCashIN = {
        ...cashIN,
        id: uuid(),
      };
      createCashIN(newCashIN)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setCashin({
              ...cashIN,
              description: "",
              amount: parseInt(""),
            });
            resetForm();
          }
        });
    } else {
      updateCashIN(cashIN)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/cash-in");
          }
        });
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.form}>
            <Typography variant="h5">
              Form {id ? "Update" : "Create"}
            </Typography>
            <Divider />
            <Formik
              validationSchema={schema}
              enableReinitialize
              initialValues={cashIN}
              onSubmit={(values, { resetForm, setSubmitting }) =>
                handleFormSubmit(values, resetForm, setSubmitting)
              }
            >
              {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <Dropdown
                    options={storeGranted.map((x) => {
                      return { text: x.name, value: x.id };
                    })}
                    name="storeId"
                    placeholder="Store"
                    label="Store"
                    disabled={isSubmitting || loading}
                  />
                  <InputField
                    variant="standard"
                    label="Cash-IN Description"
                    name="description"
                    multiline
                    minRows={4}
                    placeholder="Please input spending description"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loading}
                  />
                  <PriceInputField
                    variant="standard"
                    label="Cash-IN Amount"
                    name="amount"
                    margin="normal"
                    disabled={isSubmitting || loading}
                  />
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="stretch"
                    spacing={1}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting || !isValid || !dirty}
                      >
                        {isSubmitting && (
                          <CircularProgress
                            className={classes.progress}
                            size={16}
                            color="inherit"
                          />
                        )}
                        Save
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="default"
                        component={Link}
                        to="/cash-in"
                        disabled={isSubmitting || loading}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
});
