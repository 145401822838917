import {
  Avatar,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../app/common/table/EnhancedTableHead";
import { useStyles } from "../../../app/layout/style";
import {
  ProfitAndLoss,
  SpendingGroupReport,
  Credit,
} from "../../../app/models/report";

interface Props {
  profit: ProfitAndLoss[] | undefined;
  loss: ProfitAndLoss[] | undefined;
  spending: SpendingGroupReport[] | undefined;
  credit: Credit[] | undefined;
}

export default observer(function ReportProfitAndLossTable({
  profit,
  loss,
  spending,
  credit,
}: Props) {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Paper className={classes.paperOnlyBottom}>
            <TableContainer>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={5}>Gross Profit</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {profit !== undefined && profit?.length > 0 ? (
                    profit?.map((row, index) => (
                      <React.Fragment key={`profit_${index}`}>
                        <TableRow>
                          <TableCell>
                            <Avatar
                              alt={row.itemCode}
                              src={
                                row.imageUrl ? row.imageUrl : "/no-image.png"
                              }
                            />
                          </TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell>{row.itemDescription}</TableCell>
                          <TableCell align="right">
                            {` ${row.qty.toLocaleString("EN-US")} ${
                              row.unitDescription
                            } x ${row.amount.toLocaleString("EN-US")}`}
                          </TableCell>
                          <TableCell align="right">
                            {row.totalAmount.toLocaleString("EN-US")}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}>No Data.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paperOnlyBottom}>
            <TableContainer>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={5}>Loss</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loss !== undefined && loss?.length > 0 ? (
                    loss?.map((row, index) => (
                      <React.Fragment key={`loss_${index}`}>
                        <TableRow>
                          <TableCell>
                            <Avatar
                              alt={row.itemCode}
                              src={
                                row.imageUrl ? row.imageUrl : "/no-image.png"
                              }
                            />
                          </TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell>{row.itemDescription}</TableCell>
                          <TableCell align="right">
                            {` ${row.qty.toLocaleString("EN-US")} ${
                              row.unitDescription
                            } x ${row.amount.toLocaleString("EN-US")}`}
                          </TableCell>
                          <TableCell align="right">
                            {row.totalAmount.toLocaleString("EN-US")}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={5}>No Data.</StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper className={classes.paperOnlyBottom}>
            <TableContainer>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={2}>Spending</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {spending !== undefined && spending?.length > 0 ? (
                    spending?.map((row, index) => (
                      <React.Fragment key={`spending_${index}`}>
                        <TableRow>
                          <TableCell>{row.group}</TableCell>
                          <TableCell align="right">
                            {`IDR ${row.amount.toLocaleString("EN-US")}`}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={2}>No Data.</StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper className={classes.paperOnlyBottom}>
            <TableContainer>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={3}>Customer Debt</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {credit !== undefined && credit?.length > 0 ? (
                    credit?.map((row, index) => (
                      <React.Fragment key={`credit_${index}`}>
                        <TableRow>
                          <TableCell>{row.customerName}</TableCell>
                          <TableCell>{row.customerCode}</TableCell>
                          <TableCell align="right">
                            {`IDR ${row.amount.toLocaleString("EN-US")}`}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={3}>No Data.</StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
});
