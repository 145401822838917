import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import Filter from "../../../app/common/filter/Filter";
import DateInputField from "../../../app/common/form/DateInputFIeld";
import Dropdown2 from "../../../app/common/form/Dropdown2";
import { useStyles } from "../../../app/layout/style";
import Title from "../../../app/layout/Title";
import { StockBalanceSearch } from "../../../app/models/report";
import { useStore } from "../../../app/stores/store";
import ReportStockBalanceCard from "./ReportStockBalanceCard";
import ReportStockBalanceSkeleton from "./ReportStockBalanceSkeleton";

export default observer(function ReportStockBalance() {
  const classes = useStyles();
  const { userStore, stockBalanceStore } = useStore();
  const { stockLocationGranted } = userStore;
  const {
    setPredicate,
    loadReportStockBalance,
    stockLocationList,
    stockBalanceRegistry,
    loading,
    filter,
    skeletonCardArray,
  } = stockBalanceStore;
  const [search, setSearch] = useState(new StockBalanceSearch());
  const [open, setOpen] = useState(false);
  let stockLocation = useMemo(
    () => stockLocationGranted.filter((x) => !x.isSPBE),
    [stockLocationGranted]
  );

  useEffect(() => {
    loadReportStockBalance();
  }, [loadReportStockBalance]);

  const handleSetSearch = () => {
    setSearch(filter);
  };

  const handleSearchClick = (values: StockBalanceSearch) => {
    setPredicate(values);
  };

  return (
    <>
      <Title menuName="Report Stock Balance">
        <Filter open={open} setOpen={setOpen} setSearch={handleSetSearch}>
          <div className={classes.filter}>
            <Formik
              enableReinitialize
              initialValues={search}
              onSubmit={(values) => handleSearchClick(values)}
            >
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <Grid
                    container
                    direction="column"
                    alignItems="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Dropdown2
                        name="stockLocationId"
                        options={stockLocation.map((x) => {
                          return { text: x.name, value: x.id };
                        })}
                        label="Stock Location"
                        variant="outlined"
                        margin="dense"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        titleDefaultSelected="All Selected"
                        displayDefaultSelected
                      />
                    </Grid>
                    <Grid item>
                      <DateInputField
                        label="Date"
                        name="date"
                        placeholder="Please input Date"
                        inputVariant="outlined"
                        margin="dense"
                      />
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit">
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </Filter>
      </Title>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.paperOnlyBottom}>
            <Grid container spacing={1}>
              {loading
                ? skeletonCardArray().map((item, index) => (
                    <Grid key={index} item xl={3} lg={4} md={6} sm={6} xs={12}>
                      <ReportStockBalanceSkeleton />
                    </Grid>
                  ))
                : stockBalanceRegistry.size > 0 &&
                  stockLocationList.map((location) => (
                    <Grid item xl={3} lg={4} md={4} xs={12} key={location.id}>
                      <ReportStockBalanceCard
                        stockLocation={location}
                        stockBalance={stockBalanceRegistry.get(location)}
                      />
                    </Grid>
                  ))}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
});
