import { Divider, Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStyles } from "../../app/layout/style";
import PaymentTable from "./PaymentTable";
import PaymentChart from "./PaymentChart";

export default observer(function Payment() {
  const classes = useStyles();

  return (
    <div className={classes.dashboard_payment}>
      <Typography variant="subtitle2" component="h6">
        Payment
      </Typography>
      <Divider />
      <Grid container direction="row" spacing={1}>
        <Grid item lg={6} md={12} xs={12}>
          <PaymentChart />
        </Grid>
        <Grid item lg={6} md={12} xs={12}>
          <PaymentTable />
        </Grid>
      </Grid>
    </div>
  );
});
