import { observer } from "mobx-react-lite";
import React from "react";
import { Switch } from "react-router";
import PrivateRouteWithMenu from "../../app/layout/PrivateRouteWithMenu";
import MasterMenuForm from "./MasterMenuForm";
import Title from "../../app/layout/Title";
import MasterMenuList from "./MasterMenuList";
import { useRouteMatch } from "react-router-dom";

export default observer(function MasterMenu() {
  let { path } = useRouteMatch();

  return (
    <>
      <Title menuName="Master Menu" />
      <Switch>
        <PrivateRouteWithMenu
          exact
          path={path}
          component={MasterMenuList}
          link={path}
        />
        <PrivateRouteWithMenu
          path={`${path}/form`}
          component={MasterMenuForm}
          link={path}
        />
        <PrivateRouteWithMenu
          path={`${path}/:id`}
          component={MasterMenuForm}
          link={path}
        />
      </Switch>
    </>
  );
});
