import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { StockLocation, Store } from "../models/location";
import { Pagination } from "../models/pagination";
import { Order, SortBy } from "../models/table";
import { store } from "./store";

export class TableStore<T> {
  predicate = new Map<string, string>();
  pagination: Pagination | null = null;
  selectedItem: string[] = [];
  rowsOptions: number[] = [];
  sortBy: Extract<keyof T, string> | null = null;
  orderBy: Order = "desc";
  sortByElement: SortBy<T>[] = [];
  loading = false;

  constructor() {
    makeObservable(this, {
      predicate: observable,
      pagination: observable,
      selectedItem: observable,
      rowsOptions: observable,
      sortBy: observable,
      orderBy: observable,
      sortByElement: observable,
      loading: observable,
      getComparator: action,
      stableSort: action,
      filter: computed,
      pageNumber: computed,
      pageSize: computed,
      setLoading: action,
      axiosParams: computed,
      setSelectedItem: action,
      selectItem: action,
      isSelected: action,
      setPageNumber: action,
      setPageSize: action,
      setSortBy: action,
      setOrderBy: action,
      setRowsOptions: action,
      initRowsOptionsAndPageSize: action,
      initCardRowsOptionsAndPageSize: action,
      storeParams: action,
      stockLocationParams: action,
    });
  }

  private descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  getComparator = <Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): ((a: { [key in Key]: any }, b: { [key in Key]: any }) => number) => {
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => this.descendingComparator(b, a, orderBy);
  };

  stableSort = <T>(array: T[], comparator: (a: T, b: T) => number) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  setPredicate = action((value: any) => {
    runInAction(() => {
      this.predicate.delete("search");
      this.predicate.set("search", value);
      this.predicate.delete("pageNumber");
      this.predicate.set("pageNumber", "1");
    });
  });

  get filter() {
    return this.predicate.get("search") === undefined
      ? ""
      : (this.predicate.get("search") as any);
  }

  get axiosParams() {
    const params = new URLSearchParams();
    this.predicate.forEach((value, key) => {
      params.delete(key);
      params.append(key, value);
    });
    return params;
  }

  setSelectedItem = (item: string[]) => (this.selectedItem = item);

  selectItem = (id: string) => {
    const selectedIndex = this.selectedItem.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.selectedItem, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.selectedItem.slice(1));
    } else if (selectedIndex === this.selectedItem.length - 1) {
      newSelected = newSelected.concat(this.selectedItem.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.selectedItem.slice(0, selectedIndex),
        this.selectedItem.slice(selectedIndex + 1)
      );
    }
    this.setSelectedItem(newSelected);
  };

  isSelected = (id: string) => this.selectedItem.indexOf(id) !== -1;

  setPageNumber = (value: number) => {
    this.predicate.delete("pageNumber");
    this.predicate.set("pageNumber", value.toString());
  };

  setPageSize = (value: number) => {
    this.predicate.delete("pageSize");
    this.predicate.set("pageSize", value.toString());
  };

  setSortBy = (value: Extract<keyof T, string> | null) => {
    if (value !== null) {
      this.predicate.delete("sortBy");
      this.predicate.set("sortBy", value.toString());
      this.sortBy = value;
    }
  };

  setOrderBy = (value: Order) => {
    this.predicate.delete("orderBy");
    this.predicate.set("orderBy", value);
    this.orderBy = value;
  };

  setRowsOptions = (value: number[]) => {
    this.rowsOptions = value;
  };

  get pageNumber() {
    return Number(this.predicate.get("pageNumber")) - 1;
  }

  get pageSize() {
    return this.predicate.get("pageSize") === undefined
      ? 5
      : Number(this.predicate.get("pageSize"));
  }

  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };

  initRowsOptionsAndPageSize = () => {
    if (store.commonStore.widthSizeMd) {
      this.setRowsOptions([8]);
      this.setPageSize(8);
    } else {
      this.setRowsOptions([20, 40, 60]);
      this.setPageSize(20);
    }
  };

  initCardRowsOptionsAndPageSize = () => {
    if (store.commonStore.widthSizeXl) {
      this.setPageSize(8);
      this.setRowsOptions([8, 8 * 2, 8 * 4, 8 * 8]);
    } else {
      this.setPageSize(6);
      this.setRowsOptions([6, 6 * 2, 6 * 4, 6 * 8]);
    }
  };

  skeletonCardArray = () => {
    return Array<string>(store.commonStore.widthSizeXl ? 8 : 6).fill("");
  };

  stockLocationParams = (location: StockLocation) => {
    const params = new URLSearchParams();
    params.append("stockLocationId", location.id);
    this.predicate.forEach((value, key) => {
      if (key !== "stockLocationId") {
        params.delete(key);
        params.append(key, value);
      }
    });
    return params;
  };

  storeParams = (location: Store) => {
    const params = new URLSearchParams();
    params.append("storeId", location.id);
    this.predicate.forEach((value, key) => {
      if (key !== "storeId") {
        params.delete(key);
        params.append(key, value);
      }
    });
    return params;
  };
}
