import {
  DialogTitle,
  DialogContent,
  Button,
  CircularProgress,
  DialogActions,
  Grid,
  IconButton,
  Tooltip,
  Paper,
  Chip,
  Dialog,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useEffect, useMemo } from "react";
import { useStyles } from "../../app/layout/style";
import {
  PaymentType,
  SalesDetail,
  SalesFormValues,
  SalesPayment,
} from "../../app/models/sale";
import { useStore } from "../../app/stores/store";
import PaymentTypeAutoComplete from "./PaymentTypeAutoComplete";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { v4 as uuid } from "uuid";
import Dropdown from "../../app/common/form/Dropdown";
import PriceInputField from "../../app/common/form/NumberInputField";
import DateInputField from "../../app/common/form/DateInputFIeld";
import { Form } from "formik";
import { CustomerGranted } from "../../app/models/customer";

interface Props {
  sales: SalesFormValues;
  details: SalesDetail[];
  customer: CustomerGranted | null;
  setSales: (sales: SalesFormValues) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  payments: SalesPayment[];
  setPayments: (payments: SalesPayment[]) => void;
  isSubmitting: boolean;
  submitForm: () => void;
  setStatus: (status: "save" | "pay" | undefined) => void;
  disabled: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  validateForm: (values?: any) => void;
  customerDepositUpdate: number;
}

export default observer(function POSPayment({
  sales,
  details,
  customer,
  setSales,
  open,
  setOpen,
  payments,
  setPayments,
  isSubmitting,
  submitForm,
  setStatus,
  disabled,
  setFieldValue,
  validateForm,
  customerDepositUpdate,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { salesStore, userStore, snackbarStore } = useStore();
  const { restricted, user, restrictedPaymentDate } = userStore;
  const { openSnackbar } = snackbarStore;
  const {
    paymentTypes,
    bankAccounts,
    totalQty,
    totalAmount,
    changeAmount,
    totalPayment,
    getCustomerDeposit,
    paymentTypesGranted,
    customerDeposit,
  } = salesStore;
  const [value, setValue] = React.useState<PaymentType | null>(null);
  const deposit = useMemo(
    () => changeAmount(payments) - totalAmount(details),
    [changeAmount, payments, totalAmount, details]
  );
  let customerDepositNew = useMemo(
    () =>
      customerDepositUpdate > 0
        ? customerDepositUpdate + customerDeposit
        : customerDeposit,
    [customerDeposit, customerDepositUpdate]
  );

  useEffect(() => {
    if (customer !== null) getCustomerDeposit(customer.id);
  }, [getCustomerDeposit, customer]);

  const handleCancel = () => {
    setPayments(payments.filter((x) => x.amount > 0));
    setFieldValue(
      `payments`,
      sales.payments.filter((x) => x.amount > 0)
    );
    setOpen(false);
    setStatus(undefined);
  };

  const handleFormPay = () => {
    let depositBePaid = sales.payments
      .filter(
        (x) =>
          x.paymentTypeId === paymentTypes.find((x) => x.type === "DEPOSIT")?.id
      )
      .reduce((total, currentData) => (total = total + currentData.amount), 0);
    if (depositBePaid > customerDepositNew)
      openSnackbar("Deposit amount is invalid.", "error");
    else {
      setStatus("save");
      submitForm();
    }
  };

  const handleFormSave = () => {
    setStatus("save");
    submitForm();
  };

  const handleAddItems = () => {
    if (value) {
      const id = uuid();
      let amount = 0;

      if (value.type === "DEPOSIT") {
        amount = customerDepositNew;
      } else
        amount = value.isVerified
          ? totalQty(details) * (16000 as number) >
            totalAmount(details) - changeAmount(payments)
            ? totalAmount(details) - changeAmount(payments)
            : totalQty(details) * (16000 as number)
          : totalAmount(details) - changeAmount(payments);

      setPayments(
        payments.concat({
          id: id,
          salesId: sales.id,
          paymentType: value,
          date: user!.date,
          amount: amount < 0 ? 0 : amount,
        } as SalesPayment)
      );

      setSales({
        ...sales,
        payments: sales.payments.concat({
          id: id,
          salesId: sales.id,
          paymentTypeId: value.id,
          bankAccountId: value.needAccountNo ? bankAccounts[0].id : "",
          date: user!.date,
          amount: amount < 0 ? 0 : amount,
          lastUpdatedBy: user!.displayName,
        }),
      });

      setFieldValue(`payments[${payments.length}].id`, id);
      setFieldValue(`payments[${payments.length}].salesId`, sales.id);
      setFieldValue(`payments[${payments.length}].paymentTypeId`, value.id);
      setFieldValue(
        `payments[${payments.length}].bankAccountId`,
        value.needAccountNo ? bankAccounts[0].id : ""
      );
      setFieldValue(`payments[${payments.length}].date`, user!.date);
      setFieldValue(
        `payments[${payments.length}].amount`,
        amount < 0 ? 0 : amount
      );
      setFieldValue(
        `payments[${payments.length}].lastUpdatedBy`,
        user!.displayName
      );
    }
    setValue(null);
    validateForm();
  };

  const handleDeleteItem = (payment: SalesPayment) => {
    setPayments(payments.filter((x) => x.id !== payment.id));
    setSales({
      ...sales,
      payments: sales.payments.filter((x) => x.id !== payment.id),
    });
    setFieldValue(
      `payments`,
      sales.payments.filter((x) => x.id !== payment.id)
    );
  };

  const handleAmountChange = (
    e: ChangeEvent<{
      value: unknown;
    }>,
    index: number
  ) => {
    const amount = Number(e.target.value);
    let items = [...payments];
    let item = { ...payments[index] };

    item.amount = amount;
    items[index] = item;
    setPayments(items);
  };

  const closeDialog = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (!reason) setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      maxWidth="lg"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>POS Payment</Grid>
          <Grid item>
            <div className={classes.chip}>
              <Chip
                variant="outlined"
                color="primary"
                label={`Total Amount: ${totalAmount(details).toLocaleString(
                  "en-US"
                )}`}
              />
              {totalPayment(payments) > 0 && (
                <Chip
                  variant="outlined"
                  color="primary"
                  label={`Total Payment: ${totalPayment(
                    payments
                  ).toLocaleString("en-US")}`}
                />
              )}
              {deposit > 0 && (
                <Chip
                  variant="outlined"
                  color="secondary"
                  label={`Deposit: ${deposit.toLocaleString("en-US")}`}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Form autoComplete="off">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item md={6} xs={12}>
              <Typography variant="h6" component="p">
                Add Payment Type :
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs>
                  <PaymentTypeAutoComplete
                    options={paymentTypesGranted(
                      customerDepositUpdate,
                      customer
                    )}
                    value={value}
                    setValue={setValue}
                    loading={isSubmitting}
                  />
                </Grid>
                <Grid item>
                  <Tooltip title="Add Items">
                    <IconButton size="small" onClick={handleAddItems}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {payments.map((payment, index) => {
            const restrictedDate = restricted(payment.date)
              ? true
              : !payment.paymentType.needAccountNo &&
                payment.paymentType.type !== "SIMOLEX";

            return (
              <Paper
                key={index}
                className={classes.itemCard}
                variant="outlined"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Chip
                      variant="outlined"
                      color="primary"
                      label={payment.paymentType.type}
                    />
                  </Grid>
                  <Grid item md xs={6}>
                    <DateInputField
                      label="Date"
                      name={`payments[${index}].date`}
                      placeholder="Please input date"
                      disabled={isSubmitting || restrictedDate}
                      inputVariant="standard"
                      minDate={restrictedPaymentDate()}
                      maxDate={user!.date}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item md xs={12}>
                    <Dropdown
                      options={bankAccounts.map((x) => {
                        return {
                          text: `${x.owner} - ${x.bankName} - ${x.accountNo}`,
                          value: x.id,
                        };
                      })}
                      name={`payments[${index}].bankAccountId`}
                      placeholder="Bank Account"
                      label="Bank Account"
                      disabled={
                        isSubmitting ||
                        !payment.paymentType.needAccountNo ||
                        restricted(payment.inputDate)
                      }
                      margin="dense"
                      disableDefaultSelected={payment.paymentType.needAccountNo}
                    />
                  </Grid>
                  <Grid item md xs>
                    <PriceInputField
                      variant="standard"
                      label="Amount"
                      name={`payments[${index}].amount`}
                      margin="dense"
                      disabled={isSubmitting || restricted(payment.inputDate)}
                      onChange={(e) => handleAmountChange(e, index)}
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip title="Delete Item">
                      <IconButton
                        component="div"
                        disabled={isSubmitting || restricted(payment.inputDate)}
                        size="small"
                        onClick={() => handleDeleteItem(payment)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleCancel()}
          color="primary"
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleFormSave()}
          color="primary"
          disabled={isSubmitting || sales.payments.length > 0}
        >
          Save
        </Button>
        <Button
          onClick={() => handleFormPay()}
          color="primary"
          disabled={isSubmitting || sales.payments.length === 0 || disabled}
        >
          {isSubmitting && (
            <CircularProgress
              className={classes.progress}
              size={16}
              color="inherit"
            />
          )}
          Pay
        </Button>
      </DialogActions>
    </Dialog>
  );
});
