import Collapse from "@material-ui/core/Collapse";
import { observer } from "mobx-react-lite";
import { MasterMenu } from "../models/masterMenu";
import ParentMenu from "./ParentMenu";

interface Props {
  collapse: boolean;
  childs: MasterMenu[] | undefined;
}

export default observer(function ChildMenu({ collapse, childs }: Props) {
  return (
    <Collapse in={collapse} timeout="auto" unmountOnExit>
      {childs &&
        childs.map((child) => (
          <ParentMenu
            key={child.id}
            menu={child}
            nested={true}
            bold={false}
            activeOnlyWhenExact={false}
          />
        ))}
    </Collapse>
  );
});
