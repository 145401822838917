import { TableHead, TableSortLabel } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStyles } from "../../layout/style";
import { HeadCell, Order } from "../../models/table";
import { StyledTableCell, StyledTableRow } from "./EnhancedTableHead";

interface Props<T> {
  headCells: HeadCell<T>[];
  order: Order;
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  disableSort: boolean;
}

export default observer(function ReportTableHead<T>(props: Props<T>) {
  const classes = useStyles();
  const { headCells, order, orderBy, onRequestSort, disableSort } = props;

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) =>
          headCell.label === "" ? (
            <StyledTableCell
              key={headCell.id}
              padding="checkbox"
            ></StyledTableCell>
          ) : (
            <StyledTableCell
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.disableSort || disableSort ? (
                headCell.label.toLocaleUpperCase()
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={(e) => onRequestSort(e, headCell.id)}
                >
                  {headCell.label.toLocaleUpperCase()}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              )}
            </StyledTableCell>
          )
        )}
      </StyledTableRow>
    </TableHead>
  );
});
