import {
  Grid,
  Button,
  Divider,
  LinearProgress,
  Typography,
  Paper,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import { useState, useRef, useEffect } from "react";
import ButtonExcel from "../../../app/common/export/ButtonExcel";
import Filter from "../../../app/common/filter/Filter";
import DateInputField from "../../../app/common/form/DateInputFIeld";
import Dropdown2 from "../../../app/common/form/Dropdown2";
import ButtonPrint from "../../../app/common/print/ButtonPrint";
import ComponentToPrint from "../../../app/common/print/ComponentToPrint";
import { useStyles } from "../../../app/layout/style";
import Title from "../../../app/layout/Title";
import { ProfitAndLossSearch } from "../../../app/models/report";
import { useStore } from "../../../app/stores/store";
import ReportSpendingTable from "./ReportSpendingTable";

export default observer(function ReportSpending() {
  const classes = useStyles();
  const { userStore, spendingListStore } = useStore();
  const { storeGranted } = userStore;
  const {
    setPredicate,
    loadSpending,
    storeList,
    spendingRegistry,
    loading,
    filter,
  } = spendingListStore;
  const [search, setSearch] = useState(new ProfitAndLossSearch());
  const [open, setOpen] = useState(false);
  let componentRef = useRef(null);

  useEffect(() => {
    loadSpending();
  }, [loadSpending]);

  const handleSetSearch = () => {
    setSearch(filter);
  };

  const handleSearchClick = (values: ProfitAndLossSearch) => {
    setPredicate(values);
  };

  return (
    <>
      <Title menuName="Report Spending">
        <>
          <ButtonExcel
            fileName="Report Spending"
            sheetName="Report Spending"
            componentToExport={componentRef}
            disabled={loading || spendingRegistry.size === 0}
          />
          <ButtonPrint
            componentToPrint={componentRef}
            disabled={loading || spendingRegistry.size === 0}
          />
          <Filter open={open} setOpen={setOpen} setSearch={handleSetSearch}>
            <div className={classes.filter}>
              <Formik
                enableReinitialize
                initialValues={search}
                onSubmit={(values) => handleSearchClick(values)}
              >
                {({ handleSubmit, values }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-end"
                      spacing={1}
                    >
                      <Grid item>
                        <Dropdown2
                          name="storeId"
                          options={storeGranted.map((x) => {
                            return { text: x.name, value: x.id };
                          })}
                          label="Store"
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          titleDefaultSelected="All Selected"
                          displayDefaultSelected
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="Start Date"
                          name="startDate"
                          maxDate={values.endDate}
                          placeholder="Please input Start Date"
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="End Date"
                          name="endDate"
                          minDate={values.startDate}
                          placeholder="Please input End Date"
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </Filter>
        </>
      </Title>
      <>
        {spendingRegistry.size > 0 &&
          storeList.map((store) => (
            <Paper className={classes.form} key={store.id}>
              <Typography variant="subtitle1" component="p">
                {store.name}
              </Typography>
              <Divider />
              {loading ? (
                <LinearProgress />
              ) : (
                <ReportSpendingTable spending={spendingRegistry.get(store)} />
              )}
            </Paper>
          ))}
        <ComponentToPrint
          title="Report Spending"
          componentToPrint={componentRef}
        >
          <>
            {spendingRegistry.size > 0 &&
              storeList.map((store) => (
                <div key={store.id}>
                  <Typography variant="subtitle1" component="p">
                    {store.name}
                  </Typography>
                  <Divider />
                  <ReportSpendingTable spending={spendingRegistry.get(store)} />
                </div>
              ))}
          </>
        </ComponentToPrint>
      </>
    </>
  );
});
