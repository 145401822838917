import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { Switch, useLocation, useRouteMatch } from "react-router-dom";
import PrivateRouteWithMenu from "../../app/layout/PrivateRouteWithMenu";
import InvoiceList from "./InvoiceList";
import POS from "./POS";
import { useReactToPrint } from "react-to-print";
import { useStore } from "../../app/stores/store";
import POSSlip from "./POSSlip";

export default observer(function Invoice() {
  const { salesStore } = useStore();
  const { updatedSale, emptyQty } = salesStore;
  let { path } = useRouteMatch();
  const componentRef = useRef(null);
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (updatedSale !== undefined) {
      if (query.get("print") === "1") handlePrint();
    }
  }, [handlePrint, query, updatedSale]);

  return (
    <>
      <Switch>
        <PrivateRouteWithMenu
          exact
          path={path}
          component={InvoiceList}
          link={path}
        />
        <PrivateRouteWithMenu
          path={`${path}/:id`}
          component={POS}
          link={path}
        />
      </Switch>
      <div style={{ display: "none" }}>
        <POSSlip componentToPrint={componentRef} sales={updatedSale} emptyQty={emptyQty} />
      </div>
    </>
  );
});
