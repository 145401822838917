import {
  Grid,
  Avatar,
  Typography,
  Tooltip,
  IconButton,
  Divider,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { QtyInputField } from "../../app/common/form/NumberInputField";
import { SalesDetail, SalesFormValues } from "../../app/models/sale";
import DeleteIcon from "@material-ui/icons/Delete";
import { ChangeEvent } from "react";
import { useStyles } from "../../app/layout/style";
import { usePosStyles } from "../../app/layout/pos";

interface Props {
  sales: SalesFormValues;
  setSales: (sales: SalesFormValues) => void;
  details: SalesDetail[];
  setDetails: (details: SalesDetail[]) => void;
  isSubmitting: boolean;
}

export default observer(function POSCart({
  sales,
  setSales,
  details,
  setDetails,
  isSubmitting,
}: Props) {
  const classes = useStyles();
  const local = usePosStyles();

  const handleQtyChange = (
    e: ChangeEvent<{
      value: unknown;
    }>,
    index: number
  ) => {
    const qty = Number(e.target.value);
    let items = [...details];
    let item = { ...details[index] };

    item.totalAmount = qty * item.price - (qty * item.price * item.disc) / 100;
    item.qty = qty;
    items[index] = item;
    setDetails(items);
  };

  const handleDeleteItem = (detail: SalesDetail) => {
    setDetails(details.filter((x) => x.id !== detail.id));
    setSales(
      new SalesFormValues({
        ...sales,
        details: sales.details.filter((x) => x.id !== detail.id),
      })
    );
  };

  return (
    <>
      {details.map((detail, index) => (
        <div key={index}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Avatar
                alt={detail.itemCode}
                src={detail.imageUrl ? detail.imageUrl : "/no-image.png"}
              />
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1" component="p">
                {detail.itemDescription}
              </Typography>
            </Grid>
            <Grid item xs>
              <QtyInputField
                variant="outlined"
                label="Qty"
                name={`details[${index}].qty`}
                margin="dense"
                andorment={detail.unitDescription}
                disabled={isSubmitting}
                onChange={(e) => handleQtyChange(e, index)}
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
              />
            </Grid>
            <Grid item>
              <Tooltip title="Delete Item">
                <IconButton
                  component="div"
                  size="small"
                  onClick={() => handleDeleteItem(detail)}
                  disabled={isSubmitting}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end"
            className={local.cart_price}
          >
            <Grid item>
              <Typography
                className={classes.price}
                align="right"
                variant="subtitle2"
                component="p"
              >
                Price: {detail.price.toLocaleString("en-US")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="right" variant="subtitle1" component="p">
                Total Amount:{" "}
                {Number.isNaN(detail.totalAmount)
                  ? 0
                  : detail.totalAmount.toLocaleString("en-US")}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
        </div>
      ))}
    </>
  );
});
