import { CustomerItem } from "./customer";

export interface ProfitSetup {
  id: string;
  itemId: string;
  item: CustomerItem;
  stockLocationId: string;
  stockTransactionGroupId: string;
  stockLocation: string;
  stockTransactionGroup: string;
  type: string;
  amount: number;
}

export class ProfitSetupFormValues {
  id?: string = undefined;
  itemId: string = "";
  stockLocationId: string = "";
  stockTransactionGroupId: string = "";
  type: string = "";
  amount: number = Number.NaN;

  constructor(setup?: ProfitSetupFormValues) {
    if (setup) {
      this.id = setup.id;
      this.itemId = setup.itemId;
      this.stockLocationId = setup.stockLocationId;
      this.stockTransactionGroupId = setup.stockTransactionGroupId;
      this.type = setup.type;
      this.amount = setup.amount;
    }
  }
}
