import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import InputField from "../../app/common/form/InputField";
import { useStyles } from "../../app/layout/style";
import { ProfileFormValues } from "../../app/models/user";
import { useStore } from "../../app/stores/store";

export default observer(function ChangeProfileForm() {
  const classes = useStyles();
  const {
    userStore: { user, changeProfile },
    snackbarStore: { openSnackbar },
  } = useStore();
  const [userForm, setUserForm] = useState<ProfileFormValues>(
    new ProfileFormValues(user!)
  );

  const schema = () => {
    return Yup.object({
      firstName: Yup.string().required("First Name is required."),
      email: Yup.string().required("Email is required.").email(),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters.")
        .matches(/[0-9]+/, "Password must contain at least one number."),
      passwordConfirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    });
  };

  const handleFormSubmit = (
    userValues: ProfileFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    changeProfile(userValues)
      .catch((error) => {
        openSnackbar(error, "error");
        setSubmitting(false);
      })
      .then((result) => {
        openSnackbar("Profile changed succeded!", "success");
        setUserForm(new ProfileFormValues(result!));
        resetForm();
      });
  };

  return (
    <Paper className={classes.form}>
      <Typography variant="h5">Form Profile</Typography>
      <Formik
        validationSchema={schema}
        enableReinitialize
        initialValues={userForm}
        onSubmit={(values, { resetForm, setSubmitting }) =>
          handleFormSubmit(values, resetForm, setSubmitting)
        }
      >
        {({ handleSubmit, isSubmitting, isValid, dirty }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <InputField
                  variant="standard"
                  label="First Name"
                  name="firstName"
                  placeholder="Please input first name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={isSubmitting}
                />
                <InputField
                  variant="standard"
                  label="Last Name"
                  name="lastName"
                  placeholder="Please input last name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={isSubmitting}
                />
                <InputField
                  variant="standard"
                  label="Email"
                  name="email"
                  placeholder="Please input email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  variant="standard"
                  label="Current Password"
                  name="oldPassword"
                  placeholder="Please input your password"
                  type="password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={isSubmitting}
                />
                <InputField
                  variant="standard"
                  label="New Password"
                  name="password"
                  placeholder="Please input your new password"
                  type="password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={isSubmitting}
                />
                <InputField
                  variant="standard"
                  label="Password Confirmation"
                  name="passwordConfirmation"
                  placeholder="Please input confirmation password"
                  type="password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={isSubmitting}
                />

                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="stretch"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      {isSubmitting && (
                        <CircularProgress
                          className={classes.progress}
                          size={16}
                          color="inherit"
                        />
                      )}
                      Save
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="default"
                      component={Link}
                      to="/mastermenu"
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
});
