export class ManageMenuFormValues {
  roleId: string = "";
  menuId: string = "";

  constructor(menu?: ManageMenuFormValues) {
    if (menu) {
      this.roleId = menu.roleId;
      this.menuId = menu.menuId;
    }
  }
}
