import {
  action,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import {
  DashboardInfo,
  SalesPaymentChart,
  SalesRevenueAndCredit,
} from "../models/dashboard";
import { TableStore } from "./tableStore";

export default class DashboardStore extends TableStore<SalesPaymentChart> {
  date = new Date();
  loadingDashboardInfo = false;
  loadingSalesRevenue = false;
  loadingPaymentChart = false;
  info: DashboardInfo | undefined = undefined;
  revenueAndCredit: SalesRevenueAndCredit[] = [];
  paymentChart: SalesPaymentChart[] = [];

  constructor() {
    super();

    makeObservable(this, {
      date: observable,
      loadingDashboardInfo: observable,
      loadingSalesRevenue: observable,
      loadingPaymentChart: observable,
      info: observable,
      revenueAndCredit: observable,
      paymentChart: observable,
      setDate: action,
      loadDashboardInfo: action,
      loadPaymentChart: action,
    });

    reaction(
      () => this.date,
      () => {
        this.loadDashboardInfo();
        this.loadSalesRevenueAndCredit();
        this.loadPaymentChart();
      }
    );
  }

  get filter() {
    return this.date;
  }

  setDate = (date: Date) => {
    this.date = date;
  };

  get params() {
    const params = new URLSearchParams();
    params.append("date", this.date.toLocaleDateString("EN-US"));

    return params;
  }

  loadDashboardInfo = async () => {
    this.loadingDashboardInfo = true;

    try {
      const result = await agent.Dashboard.info(this.params);
      runInAction(() => {
        this.info = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingDashboardInfo = false));
    }
  };

  loadSalesRevenueAndCredit = async () => {
    runInAction(() => (this.loadingSalesRevenue = true));

    try {
      const result = await agent.Dashboard.revenue_credit(this.params);
      runInAction(() => {
        this.revenueAndCredit = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingSalesRevenue = false));
    }
  };

  loadPaymentChart = async () => {
    runInAction(() => (this.loadingPaymentChart = true));

    try {
      const result = await agent.Dashboard.salesPaymentChart(this.params);
      runInAction(() => {
        this.paymentChart = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingPaymentChart = false));
    }
  };
}
