import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { useStyles } from "../../app/layout/style";
import { PaymentType } from "../../app/models/sale";

interface Props {
  options: PaymentType[] | undefined;
  value: PaymentType | null;
  setValue: (value: PaymentType | null) => void;
  optionDisabled?: PaymentType[];
  loading: boolean;
}

export default function PaymentTypeAutoComplete({
  options,
  value,
  setValue,
  optionDisabled,
}: Props) {
  const classes = useStyles();
  const handleOptionDisabled = (item: PaymentType) => {
    if (optionDisabled) return optionDisabled.some((x) => x.id === item.id);
    else return false;
  };

  return (
    <FormControl fullWidth margin="dense" className={classes.icon}>
      <Autocomplete
        id="search-items"
        options={options === undefined ? [] : options}
        getOptionLabel={(option) => option.type}
        getOptionDisabled={(option) => handleOptionDisabled(option)}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue !== "string") {
            setValue(newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Payment Type"
            placeholder="Please select any payment type."
            margin="dense"
          />
        )}
      />
    </FormControl>
  );
}
