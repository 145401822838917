import {
  Paper,
  Typography,
  Divider,
  Grid,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import { Link, useHistory, useParams } from "react-router-dom";
import Dropdown from "../../app/common/form/Dropdown";
import PriceInputField from "../../app/common/form/NumberInputField";
import { useStyles } from "../../app/layout/style";
import { CustomerItem } from "../../app/models/customer";
import {
  ProfitSetup,
  ProfitSetupFormValues,
} from "../../app/models/profitSetup";
import { useStore } from "../../app/stores/store";
import ItemsAutoComplete from "../Customers/ItemsAutoComplete";

export default observer(function ProfitSetupForm() {
  const classes = useStyles();
  const {
    userStore,
    itemStore,
    profitSetupStore,
    stockOutgoingStore,
    snackbarStore,
  } = useStore();
  const { stockLocationGranted } = userStore;
  const { itemsDropDown, loadDropDown, loading: loadingItems } = itemStore;
  const { stockTransactionGroup, loadTransactionGroup } = stockOutgoingStore;
  const { loading, createSetup, updateSetup, loadSetup } = profitSetupStore;
  const [setup, setSetup] = useState<ProfitSetupFormValues>(
    new ProfitSetupFormValues()
  );
  const { openSnackbar } = snackbarStore;
  const [item, setItem] = useState<CustomerItem | null>(null);
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadDropDown();
    loadTransactionGroup();
    if (id) {
      loadSetup(id).then((result) => {
        loadSetupResult(result);
      });
    }
  }, [id, loadSetup, loadDropDown, loadTransactionGroup]);

  const loadSetupResult = (result: ProfitSetup | undefined) => {
    if (result) {
      setItem(result.item);
      setSetup(
        new ProfitSetupFormValues({
          id: result.id,
          itemId: result.itemId,
          stockLocationId: result.stockLocationId,
          stockTransactionGroupId: result.stockTransactionGroupId,
          type: result.type,
          amount: result.amount,
        })
      );
    }
  };

  const schema = Yup.object({
    stockLocationId: Yup.string().required("Stock Location is required."),
    itemId: Yup.string().nullable().required("Item is required."),
    stockTransactionGroupId: Yup.string().required(
      "Stock Transaction is required."
    ),
    type: Yup.string().required("Type is required."),
    amount: Yup.number()
      .typeError("Amount is must be a number.")
      .positive("Amount is must be greater than zero.")
      .required("Amount is required."),
  });

  const handleItemChange = (
    item: CustomerItem | null,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (item) {
      setItem(item);
      setFieldValue("itemId", item.id);
    } else {
      setItem(null);
      setFieldValue("itemId", null);
    }
  };

  const handleFormSubmit = (
    setup: ProfitSetupFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!setup.id) {
      let newSetup = {
        ...setup,
        id: uuid(),
      };
      createSetup(newSetup)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setSetup(new ProfitSetupFormValues());
            setItem(null);
            resetForm();
          }
        });
    } else {
      updateSetup(setup)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/profitsetup");
          }
        });
    }
  };

  return (
    <>
      <Paper className={classes.form}>
        <Typography variant="h5">Form {id ? "Update" : "Create"}</Typography>
        <Divider />
        <Formik
          validationSchema={schema}
          enableReinitialize
          initialValues={setup}
          onSubmit={(values, { resetForm, setSubmitting }) =>
            handleFormSubmit(values, resetForm, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldValue,
            errors,
            touched,
            setFieldTouched,
          }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Dropdown
                    options={stockLocationGranted
                      .filter((x) => x.isSPBE)
                      .map((x) => {
                        return { text: x.name, value: x.id };
                      })}
                    name="stockLocationId"
                    placeholder="Stock Location"
                    label="Stock Location"
                    disabled={isSubmitting || loading}
                  />
                  <ItemsAutoComplete
                    name="itemId"
                    options={itemsDropDown.filter(
                      (x) => !x.itemCode.includes("(K)")
                    )}
                    value={item}
                    setValue={setItem}
                    loading={isSubmitting || loadingItems || loading}
                    onChange={handleItemChange}
                    setFieldValue={setFieldValue}
                    variant="standard"
                    error={errors.itemId}
                    touched={touched.itemId}
                    setFieldTouched={setFieldTouched}
                  />
                  <Dropdown
                    options={stockTransactionGroup
                      .filter((x) => !x.isSPBE)
                      .map((x) => {
                        return { text: x.code, value: x.id };
                      })}
                    name="stockTransactionGroupId"
                    placeholder="Stock Transaction Group"
                    label="Stock Transaction Group"
                    disabled={isSubmitting || loading}
                  />
                  <Dropdown
                    options={[
                      { text: "Profit", value: "Profit" },
                      { text: "Loss", value: "Loss" },
                    ]}
                    name="type"
                    placeholder="Type"
                    label="Type"
                    disabled={isSubmitting || loading}
                  />
                  <PriceInputField
                    label="Amount"
                    name="amount"
                    disabled={isSubmitting || loading}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="stretch"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    {isSubmitting && (
                      <CircularProgress
                        className={classes.progress}
                        size={16}
                        color="inherit"
                      />
                    )}
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    component={Link}
                    to="/profitsetup"
                    disabled={isSubmitting || loading}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
});
