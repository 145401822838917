import {
  action,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { CustomerDeposit, CustomerDepositSearch } from "../models/report";
import { TableStore } from "./tableStore";

export default class CustomerDepositStore extends TableStore<CustomerDeposit> {
  customerDepositRegistry: CustomerDeposit[] = [];

  constructor() {
    super();

    makeObservable(this, {
      customerDepositRegistry: observable,
      loadReportCustomerDeposit: action,
    });

    this.setPredicate(new CustomerDepositSearch());

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadReportCustomerDeposit();
      }
    );
  }

  setPredicate = (search: CustomerDepositSearch) => {
    runInAction(() => {
      Object.keys(search).forEach((key) => {
        this.predicate.delete(key as keyof CustomerDepositSearch);
      });
      this.predicate.set("customerName", search.customerName);
      this.predicate.set(
        "storeId",
        search.storeId === "All" ? "" : search.storeId
      );
    });
  };

  get filter() {
    return new CustomerDepositSearch({
      storeId:
        this.predicate.get("storeId") === undefined ||
        this.predicate.get("storeId") === ""
          ? "All"
          : this.predicate.get("storeId")!,
      customerName:
        this.predicate.get("customerName") === undefined
          ? ""
          : this.predicate.get("customerName")!,
    });
  }

  loadReportCustomerDeposit = async () => {
    this.loading = true;

    try {
      const result = await agent.Reports.reportCustomerDeposit(
        this.axiosParams
      );
      runInAction(() => {
        this.customerDepositRegistry = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };
}
